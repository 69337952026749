import { ProfileSimple } from 'commonTypes/authTypes';
import { SecondRow } from 'pages/profiles/manageProfiles/SecondRow';
import { ThirdRow } from 'pages/profiles/manageProfiles/ThirdRow';

type SecondaryProfileItemTextProps = {
  profile: ProfileSimple;
};

export const SecondaryProfileItemText = ({
  profile,
}: SecondaryProfileItemTextProps) => {
  return (
    <>
      <SecondRow profile={profile} />
      <ThirdRow profile={profile} />
    </>
  );
};
