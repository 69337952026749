import { useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'context/providers/AuthProvider';
import { DialogBase } from 'components/Dialog/DialogBase';
import { ButtonBase } from 'components/Button/ButtonBase';
import { downloadPdf } from 'api/examinations/examinationsApi';
import { SelectedExamination } from 'commonTypes/examinationTypes';
import { DownloadInProgress } from 'pages/examinations/pdfDownload/DownloadInProgress';

type PdfDownloadDialogProps = {
  selectedExaminations: SelectedExamination[];
  naturalId?: string | undefined;
  open: boolean;
  close: () => void;
};

export const PdfDownloadDialog = ({
  selectedExaminations,
  naturalId,
  open,
  close,
}: PdfDownloadDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeProfile } = useAuth();
  const [downloadError, setDownloadError] = useState(false);
  const [loading, setLoading] = useState(false);

  const examinationIds = selectedExaminations.map(
    (examination) => examination.id
  );

  const requestData = {
    ids: examinationIds,
  };

  const handleClose = () => {
    setDownloadError(false);
    close();
  };

  const handleDownload = (anonymous: boolean) => {
    setDownloadError(false);
    setLoading(true);
    if (activeProfile?.profileId) {
      try {
        downloadPdf(
          activeProfile.profileId,
          requestData,
          naturalId,
          anonymous
        ).then(() => {
          handleClose();
          setTimeout(() => {
            setLoading(false);
          }, 400);
        });
      } catch (error) {
        setLoading(false);
        setDownloadError(true);
      }
    }
  };

  return (
    <DialogBase
      open={open}
      onClose={handleClose}
      disabled={loading}
      id="pdf-download-dialog"
    >
      <div className={classes.container}>
        {loading ? (
          <DownloadInProgress />
        ) : (
          <>
            <Typography className={classes.title}>
              {t('examinations.pdf-type')}
            </Typography>
            {downloadError && (
              <Alert
                id="pdf-download-error"
                className={classes.alert}
                severity="error"
              >
                {t('error')}
              </Alert>
            )}
            <ButtonBase
              fullWidth
              onClick={() => handleDownload(false)}
              className={classes.button}
              id="normal-pdf"
            >
              {t('examinations.pdf-normal')}
            </ButtonBase>
            <ButtonBase
              fullWidth
              onClick={() => handleDownload(true)}
              className={classes.button}
              id="anonymized-pdf"
            >
              {t('examinations.pdf-anonymized')}
            </ButtonBase>
          </>
        )}
      </div>
    </DialogBase>
  );
};

PdfDownloadDialog.defaultProps = {
  naturalId: undefined,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
    color: theme.palette.primary.darkmode,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  alert: {
    width: '100%',
  },
}));
