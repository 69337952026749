import * as Yup from 'yup';
import i18n from 'translations/i18n';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const validateDateMin = (date: string | undefined | null) => {
  if (!date) return true;
  const min = new Date(1900, 0, 1);
  return new Date(date) > min;
};

const validateDateMax = (date: string | undefined | null) => {
  if (!date) return true;
  const max = new Date();
  return new Date(date) < max;
};

const validatePhoneNumber = (phoneNumber: string | null | undefined) => {
  let valid = true;
  if (!phoneNumber) return valid;
  try {
    if (phoneNumber.length <= 5 && phoneNumber.length > 3) {
      valid = false;
    }
    // some contry codes have 5 digits
    if (phoneNumber.length > 5) {
      const region = phoneUtil.getRegionCodeForNumber(
        phoneUtil.parse(phoneNumber)
      );
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber, region);
      valid = phoneUtil.isValidNumberForRegion(number, region);
    }
  } catch (error) {
    valid = false;
  }
  return valid;
};

export const emailValidation = () =>
  Yup.string()
    .email(i18n.t('validation.invalid-email'))
    .required(i18n.t('validation.required-email'));

export const passwordValidation = () =>
  Yup.string()
    .required(i18n.t('validation.required-password'))
    .matches(
      /^(?=.*[0-9])(?=.*[a-z-żźćńółęąś])(?=.*[A-Z-ŻŹĆŃÓŁĘĄŚ])(?=.*[*.!@#$%^&(){}[\]:;'"<>,.?/~`_+\-=|\\]).{8,}$/,
      i18n.t('validation.invalid-password')
    );

export const firstNameValidation = () =>
  Yup.string()
    .required(i18n.t('validation.required-firstName'))
    .min(2, i18n.t('validation.firstName-length'))
    .max(32, i18n.t('validation.firstName-length'))
    .matches(
      /^[^0-9!@#$%^&(){}[\]:;"<>,.?/~`_+=|\\]+$/,
      i18n.t('validation.invalid-firstName')
    );

export const lastNameValidation = () =>
  Yup.string()
    .required(i18n.t('validation.required-lastName'))
    .min(2, i18n.t('validation.lastName-length'))
    .max(32, i18n.t('validation.lastName-length'))
    .matches(
      /^[^0-9!@#$%^&(){}[\]:;"<>,.?/~`_+=|\\]+$/,
      i18n.t('validation.invalid-lastName')
    );

export const dateOfBirthValidation = () =>
  Yup.string()
    .nullable()
    .test(
      'Check dateOfBirth min',
      i18n.t('validation.invalid-dateOfBirth-min'),
      validateDateMin
    )
    .test(
      'Check dateOfBirth max',
      i18n.t('validation.invalid-dateOfBirth-max'),
      validateDateMax
    )
    .required(i18n.t('validation.required-dateOfBirth'));

export const heightValidation = () =>
  Yup.number()
    .required(i18n.t('validation.required-height'))
    .min(0, i18n.t('validation.invalid-height'))
    .max(250, i18n.t('validation.invalid-height'));

export const weightValidation = () =>
  Yup.number()
    .required(i18n.t('validation.required-weight'))
    .min(0, i18n.t('validation.invalid-weight'))
    .max(200, i18n.t('validation.invalid-weight'));

export const phoneNumberValidation = () =>
  Yup.string()
    .nullable()
    .test(
      'Check phoneNumber',
      i18n.t('validation.invalid-phoneNumber'),
      validatePhoneNumber
    );

export const consentValidation = () =>
  Yup.bool().oneOf([true], i18n.t('validation.required-consent'));
