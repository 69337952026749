import { Dispatch, SetStateAction } from 'react';
import { RangeSlider } from 'components/Slider/RangeSlider';
import { useTranslation } from 'react-i18next';
import { SliderProps } from '@material-ui/core';
import { ahiMarks } from 'pages/examinations/examinationsList/examTableActions/filters/sliderMarks';

type AhiSliderProps = Omit<SliderProps, 'value'> & {
  value: number[];
  onChangeValue: Dispatch<SetStateAction<number[]>>;
};

export const AhiSlider = ({ value, marks, ...props }: AhiSliderProps) => {
  const { t } = useTranslation();

  return (
    <RangeSlider
      id="ahi-slider"
      label={t('examinations.ahi')}
      marks={ahiMarks}
      max={30}
      value={value}
      {...props}
    />
  );
};
