import { makeStyles, CheckboxProps } from '@material-ui/core';
import { CheckboxWithLabel } from 'components/Input/CheckboxWithLabel';

type AgreementsCheckboxProps = Omit<CheckboxProps, 'size'> & {
  label: any;
};

export const AgreementsCheckbox = ({
  label,
  ...props
}: AgreementsCheckboxProps) => {
  const classes = useStyles();

  return (
    <CheckboxWithLabel
      className={classes.container}
      checkboxClass={classes.checkbox}
      labelClickable={false}
      label={label}
      smallFont
      size={28}
      {...props}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
    marginRight: 0,
    alignItems: 'flex-start',
  },
  checkbox: {
    paddingTop: 0,
  },
}));
