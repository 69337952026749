import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VisibilityOn } from 'icons/VisibilityOn.svg';
import { ReactComponent as VisibilityOff } from 'icons/VisibilityOff.svg';
import { ReactComponent as Lock } from 'icons/Lock.svg';
import { TextFieldWithIcon } from 'components/Input/TextFieldWithIcon';
import { InputAdornment, IconButton, makeStyles } from '@material-ui/core';

export const PasswordInput = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <TextFieldWithIcon
      id="password"
      icon={<Lock />}
      type={showPassword ? 'text' : 'password'}
      name="password"
      label={t('password')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleShowPassword}
              className={classes.iconButton}
            >
              {showPassword ? <VisibilityOn /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
