import { useCallback, useState, useMemo } from 'react';

export const useYAxisData = (negative: boolean) => {
  const positiveValues = useMemo(() => [0, 25, 50, 75, 100], []);
  const positiveAndNegativeValues = useMemo(
    () => [-100, -75, -50, -25, 0, 25, 50, 75, 100],
    []
  );
  let initialTicks: number[];
  if (negative) {
    initialTicks = positiveAndNegativeValues;
  } else {
    initialTicks = positiveValues;
  }
  const [highestValue, setHighestValue] = useState(120);
  const [yAxisTicks, setYAxisTicks] = useState(initialTicks);

  const createYAxisData = useCallback(() => {
    if (highestValue > 120 && !yAxisTicks.includes(highestValue)) {
      setYAxisTicks([...initialTicks, highestValue]);
    }
  }, [highestValue, initialTicks, yAxisTicks]);

  return { yAxisTicks, createYAxisData, setHighestValue, highestValue };
};
