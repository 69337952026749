import { RadialBar, PolarAngleAxis } from 'recharts';
import { makeStyles } from '@material-ui/core';
import theme from 'theme';
import { matchSleepScoreColor, colorEnum } from 'utils/matchSleepScoreColor';

type BaseCircleChartProps = {
  metric: {
    label: string;
    value: number | null | undefined;
    range: number[] | undefined;
    unit: string;
  };
  roundedValue: number | undefined | null;
  circleSize: number;
  innerLabelClass: string;
};

export const BaseCircleChart = ({
  circleSize,
  metric,
  roundedValue,
  innerLabelClass,
}: BaseCircleChartProps) => {
  const classes = useStyles();

  let color;

  if (roundedValue === null) {
    color = colorEnum.TRANSPARENT;
  }

  if (
    roundedValue !== undefined &&
    roundedValue !== null &&
    metric.range !== undefined
  ) {
    color = matchSleepScoreColor(roundedValue, metric.range);
  }

  return (
    <>
      <PolarAngleAxis
        type="number"
        domain={[0, 100]}
        angleAxisId={0}
        tick={false}
      />
      {metric.range && color && (
        <RadialBar
          background={{
            fill: theme.palette.primary.background,
          }}
          dataKey="value"
          cornerRadius={circleSize / 2}
          className={classes[color]}
        />
      )}
      {roundedValue !== undefined && (
        <>
          <text
            id={`${metric.label}-inner-label`}
            className={innerLabelClass}
            x={circleSize / 2}
            y={circleSize / 2}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {roundedValue === null ? '-' : `${roundedValue}${metric.unit}`}
          </text>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  GREEN: {
    fill: theme.palette.success.darkmode,
  },
  YELLOW: {
    fill: theme.palette.warning.darkmode,
  },
  ORANGE: {
    fill: theme.palette.warning.main,
  },
  RED: {
    fill: theme.palette.error.dark,
  },
  TRANSPARENT: {
    fill: 'transparent',
  },
}));
