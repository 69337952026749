import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { round } from 'lodash';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getExaminationById } from 'api/examinations/examinationsApi';
import { useAuth } from 'context/providers/AuthProvider';
import { useConfig } from 'context/providers/ConfigProvider';
import { formatDurationInSeconds } from 'utils/helpers';
import { Examination } from 'commonTypes/examinationTypes';
import { CustomTooltip } from 'components/Tooltip/CustomTooltip';
import { TooltipContent } from 'pages/examinations/examinationsList/TooltipContent';
import { ValueColorIndicator } from 'pages/examinations/examinationsList/ValueColorIndicator';
import { ReactComponent as TooltipIcon } from 'icons/Tooltip.svg';

const initialData: Pick<Examination, 'startDateTime' | 'sleepScore'> = {
  startDateTime: '',
  sleepScore: { totalSleepTime: undefined, ahi: undefined },
};

export const ExaminationData = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeProfile } = useAuth();
  const { colorRanges } = useConfig();
  const { examinationId } = useParams<{ examinationId: string }>();
  const [examinationData, setExaminationData] = useState(initialData);

  useEffect(() => {
    setExaminationData(initialData);
    if (examinationId && activeProfile?.profileId) {
      getExaminationById(activeProfile.profileId, examinationId)
        .then((data) => {
          if (data.sleepScore) {
            setExaminationData(data);
          }
        })
        .catch(() => setExaminationData(initialData));
    }
  }, [activeProfile?.profileId, examinationId]);

  const {
    startDateTime,
    sleepScore: { ahi, totalSleepTime },
  } = examinationData;

  const ahiTooltipData = {
    name: 'ahi',
    tooltip: {
      text: t('examinations.ahi-tooltip'),
      range: colorRanges.ahi,
    },
  };

  return (
    <div className={classes.examinationMetricsContainer}>
      <div id="examination-date" className={classes.dataBox}>
        <Typography className={classes.label}>{t('date')}</Typography>
        {startDateTime ? (
          <Typography variant="body2" className={classes.dateItem}>
            {DateTime.fromISO(startDateTime).toFormat('dd/LL/yyyy')}
          </Typography>
        ) : (
          <div className={classes.placeholder} />
        )}
      </div>
      <div id="examination-ahi" className={classes.dataBox}>
        <div className={classes.labelWithTooltip}>
          <Typography className={classes.label}>
            {t('examinations.ahi')}
          </Typography>
          <CustomTooltip
            title={<TooltipContent data={ahiTooltipData} />}
            id="ahi-tooltip"
          >
            <TooltipIcon className={classes.icon} id="ahi-tooltip-icon" />
          </CustomTooltip>
        </div>
        {ahi !== undefined && ahi !== null && (
          <div className={classes.ahiValue}>
            {colorRanges.ahi && (
              <ValueColorIndicator
                id="ahi-color-indicator"
                value={round(ahi, 1)}
                range={colorRanges.ahi}
              />
            )}
            <Typography variant="body2">{ahi && round(ahi, 1)}</Typography>
          </div>
        )}
      </div>
      <div id="examination-sleep-time" className={classes.dataBox}>
        <Typography noWrap className={classes.label}>
          {t('examinations.total-sleep-time')}
        </Typography>
        {totalSleepTime !== undefined && totalSleepTime !== null && (
          <Typography variant="body2" noWrap>
            {formatDurationInSeconds(totalSleepTime)}
          </Typography>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  dataBox: {
    margin: theme.spacing(0, 4, 0, 3),
  },
  labelWithTooltip: {
    display: 'flex',
  },
  icon: {
    width: '16px',
    height: '16px',
    marginLeft: theme.spacing(0.5),
  },
  examinationMetricsContainer: {
    display: 'flex',
  },
  ahiValue: {
    display: 'flex',
    alignItems: 'center',
  },
  placeholder: {
    visibility: 'hidden',
    width: '90px',
    height: '24px',
  },
  dateItem: {
    width: '90px',
  },
}));
