import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { DialogBase } from 'components/Dialog/DialogBase';
import { TextFieldBase } from 'components/Input/TextFieldBase';
import { CancelButton } from 'components/Button/CancelButton';
import { SubmitButton } from 'components/Button/SubmitButton';
import { CircularLoader } from 'components/Progress/CircularLoader';
import { ErrorMessage } from 'pages/auth/ErrorMessage';
import { useAuth } from 'context/providers/AuthProvider';
import { userValidationSchema } from 'pages/account/userValidationSchema';
import { editSelf } from 'api/auth/authApi';

type EditUserDialogProps = {
  open: boolean;
  close: () => void;
};

export const EditUserDialog = ({ open, close }: EditUserDialogProps) => {
  const { t } = useTranslation();
  const { user, login } = useAuth();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user!.firstName,
      lastName: user!.lastName,
      userId: user!.id,
    },
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setError(false);
      editSelf(values)
        .then((updatedUser) => {
          login(updatedUser);
          closeDialog();
          setTimeout(() => {
            setLoading(false);
          }, 100);
        })
        .catch(() => {
          resetForm();
          setLoading(false);
          setError(true);
        });
    },
    validationSchema: userValidationSchema,
  });

  const closeDialog = () => {
    close();
    setTimeout(() => {
      formik.resetForm();
      setError(false);
    }, 100);
  };

  return (
    <DialogBase
      open={open}
      onClose={closeDialog}
      title={t('account.edit-account')}
      className={classes.editProfileDialog}
      disabled={loading}
      id="edit-user-dialog"
    >
      {error && <ErrorMessage message={t('account.user-edit-error')} />}
      <TextFieldBase
        name="firstName"
        id="first-name"
        value={formik.values.firstName}
        label={t('first-name')}
        className={classes.textField}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors.firstName && formik.touched.firstName)}
        helperText={
          formik.errors.firstName && formik.touched.firstName
            ? formik.errors.firstName
            : null
        }
      />
      <TextFieldBase
        name="lastName"
        id="last-name"
        value={formik.values.lastName}
        label={t('last-name')}
        className={classes.textField}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors.lastName && formik.touched.lastName)}
        helperText={
          formik.errors.lastName && formik.touched.lastName
            ? formik.errors.lastName
            : null
        }
      />
      <TextFieldBase
        name="email"
        id="email"
        value={user!.email}
        label={t('e-mail')}
        className={classes.textField}
        disabled
      />
      <div className={classes.actions}>
        <CancelButton
          onClick={closeDialog}
          className={classes.cancelButton}
          disabled={loading}
          id="cancel-button"
        >
          {t('cancel')}
        </CancelButton>
        <SubmitButton
          onClick={formik.handleSubmit}
          disabled={!isEmpty(formik.errors) || loading}
          id="submit-button"
        >
          {loading ? <CircularLoader /> : t('confirm')}
        </SubmitButton>
      </div>
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  editProfileDialog: {
    width: '508px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
}));
