import clsx from 'clsx';
import { makeStyles, Typography, AccordionDetails } from '@material-ui/core';
import { QAItem } from 'pages/help/QAGroup';

type AnswerProps = {
  item: QAItem;
  index: number;
  group: string;
};

type ImagesProps = {
  imageList: string[];
};

const Images = ({ imageList }: ImagesProps) => {
  const classes = useStyles();

  const createRows = () => {
    const rows = [];
    for (let j = 0; j < imageList.length; j += 3) {
      const slice = imageList.slice(j, j + 3);
      rows.push(slice);
    }
    return rows;
  };

  const rows = createRows();

  return (
    <>
      {rows.map((row) => {
        return (
          <div key={row[0]} className={classes.horizontalContainer}>
            {row.map((image) => {
              return (
                <img
                  key={image}
                  className={classes.image}
                  alt={image}
                  src={image}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export const Answer = ({ item, index, group }: AnswerProps) => {
  const classes = useStyles();

  return (
    <AccordionDetails
      className={classes.details}
      id={`${group}-item-${index}-details`}
    >
      {item.answer.map((answerPart, itemIndex) => {
        return (
          // eslint-disable-next-line
          <div key={itemIndex}>
            {answerPart.bulletpoint ? (
              <ul className={classes.list}>
                <li>
                  <Typography variant="body2" className={classes.text}>
                    {answerPart.text}
                  </Typography>
                </li>
              </ul>
            ) : (
              <Typography
                variant="body2"
                className={clsx(classes.paragraph, classes.text)}
                id={`${group}-item-${index}-answer`}
              >
                {answerPart.text}
              </Typography>
            )}
            {answerPart.images && <Images imageList={answerPart.images.src} />}
          </div>
        );
      })}
    </AccordionDetails>
  );
};

const useStyles = makeStyles((theme) => ({
  details: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: '25%',
    height: '25%',
    margin: theme.spacing(3, 3, 3, 3),
    alignSelf: 'center',
    boxShadow: theme.shadows[1],
  },
  paragraph: {
    margin: theme.spacing(1, 'auto', 1, 'auto'),
    textAlign: 'justify',
  },
  list: {
    margin: theme.spacing(1, 0, 0.5, 4),
  },
  horizontalContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    width: '90%',
  },
}));
