import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';
import { ReactComponent as Male } from 'icons/GreyMale.svg';
import { ReactComponent as Female } from 'icons/GreyFemale.svg';
import { GenderEnum } from 'commonTypes/profileTypes';
import { calculateAge, valueExists } from 'utils/helpers';
import { Profile } from 'commonTypes/authTypes';
import { doNotHavePesel } from 'utils/constants';
import { ValueColorIndicator } from 'pages/examinations/examinationsList/ValueColorIndicator';
import { useExaminations } from 'context/providers/ExaminationsProvider';
import { useConfig } from 'context/providers/ConfigProvider';

type BasicProfileDataProps = {
  profileData: Profile;
};

type BasicDataValues = {
  gender: string | undefined;
  dateOfBirth: string | undefined;
  weight: number | undefined;
  height: number | undefined;
  bmi: number | undefined;
};

export const BasicProfileData = ({ profileData }: BasicProfileDataProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { examinations } = useExaminations();
  const { colorRanges } = useConfig();

  const basicData: BasicDataValues = {
    gender: undefined,
    dateOfBirth: undefined,
    weight: undefined,
    height: undefined,
    bmi: undefined,
  };

  if (profileData) {
    basicData.gender = profileData.gender;
    basicData.dateOfBirth = profileData.dateOfBirth;
    basicData.weight = profileData.weight;
    basicData.height = profileData.height;
    basicData.bmi = profileData.bmi;
  }

  const lastExamination =
    examinations.examinationsList && examinations.examinationsList[0];
  const lastSleepScore = lastExamination?.sleepScore;

  return (
    <div className={classes.dataBox} id="active-profile-data">
      {profileData.govIdentification &&
        profileData.govIdentification !== doNotHavePesel && (
          <Typography className={classes.govid} id="active-profile-govid">{`${t(
            'profiles.govid'
          )} ${profileData.govIdentification}`}</Typography>
        )}
      {Object.values(basicData).every(valueExists) && (
        <div className={classes.metrics}>
          {basicData.gender === GenderEnum.male ? (
            <Male className={classes.genderIcon} id="active-profile-male" />
          ) : (
            <Female className={classes.genderIcon} id="active-profile-female" />
          )}
          {colorRanges.sleep_score && lastSleepScore && (
            <ValueColorIndicator
              id="sleep-score-color-indicator"
              value={round(lastSleepScore, 1)}
              range={colorRanges.sleep_score}
              className={classes.colorIndicator}
            />
          )}
          <Typography
            variant="body2"
            className={classes.textField}
            id="active-profile-bmi"
          >
            <span className={classes.unit}>{`${t('profiles.bmi')} `}</span>
            {basicData.bmi}
          </Typography>
          <Typography
            variant="body2"
            className={classes.textField}
            id="active-profile-age"
          >
            {calculateAge(basicData.dateOfBirth)}
            <span className={classes.unit}>{` ${t(
              'profiles.years-old'
            )}`}</span>
          </Typography>
          <Typography
            variant="body2"
            className={classes.textField}
            id="active-profile-weight"
          >
            {basicData.weight}
            <span className={classes.unit}>{` ${t('weight-unit')}`}</span>
          </Typography>
          <Typography
            variant="body2"
            className={classes.textField}
            id="active-profile-height"
          >
            {basicData.height}
            <span className={classes.unit}>{` ${t('height-unit')}`}</span>
          </Typography>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  govid: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  genderIcon: {
    width: '18px',
    height: '20px',
    margin: theme.spacing(0, 1.5, 0, 0),
  },
  metrics: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    padding: theme.spacing(0, 1, 0, 1),
  },
  unit: {
    color: theme.palette.text.secondary,
  },
  dataBox: {
    margin: theme.spacing(0, 3, 0, 3),
  },
  colorIndicator: {
    marginRight: theme.spacing(0.5),
  },
}));
