import { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ExaminationFile } from 'commonTypes/examinationTypes';
import { ExamFilesTable } from 'pages/examinations/examinationDetails/ExamFilesTable';

type ExaminationFilesProps = {
  files: ExaminationFile[];
  examinationId: string;
  fetchExamination: () => void;
};

export const ExaminationFiles = ({
  files,
  examinationId,
  fetchExamination,
}: ExaminationFilesProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [optionalFiles, setOptionalFiles] = useState<ExaminationFile[]>([]);

  useEffect(() => {
    const requiredFiles = [
      'ZIP',
      'JSN',
      'ENV',
      'POX',
      'PAR',
      'LOG',
      'ACC',
      'ADR',
      'E8H',
      'PA2',
      'DAC',
    ];

    const filteredFiles = files.filter(
      (file) => !requiredFiles.includes(file.fileTypes)
    );
    setOptionalFiles(filteredFiles);
  }, [files]);

  return (
    <div className={classes.container}>
      <Typography variant="h3" className={classes.heading}>
        {t('examinations.additional-files')}
      </Typography>
      <Typography variant="body2">
        {t('examinations.file-upload-intro')}
      </Typography>
      <ExamFilesTable
        files={optionalFiles}
        examinationId={examinationId}
        fetchExamination={fetchExamination}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(4, 0, 4, 0),
  },
  heading: {
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[900],
  },
}));
