import { orderEnum } from 'commonTypes/otherTypes';

// eslint-disable-next-line
export enum colorEnum {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  ORANGE = 'ORANGE',
  RED = 'RED',
  TRANSPARENT = 'TRANSPARENT',
}

export const matchSleepScoreColor = (value: number, range: number[]) => {
  const order = range[0] < range[1] ? orderEnum.ASC : orderEnum.DESC;

  let color;

  if (order === orderEnum.ASC) {
    switch (true) {
      case value >= range[0] && value < range[1]:
        color = colorEnum.GREEN;
        break;
      case value >= range[1] && value < range[2]:
        color = colorEnum.YELLOW;
        break;
      case value >= range[2] && value < range[3]:
        color = colorEnum.ORANGE;
        break;
      case value >= range[3]:
        color = colorEnum.RED;
        break;
      default:
        color = colorEnum.TRANSPARENT;
    }
  } else {
    switch (true) {
      case value <= range[0] && value > range[1]:
        color = colorEnum.GREEN;
        break;
      case value <= range[1] && value > range[2]:
        color = colorEnum.YELLOW;
        break;
      case value <= range[2] && value > range[3]:
        color = colorEnum.ORANGE;
        break;
      case value <= range[3]:
        color = colorEnum.RED;
        break;
      default:
        color = colorEnum.TRANSPARENT;
    }
  }

  return color;
};
