import { useState, ChangeEvent } from 'react';
import { makeStyles, Typography, Accordion } from '@material-ui/core';
import { Question } from 'pages/help/Question';
import { Answer } from 'pages/help/Answer';

type QAContent = {
  header: string;
  questions: {
    question: string;
    answer: {
      text: string;
      images?: { src: string[] };
      bulletpoint?: boolean;
    }[];
  }[];
};

export type QAItem = {
  question: string;
  answer: {
    text: string;
    images?: { src: string[] };
    bulletpoint?: boolean;
  }[];
};

type QAGroupProps = {
  content: QAContent;
};

export const QAGroup = ({ content }: QAGroupProps) => {
  const [expanded, setExpanded] = useState<number | false>(false);
  const classes = useStyles();

  const handleChange = (index: number) => (
    event: ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? index : false);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h3" className={classes.header}>
        {content.header}
      </Typography>
      {content.questions.map((item, index) => {
        return (
          <Accordion
            className={classes.content}
            square
            expanded={expanded === index}
            onChange={handleChange(index)}
            key={item.question}
          >
            <Question item={item} index={index} group={content.header} />
            <Answer item={item} index={index} group={content.header} />
          </Accordion>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '900px',
  },
  header: {
    color: theme.palette.primary.main,
    margin: theme.spacing(2, 0, 2, 0),
  },
  content: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 'auto',
    },
  },
}));
