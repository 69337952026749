import { useConfig } from 'context/providers/ConfigProvider';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as Tooltip } from 'icons/Tooltip.svg';
import { CustomTooltip } from 'components/Tooltip/CustomTooltip';
import { TooltipContent } from 'pages/examinations/examinationsList/TooltipContent';
import { LegendItem, LabelColors } from 'charts/ChartComponents/LegendItem';
import { LegendWrapper } from 'charts/ChartComponents/LegendWrapper';

export const CombinedAhiChartLegend = () => {
  const { colorRanges } = useConfig();
  const { t } = useTranslation();
  const classes = useStyles();

  const tooltipData = [
    {
      name: 'ahi',
      tooltip: {
        text: t(`examinations.ahi-tooltip`),
        range: colorRanges.ahi,
      },
    },
  ];

  const legendItem = {
    label: t('examinations.combined-ahi'),
    color: LabelColors.DARK_YELLOW,
  };

  return (
    <LegendWrapper>
      <LegendItem label={legendItem.label} color={legendItem.color} />
      <CustomTooltip
        id="combined-ahi-chart-legend-tooltip"
        title={<TooltipContent data={tooltipData[0]} />}
        multiple
      >
        <Tooltip
          className={classes.tooltip}
          id="combined-ahi-chart-legend-tooltip-icon"
        />
      </CustomTooltip>
    </LegendWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    minWidth: '22px',
    minHeight: '22px',
    maxHeight: '22px',
    maxWidth: '22px',
    marginLeft: theme.spacing(1),
  },
}));
