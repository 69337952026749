import { Dispatch, SetStateAction } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { mainApi } from 'api/config/interceptors';
import {
  zipFileShareInExamWithWav,
  zipFileShareInExamWithoutWav,
} from 'utils/constants';
import { FileData } from 'pages/examinations/examinationFileUpload/sendExaminationFile';
import { saveAs } from 'file-saver';

export type FilterType = {
  field: string;
  operand: string;
  value: string | number | undefined;
};

export const getExaminationsList = (
  profileId: string,
  filters: FilterType[],
  source?: CancelTokenSource
) => {
  const params = {
    sortOrder: 'ASC',
    sortField: '',
    offset: 0,
    size: 0,
    filters,
  };

  return mainApi
    .get(`/profile/${profileId}/examination`, {
      cancelToken: source ? source.token : undefined,
      params: {
        request: btoa(unescape(encodeURIComponent(JSON.stringify(params)))),
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const getExaminationById = (
  profileId: string,
  examinationId: string
) => {
  return mainApi
    .get(`/profile/${profileId}/examination/${examinationId}`)
    .then((res) => {
      return res.data;
    });
};

export const findExaminationByRecordId = (
  sensorSerialNumber: string,
  recordId: string
) => {
  return mainApi
    .get(`/examination/${sensorSerialNumber}/${recordId}`)
    .then((res) => {
      return res.data;
    });
};

export const uploadExamination = (
  base64examinationId: string,
  zipFile: Blob,
  jsnFile: Blob,
  wavFile: File | undefined,
  profileId: string,
  setUploadStatus: Dispatch<SetStateAction<number>>
) => {
  const examinationData = new FormData();

  examinationData.append('file', zipFile, `${base64examinationId}.zip`);
  examinationData.append('jsn', jsnFile, `${base64examinationId}.json`);
  examinationData.append('profileId', profileId);

  let zipFileProgressShare: number;
  if (wavFile) {
    zipFileProgressShare = zipFileShareInExamWithWav;
  } else {
    zipFileProgressShare = zipFileShareInExamWithoutWav;
  }

  return mainApi
    .post(`/batch`, examinationData, {
      timeout: 1000000,
      onUploadProgress: (ProgressEvent) => {
        const progressStatus =
          (ProgressEvent.loaded / ProgressEvent.total) * zipFileProgressShare;
        if (progressStatus > 1) {
          setUploadStatus(progressStatus);
        }
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const initiateFileUpload = (
  examinationId: string,
  fileData: FileData
) => {
  return mainApi.post(`/file/${examinationId}/block`, fileData).then((res) => {
    return res.data;
  });
};

export const uploadFileBlock = (
  examinationId: string,
  examinationFileId: string,
  blockNumber: number,
  block: Blob,
  extension: string
) => {
  const fileBlock = new FormData();
  fileBlock.append('file', block, `chunk.${extension}`);

  return mainApi
    .put(
      `/file/${examinationId}/block/${examinationFileId}/${blockNumber}`,
      fileBlock
    )
    .then((res) => {
      return res.data;
    });
};

export const commitFileUpload = (
  examinationId: string,
  examinationFileId: string
) => {
  return mainApi
    .post(`/file/${examinationId}/block/${examinationFileId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const checkProcessingStatus = (examinationId: string) => {
  return mainApi.get(`/examination/${examinationId}/status`).then((res) => {
    return res.data;
  });
};

export const downloadPdf = (
  profileId: string,
  requestData: { ids: string[] },
  naturalId: string | undefined,
  anonymous: boolean
) => {
  return mainApi
    .post(`/profile/${profileId}/document`, requestData, {
      params: {
        anonymous,
      },
      responseType: 'blob',
    })
    .then((file) => {
      if (file.data && file.data.size !== 0) {
        const fileName = naturalId
          ? `${naturalId}.pdf`
          : 'examination-report.pdf';
        saveAs(file.data, fileName);
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteExamination = (examinationId: string) => {
  return mainApi.delete(`/examination/${examinationId}`).then((res) => {
    return res.data;
  });
};

export const getConfigFile = () => {
  return axios.get(`${process.env.REACT_APP_CONFIG_FILE_URL}`).then((res) => {
    return res.data;
  });
};

export const getChartData = (examinationId: string) => {
  return mainApi.get(`/examination/${examinationId}/graph`).then((res) => {
    return res.data;
  });
};

export const checkIfExamExists = (examinationId: string) => {
  const base64examinationId = btoa(examinationId);
  return mainApi
    .get(`/examination/present/${base64examinationId}`)
    .then((res) => {
      return res.data;
    });
};
