import { ReactNode } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { Drawer, makeStyles, Divider } from '@material-ui/core';
import { MenuContent, Location } from 'components/Navbar/MenuContent';
import { ProfilesList } from 'pages/profiles/manageProfiles/ProfilesList';
import { User } from 'commonTypes/authTypes';
import { useAuth } from 'context/providers/AuthProvider';

const drawerWidthExpanded = 247;
const drawerWidthClosed = 120;

type NavbarProps = {
  children: ReactNode;
};

const calculateExpanded = (user: User, location: Location) => {
  if (location.pathname === '/' || location.pathname === '/examinations-list') {
    return !user!.profile;
  }
  if (location.pathname.startsWith('/examination/')) {
    return !user!.profile;
  }
  return true;
};

export const Navbar = ({ children }: NavbarProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  const expanded = calculateExpanded(user, location);

  return (
    <div className={classes.root}>
      <div className={classes.dividerContainer}>
        <nav>
          <Drawer
            id="doctor-nav"
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: expanded,
              [classes.drawerClose]: !expanded,
            })}
            classes={{
              paper: clsx(classes.paper, {
                [classes.drawerOpen]: expanded,
                [classes.drawerClose]: !expanded,
              }),
            }}
          >
            <div className={classes.innerContainer}>
              <MenuContent expanded={expanded} />
            </div>
          </Drawer>
        </nav>
        <Divider orientation="vertical" className={classes.divider} />
      </div>
      <main className={classes.content}>
        {!expanded && <ProfilesList />}
        <div
          className={clsx(classes.children, { [classes.bigPadding]: expanded })}
          id="scrollable-content"
        >
          {children}
        </div>
      </main>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidthExpanded,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    border: 'none',
  },
  drawerOpen: {
    width: drawerWidthExpanded,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: drawerWidthClosed,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
  },
  children: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    padding: theme.spacing(3, 4, 0, 2),
    marginLeft: 0,
    // this is needed for ProfileConsentsDialog to show up in the right place
    position: 'relative',
  },
  innerContainer: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    overflow: 'auto',
  },
  bigPadding: {
    padding: theme.spacing(3, 4, 0, 4),
  },
  dividerContainer: {
    display: 'flex',
  },
  divider: {
    marginTop: theme.spacing(3),
    height: 'calc(100% - 48px)',
  },
}));
