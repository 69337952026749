import { XAxisProps } from 'recharts';

type CustomXAxisTickProps = XAxisProps & {
  payload: { value: string; index: number };
};

export const CustomXAxisTick = (props: CustomXAxisTickProps) => {
  const { x, y, fill, payload } = props;

  let labelValues: string[] = [];

  if (payload.value) {
    labelValues = payload.value.split(' ');
  }

  const dayString = labelValues.shift();

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill={fill}>
        <tspan textAnchor="middle" x="0">
          {dayString}
        </tspan>
        <tspan
          textAnchor="middle"
          x="0"
          dy="20"
          id={`${payload.index}-xaxis-tick`}
        >
          {labelValues.join(' ')}
        </tspan>
      </text>
    </g>
  );
};
