import { useEffect, useState } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
} from 'recharts';
import theme from 'theme';
import { DateTime } from 'luxon';
import { CustomResponsiveContainer } from 'charts/ChartComponents/CustomResponsiveContainer';
import { CustomXAxisTick } from 'charts/DotCharts/DotChartComponents/CustomXAxisTick';
import { FixedSizeContainer } from 'charts/ChartComponents/FixedSizeContainer';
import { SSSIChartDataItem } from 'charts/chartTypes/chartTypes';
import { formatYAxisTick } from 'charts/utils';
import { formatXAxisTick } from 'charts/DotCharts/utils';
import { ExaminationSimple } from 'commonTypes/examinationTypes';
import { TimeFrames } from 'pages/examinations/ExaminationsSummaryPage';
import { useXAxisData } from 'charts/DotCharts/useXAxisData';

type SleepScoreSnoringIndexDotChartProps = {
  data: ExaminationSimple[] | null;
  timeFrame: TimeFrames;
  dates: { from: DateTime | undefined; to: DateTime | undefined };
};

export const SleepScoreSnoringIndexDotChart = ({
  data,
  timeFrame,
  dates,
}: SleepScoreSnoringIndexDotChartProps) => {
  const [chartData, setChartData] = useState<SSSIChartDataItem[] | undefined>(
    undefined
  );
  const { xAxisDomain, xAxisTicks, createXAxisData } = useXAxisData(
    timeFrame,
    dates
  );

  useEffect(() => {
    const createChartData = () => {
      if (!data) {
        return;
      }
      const dataObjects: SSSIChartDataItem[] = [];
      data.forEach((examination) => {
        const {
          examinationId,
          sleepScore,
          snoringIndex,
          startDate,
        } = examination;
        if (sleepScore === null || snoringIndex == null) {
          return;
        }

        const luxonDate = DateTime.fromISO(startDate);

        const dotData = {
          examid: examinationId,
          name: luxonDate.toMillis(),
          sleepScore,
          snoringIndex: -snoringIndex,
        };
        dataObjects.push(dotData);
      });

      return dataObjects;
    };
    if (data) {
      setChartData(createChartData());
      createXAxisData();
    }
  }, [setChartData, data, createXAxisData]);

  return (
    <>
      <FixedSizeContainer>
        <CustomResponsiveContainer>
          <ScatterChart
            data={chartData}
            id={`ss-si-dot-chart-${timeFrame}`}
            margin={{ left: -25, right: 10, top: 2 }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3" />
            <XAxis
              dataKey="name"
              height={60}
              interval={0}
              padding={{ right: 10 }}
              stroke={theme.palette.text.secondary}
              tickFormatter={
                timeFrame === TimeFrames.MONTH ? formatXAxisTick : undefined
              }
              tick={
                timeFrame === TimeFrames.MONTH
                  ? { transform: 'translate(0, 6)' }
                  : (props) => <CustomXAxisTick {...props} />
              }
              type="number"
              domain={xAxisDomain}
              ticks={xAxisTicks}
            />
            <YAxis
              ticks={[-100, -75, -50, -25, 0, 25, 50, 75, 100]}
              tickFormatter={formatYAxisTick}
              stroke={theme.palette.text.secondary}
              domain={[-120, 120]}
            />
            <ReferenceLine y={0} />
            <Scatter
              isAnimationActive={false}
              data={chartData}
              name="sleepScore"
              dataKey="sleepScore"
              fill={theme.palette.primary.light}
              id="ss-dot"
            />
            <Scatter
              isAnimationActive={false}
              data={chartData}
              name="snoringIndex"
              dataKey="snoringIndex"
              fill={theme.palette.error.darkmode}
              id="si-dot"
            />
          </ScatterChart>
        </CustomResponsiveContainer>
      </FixedSizeContainer>
    </>
  );
};
