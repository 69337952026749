import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { makeStyles, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SelectBase } from 'components/Select/SelectBase';
import { TimeFrames } from 'pages/examinations/ExaminationsSummaryPage';
import { ExaminationSimple } from 'commonTypes/examinationTypes';

type TimeFrameSelectProps = {
  timeFrame: TimeFrames;
  selectTimeFrame: (timeframe: TimeFrames) => void;
  setChartData: Dispatch<SetStateAction<ExaminationSimple[] | null>>;
};

export const TimeFrameSelect = ({
  timeFrame,
  selectTimeFrame,
  setChartData,
}: TimeFrameSelectProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    selectTimeFrame(event.target.value as TimeFrames);
    if (timeFrame !== TimeFrames.LAST_RESULTS) {
      setChartData([]);
    }
  };

  return (
    <SelectBase
      className={classes.select}
      value={timeFrame}
      handleChange={handleChange}
      id="time-frame-select"
    >
      <MenuItem id="last-results" value={TimeFrames.LAST_RESULTS}>
        {t('examinations.14-results')}
      </MenuItem>
      <MenuItem id="month" value={TimeFrames.MONTH}>
        {t('examinations.1-month')}
      </MenuItem>
      <MenuItem id="three-months" value={TimeFrames.THREE_MONTHS}>
        {t('examinations.3-months')}
      </MenuItem>
      <MenuItem id="six-months" value={TimeFrames.SIX_MONTHS}>
        {t('examinations.6-months')}
      </MenuItem>
      <MenuItem id="year" value={TimeFrames.YEAR}>
        {t('examinations.1-year')}
      </MenuItem>
    </SelectBase>
  );
};

const useStyles = makeStyles((theme) => ({
  select: {
    marginRight: theme.spacing(3),
  },
}));
