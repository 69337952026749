import { DateTime } from 'luxon';

const getRoundedDate = (minutes: any, date: any) => {
  const ms = 1000 * 60 * minutes;
  const roundedDate = DateTime.fromMillis(Math.ceil(date.toMillis() / ms) * ms);
  return roundedDate;
};

export const createXAxisTicks = (examStart: string, examEnd: string) => {
  const startDate = DateTime.fromISO(examStart);
  const endDate = DateTime.fromISO(examEnd);

  const diff = endDate.diff(startDate, ['days', 'hours', 'minutes']);

  let tickDistance;
  let firstTick;

  if (diff.hours >= 6 || diff.days >= 1) {
    tickDistance = { hours: 1 };
    firstTick = getRoundedDate(60, startDate);
  } else if (diff.hours < 6 && diff.hours >= 3) {
    tickDistance = { minutes: 30 };
    firstTick = getRoundedDate(30, startDate);
  } else {
    tickDistance = { minutes: 15 };
    firstTick = getRoundedDate(15, startDate);
  }

  if (diff.hours >= 6) {
    tickDistance = { hours: 1 };
  } else if (diff.hours < 6 && diff.hours >= 4) {
    tickDistance = { minutes: 30 };
  } else {
    tickDistance = { minutes: 15 };
  }

  const ticks = [firstTick.toSeconds()];
  let currentLeft = firstTick;

  while (currentLeft.toSeconds() < DateTime.fromISO(examEnd).toSeconds()) {
    const tick = currentLeft.plus(tickDistance).toSeconds();
    ticks.push(tick);
    currentLeft = DateTime.fromSeconds(tick);
  }
  return ticks;
};
