const colors = [
  '#A0DCB5',
  '#97D7E5',
  '#99B4DD',
  '#AAB1EF',
  '#D5DB8E',
  '#EACB9E',
  '#F2AC96',
  '#F1AAAA',
  '#D3A3F1',
  '#F688A9',
  '#B5DE8C',
  '#8ECAE4',
];

export const generateAvatarColor = (
  firstLetter: string | undefined,
  secondLetter: string | undefined
) => {
  if (!firstLetter || !secondLetter) {
    return colors[0];
  }

  const firstAscii = firstLetter.charCodeAt(0);
  const secondAscii = secondLetter.charCodeAt(0);

  return colors[(firstAscii + secondAscii) % colors.length];
};
