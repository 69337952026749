import { ReactNode } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import clsx from 'clsx';

type FiltersAreaProps = {
  open: boolean;
  children: ReactNode;
  id: string;
};

export const FiltersContainer = ({
  open,
  children,
  ...props
}: FiltersAreaProps) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(
        classes.filtersArea,
        open ? classes.open : classes.closed
      )}
      {...props}
    >
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  filtersArea: {
    border: `1px solid ${theme.palette.primary.background}`,
    borderRadius: '16px',
  },
  open: {
    transition: 'height 0.3s',
    height: '196px',
    visibility: 'visible',
    overflow: 'hidden',
    padding: theme.spacing(2, 3, 3, 3),
    marginTop: theme.spacing(2),
  },
  closed: {
    transition: 'height 0.3s',
    height: '0px',
    visibility: 'hidden',
  },
}));
