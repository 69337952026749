import { makeStyles, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'context/providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { SensorBorrowerData } from 'commonTypes/sensorTypes';
import { ReactComponent as NextIcon } from 'icons/Next.svg';

type SensorProfileItemProps = {
  profile: SensorBorrowerData | null;
  id: string;
};

export const SensorProfileItem = ({ profile, id }: SensorProfileItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setActiveProfileId } = useAuth();
  const history = useHistory();

  const handleProfileClick = () => {
    setActiveProfileId(profile?.profileId);
    setTimeout(() => {
      history.push('/');
    }, 200);
  };

  return (
    <Box
      className={clsx(classes.profileContainer, {
        [classes.clickable]: profile,
      })}
      onClick={profile ? () => handleProfileClick() : undefined}
      id={`${id}-sensor-profile`}
    >
      <Typography variant="caption" className={classes.profileName}>
        {profile
          ? `${profile.firstName} ${profile.lastName}`
          : t('sensors.not-assigned')}
      </Typography>
      {profile ? <NextIcon /> : null}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  profileName: {
    margin: theme.spacing(0, 5, 0, 5),
    maxWidth: '110px',
    maxHeight: '90px',
    overflow: 'hidden',
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer',
  },
}));
