import { Typography, makeStyles } from '@material-ui/core';

type ErrorMessageProps = {
  message: string;
};

export const ErrorMessage = ({ message }: ErrorMessageProps) => {
  const classes = useStyles();

  return <Typography className={classes.message}>{message}</Typography>;
};

const useStyles = makeStyles((theme) => ({
  message: {
    textAlign: 'center',
    color: theme.palette.error.main,
    padding: theme.spacing(1),
  },
}));
