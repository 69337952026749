import axios, { AxiosResponse } from 'axios';
import { decodeToken } from 'utils/decodeJWT';

const authApi = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
});

const mainApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

const setupInterceptors = (logout: () => void) => {
  authApi.interceptors.response.use(
    (response) => {
      return updateToken(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  mainApi.interceptors.response.use(
    (response) => {
      return updateToken(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  mainApi.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    const newConfig = { ...config };
    if (token) {
      if (decodeToken(token)) {
        newConfig.headers.Authorization = token;
      } else {
        logout();
      }
    } else {
      logout();
    }
    return newConfig;
  });

  authApi.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    const newConfig = { ...config };
    if (token) {
      if (decodeToken(token)) {
        newConfig.headers.Authorization = token;
      }
    }
    return newConfig;
  });
};

const updateToken = (response: AxiosResponse) => {
  if (response.headers.authorization) {
    localStorage.setItem('token', `${response.headers.authorization}`);
  }
  return response;
};

export { authApi, mainApi, setupInterceptors };
