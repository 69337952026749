import { useEffect, useState, SetStateAction, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Dialog, Typography } from '@material-ui/core';
import { useAuth } from 'context/providers/AuthProvider';
import { ButtonBase } from 'components/Button/ButtonBase';
import { ButtonPrimary } from 'components/Button/ButtonPrimary';
import { getProfileById } from 'api/profiles/profilesApi';
import { Profile } from 'commonTypes/authTypes';
import { SensorBorrowerData } from 'commonTypes/sensorTypes';

type SensorLendingWarningProps = {
  sensorBorrowerData: SensorBorrowerData;
  setSensorBorrowerData: Dispatch<SetStateAction<SensorBorrowerData | null>>;
  closeUploadDialog: () => void;
};

export const SensorLendingWarning = ({
  sensorBorrowerData,
  setSensorBorrowerData,
  closeUploadDialog,
}: SensorLendingWarningProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { activeProfile, setActiveProfileId } = useAuth();
  const [profileData, setProfileData] = useState<Profile | null>(null);

  useEffect(() => {
    if (activeProfile?.profileId) {
      getProfileById(activeProfile.profileId).then((data) => {
        setProfileData(data);
      });
    }
  }, [activeProfile?.profileId]);

  const handleClose = () => {
    setSensorBorrowerData(null);
  };

  const handleChangeProfile = (profileId: string) => {
    handleClose();
    closeUploadDialog();
    setActiveProfileId(profileId);
  };

  return (
    <>
      {profileData && (
        <Dialog classes={{ paper: classes.dialog }} open>
          <Typography variant="subtitle1" id="sensor-lending-info">
            {t('examinations.sensor-lent-to-other-user')}{' '}
            <span className={classes.name}>
              {sensorBorrowerData.firstName} {sensorBorrowerData.lastName}
            </span>
            . {t('examinations.continue-question')}{' '}
            <span className={classes.name}>
              {profileData.firstName} {profileData.lastName}
            </span>
            ?
          </Typography>
          <ButtonBase
            id="add-examination-continue"
            onClick={handleClose}
            className={classes.button}
          >
            {`${t('examinations.add-continue')} ${profileData.firstName} ${
              profileData.lastName
            }`}
          </ButtonBase>
          <ButtonPrimary
            id="add-examination-abort"
            onClick={() => handleChangeProfile(sensorBorrowerData.profileId)}
            className={classes.button}
          >
            {`${t('examinations.add-abort')} ${sensorBorrowerData.firstName} ${
              sensorBorrowerData.lastName
            }`}
          </ButtonPrimary>
        </Dialog>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(4),
  },
  button: {
    fontSize: '14px',
    fontWeight: 500,
    borderRadius: '8px',
    height: '46px',
    marginTop: theme.spacing(2),
  },
  name: {
    color: theme.palette.primary.main,
  },
}));
