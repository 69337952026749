import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CircularLoader } from 'components/Progress/CircularLoader';

export const DownloadInProgress = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography>{t('examinations.generating-pdf')}</Typography>
      <CircularLoader className={classes.margin} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(3, 0, 3, 0),
    color: theme.palette.secondary.main,
  },
}));
