import { Typography, makeStyles, DialogProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { InfoDialog } from 'components/Dialog/InfoDialog';
import { ButtonPrimary } from 'components/Button/ButtonPrimary';

export const ActivationFailedDialog = ({ open }: DialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <InfoDialog open={open}>
      <Typography className={classes.heading} variant="h2">
        {t('activate-account.activation-failed')}
      </Typography>
      <Typography className={classes.message}>
        {t('activate-account.try-password-reset')}
      </Typography>
      <ButtonPrimary onClick={() => history.push('/')}>
        {t('back-to-login')}
      </ButtonPrimary>
    </InfoDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.primary.main,
    margin: theme.spacing(3, 0, 3, 0),
  },
  message: {
    margin: theme.spacing(2, 0, 4, 0),
    textAlign: 'center',
  },
}));
