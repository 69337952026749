import { useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import { CustomResponsiveContainer } from 'charts/ChartComponents/CustomResponsiveContainer';
import { FixedSizeContainer } from 'charts/ChartComponents/FixedSizeContainer';
import { useXAxisData } from 'charts/LineCharts/useXAxisData';
import { ChartData } from 'charts/chartTypes/chartTypes';

export const ApneaChart = ({ chartData }: ChartData) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, start, end } = chartData;

  const { xAxisDomain, xAxisTicks, createXAxisData } = useXAxisData(start, end);

  useEffect(() => {
    createXAxisData();
  }, [createXAxisData]);

  return (
    <FixedSizeContainer height={180} className={classes.container}>
      <CustomResponsiveContainer>
        <LineChart data={data} margin={{ top: 0, bottom: 0 }} id="apnea-chart">
          <text
            className={classes.legend}
            textAnchor="middle"
            transform="rotate(-90)"
            x={-80}
            y={30}
          >
            {t('charts.apnea')}
          </text>
          <XAxis
            orientation="bottom"
            dataKey="timeStamp"
            type="number"
            domain={xAxisDomain}
            ticks={xAxisTicks}
            stroke={theme.palette.text.secondary}
            strokeWidth={0.5}
            tick={
              isEmpty(xAxisTicks)
                ? false
                : { fill: theme.palette.text.primary, id: 'apnea-x-axis-tick' }
            }
            padding={{ right: 20 }}
            tickFormatter={(timeStamp) =>
              DateTime.fromSeconds(timeStamp).toFormat('HH:mm')
            }
          />
          <YAxis
            type="number"
            domain={[0, 20]}
            tick={{
              fill: theme.palette.text.primary,
              fontSize: '12px',
              id: 'apnea-value-tick',
            }}
            ticks={[0, 5, 10, 15, 20]}
            stroke={theme.palette.text.secondary}
            padding={{ top: 20, bottom: 20 }}
            interval={0}
            width={80}
          />
          <CartesianGrid strokeDasharray="4 4" />
          <Line
            type="linear"
            dataKey="value"
            id="apnea-line"
            stroke={theme.palette.primary.main}
            dot={false}
            isAnimationActive={false}
          />
        </LineChart>
      </CustomResponsiveContainer>
    </FixedSizeContainer>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 0,

    '& .recharts-cartesian-grid-vertical line': {
      strokeDasharray: '0',
      stroke: theme.palette.text.secondary,
    },

    '& .recharts-cartesian-grid-vertical line:last-child': {
      opacity: 0,
    },

    '& .recharts-cartesian-grid-horizontal line:nth-child(n + 6)': {
      opacity: 0,
    },
  },
  legend: {
    fill: theme.palette.text.secondary,
    fontSize: '12px',
  },
}));
