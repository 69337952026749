import { Typography, Divider, makeStyles } from '@material-ui/core';
import { LettersAvatar } from 'components/Avatar/LettersAvatar';
import { Profile } from 'commonTypes/authTypes';

type ActiveProfileNameProps = {
  profile: Profile;
};

export const ActiveProfileName = ({ profile }: ActiveProfileNameProps) => {
  const classes = useStyles();

  return (
    <div className={classes.activeProfile}>
      <LettersAvatar user={profile} className={classes.avatar} />
      <Typography
        variant="h3"
        noWrap
        className={classes.activeProfileName}
        id="active-profile-name"
      >
        {`${profile.firstName} ${profile.lastName}`}
      </Typography>
      <Divider className={classes.divider} orientation="vertical" />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  activeProfile: {
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'flex-start',
    width: '270px',
    marginLeft: theme.spacing(1),
  },
  activeProfileName: {
    color: theme.palette.grey[900],
    margin: theme.spacing(0, 1, 0, 1),
  },
  avatar: {
    margin: 0,
  },
  divider: {
    height: '30px',
    margin: '0 0 0 auto',
  },
}));
