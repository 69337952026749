import { makeStyles, Typography, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProfileSimple } from 'commonTypes/authTypes';
import { useAuth } from 'context/providers/AuthProvider';
import { ReactComponent as Warning } from 'icons/WarningBlue.svg';

type PrimaryProfileItemTextProps = {
  profile: ProfileSimple;
};

export const PrimaryProfileItemText = ({
  profile,
}: PrimaryProfileItemTextProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isBasic } = useAuth();

  const { firstName, lastName, requiredConsents } = profile;

  return (
    <div className={classes.firstRow}>
      <Typography
        noWrap
        className={classes.profileName}
      >{`${firstName} ${lastName}`}</Typography>
      {!requiredConsents && isBasic && (
        <Tooltip title={t('consents.no-consents')!}>
          <Warning
            className={classes.warningIcon}
            id="profile-consents-warning"
          />
        </Tooltip>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  profileName: {
    color: theme.palette.grey[900],
  },
  firstRow: {
    display: 'flex',
  },

  warningIcon: {
    width: '16px',
    height: '16px',
    minWidth: '16px',
    minHeight: '16px',
    alignSelf: 'center',
    margin: theme.spacing(0, 0.5, 0, 1),
  },
}));
