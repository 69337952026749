import { makeStyles } from '@material-ui/core';
import { LabelProps } from 'recharts';
import {
  SSSIChartDataItem,
  DividedAhiChartDataItem,
  CombinedAhiChartDataItem,
} from 'charts/chartTypes/chartTypes';

type CustomLabelProps = Omit<LabelProps, 'value' | 'x' | 'onClick'> & {
  dataKey: string;
  x?: number;
  value?: number;
  data:
    | SSSIChartDataItem[]
    | DividedAhiChartDataItem[]
    | CombinedAhiChartDataItem[]
    | undefined;
  index?: number;
  onClick: (examinationData: { examid: string | undefined }) => void;
};

export const CustomLabel = (props: CustomLabelProps) => {
  const classes = useStyles();
  const { dataKey, onClick, x, y, value, data, index, width, dy } = props;

  if (
    x === undefined ||
    value === undefined ||
    y === undefined ||
    index === undefined
  ) {
    return null;
  }

  if (!data) {
    return null;
  }

  let newDY;
  let newY = y;
  let newX = x;

  if (width && typeof width === 'number') {
    newX = x + width / 2;
  }

  const upperBars = ['sleepScore', 'supineAhi', 'nonSupineAhi', 'ahi'];
  const lowerBars = ['snoringIndex', 'supinePositionRatio'];

  if (upperBars.includes(dataKey)) {
    newDY = -10;
  } else if (lowerBars.includes(dataKey)) {
    newDY = 20;
  } else {
    newDY = dy;
  }

  // bar is over the scale, but we still want to show the label on the chart
  if (
    (dataKey === 'supineAhi' ||
      dataKey === 'nonSupineAhi' ||
      dataKey === 'ahi') &&
    value > 115
  ) {
    newY = 20;
  }

  return (
    <text
      id={`${dataKey}-${index}-bar-label`}
      dy={newDY}
      x={newX}
      y={newY}
      textAnchor="middle"
      className={classes.label}
      onClick={() => onClick(data[index])}
    >
      {Math.abs(value)}
    </text>
  );
};

CustomLabel.defaultProps = {
  x: 0,
  value: 0,
  index: 0,
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '12px',
    fill: theme.palette.text.primary,
  },
}));
