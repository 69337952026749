import { makeStyles, Typography } from '@material-ui/core';
import { ProfileSimple } from 'commonTypes/authTypes';
import { doNotHavePesel } from 'utils/constants';

type ThirdRowProps = {
  profile: ProfileSimple;
};

export const ThirdRow = ({ profile }: ThirdRowProps) => {
  const classes = useStyles();

  const { govIdentification, phoneNumber } = profile;

  const showThirdDataRow = Boolean(
    (govIdentification && govIdentification !== doNotHavePesel) || phoneNumber
  );

  return (
    <>
      {showThirdDataRow && (
        <div className={classes.additionalInfo}>
          {govIdentification && govIdentification !== doNotHavePesel && (
            <Typography
              noWrap
              variant="caption"
              className={classes.captionText}
            >
              {govIdentification}
            </Typography>
          )}
          {phoneNumber && (
            <Typography
              noWrap
              variant="caption"
              className={classes.captionText}
            >
              {phoneNumber}
            </Typography>
          )}
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  additionalInfo: {
    color: theme.palette.text.secondary,
    lineHeight: '18px',
    display: 'flex',
  },
  captionText: {
    marginRight: theme.spacing(1),
  },
}));
