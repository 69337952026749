import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import clsx from 'clsx';

export const CircularLoader = ({ color, className }: CircularProgressProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.root)}>
      <CircularProgress color={color || 'inherit'} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));
