import { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UserInfo } from 'pages/account/UserInfo';
import { ChangePassword } from 'pages/account/ChangePassword';
import { EditConsents } from 'pages/account/EditConsents';
import { Footer } from 'pages/account/Footer';
import { CustomLink } from 'components/Link/Link';
import { ContactForm } from 'pages/account/ContactForm';
import { Toast } from 'components/Alert/Toast';

const AccountPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [contactFormOpen, setContactFormOpen] = useState(false);
  const [sendMsgResult, setSendMsgResult] = useState({
    resolved: false,
    status: '',
  });

  return (
    <>
      <Typography variant="h2" className={classes.title}>
        {t('account.my-account')}
      </Typography>
      <div className={classes.container}>
        <UserInfo className={classes.margin} />
        <ChangePassword className={classes.margin} />
        <EditConsents />
      </div>
      <div className={classes.container}>
        <Typography className={classes.contactText}>
          {t('account.contact-us')}
        </Typography>
        <CustomLink
          to="#"
          onClick={() => setContactFormOpen(true)}
          label={t('account.contact-form')}
        />
      </div>
      <ContactForm
        open={contactFormOpen}
        onClose={() => setContactFormOpen(false)}
        setSendMsgResult={setSendMsgResult}
      />
      {sendMsgResult.resolved && (
        <Toast
          open
          setState={setSendMsgResult}
          severity={sendMsgResult.status === 'success' ? 'success' : 'error'}
        >
          {sendMsgResult.status === 'success'
            ? t('account.send-msg-success')
            : t('account.send-msg-error')}
        </Toast>
      )}
      <Footer />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(7),
  },
  container: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  margin: {
    marginRight: theme.spacing(6),
  },
  contactText: {
    marginRight: theme.spacing(1),
  },
}));

export default AccountPage;
