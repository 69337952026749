import {
  makeStyles,
  LinearProgress,
  Typography,
  Box,
  LinearProgressProps,
} from '@material-ui/core';

type ProgressBarWithLabelProps = LinearProgressProps & {
  progress: number;
};

export const ProgressBarWithLabel = ({
  progress,
  ...props
}: ProgressBarWithLabelProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <LinearProgress
        variant="determinate"
        className={classes.progress}
        classes={{ root: classes.bar }}
        value={progress}
        {...props}
      />
      <Box className={classes.labelContainer}>
        <Typography variant="body2" className={classes.label}>{`${Math.round(
          progress
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  bar: {
    height: '20px',
    borderRadius: '8px',
  },
  label: {
    color: theme.palette.text.primary,
    textAlign: 'right',
  },
  progress: {
    width: '100%',
  },
  labelContainer: {
    width: '4%',
    marginLeft: theme.spacing(1),
  },
}));
