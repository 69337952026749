import { useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BoxBase } from 'components/Box/Box';
import { EditButton } from 'components/Button/EditButton';
import { EditConsentsDialog } from 'pages/account/EditConsentsDialog';
import { Toast } from 'components/Alert/Toast';

export const EditConsents = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [editConsentsDialogOpen, setEditConsentsDialogOpen] = useState(false);
  const [editConsentsResult, setEditConsentsResult] = useState({
    resolved: false,
    status: '',
  });

  const closeDialog = () => {
    setEditConsentsDialogOpen(false);
  };

  return (
    <BoxBase>
      <div>
        <Typography
          variant="button"
          className={classes.header}
          id="edit-consents"
        >
          {t('account.your-consents')}
        </Typography>
        <Typography noWrap>{t('account.manage-consents')}</Typography>
      </div>
      <EditButton
        className={classes.editButton}
        onClick={() => setEditConsentsDialogOpen(true)}
        id="edit-consents-button"
      />
      <EditConsentsDialog
        open={editConsentsDialogOpen}
        onClose={closeDialog}
        setEditConsentsResult={setEditConsentsResult}
      />
      {editConsentsResult.resolved && (
        <Toast
          open
          setState={setEditConsentsResult}
          severity={editConsentsResult.status === 'error' ? 'error' : 'success'}
        >
          {editConsentsResult.status === 'error'
            ? t('account.edit-consents-error')
            : t('account.edit-consents-success')}
        </Toast>
      )}
    </BoxBase>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.grey[900],
  },
  editButton: {
    marginLeft: theme.spacing(6),
  },
}));
