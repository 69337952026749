import { makeStyles, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomLink } from 'components/Link/Link';

export const Footer = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Divider className={classes.divider} />
      <CustomLink
        className={classes.link}
        label={t('account.clebre-terms-of-use')}
        to="/account/terms-of-use"
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'absolute',
    bottom: 0,
    right: '30px',
    left: '30px',
    minWidth: '400px',
    marginBottom: theme.spacing(3),
  },
  link: {
    margin: '0 auto',
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
}));
