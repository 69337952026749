import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WelcomeDialog } from 'pages/auth/invite/WelcomeDialog';
import { useAuth } from 'context/providers/AuthProvider';
import { Toast } from 'components/Alert/Toast';

const WelcomePage = () => {
  const { logout, user } = useAuth();
  const { t } = useTranslation();
  const [createPasswordResult, setCreatePasswordResult] = useState({
    resolved: false,
    status: '',
  });

  useEffect(() => {
    if (user) {
      logout();
    }
  }, [user, logout]);

  return (
    <>
      <WelcomeDialog open setCreatePasswordResult={setCreatePasswordResult} />
      <Toast
        open={createPasswordResult.status === 'error'}
        setState={setCreatePasswordResult}
        severity="error"
      >
        {t('welcome.set-password-error')}
      </Toast>
    </>
  );
};

export default WelcomePage;
