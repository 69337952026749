import { useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BoxBase } from 'components/Box/Box';
import { EditButton } from 'components/Button/EditButton';
import { ChangePasswordDialog } from 'pages/account/ChangePasswordDialog';

type ChangePasswordProps = {
  className: string;
};

export const ChangePassword = ({ className }: ChangePasswordProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(
    false
  );

  return (
    <BoxBase className={className}>
      <div>
        <Typography
          variant="button"
          className={classes.header}
          id="change-password"
        >
          {t('account.change-password')}
        </Typography>
        <Typography id="masked-password" noWrap>{`${t(
          'password'
        )}: **************`}</Typography>
      </div>
      <EditButton
        className={classes.editButton}
        onClick={() => setChangePasswordDialogOpen(true)}
        id="change-password-button"
      />
      <ChangePasswordDialog
        open={changePasswordDialogOpen}
        close={() => setChangePasswordDialogOpen(false)}
      />
    </BoxBase>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.grey[900],
  },
  editButton: {
    marginLeft: theme.spacing(6),
  },
}));
