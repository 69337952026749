import { mainApi } from 'api/config/interceptors';

export const getSensor = (serialNumber: string) => {
  return mainApi.get(`/sensor/${serialNumber}`).then((response) => {
    return response.data;
  });
};

export const getFirmwareVersion = (firmware: string, hardware: string) => {
  return mainApi
    .get(`/sensorVersion/search`, {
      params: {
        firmware,
        hardware,
      },
    })
    .then((response) => {
      return response.data;
    });
};

export const getSensorsList = () => {
  return mainApi.get(`/sensor`).then((response) => {
    return response.data;
  });
};
