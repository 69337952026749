import { makeStyles, TableCell, TableCellProps } from '@material-ui/core';
import clsx from 'clsx';

type HeadCellProps = TableCellProps & {
  sortable?: boolean;
};

export const HeadCell = ({ className, sortable, ...props }: HeadCellProps) => {
  const classes = useStyles();

  return <TableCell className={clsx(className, classes.headCell)} {...props} />;
};

HeadCell.defaultProps = {
  sortable: false,
};

const useStyles = makeStyles((theme) => ({
  headCell: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    backgroundColor: theme.palette.grey[600],
    padding: theme.spacing(1, 1, 1, 2),

    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    '&:first-child': {
      borderRadius: '5px 0px 0px 5px',
    },
    '&:last-child': {
      borderRadius: '0px 5px 5px 0px',
    },

    [theme.breakpoints.up('xl')]: {
      fontSize: '14px',
    },
  },
}));
