import { useEffect, useState } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
} from 'recharts';
import theme from 'theme';
import { DateTime } from 'luxon';
import { CustomResponsiveContainer } from 'charts/ChartComponents/CustomResponsiveContainer';
import { CustomXAxisTick } from 'charts/DotCharts/DotChartComponents/CustomXAxisTick';
import { FixedSizeContainer } from 'charts/ChartComponents/FixedSizeContainer';
import { CombinedAhiChartDataItem } from 'charts/chartTypes/chartTypes';
import { formatYAxisTick } from 'charts/utils';
import { formatXAxisTick } from 'charts/DotCharts/utils';
import { ExaminationSimple } from 'commonTypes/examinationTypes';
import { TimeFrames } from 'pages/examinations/ExaminationsSummaryPage';
import { useXAxisData } from 'charts/DotCharts/useXAxisData';
import { useYAxisData } from 'charts/DotCharts/useYAxisData';

type CombinedAhiDotChartProps = {
  data: ExaminationSimple[] | null;
  timeFrame: TimeFrames;
  dates: { from: DateTime | undefined; to: DateTime | undefined };
};

export const CombinedAhiDotChart = ({
  data,
  timeFrame,
  dates,
}: CombinedAhiDotChartProps) => {
  const [chartData, setChartData] = useState<
    CombinedAhiChartDataItem[] | undefined
  >(undefined);
  const { xAxisDomain, xAxisTicks, createXAxisData } = useXAxisData(
    timeFrame,
    dates
  );
  const {
    yAxisTicks,
    createYAxisData,
    setHighestValue,
    highestValue,
  } = useYAxisData(false);

  useEffect(() => {
    const createChartData = () => {
      if (!data) {
        return;
      }
      const dataObjects: CombinedAhiChartDataItem[] = [];
      data.forEach((examination) => {
        const { examinationId, ahi, startDate } = examination;
        if (ahi === null) {
          return;
        }
        if (ahi > highestValue) {
          setHighestValue(ahi);
        }
        const luxonDate = DateTime.fromISO(startDate);

        const dotData = {
          examid: examinationId,
          name: luxonDate.toMillis(),
          ahi,
        };
        dataObjects.push(dotData);
      });

      return dataObjects;
    };
    if (data) {
      setChartData(createChartData());
      createXAxisData();
      createYAxisData();
    }
  }, [
    setChartData,
    data,
    createXAxisData,
    createYAxisData,
    highestValue,
    setHighestValue,
  ]);

  return (
    <FixedSizeContainer height={260}>
      <CustomResponsiveContainer>
        <ScatterChart
          data={chartData}
          id={`comb-ahi-dot-chart-${timeFrame}`}
          margin={{ left: -25, right: 10, top: 2 }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3" />
          <XAxis
            dataKey="name"
            height={60}
            interval={0}
            padding={{ right: 10 }}
            stroke={theme.palette.text.secondary}
            tickFormatter={
              timeFrame === TimeFrames.MONTH ? formatXAxisTick : undefined
            }
            tick={
              timeFrame === TimeFrames.MONTH
                ? { transform: 'translate(0, 6)' }
                : (props) => <CustomXAxisTick {...props} />
            }
            type="number"
            domain={xAxisDomain}
            ticks={xAxisTicks}
          />
          <YAxis
            ticks={yAxisTicks}
            tickFormatter={formatYAxisTick}
            stroke={theme.palette.text.secondary}
            domain={[0, 120]}
          />
          <ReferenceLine y={0} />
          <Scatter
            isAnimationActive={false}
            data={chartData}
            name="ahi"
            dataKey="ahi"
            fill={theme.palette.warning.dark}
            id="ahi-dot"
          />
        </ScatterChart>
      </CustomResponsiveContainer>
    </FixedSizeContainer>
  );
};
