import { ResponsiveContainer, ResponsiveContainerProps } from 'recharts';
import { makeStyles } from '@material-ui/core';

// the default responsive container is laggy when resizing viewport
export const CustomResponsiveContainer = (props: ResponsiveContainerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.outerContainer}>
      <div className={classes.innerContainer}>
        <ResponsiveContainer {...props} />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  outerContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  innerContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));
