import { ExaminationSimple } from 'commonTypes/examinationTypes';
import { orderEnum } from 'commonTypes/otherTypes';

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: orderEnum,
  orderBy: Key
): ((
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <T>(
  array: any,
  comparator: (a: T, b: T) => number
) => {
  const stabilizedThis = array.map((el: ExaminationSimple, index: number) => [
    el,
    index,
  ]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
  });
  return stabilizedThis.map(
    (el: { el: ExaminationSimple; index: number }[]) => el[0]
  );
};
