import i18n from 'translations/i18n';

export const userAccountQA = {
  header: i18n.t('help.user-account.title'),
  questions: [
    {
      question: i18n.t('help.user-account.question1'),
      answer: [
        {
          text: i18n.t('help.user-account.question1-answer'),
          images: {
            src: [
              'images/faq/userAccount/question1-1.png',
              'images/faq/userAccount/question1-2.png',
              'images/faq/userAccount/question1-3.png',
            ],
          },
        },
      ],
    },
    {
      question: i18n.t('help.user-account.question2'),
      answer: [
        {
          text: i18n.t('help.user-account.question2-answer-part1'),
          images: { src: ['images/faq/userAccount/question2-1.png'] },
        },
        {
          text: i18n.t('help.user-account.question2-answer-part2'),
          images: { src: ['images/faq/userAccount/question2-2.png'] },
        },
      ],
    },
    {
      question: i18n.t('help.user-account.question3'),
      answer: [
        {
          text: i18n.t('help.user-account.question3-answer'),
          images: { src: ['images/faq/userAccount/question3-1.png'] },
        },
      ],
    },
    {
      question: i18n.t('help.user-account.question4'),
      answer: [
        {
          text: i18n.t('help.user-account.question4-answer-part1'),
        },
        {
          text: i18n.t('help.user-account.question4-answer-part2'),
        },
      ],
    },
  ],
};
