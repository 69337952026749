import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { makeStyles, Typography } from '@material-ui/core';
import { LoginWrapper } from 'pages/auth/login/LoginWrapper';
import { AuthFooter } from 'pages/auth/AuthFooter';
import { LoginForm } from 'pages/auth/login/LoginForm';
import { ButtonPrimary } from 'components/Button/ButtonPrimary';
import { GoogleAuth } from 'pages/auth/googleAuth/GoogleAuth';
import { CheckboxWithLabel } from 'components/Input/CheckboxWithLabel';
import { CustomLink } from 'components/Link/Link';
import { CircularLoader } from 'components/Progress/CircularLoader';
import { ResetRequestDialog } from 'pages/auth/resetPassword/ResetRequestDialog';
import { loginValidationSchema } from 'pages/auth/login/loginValidationSchema';
import { loginWithEmail } from 'api/auth/authApi';
import { loginErrors } from 'pages/auth/login/loginErrors';
import { ErrorMessage } from 'pages/auth/ErrorMessage';
import { useAuth } from 'context/providers/AuthProvider';

const LoginPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loginError, setLoginError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [rememberMeChecked, setRememberMeChecked] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
  });
  const { login, logout } = useAuth();

  useEffect(() => {
    const email = localStorage.getItem('userEmail');

    if (email) {
      setInitialValues({ email, password: '' });
    }
  }, []);

  const loginWithEmailMutation = useMutation(loginWithEmail, {
    onError: (error: AxiosError) => {
      if (error.message === loginErrors.userNotActive.id) {
        setLoginError(loginErrors.userNotActive.message);
      } else {
        setLoginError(loginErrors.genericError.message);
      }
      logout();
    },
    onSuccess: (data) => {
      login(data);
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { resetForm, setFieldValue }) => {
      loginWithEmailMutation.mutate(values);
      if (rememberMeChecked) {
        localStorage.setItem('userEmail', values.email);
      }
      resetForm();
      setFieldValue('email', values.email);
    },
    validationSchema: loginValidationSchema,
  });

  const togglePasswordResetDialog = () => {
    setResetDialogOpen(!resetDialogOpen);
  };

  const handleRememberMeClick = () => {
    setRememberMeChecked(!rememberMeChecked);
  };

  return (
    <LoginWrapper>
      <>
        <GoogleAuth
          className={classes.googleAuth}
          label={t('login-page.google')}
          setError={setLoginError}
          setLoading={setLoading}
        />
        <Typography className={classes.emailLogin}>
          {t('login-page.or-email')}
        </Typography>
        {loginError && (
          <ErrorMessage message={t(`login-page.error.${loginError}`)} />
        )}
        <LoginForm formik={formik} />
        <div className={classes.speceBetweenContainer}>
          <CheckboxWithLabel
            checked={rememberMeChecked}
            onChange={handleRememberMeClick}
            name="rememberMe"
            label={t('login-page.remember-me')}
          />
          <CustomLink
            to="#"
            onClick={togglePasswordResetDialog}
            label={t('login-page.forgot-password')}
          />
          <ResetRequestDialog
            open={resetDialogOpen}
            onClose={togglePasswordResetDialog}
          />
        </div>
        <ButtonPrimary
          onClick={formik.handleSubmit}
          className={classes.loginButton}
        >
          {loginWithEmailMutation.isLoading || loading ? (
            <CircularLoader />
          ) : (
            t('login')
          )}
        </ButtonPrimary>
        <div className={classes.centerContainer}>
          <Typography className={classes.noAccountText}>
            {t('login-page.create-account')}
          </Typography>
          <CustomLink to="/register" label={t('register')} />
        </div>
        <AuthFooter />
      </>
    </LoginWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  googleAuth: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  emailLogin: {
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    alignSelf: 'center',
  },
  loginButton: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  speceBetweenContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  noAccountText: {
    marginRight: theme.spacing(2),
  },
}));

export default LoginPage;
