import { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { GenderEnum, profileActionType } from 'commonTypes/profileTypes';
import { DialogProps } from '@material-ui/core';
import { ProfileFormValues } from 'pages/profiles/ProfileForm';
import { profileValidationSchema } from 'pages/profiles/profileValidationSchema';
import { useAuth } from 'context/providers/AuthProvider';
import { createProfile } from 'api/profiles/profilesApi';
import { updateUserFromToken } from 'utils/decodeJWT';
import { ProfileDialog } from 'pages/profiles/ProfileDialog';
import { processProfileValues } from 'pages/profiles/helpers';

type AddProfileDialogProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  setAddProfileResult: Dispatch<
    SetStateAction<{ resolved: boolean; status: string }>
  >;
  firstProfile?: boolean;
};

export const AddProfileDialog = ({
  open,
  onClose,
  setAddProfileResult,
  firstProfile,
}: AddProfileDialogProps) => {
  const { t } = useTranslation();
  const { user, setActiveProfileId, login, isDoctor, isBasic } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [peselValidationCache, setPeselValidationCache] = useState({});

  const createProfileMutation = useMutation(createProfile, {
    onError: () => {
      handleClose();
      setAddProfileResult({ resolved: true, status: 'error' });
    },
    onSuccess: (data) => {
      if (firstProfile) {
        updateUserFromToken(login);
      }
      if (location.pathname !== '/') {
        setActiveProfileId(undefined);
        history.push('/');
      }
      setActiveProfileId(data.profileId);
      if (!firstProfile) {
        onClose();
        formik.resetForm();
      }
      setAddProfileResult({ resolved: true, status: 'success' });
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const doctorInitialValues = {
    firstName: '',
    lastName: '',
    govIdentification: '',
    dateOfBirth: null,
    height: '',
    weight: '',
    phoneNumber: '',
    gender: GenderEnum.male,
    noPesel: false,
  };

  const basicInitialValues = {
    ...doctorInitialValues,
    termsConditionsAgreement: false,
    dataProcessingAgreement: false,
  };

  const formik = useFormik<ProfileFormValues>({
    initialValues: isDoctor ? doctorInitialValues : basicInitialValues,
    onSubmit: (values) => {
      const apiValues = processProfileValues(
        values,
        profileActionType.ADD,
        isBasic,
        isDoctor,
        user
      );
      setAddProfileResult({ resolved: false, status: '' });
      createProfileMutation.mutate(apiValues);
    },
    validationSchema: profileValidationSchema(
      isDoctor,
      peselValidationCache,
      profileActionType.ADD
    ),
  });

  return (
    <ProfileDialog
      open={open}
      title={t('profiles.add-profile')}
      type={profileActionType.ADD}
      formik={formik}
      handleClose={handleClose}
      isLoading={createProfileMutation.isLoading}
      setPeselValidationCache={setPeselValidationCache}
      peselValidationCache={peselValidationCache}
    />
  );
};

AddProfileDialog.defaultProps = {
  firstProfile: false,
};
