import { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from 'recharts';
import { round, isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core';
import theme from 'theme';
import { useHistory } from 'react-router-dom';
import { CustomCursor } from 'charts/BarCharts/BarChartComponents/CustomCursor';
import { CustomLabel } from 'charts/BarCharts/BarChartComponents/CustomLabel';
import { CustomXAxisTick } from 'charts/BarCharts/BarChartComponents/CustomXAxisTick';
import { CustomResponsiveContainer } from 'charts/ChartComponents/CustomResponsiveContainer';
import { FixedSizeContainer } from 'charts/ChartComponents/FixedSizeContainer';
import { ExaminationSimple } from 'commonTypes/examinationTypes';
import { formatXAxisDate, fillEmptyData } from 'charts/BarCharts/utils';
import { formatYAxisTick } from 'charts/utils';
import { SSSIChartDataItem } from 'charts/chartTypes/chartTypes';

type SleepScoreSnoringIndexBarChartProps = {
  data: ExaminationSimple[] | null;
};

export const SleepScoreSnoringIndexBarChart = ({
  data,
}: SleepScoreSnoringIndexBarChartProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [chartData, setChartData] = useState<SSSIChartDataItem[] | undefined>(
    undefined
  );

  useEffect(() => {
    const createChartData = () => {
      if (!data) {
        return;
      }
      const dataObjects: SSSIChartDataItem[] = [];
      data.forEach((examination) => {
        const {
          sleepScore,
          snoringIndex,
          examinationId,
          startDate,
        } = examination;
        if (sleepScore === null || snoringIndex == null) {
          return;
        }

        const { dayString, day, month } = formatXAxisDate(startDate);

        const barData = {
          examid: examinationId,
          name: `${dayString} ${day} ${month}`,
          sleepScore: round(sleepScore),
          snoringIndex: -round(snoringIndex),
        };
        dataObjects.push(barData);
      });

      fillEmptyData(data, dataObjects);

      return dataObjects;
    };
    if (data) {
      setChartData(createChartData());
    } else {
      setChartData(undefined);
    }
  }, [setChartData, data]);

  const handleExaminationClick = (examinationData: {
    examid: string | undefined;
  }) => {
    history.push(`/examination/${examinationData.examid}`);
  };

  return (
    <>
      <FixedSizeContainer>
        <CustomResponsiveContainer>
          <BarChart
            id="ss-si-bar-chart"
            data={chartData}
            barSize={24}
            margin={{
              left: -25,
              right: 10,
            }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3" />
            <XAxis
              dataKey="name"
              reversed
              height={60}
              stroke={theme.palette.text.secondary}
              interval={0}
              tick={
                !isEmpty(chartData)
                  ? (props) => <CustomXAxisTick {...props} />
                  : false
              }
            />
            <YAxis
              ticks={[-100, -75, -50, -25, 0, 25, 50, 75, 100]}
              tickFormatter={formatYAxisTick}
              stroke={theme.palette.text.secondary}
              domain={[-120, 120]}
            />
            <Tooltip
              contentStyle={{ display: 'none' }}
              cursor={
                <CustomCursor
                  dataLoaded={!isEmpty(chartData)}
                  onClick={handleExaminationClick}
                />
              }
            />
            <ReferenceLine y={0} />
            <Bar
              id="sleep-score-bar"
              dataKey="sleepScore"
              fill={theme.palette.primary.light}
              transform="translate(0, -5)"
              radius={40}
              minPointSize={3}
              label={
                <CustomLabel
                  dataKey="sleepScore"
                  data={chartData}
                  onClick={handleExaminationClick}
                />
              }
              onClick={handleExaminationClick}
              className={classes.dataBar}
            />
            <Bar
              id="snoring-index-bar"
              dataKey="snoringIndex"
              fill={theme.palette.error.darkmode}
              transform="translate(0, 5)"
              radius={40}
              minPointSize={3}
              label={
                <CustomLabel
                  dataKey="snoringIndex"
                  data={chartData}
                  onClick={handleExaminationClick}
                />
              }
              onClick={handleExaminationClick}
              className={classes.dataBar}
            />
          </BarChart>
        </CustomResponsiveContainer>
      </FixedSizeContainer>
    </>
  );
};

const useStyles = makeStyles(() => ({
  dataBar: {
    cursor: 'pointer',
  },
}));
