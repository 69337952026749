import { useState } from 'react';
import {
  makeStyles,
  Table,
  TableContainer,
  TableRow,
  TableHead,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UploadResult } from 'pages/examinations/examinationDetails/ExamFileRow';
import { HeadCell } from 'components/Table/HeadCell';
import { ExaminationFile, OptionalFile } from 'commonTypes/examinationTypes';
import { OtherFileUploadDialog } from 'pages/examinations/examinationFileUpload/OtherFileUploadDialog';
import { TableRows } from 'pages/examinations/examinationDetails/TableRows';
import { ExamFileUploadInfo } from 'pages/examinations/examinationFileUpload/ExamFileUploadInfo';
import { uploadExaminationFile } from 'pages/examinations/examinationFileUpload/sendExaminationFile';

type ExamFilesTableProps = {
  files: ExaminationFile[];
  examinationId: string;
  fetchExamination: () => void;
};

export const ExamFilesTable = ({
  files,
  examinationId,
  fetchExamination,
}: ExamFilesTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [otherFileDialogOpen, setOtherFileDialogOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(0);
  const [uploadResult, setUploadResult] = useState<UploadResult | null>(null);

  const columnLabels = [
    'file-type',
    'file-name',
    'file-status',
    'file-comment',
    'file-actions',
  ];

  const handleDialogOpen = () => {
    setOtherFileDialogOpen(true);
  };

  const handleDialogClose = () => {
    setOtherFileDialogOpen(false);
  };

  const handleUploadFinish = () => {
    setUploadStatus(0);
    setUploadResult(null);
  };

  const handleUpload = (
    selectedFile: File,
    optionalFileType: OptionalFile,
    comment?: string | null
  ) => {
    setUploadStatus(1);
    const isBathUpload = false;

    uploadExaminationFile(
      examinationId,
      selectedFile,
      optionalFileType.fileType,
      setUploadStatus,
      isBathUpload,
      comment
    )
      .then(() => {
        setUploadResult(UploadResult.SUCCESS);
        fetchExamination();
      })
      .catch((error) => {
        if (error.message === UploadResult.EMPTY_FILE) {
          setUploadResult(UploadResult.EMPTY_FILE);
        } else {
          setUploadResult(UploadResult.ERROR);
        }
        fetchExamination();
      });
  };

  return (
    <>
      <TableContainer
        id="examination-files-table"
        className={classes.tableContainer}
      >
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow className={classes.headRow}>
              {columnLabels.map((label) => (
                <HeadCell key={label} className={classes.headCell}>
                  {t(`examinations.${label}`)}
                </HeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableRows
            files={files}
            openOtherFileDialog={handleDialogOpen}
            handleUpload={handleUpload}
          />
        </Table>
      </TableContainer>
      {uploadStatus !== 0 && (
        <ExamFileUploadInfo
          progress={uploadStatus}
          uploadResult={uploadResult}
          handleClose={handleUploadFinish}
        />
      )}
      <OtherFileUploadDialog
        open={otherFileDialogOpen}
        closeDialog={handleDialogClose}
        handleUpload={handleUpload}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 1200,
    backgroundColor: theme.palette.background.default,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    overflow: 'visible',
  },
  headRow: {
    height: '35px',
  },
  headCell: {
    '&:last-child': {
      textAlign: 'center',
    },
  },
}));
