import React from 'react';
import ReactDOM from 'react-dom';
import 'translations/i18n';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppProviders from 'context/AppProviders';
import Root from 'Root';

ReactDOM.render(
  <AppProviders>
    <React.StrictMode>
      <CssBaseline />
      <Root />
    </React.StrictMode>
  </AppProviders>,
  document.getElementById('root')
);
