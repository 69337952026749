import {
  useState,
  useContext,
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { RangeFilter } from 'pages/examinations/examinationsList/examTableActions/filters/RangeFilter';
import { SingleValueFilter } from 'pages/examinations/examinationsList/examTableActions/filters/SingleValueFilter';
import { DateFilter } from 'pages/examinations/examinationsList/examTableActions/filters/DateFilter';
import {
  sleepTimeMarks,
  ahiMarks,
  siMarks,
  calcMarksRange,
} from 'pages/examinations/examinationsList/examTableActions/filters/sliderMarks';
import { FilterType } from 'api/examinations/examinationsApi';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useExaminations } from 'context/providers/ExaminationsProvider';
import { useAuth } from 'context/providers/AuthProvider';

// eslint-disable-next-line
export enum FilterOperands {
  GTE = 'GTE',
  LTE = 'LTE',
  LIKE = 'LIKE',
}

// eslint-disable-next-line
export enum ValueTypes {
  TIME = 'TIME',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
}

// eslint-disable-next-line
export enum FilterNames {
  TST = 'TOTALSLEEPTIME',
  AHI = 'AHI',
  SI = 'SNORINGINDEX',
  EXAMNUMBER = 'EXAMINATION_NUMBER',
  EXAMSTARTDATE = 'EXAMINATION_START_DATE',
}

const useRangeFilterFilter = (
  name: FilterNames,
  valueType: ValueTypes,
  defaultFilter: number[],
  label: { text: string; unit: string }
): [RangeFilter, Dispatch<SetStateAction<number[]>>] => {
  const [value, setValue] = useState<number[]>(defaultFilter);

  const rangeFilter = new RangeFilter(
    name,
    valueType,
    value,
    setValue,
    defaultFilter,
    label
  );

  return [rangeFilter, setValue];
};

type FiltersContextValue = {
  tstFilter: RangeFilter | undefined;
  ahiFilter: RangeFilter | undefined;
  siFilter: RangeFilter | undefined;
  examStartDateFilter: DateFilter | undefined;
  examNumberFilter: SingleValueFilter | undefined;
  submittedFilters: (RangeFilter | SingleValueFilter | DateFilter)[];
  setValidationError: Dispatch<SetStateAction<boolean>> | undefined;
  validationError: boolean;
  handleFiltersApply: (
    removedFilters?: (RangeFilter | SingleValueFilter | DateFilter)[]
  ) => void;
  clearAllFilters: () => void;
};

const defaultValue: FiltersContextValue = {
  tstFilter: undefined,
  ahiFilter: undefined,
  siFilter: undefined,
  examStartDateFilter: undefined,
  examNumberFilter: undefined,
  submittedFilters: [],
  setValidationError: undefined,
  validationError: false,
  handleFiltersApply: () => {},
  clearAllFilters: () => {},
};

const FiltersContext = createContext(defaultValue);

export type FilterProviderProps = {
  children: ReactNode;
};

const DEFAULT_DATE_FILTER = { low: null, high: null };
const DEFAULT_TST_FILTER = calcMarksRange(sleepTimeMarks);
const DEFAULT_AHI_FILTER = calcMarksRange(ahiMarks);
const DEFAULT_SI_FILTER = calcMarksRange(siMarks);
const DEFAULT_EXAM_NO_FILTER = '';
const DEFAULT_SUBMITTED_FILTERS = [] as (
  | RangeFilter
  | SingleValueFilter
  | DateFilter
)[];

const FiltersProvider = ({ ...props }: FilterProviderProps) => {
  const { t } = useTranslation();
  const [dateFilterValue, setDateFilterValue] = useState<{
    low: MaterialUiPickersDate;
    high: MaterialUiPickersDate;
  }>(DEFAULT_DATE_FILTER);
  const [examNumberFilterValue, setExamNumberFilterValue] = useState<string>(
    DEFAULT_EXAM_NO_FILTER
  );
  const [submittedFilters, setSubmittedFilters] = useState<
    (RangeFilter | SingleValueFilter | DateFilter)[]
  >(DEFAULT_SUBMITTED_FILTERS);
  const [validationError, setValidationError] = useState(false);
  const { getExaminations, orderBy, order } = useExaminations();
  const { activeProfile } = useAuth();

  const [tstFilter, setTstFilter] = useRangeFilterFilter(
    FilterNames.TST,
    ValueTypes.TIME,
    DEFAULT_TST_FILTER,
    {
      text: t('examinations.sleep-time'),
      unit: 'h',
    }
  );

  const [ahiFilter, setAhiFilter] = useRangeFilterFilter(
    FilterNames.AHI,
    ValueTypes.NUMBER,
    DEFAULT_AHI_FILTER,
    {
      text: t('examinations.ahi'),
      unit: '',
    }
  );

  const [siFilter, setSiFilter] = useRangeFilterFilter(
    FilterNames.SI,
    ValueTypes.NUMBER,
    DEFAULT_SI_FILTER,
    {
      text: t('examinations.snoring-index'),
      unit: '%',
    }
  );

  const examStartDateFilter = useMemo(() => {
    return new DateFilter(
      FilterNames.EXAMSTARTDATE,
      ValueTypes.STRING,
      dateFilterValue,
      setDateFilterValue,
      { text: t('examinations.examination-date'), unit: '' }
    );
  }, [dateFilterValue, t]);

  const examNumberFilter = useMemo(() => {
    return new SingleValueFilter(
      FilterNames.EXAMNUMBER,
      ValueTypes.STRING,
      examNumberFilterValue,
      setExamNumberFilterValue,
      { text: t('examinations.examination-number'), unit: '' }
    );
  }, [examNumberFilterValue, t]);

  const handleFiltersApply = (
    removedFilters?: (RangeFilter | SingleValueFilter | DateFilter)[]
  ) => {
    if (validationError) {
      return;
    }

    const filters = [
      tstFilter,
      ahiFilter,
      siFilter,
      examNumberFilter,
      examStartDateFilter,
    ];

    const activeFilters: (RangeFilter | SingleValueFilter | DateFilter)[] = [];

    let filtersCopy = cloneDeep(filters);

    if (removedFilters) {
      removedFilters.forEach((removedFilter) => {
        filtersCopy = filtersCopy.filter(
          (filter) => filter.name !== removedFilter.name
        );
      });
    }

    filtersCopy.forEach((filter) => {
      if (filter.shouldSkip()) {
        return;
      }
      activeFilters.push(filter);
    });

    setSubmittedFilters(activeFilters);

    const apiFilters: FilterType[] = [];
    activeFilters.forEach((filter) => {
      apiFilters.push(...filter.toApiFilter());
    });

    getExaminations(undefined, apiFilters, order, orderBy);
  };

  const clearAllFilters = useCallback(() => {
    setDateFilterValue(DEFAULT_DATE_FILTER);
    setExamNumberFilterValue(DEFAULT_EXAM_NO_FILTER);
    setTstFilter(DEFAULT_TST_FILTER);
    setAhiFilter(DEFAULT_AHI_FILTER);
    setSiFilter(DEFAULT_SI_FILTER);
    setSubmittedFilters(DEFAULT_SUBMITTED_FILTERS);
  }, [setAhiFilter, setSiFilter, setTstFilter]);

  useEffect(() => {
    clearAllFilters();
  }, [activeProfile?.profileId, clearAllFilters]);

  return (
    <FiltersContext.Provider
      value={{
        tstFilter,
        ahiFilter,
        siFilter,
        examNumberFilter,
        examStartDateFilter,
        submittedFilters,
        setValidationError,
        validationError,
        handleFiltersApply,
        clearAllFilters,
      }}
      {...props}
    />
  );
};

const useFilters = () => {
  const context = useContext(FiltersContext);

  if (
    !context.tstFilter ||
    !context.ahiFilter ||
    !context.examNumberFilter ||
    !context.siFilter ||
    !context.examStartDateFilter ||
    !context.setValidationError
  ) {
    throw new Error('useFilters used outside proper provider');
  }

  return {
    tstFilter: context.tstFilter,
    ahiFilter: context.ahiFilter,
    siFilter: context.siFilter,
    examNumberFilter: context.examNumberFilter,
    examStartDateFilter: context.examStartDateFilter,
    submittedFilters: context.submittedFilters,
    setValidationError: context.setValidationError,
    validationError: context.validationError,
    handleFiltersApply: context.handleFiltersApply,
    clearAllFilters: context.clearAllFilters,
  };
};
export { FiltersProvider, FiltersContext, useFilters };
