import { useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DialogBase } from 'components/Dialog/DialogBase';
import { ButtonPrimary } from 'components/Button/ButtonPrimary';
import { ButtonBase } from 'components/Button/ButtonBase';
import { ErrorMessage } from 'pages/examinations/examinationDelete/ErrorMessage';
import { CircularLoader } from 'components/Progress/CircularLoader';
import { deleteExamination } from 'api/examinations/examinationsApi';
import { SelectedExamination } from 'commonTypes/examinationTypes';

type ExamDeleteDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedExaminations: SelectedExamination[];
  postDeleteAction: () => void;
  shouldClose: boolean;
};

export const ExamDeleteDialog = ({
  open,
  onClose,
  selectedExaminations,
  postDeleteAction,
  shouldClose,
}: ExamDeleteDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [deleteError, setDeleteError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setDeleteError(false);
    }, 200);
  };

  const deleteAllSelected = () => {
    const deletePromises = selectedExaminations.map((exam) =>
      deleteExamination(exam.id)
    );
    return Promise.all(deletePromises);
  };

  const handleDeleteClick = () => {
    setLoading(true);
    deleteAllSelected()
      .then(() => {
        return postDeleteAction();
      })
      .then(() => {
        if (shouldClose) {
          handleClose();
          setTimeout(() => {
            setLoading(false);
          }, 100);
        }
      })
      .catch(() => {
        setLoading(false);
        setDeleteError(true);
      });
  };

  return (
    <DialogBase
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      id="exam-delete-dialog"
      disabled={loading}
    >
      {deleteError ? (
        <ErrorMessage onClose={handleClose} />
      ) : (
        <>
          {loading ? (
            <Typography className={classes.text}>
              {t('examinations.delete-in-progress')}
            </Typography>
          ) : (
            <Typography className={classes.text}>
              {t('examinations.delete-confirm')}
            </Typography>
          )}
          <div className={classes.actions}>
            <ButtonBase
              className={clsx(classes.button, { [classes.hide]: loading })}
              onClick={handleClose}
              id="exam-delete-deny"
            >
              {t('no')}
            </ButtonBase>
            <ButtonPrimary
              className={classes.button}
              onClick={handleDeleteClick}
              id="exam-delete-confirm"
              disabled={loading}
            >
              {loading ? <CircularLoader /> : t('yes')}
            </ButtonPrimary>
          </div>
        </>
      )}
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 0, 1, 0),
  },
  button: {
    width: '130px',
    height: '46px',
    borderRadius: '8px',
  },
  dialog: {
    width: '350px',
  },
  text: {
    width: '250px',
    textAlign: 'center',
    margin: '0 auto',
  },
  hide: {
    visibility: 'hidden',
  },
}));
