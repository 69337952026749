import clsx from 'clsx';
import { Button, Avatar, makeStyles } from '@material-ui/core';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';

type GoogleAuthButtonProps = ButtonBaseProps & {
  label: string;
};

export const GoogleAuthButton = ({
  className,
  onClick,
  label,
}: GoogleAuthButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      fullWidth
      color="primary"
      className={clsx(classes.button, className)}
      classes={{
        startIcon: classes.startIcon,
      }}
      startIcon={
        <Avatar className={classes.avatar} src="images/google-icon.svg" />
      }
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    boxShadow: theme.shadows[1],
    height: '56px',
    minHeight: '48px',
    color: theme.palette.primary.darkmode,
    paddingLeft: theme.spacing(5),
  },
  startIcon: {
    position: 'absolute',
    left: theme.spacing(3),
  },
  avatar: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
}));
