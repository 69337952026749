import { useTranslation } from 'react-i18next';
import { makeStyles, TableHead, TableRow, Typography } from '@material-ui/core';
import { useConfig } from 'context/providers/ConfigProvider';
import { CheckboxBase } from 'components/Input/Checkbox';
import { HeadCell } from 'components/Table/HeadCell';
import { HeadCellWithTooltip } from 'pages/examinations/examinationsList/HeadCellWithTooltip';

type ExaminationsTableHeaderProps = {
  handleSelectAllClick: () => void;
  allSelected: boolean;
  disabled: boolean;
};

export const ExaminationsTableHeader = ({
  handleSelectAllClick,
  allSelected,
  disabled,
}: ExaminationsTableHeaderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { colorRanges } = useConfig();

  const columnLabels = [
    {
      name: 'startDate',
      text: 'examination-date-number',
      sortable: true,
      tooltip: { text: '' },
    },
    {
      name: 'totalRegistrationTime',
      text: 'examination-time',
      sortable: false,
      tooltip: { text: '' },
    },
    {
      name: 'totalSleepTime',
      text: 'sleep-time',
      sortable: false,
      tooltip: { text: '' },
    },
    {
      name: 'timeToSleepFromExaminationBeginning',
      text: 'time-to-sleep',
      sortable: false,
      tooltip: { text: '' },
    },
    {
      name: 'supinePositionRatio',
      text: 'supine-position-ratio',
      sortable: false,
      tooltip: {
        text: t('examinations.supine-position-ratio-tooltip'),
      },
    },
    {
      name: 'ahi',
      text: 'ahi',
      sortable: true,
      tooltip: {
        text: t('examinations.ahi-tooltip'),
        range: colorRanges.ahi,
      },
    },
    {
      name: 'supineAhi',
      text: 'supine-ahi',
      sortable: true,
      tooltip: {
        text: t('examinations.supine-ahi-tooltip'),
        range: colorRanges.supine_ahi,
      },
    },
    {
      name: 'snoringIndex',
      text: 'snoring-index-abbr',
      sortable: true,
      tooltip: {
        text: t('examinations.snoring-index-tooltip'),
        range: colorRanges.snoring_index,
      },
    },
    {
      name: 'supineSnorigIndex',
      text: 'supine-snoring-index-abbr',
      sortable: true,
      tooltip: {
        text: t('examinations.supine-snoring-index-tooltip'),
        range: colorRanges.supine_snoring_index,
      },
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {columnLabels.map((label) => (
          <HeadCellWithTooltip key={label.name} id={label.name} label={label} />
        ))}
        <HeadCell className={classes.checkboxCell}>
          <div className={classes.checkboxCellContent}>
            <CheckboxBase
              id="select-all-checkbox"
              className={classes.checkbox}
              checked={allSelected}
              disabled={disabled}
              onClick={handleSelectAllClick}
              size={24}
              radius={4}
            />
            <Typography className={classes.checkboxLabel}>
              {t('all')}
            </Typography>
          </div>
        </HeadCell>
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles((theme) => ({
  checkboxCell: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  checkboxCellContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  checkbox: {
    padding: 0,
    width: '24px',
  },
  checkboxLabel: {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5, 0, 0, 0),
  },
}));
