import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OkButton } from 'components/Button/OkButton';

type ErrorMessageProps = {
  onClose: () => void;
};

export const ErrorMessage = ({ onClose }: ErrorMessageProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Alert severity="error" className={classes.errorMessage}>
        {t('examinations.delete-error')}
      </Alert>
      <div className={classes.buttonContainer}>
        <OkButton onClick={onClose} id="delete-error-confirm-button" />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
