import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  calculateAverageValue,
  metricsEnum,
} from 'pages/examinations/examinationsSummary/calculateAverageValue';
import { formatDurationInSeconds } from 'utils/helpers';
import { ExaminationSimple } from 'commonTypes/examinationTypes';

type AverageSleepTimeProps = {
  data: ExaminationSimple[] | null;
};

export const AverageSleepTime = ({ data }: AverageSleepTimeProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  let averageSleepTime;

  if (data) {
    averageSleepTime = calculateAverageValue(data, metricsEnum.TOTALSLEEPTIME);
  }

  return (
    <div>
      <Typography variant="body2" className={classes.label}>
        {t('examinations.average-sleep-time')}
      </Typography>
      <Typography
        variant="h3"
        className={classes.value}
        id="average-sleep-time"
      >
        {averageSleepTime && formatDurationInSeconds(averageSleepTime)}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.secondary,
  },
  value: {
    lineHeight: '24px',
  },
}));
