import { RadialBarChart } from 'recharts';
import { makeStyles } from '@material-ui/core';
import { round } from 'lodash';
import clsx from 'clsx';
import theme from 'theme';
import { BaseCircleChart } from 'charts/CircleCharts/BaseCircleChart';
import { SummaryCircleChartLegend } from 'charts/CircleCharts/SummaryCircleChartLegend';

type SummaryCircleChartProps = {
  metric: {
    label: string;
    value: number | undefined;
    range: number[] | undefined;
    unit: string;
  };
  id: string;
  className?: string;
};

const circleSize = 130;

export const SummaryCircleChart = ({
  metric,
  id,
  className,
}: SummaryCircleChartProps) => {
  const classes = useStyles();

  let roundedValue;

  if (metric.value === null) {
    roundedValue = null;
  }

  if (
    metric.value !== null &&
    metric.value !== undefined &&
    metric.range !== undefined
  ) {
    if (metric.unit === '%') {
      roundedValue = round(metric.value, 0);
    } else {
      roundedValue = round(metric.value, 2);
    }
  }

  const data = [{ name: metric.label, value: roundedValue }];

  return (
    <div className={clsx(className, classes.paper)}>
      <SummaryCircleChartLegend data={metric} />
      <RadialBarChart
        className={classes.chartArea}
        id={id}
        width={circleSize}
        height={circleSize}
        cx={circleSize / 2}
        cy={circleSize / 2}
        innerRadius={55}
        outerRadius={75}
        barSize={20}
        data={data}
        startAngle={90}
        endAngle={-270}
      >
        {BaseCircleChart({
          circleSize,
          metric,
          roundedValue,
          innerLabelClass: classes.innerLabel,
        })}
      </RadialBarChart>
    </div>
  );
};

SummaryCircleChart.defaultProps = {
  className: undefined,
};

const useStyles = makeStyles(() => ({
  innerLabel: {
    fill: theme.palette.text.primary,
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 700,
  },
  paper: {
    boxShadow: theme.shadows[1],
    width: '330px',
    height: '160px',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2, 3, 2, 3),
    display: 'flex',
  },
  chartArea: {
    margin: theme.spacing(0, 0, 0, 'auto'),
  },
}));
