import { toRoman } from 'roman-numerals';
import { DateTime } from 'luxon';
import i18n from 'translations/i18n';
import { ExaminationSimple } from 'commonTypes/examinationTypes';
import {
  SSSIChartDataItem,
  DividedAhiChartDataItem,
  CombinedAhiChartDataItem,
} from 'charts/chartTypes/chartTypes';

export const formatXAxisDate = (startDate: string) => {
  const date = DateTime.fromISO(startDate).setLocale(i18n.language);
  const dayString = date.toLocaleString({ weekday: 'short' });
  const { day } = date;
  const month = toRoman(date.month);

  return {
    dayString,
    day,
    month,
  };
};

export const fillEmptyData = <
  T extends
    | SSSIChartDataItem
    | DividedAhiChartDataItem
    | CombinedAhiChartDataItem
>(
  data: ExaminationSimple[],
  dataObjects: T[]
) => {
  if (data.length > 0 && data.length < 14) {
    const copy = { ...dataObjects[0] };
    Object.keys(copy).forEach((key) => {
      // @ts-ignore
      copy[key] = undefined;
    });

    const numberOfEmptyObjects = 14 - data.length;
    for (let i = 0; i < numberOfEmptyObjects; i += 1) {
      dataObjects.push(copy);
    }
  }
};
