import { ChangeEvent } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { IconButton } from 'components/Button/IconButton';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Upload } from 'icons/Upload.svg';

type FileUploadButtonProps = {
  accept: string;
  id: string;
  handleUpload: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const FileUploadButton = ({
  accept,
  handleUpload,
  id,
}: FileUploadButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <IconButton size="small" component="label" className={classes.button}>
      <Tooltip title={t('examinations.file-upload')!}>
        <Upload />
      </Tooltip>
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={(event) => handleUpload(event)}
        accept={accept}
        id={id}
      />
    </IconButton>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    boxShadow: 'none',
    backgroundColor: 'inherit',
  },
}));
