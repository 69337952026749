import { Dispatch, SetStateAction } from 'react';
import { checkProcessingStatus } from 'api/examinations/examinationsApi';
import { isEmpty } from 'lodash';
import { UploadResult } from 'pages/examinations/examinationUpload/AddExaminationDialog';

// eslint-disable-next-line
export enum ExaminationStatuses {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export const checkExaminationStatus = (
  examinationId: string,
  setIsProcessing: Dispatch<SetStateAction<boolean>>
): Promise<ExaminationStatuses> => {
  setIsProcessing(true);
  let triesLeft = 60;
  return new Promise((resolve, reject) => {
    const examStatusInterval = setInterval(() => {
      checkProcessingStatus(examinationId).then((statusList) => {
        if (isEmpty(statusList)) {
          return;
        }
        const { examinationStatus } = statusList.pop();
        if (
          examinationStatus === ExaminationStatuses.DONE ||
          examinationStatus === ExaminationStatuses.FAILED
        ) {
          resolve(examinationStatus);
          clearInterval(examStatusInterval);
          setIsProcessing(false);
        } else if (triesLeft < 1) {
          reject(new Error(UploadResult.TIMEOUT_ERROR));
          clearInterval(examStatusInterval);
          setIsProcessing(false);
        }
      });
      triesLeft -= 1;
    }, 1000);
  });
};
