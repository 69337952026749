import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBaseProps } from '@material-ui/core';
import { ButtonBaseWithIcon } from 'components/Button/ButtonBaseWithIcon';
import { ReactComponent as File } from 'icons/File.svg';
import { ReactComponent as FileDisabled } from 'icons/FileDisabled.svg';

type PdfDownloadButtonProps = ButtonBaseProps & {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

export const PdfDownloadButton = ({
  onClick,
  disabled,
  className,
}: PdfDownloadButtonProps) => {
  const { t } = useTranslation();

  return (
    <ButtonBaseWithIcon
      className={className}
      onClick={onClick}
      startIcon={disabled ? <FileDisabled /> : <File />}
      disabled={disabled}
      id="pdf-button"
    >
      {t('PDF')}
    </ButtonBaseWithIcon>
  );
};

PdfDownloadButton.defaultProps = {
  disabled: false,
};
