import { mainApi } from 'api/config/interceptors';
import { createProfileDTO, editProfileDTO } from 'api/profiles/profileDTOs';

export const validatePesel = (pesel: string) => {
  return mainApi.get(`/validators/pesel/${pesel}`).then((response) => {
    return response.data;
  });
};

export const createProfile = (profileData: createProfileDTO) => {
  return mainApi.post(`/profile`, profileData).then((response) => {
    return response.data;
  });
};

export const editProfile = ({ profileId, profileData }: editProfileDTO) => {
  return mainApi.patch(`/profile/${profileId}`, profileData).then((res) => {
    return res.data;
  });
};

export const getProfileById = (profileId: string) => {
  return mainApi.get(`/profile/${profileId}`).then((res) => {
    return res.data;
  });
};

export const checkConsents = (profileId: string) => {
  return mainApi.get(`/consent/profile/${profileId}`).then((res) => {
    return res.data;
  });
};

export const getProfiles = () => {
  const params = {
    sortOrder: 'DESC',
    sortField: 'accessLogs',
    offset: 0,
    size: 9999,
    filters: [],
  };

  return mainApi
    .get(`/profile/search`, {
      params: {
        requestQuery: btoa(
          unescape(encodeURIComponent(JSON.stringify(params)))
        ),
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const searchProfiles = (searchPhrase: string) => {
  const params = {
    sortOrder: 'DESC',
    sortField: 'accessLogs',
    offset: 0,
    size: 9999,
    filters: [
      {
        field: '',
        operand: 'LIKE',
        value: searchPhrase,
      },
    ],
  };

  return mainApi
    .get(`/profile/search`, {
      params: {
        requestQuery: btoa(
          unescape(encodeURIComponent(JSON.stringify(params)))
        ),
      },
    })
    .then((res) => {
      return res.data;
    });
};
