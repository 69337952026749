import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import check from 'icons/WhiteCheck.svg';

type CheckboxBaseProps = Omit<CheckboxProps, 'size'> & {
  size?: number;
  radius?: number;
};

export const CheckboxBase = ({
  size,
  radius,
  checked,
  className,
  ...props
}: CheckboxBaseProps) => {
  const cssProps = {
    size,
    radius,
  };
  const classes = useStyles(cssProps);

  return (
    <Checkbox
      className={clsx(className, classes.root)}
      disableRipple
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      checked={checked}
      {...props}
    />
  );
};

CheckboxBase.defaultProps = {
  size: 32,
  radius: 8,
};

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: (cssProps: CheckboxBaseProps) => cssProps.radius,
    height: (cssProps: CheckboxBaseProps) => cssProps.size,
    width: (cssProps: CheckboxBaseProps) => cssProps.size,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.default,
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      display: 'block',
      width: (cssProps: CheckboxBaseProps) => cssProps.size,
      height: (cssProps: CheckboxBaseProps) => cssProps.size,
      backgroundImage: `url(${check})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.primary.dark,
    },
    'input:disabled ~ &': {
      backgroundColor: theme.palette.grey[400],
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
}));
