import { MouseEvent } from 'react';
import { ButtonBaseProps, Tooltip, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ButtonBaseWithIcon } from 'components/Button/ButtonBaseWithIcon';
import { ReactComponent as Delete } from 'icons/Delete.svg';
import { ReactComponent as DeleteDisabled } from 'icons/DeleteDisabled.svg';
import { ReactComponent as TooltipIcon } from 'icons/Tooltip.svg';

type ExamDeleteButtonProps = ButtonBaseProps & {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  tooltip: boolean;
};

const DeleteButton = ({
  onClick,
  className,
  disabled,
  tooltip,
}: ExamDeleteButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ButtonBaseWithIcon
      className={className}
      onClick={onClick}
      startIcon={disabled ? <DeleteDisabled /> : <Delete />}
      endIcon={
        tooltip && disabled ? (
          <TooltipIcon className={classes.tooltipIcon} />
        ) : null
      }
      disabled={disabled}
      id="exam-delete-button"
    >
      {t('delete')}
    </ButtonBaseWithIcon>
  );
};

export const ExamDeleteButton = ({
  disabled,
  tooltip,
  ...props
}: ExamDeleteButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Tooltip
      classes={{ tooltipPlacementBottom: classes.tooltip }}
      title={tooltip && disabled ? t('examinations.delete-impossible')! : ''}
    >
      <span>
        <DeleteButton tooltip={tooltip} disabled={disabled} {...props} />
      </span>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    marginTop: '20px',
  },
  tooltipIcon: {
    height: '16px',
    width: '16px',
  },
}));
