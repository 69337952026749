import { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'context/providers/AuthProvider';
import { ActiveProfileData } from 'pages/profiles/activeProfileInfo/ActiveProfileData';
import { EditProfileButton } from 'pages/profiles/editProfile/EditProfileButton';
import { ActiveProfileName } from 'pages/profiles/activeProfileInfo/ActiveProfileName';
import { ExaminationData } from 'pages/profiles/activeProfileInfo/ExaminationData';
import { ListSummarySwitch } from 'pages/profiles/activeProfileInfo/ListSummarySwitch';
import { NavigationButtons } from 'pages/profiles/activeProfileInfo/NavigationButtons';
import { getProfileById } from 'api/profiles/profilesApi';
import { Profile } from 'commonTypes/authTypes';
import { ProfileConsentDialog } from 'components/Consents/ProfileConsentsDialog';
import { Toast } from 'components/Alert/Toast';

export const ActiveProfilePanel = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { examinationId } = useParams<{ examinationId: string }>();
  const { activeProfile, isBasic, user } = useAuth();
  const [activeProfileData, setActiveProfileData] = useState<
    Profile | undefined
  >(undefined);
  const [acceptConsentsResult, setAcceptConsentsResult] = useState({
    resolved: false,
    status: '',
  });

  const fetchProfile = useCallback(() => {
    if (activeProfile?.profileId) {
      getProfileById(activeProfile.profileId)
        .then((data) => {
          setActiveProfileData(data);
        })
        .catch(() => {});
    }
  }, [
    activeProfile?.profileId,
    activeProfile?.hasConsents,
    user?.requiredConsents,
  ]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  return (
    <>
      {activeProfileData && (
        <div className={classes.container}>
          <div className={classes.profileInfo}>
            <ActiveProfileName profile={activeProfileData} />
            <ActiveProfileData profileData={activeProfileData} />
            <EditProfileButton
              className={classes.editButton}
              profileData={activeProfileData}
              setProfileData={setActiveProfileData}
            />
          </div>
          <div className={classes.bottomRow}>
            <ListSummarySwitch />
            {examinationId && (
              <>
                <NavigationButtons />
                <ExaminationData />
              </>
            )}
          </div>
        </div>
      )}
      <ProfileConsentDialog
        open={Boolean(
          user?.requiredConsents &&
            activeProfile &&
            !activeProfile.hasConsents &&
            isBasic
        )}
        setAcceptConsentsResult={setAcceptConsentsResult}
        profileId={activeProfile?.profileId}
        profileConsents={[]}
      />
      <Toast
        open={acceptConsentsResult.status === 'error'}
        setState={setAcceptConsentsResult}
        severity="error"
      >
        {t('error')}
      </Toast>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  editButton: {
    margin: theme.spacing(0, 0, 0, 'auto'),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  profileInfo: {
    display: 'flex',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));
