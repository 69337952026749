import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { makeStyles, Typography } from '@material-ui/core';
import { RegisterWrapper } from 'pages/auth/register/RegisterWrapper';
import { RegisterForm } from 'pages/auth/register/RegisterForm';
import { GoogleAuth } from 'pages/auth/googleAuth/GoogleAuth';
import { ConsentList } from 'pages/auth/register/ConsentList';
import { ErrorMessage } from 'pages/auth/ErrorMessage';
import { EmailSentConfirmation } from 'pages/auth/register/EmailSentConfirmation';
import { register } from 'api/auth/authApi';
import { registerValidationSchema } from 'pages/auth/register/registerValidationSchema';
import { registerErrors } from 'pages/auth/register/registerErrors';
import { processRegisterValues } from 'pages/auth/register/helpers';
import { initialValues } from 'pages/auth/register/formikValues';
import { SubmitButton } from 'pages/auth/register/SubmitButton';
import { RegisterFooter } from 'pages/auth/register/RegisterFooter';

const RegisterPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [registerError, setRegisterError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [consentsExpanded, setConsentsExpanded] = useState(false);

  const registerMutation = useMutation(register, {
    onError: (error: AxiosError) => {
      if (
        error.response &&
        error.response.data.errorId === registerErrors.accountAlreadyExists.id
      ) {
        setRegisterError(registerErrors.accountAlreadyExists.message);
        setConsentsExpanded(false);
      } else {
        setRegisterError(registerErrors.genericError.message);
        setConsentsExpanded(false);
      }
    },
    onSuccess: (data) => {
      setUserEmail(data.email);
      setConfirmationDialogOpen(true);
      formik.resetForm();
      setConsentsExpanded(false);
    },
  });

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setRegisterError(null);
      const apiValues = processRegisterValues(values);

      registerMutation.mutate(apiValues);
    },
    validationSchema: registerValidationSchema,
  });

  const handleDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  return (
    <RegisterWrapper>
      <>
        <GoogleAuth
          className={classes.googleAuth}
          label={t('register-page.google')}
          setError={setRegisterError}
          setLoading={setLoading}
        />
        <Typography className={classes.emailLogin}>
          {t('login-page.or-email')}
        </Typography>
        {registerError && (
          <ErrorMessage message={t(`register-page.error.${registerError}`)} />
        )}
        <RegisterForm formik={formik} />
        <ConsentList
          formik={formik}
          expanded={consentsExpanded}
          setExpanded={setConsentsExpanded}
        />
        <SubmitButton
          onSubmit={formik.handleSubmit}
          loading={registerMutation.isLoading || loading}
        />
        <RegisterFooter />
        <EmailSentConfirmation
          email={userEmail}
          open={confirmationDialogOpen}
          onClose={handleDialogClose}
        />
      </>
    </RegisterWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  googleAuth: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    height: '52px',
    minHeight: '52px',
  },
  emailLogin: {
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    alignSelf: 'center',
  },
}));

export default RegisterPage;
