import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import {
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { debounce } from 'lodash';
import { useProfiles } from 'context/providers/ProfilesProvider';
import { searchProfiles } from 'api/profiles/profilesApi';
import { ReactComponent as Clear } from 'icons/Close.svg';
import { ReactComponent as Search } from 'icons/Search.svg';

type SearchBoxProps = {
  setNoResults: Dispatch<SetStateAction<boolean>>;
};

export const SearchBox = ({ setNoResults }: SearchBoxProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setProfiles } = useProfiles();
  const [searchValue, setSearchValue] = useState('');
  const serachPerformed = useRef(false);

  const searchProfilesMutation = useMutation(searchProfiles, {
    onSuccess: (data) => {
      setProfiles(data.content);
      if (data.empty) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    },
  });

  useEffect(() => {
    // Clear search when component unmounts
    return () => {
      if (serachPerformed.current) {
        handleClear();
      }
    };
  }, []);

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((searchPhrase) => {
      searchProfilesMutation.mutate(searchPhrase);
    }, 300),
    []
  );

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    serachPerformed.current = true;
    setSearchValue(event.target.value);
    const { value } = event.target;

    if (value.length > 2) {
      debouncedSearch(value);
    }
    if (value.length === 0) {
      debouncedSearch('');
    }
  };

  const handleClear = () => {
    setSearchValue('');
    searchProfilesMutation.mutate('');
  };

  const ClearButton = () => {
    return (
      <InputAdornment position="end">
        <IconButton onClick={handleClear} className={classes.iconButton}>
          <Clear className={classes.clearIcon} />
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <TextField
      id="profiles-searchbox"
      variant="outlined"
      value={searchValue}
      classes={{ root: classes.root }}
      placeholder={t('profiles.search-placeholder')}
      onChange={(event) => handleChange(event)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" className={classes.searchIcon}>
            <Search />
          </InputAdornment>
        ),
        endAdornment: searchValue ? ClearButton() : null,
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 1, 1, 2),
    '& .MuiOutlinedInput-root': {
      width: '200px',
      height: '40px',
      borderRadius: '8px',
      paddingLeft: theme.spacing(1),
      boxShadow: theme.shadows[1],
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.light,
      },
    },
  },
  searchIcon: {
    width: '16px',
    height: '16px',
  },
  clearIcon: {
    width: '12px',
    height: '12px',
  },
  iconButton: {
    padding: 0,
  },
}));
