import { ButtonPrimary } from 'components/Button/ButtonPrimary';
import { CircularLoader } from 'components/Progress/CircularLoader';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

type SubmitButtonProps = {
  onSubmit: () => void;
  loading: boolean;
};

export const SubmitButton = ({ onSubmit, loading }: SubmitButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ButtonPrimary onClick={onSubmit} className={classes.registerButton}>
      {loading ? <CircularLoader /> : t('register')}
    </ButtonPrimary>
  );
};

const useStyles = makeStyles((theme) => ({
  registerButton: {
    margin: theme.spacing(1, 0, 1, 0),
    height: '52px',
    minHeight: '52px',
  },
}));
