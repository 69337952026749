import { useState } from 'react';
import { Typography, makeStyles, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { QAGroup } from 'pages/help/QAGroup';
import { performingExaminationQA } from 'pages/help/performingExaminationQA';
import { sensorHandlingQA } from 'pages/help/sensorHandlingQA';
import { userAccountQA } from 'pages/help/userAccountQA';
import { mobileAppQA } from 'pages/help/mobileAppQA';
import { CustomLink } from 'components/Link/Link';
import { ContactForm } from 'pages/account/ContactForm';
import { Toast } from 'components/Alert/Toast';

const HelpPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [contactFormOpen, setContactFormOpen] = useState(false);
  const [sendMsgResult, setSendMsgResult] = useState({
    resolved: false,
    status: '',
  });

  return (
    <div className={classes.content}>
      <Typography variant="h2" className={classes.title}>
        {t('help.header')}
      </Typography>
      <QAGroup content={performingExaminationQA} />
      <QAGroup content={sensorHandlingQA} />
      <QAGroup content={userAccountQA} />
      <QAGroup content={mobileAppQA} />
      <Divider className={classes.divider} />
      <div className={classes.container}>
        <Typography className={classes.contactText}>
          {t('help.no-answer')}
        </Typography>
        <CustomLink
          to="#"
          onClick={() => setContactFormOpen(true)}
          label={t('account.contact-form')}
        />
      </div>
      <ContactForm
        open={contactFormOpen}
        onClose={() => setContactFormOpen(false)}
        setSendMsgResult={setSendMsgResult}
      />
      {sendMsgResult.resolved && (
        <Toast
          open
          setState={setSendMsgResult}
          severity={sendMsgResult.status === 'success' ? 'success' : 'error'}
        >
          {sendMsgResult.status === 'success'
            ? t('account.send-msg-success')
            : t('account.send-msg-error')}
        </Toast>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(5),
  },
  content: {
    marginBottom: theme.spacing(5),
  },
  container: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
  },
  contactText: {
    marginRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(4),
  },
}));

export default HelpPage;
