import * as Yup from 'yup';
import i18n from 'translations/i18n';
import {
  firstNameValidation,
  lastNameValidation,
  dateOfBirthValidation,
  heightValidation,
  weightValidation,
  phoneNumberValidation,
  consentValidation,
} from 'validation/validationFunctions';
import { profileActionType } from 'commonTypes/profileTypes';

export const profileValidationSchema = (
  isDoctor: boolean,
  peselValidationCache: { [key: string]: boolean },
  type: profileActionType
) => {
  const validatePesel = (pesel: string | undefined) => {
    // PESEL is optional
    if (!pesel) {
      return true;
    }
    // Every validated PESEL is added to peselValidationCache with validation result
    if (pesel in peselValidationCache) {
      return peselValidationCache[pesel];
    }
    return true;
  };

  const basicValidationSchema = Yup.object().shape({
    firstName: firstNameValidation(),
    lastName: lastNameValidation(),
    govIdentification: Yup.string()
      .length(11, i18n.t('validation.invalid-pesel-length'))
      .test('Check govid', i18n.t('validation.invalid-pesel'), validatePesel)
      .nullable(),
    dateOfBirth: dateOfBirthValidation(),
    height: heightValidation(),
    weight: weightValidation(),
    phoneNumber: phoneNumberValidation(),
    termsConditionsAgreement:
      type === profileActionType.ADD ? consentValidation() : Yup.bool(),
    dataProcessingAgreement:
      type === profileActionType.ADD ? consentValidation() : Yup.bool(),
  });

  const doctorValidationSchema = Yup.object().shape({
    noPeselChecked: Yup.boolean(),
    firstName: firstNameValidation(),
    lastName: lastNameValidation(),
    govIdentification: Yup.string()
      .when('noPesel', {
        is: false,
        then: Yup.string().required(i18n.t('validation.required-pesel')),
      })
      .length(11, i18n.t('validation.invalid-pesel-length'))
      .test('Check govid', i18n.t('validation.invalid-pesel'), validatePesel)
      .nullable(),
    dateOfBirth: dateOfBirthValidation(),
    height: heightValidation(),
    weight: weightValidation(),
    phoneNumber: phoneNumberValidation(),
  });

  const validationSchema = isDoctor
    ? doctorValidationSchema
    : basicValidationSchema;

  return validationSchema;
};
