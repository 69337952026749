import { ChangeEvent, ReactNode } from 'react';
import clsx from 'clsx';
import { Select, FormControl, makeStyles } from '@material-ui/core';
import { ReactComponent as DownArrowDisabled } from 'icons/DownArrowDisabled.svg';

type SelectBaseProps = {
  className: string;
  children: ReactNode;
  value: string;
  handleChange: (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => void;
  id: string;
};

export const SelectBase = ({
  className,
  children,
  value,
  handleChange,
  id,
}: SelectBaseProps) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined">
      <Select
        id={id}
        value={value}
        onChange={handleChange}
        className={clsx(className, classes.select)}
        IconComponent={(props) => <DownArrowDisabled {...props} />}
        classes={{ icon: classes.icon }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          classes: { paper: classes.selectMenu },
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  select: {
    width: '200px',
    height: '46px',
    borderRadius: '8px',
    boxShadow: theme.shadows[1],
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.75px',

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  icon: {
    top: theme.spacing(2.5),
    right: theme.spacing(1.5),
  },
  selectMenu: {
    borderRadius: '8px',
  },
}));
