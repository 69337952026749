import { Dispatch, SetStateAction } from 'react';
import { isEmpty } from 'lodash';
import {
  ValueTypes,
  FilterNames,
  FilterOperands,
} from 'context/providers/FiltersProvider';

export class RangeFilter {
  name: FilterNames;
  valueType: ValueTypes;
  rawValue: number[];
  setValue: Dispatch<SetStateAction<number[]>>;
  marks: number[];
  label: { text: string; unit: string };

  constructor(
    name: FilterNames,
    valueType: ValueTypes,
    rawValue: number[],
    setValue: Dispatch<SetStateAction<number[]>>,
    marks: number[],
    label: { text: string; unit: string }
  ) {
    this.name = name;
    this.valueType = valueType;
    this.rawValue = rawValue;
    this.setValue = setValue;
    this.marks = marks;
    this.label = label;
  }

  shouldSkip = () => {
    const [firstMark, lastMark] = this.marks;
    return (
      !this.rawValue ||
      isEmpty(this.rawValue) ||
      (this.rawValue[0] === firstMark && this.rawValue[1] === lastMark)
    );
  };

  valueLow = () => {
    if (this.rawValue) {
      return this.processValue(this.rawValue[0]);
    }
  };

  valueHigh = () => {
    if (this.rawValue) {
      return this.processValue(this.rawValue[1]);
    }
  };

  processValue = (value: number) => {
    if (this.valueType === ValueTypes.TIME) {
      return value * 3600;
    }
    return value;
  };

  clear = () => {
    this.setValue(this.marks);
  };

  toApiFilter = () => {
    const lowValueFilter = {
      field: this.name,
      operand: FilterOperands.GTE,
      value: this.valueLow(),
    };
    const highValueFilter = {
      field: this.name,
      operand: FilterOperands.LTE,
      value: this.valueHigh(),
    };

    const [firstMark, lastMark] = this.marks;

    if (this.rawValue[0] === firstMark && this.rawValue[1] === lastMark) {
      return [];
    }
    if (this.rawValue[1] === lastMark) {
      return [lowValueFilter];
    }
    return [lowValueFilter, highValueFilter];
  };
}
