import { useState, MouseEvent, useEffect } from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

export const ListSummarySwitch = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { examinationId } = useParams<{ examinationId: string }>();
  const [alignment, setAlignment] = useState<string | null>(null);

  useEffect(() => {
    if (examinationId) {
      setAlignment(null);
    } else if (location.pathname === '/') {
      setAlignment('summary');
    } else {
      setAlignment('list');
    }
  }, [examinationId, location.pathname]);

  const handleAlignment = (
    event: MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment === null) {
      return;
    }
    if (examinationId) {
      history.push('/');
    }
    if (newAlignment === 'summary') {
      history.push('/');
    }
    if (newAlignment === 'list') {
      history.push('/examinations-list');
    }
    setAlignment(newAlignment);
  };

  return (
    <ToggleButtonGroup
      id="list-summary-toggle"
      value={alignment}
      exclusive
      onChange={handleAlignment}
      classes={{ root: classes.buttonGroup, grouped: classes.buttons }}
    >
      <ToggleButton
        id="examinations-summary-toggle"
        value="summary"
        classes={{ root: classes.button, selected: classes.selected }}
      >
        {t('examinations.summary')}
      </ToggleButton>
      {examinationId && (
        <Divider className={classes.divider} orientation="vertical" />
      )}
      <ToggleButton
        id="examinations-list-toggle"
        value="list"
        classes={{ root: classes.button, selected: classes.selected }}
      >
        {t('examinations.list')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    width: '325px',
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing(1, 0, 0, 0),
    boxShadow: theme.shadows[1],
  },
  buttons: {
    width: '160px',
    height: '44px',
    margin: theme.spacing(0.25),
    border: 'none',
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.primary.darkmode,
  },
  button: {
    '&&': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  selected: {
    '&&': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
        cursor: 'default',
      },
    },
  },
  divider: {
    width: '1px',
    height: '33px',
    margin: 'auto 0',
  },
}));
