import AccountActivation from 'pages/auth/activateAccount/AccountActivation';
import ResetPasswordPage from 'pages/auth/resetPassword/ResetPasswordPage';
import WelcomePage from 'pages/auth/invite/WelcomePage';

export const commonRoutes = [
  {
    path: '/activate-account/:userId/:code',
    component: AccountActivation,
  },
  {
    path: '/forgot-password/:userId/:code',
    component: ResetPasswordPage,
  },
  {
    path: '/welcome/:userId/:code',
    component: WelcomePage,
  },
];
