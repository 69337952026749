import * as Yup from 'yup';
import i18n from 'translations/i18n';
import {
  passwordValidation,
  emailValidation,
} from 'validation/validationFunctions';

export const emailValidationSchema = () => {
  return Yup.object().shape({
    email: emailValidation(),
  });
};

export const passwordValidationSchema = (password: string) => {
  const validatePasswordMatch = (passwordConfirm: string | undefined) => {
    return passwordConfirm === password;
  };

  return Yup.object().shape({
    password: passwordValidation(),
    passwordConfirm: Yup.string()
      .required(i18n.t('validation.required-password'))
      .test(
        'Check if password matches',
        i18n.t('validation.invalid-passwordConfirm'),
        validatePasswordMatch
      ),
  });
};
