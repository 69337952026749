import { ChangeEvent } from 'react';
import { TableRow, Tooltip, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FileUploadButton } from 'pages/examinations/examinationFileUpload/FileUploadButton';
import { DataCell } from 'components/Table/DataCell';
import { IconButton } from 'components/Button/IconButton';
import { ExaminationFile, OptionalFile } from 'commonTypes/examinationTypes';
import { AcceptedOptionalFileTypes } from 'utils/constants';
import { ReactComponent as Upload } from 'icons/Upload.svg';

type ExamFileRowProps = {
  optionalFile: OptionalFile;
  file: ExaminationFile | null;
  handleUpload?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDialogOpen?: () => void;
};

// eslint-disable-next-line
export enum UploadResult {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  EMPTY_FILE = 'EMPTY_FILE',
}

export const ExamFileRow = ({
  optionalFile,
  file,
  handleUpload,
  handleDialogOpen,
}: ExamFileRowProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const tableRowId =
    optionalFile.fileType === AcceptedOptionalFileTypes.OTHER && file
      ? file.id
      : optionalFile.fileType;

  return (
    <TableRow className={classes.tableRow}>
      <DataCell id={`${tableRowId}-description`}>
        {optionalFile.description}
      </DataCell>
      <DataCell id={`${tableRowId}-name`}>
        {file && (file.originalName ? file.originalName : file.fileName)}
      </DataCell>
      <DataCell id={`${tableRowId}-status`}>
        {file && (file.uploaded ? t('success') : t('failed'))}
      </DataCell>
      <DataCell id={`${tableRowId}-comment`}>
        {file && file.description}
      </DataCell>
      <DataCell align="center">
        {!file && handleUpload && (
          <FileUploadButton
            accept={optionalFile.acceptExtension}
            handleUpload={handleUpload}
            id={`${tableRowId}-upload`}
          />
        )}
        {handleDialogOpen && (
          <IconButton
            size="small"
            component="label"
            className={classes.button}
            onClick={handleDialogOpen}
            id="add-other-file"
          >
            <Tooltip title={t('examinations.file-upload')!}>
              <Upload />
            </Tooltip>
          </IconButton>
        )}
      </DataCell>
    </TableRow>
  );
};

ExamFileRow.defaultProps = {
  handleUpload: undefined,
  handleDialogOpen: undefined,
};

const useStyles = makeStyles((theme) => ({
  tableRow: {
    height: '35px',

    '&:nth-child(even)': {
      backgroundColor: theme.palette.background.default,
    },
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.grey[500],
    },
  },
  button: {
    boxShadow: 'none',
    backgroundColor: 'inherit',
  },
}));
