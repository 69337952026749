import { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, List, Box, Link } from '@material-ui/core';
import { MenuItem } from 'components/Navbar/MenuItem';
import { ReactComponent as Chart } from 'icons/Chart.svg';
import { ReactComponent as AlertInfo } from 'icons/AlertInfo.svg';
import { ReactComponent as Settings } from 'icons/Settings.svg';
import { ReactComponent as Sensor } from 'icons/Sensor.svg';
import { ReactComponent as Hub } from 'icons/Hub.svg';
import { LogoutButton } from 'components/Navbar/LogoutButton';
import { UserAvatar } from 'components/Navbar/UserAvatar';

type MenuContentProps = {
  expanded?: boolean;
};

const getToken = () => {
  const token = localStorage.getItem('token');

  if (token) {
    return token.replace('Bearer', '').trim();
  }
};

const menuItems = [
  {
    name: 'examinations',
    paths: ['/', '/examination', 'examinations-list'],
    icon: <Chart />,
  },
  {
    name: 'account',
    paths: ['/account'],
    icon: <Settings />,
  },
  {
    name: 'help',
    paths: ['/help'],
    icon: <AlertInfo />,
  },
  {
    name: 'sensors',
    paths: ['/sensors'],
    icon: <Sensor />,
  },
];

export type Location = {
  pathname: string;
};

const calcSelectedItem = (location: Location) => {
  let selectedItem = 0;
  menuItems.forEach((item, index) => {
    item.paths.forEach((path) => {
      if (location.pathname.includes(path)) {
        selectedItem = index;
      }
    });
  });
  return selectedItem;
};

export const MenuContent = ({ expanded }: MenuContentProps) => {
  const classes = useStyles();
  const location = useLocation();
  const [transitionFinished, setTransitionFinished] = useState(true);

  const selectedItem = calcSelectedItem(location);

  const handleClick = () => {
    setTransitionFinished(false);
    setTimeout(() => {
      setTransitionFinished(true);
    }, 500);
  };

  const clebreHubItem = {
    name: 'clebre-hub',
    paths: [`${process.env.REACT_APP_CLEBRE_HUB_URL}/?token=${getToken()}`],
    icon: <Hub />,
    component: Link,
    target: '_blank',
  };

  return (
    <div className={classes.drawerContainer}>
      <Box>
        <RouterLink to="/">
          <img
            src="images/clebre-logoP293.png"
            alt="clebre logo"
            className={clsx(classes.logo, {
              [classes.bigLogo]: expanded,
              [classes.smallLogo]: !expanded,
            })}
          />
        </RouterLink>
        <List
          className={clsx(classes.menuList, { [classes.smallMenu]: !expanded })}
        >
          {menuItems.map((item, index) => (
            <MenuItem
              key={item.name}
              name={item.name}
              path={item.paths[0]}
              icon={item.icon}
              onClick={() => handleClick()}
              selected={selectedItem === index}
              expanded={expanded}
              transitionFinished={transitionFinished}
            />
          ))}
          <MenuItem
            key={clebreHubItem.name}
            name={clebreHubItem.name}
            path={clebreHubItem.paths[0]}
            icon={clebreHubItem.icon}
            onClick={() => handleClick()}
            selected={false}
            expanded={expanded}
            transitionFinished={transitionFinished}
            component={clebreHubItem.component}
            target={clebreHubItem.target}
          />
        </List>
      </Box>
      <Box className={classes.bottomItems}>
        <UserAvatar initialsOnly={!expanded} />
        <LogoutButton miniVersion={!expanded} />
      </Box>
    </div>
  );
};

MenuContent.defaultProps = {
  expanded: true,
};

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  logo: {
    display: 'block',
  },
  bigLogo: {
    width: '120px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    margin: theme.spacing(0, 7.5, 3, 7.5),
  },
  smallLogo: {
    width: '75px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    margin: theme.spacing(0, 3, 3, 3),
  },
  menuList: {
    padding: theme.spacing(0, 4, 0, 4),
    overflow: 'hidden',
  },
  smallMenu: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  bottomItems: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4, 3, 4),
  },
}));
