import { checkIfExamExists } from 'api/examinations/examinationsApi';
import { getSensor, getFirmwareVersion } from 'api/sensors/sensorsApi';
import { ValidationErrors } from 'pages/examinations/examinationUpload/AddExaminationDialog';
import { JsnMetadata } from 'pages/examinations/examinationUpload/validation/validateJSN';

export const validateWithApiCalls = ({
  examinationId,
  sensorSerial,
  firmwareVersion,
  hardwareRevision,
}: JsnMetadata) => {
  return Promise.all([
    validateFirmware(firmwareVersion, hardwareRevision),
    validateExaminationId(examinationId),
    validateSensor(sensorSerial),
  ]).then(([firmwareResponse, examinationResponse, sensorData]) => {
    return sensorData;
  });
};

const validateFirmware = (
  firmwareVersion: string,
  hardwareRevision: string
) => {
  return (
    getFirmwareVersion(firmwareVersion, hardwareRevision)
      // If the response is found (200), firmware is valid
      .catch((error) => {
        // if the response is 'not found' firmware is invalid
        if (error.response.status === 404) {
          throw new Error(ValidationErrors.FirmwareInvalid);
        }
        throw error;
      })
  );
};

const validateSensor = (sensorId: string) => {
  return getSensor(sensorId)
    .then((data) => {
      // if the response is found (200), sensor serial is valid
      return data;
    })
    .catch((error) => {
      // if the response is 'not found' sensor serial is invalid
      if (error.response.status === 404) {
        throw new Error(ValidationErrors.SensorInvalid);
      }
      throw error;
    });
};

const validateExaminationId = (examinationId: string) => {
  return checkIfExamExists(examinationId)
    .then(() => {
      // if the response is 204 examinationID is invalid (duplicated)
      throw new Error(ValidationErrors.ExamIdInvalid);
    })
    .catch((error) => {
      if (error.message === ValidationErrors.ExamIdInvalid) {
        throw error;
      }
      if (error.response.status === 404) {
        // examinationID is valid if not found in the database
        return;
      }
      throw error;
    });
};
