import { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { DialogBase } from 'components/Dialog/DialogBase';
import { PasswordInput } from 'components/Input/PasswordInput';
import { CancelButton } from 'components/Button/CancelButton';
import { SubmitButton } from 'components/Button/SubmitButton';
import { CircularLoader } from 'components/Progress/CircularLoader';
import { ErrorMessage } from 'pages/auth/ErrorMessage';
import { changePasswordValidationSchema } from 'pages/account/changePasswordValidationSchema';
import { changePassword } from 'api/auth/authApi';

type ChangePasswordDialogProps = {
  open: boolean;
  close: () => void;
};

export const ChangePasswordDialog = ({
  open,
  close,
}: ChangePasswordDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [newPassword, setNewPassword] = useState('');

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
    },
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setError(false);
      const apiValues = {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      };
      changePassword(apiValues)
        .then(() => {
          closeDialog();
          setTimeout(() => {
            setLoading(false);
          }, 100);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    },
    validationSchema: changePasswordValidationSchema(newPassword),
  });

  const closeDialog = () => {
    close();
    setTimeout(() => {
      formik.resetForm();
      setError(false);
    }, 100);
  };

  const handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
    formik.handleChange(event);
  };

  return (
    <DialogBase
      open={open}
      onClose={closeDialog}
      title={t('account.change-password')}
      className={classes.editProfileDialog}
      disabled={loading}
      id="change-password-dialog"
    >
      {error && <ErrorMessage message={t('account.change-password-error')} />}
      <PasswordInput
        fullWidth
        name="currentPassword"
        id="current-password"
        value={formik.values.currentPassword}
        label={t('account.current-password')}
        className={classes.passwordField}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.currentPassword && formik.touched.currentPassword}
        helperText={
          formik.errors.currentPassword && formik.touched.currentPassword
            ? formik.errors.currentPassword
            : null
        }
      />
      <PasswordInput
        name="newPassword"
        id="new-password"
        value={formik.values.newPassword}
        label={t('account.new-password')}
        className={classes.passwordField}
        onChange={handleNewPasswordChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors.newPassword && formik.touched.newPassword)}
        helperText={
          formik.errors.newPassword && formik.touched.newPassword
            ? formik.errors.newPassword
            : null
        }
        FormHelperTextProps={{
          className: classes.helperText,
        }}
      />
      <PasswordInput
        name="newPasswordRepeat"
        id="new-password-repeat"
        value={formik.values.newPasswordRepeat}
        label={t('account.new-password-repeat')}
        className={classes.passwordField}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(
          formik.errors.newPasswordRepeat && formik.touched.newPasswordRepeat
        )}
        helperText={
          formik.errors.newPasswordRepeat && formik.touched.newPasswordRepeat
            ? formik.errors.newPasswordRepeat
            : null
        }
      />
      <div className={classes.actions}>
        <CancelButton
          onClick={closeDialog}
          className={classes.cancelButton}
          disabled={loading}
          id="cancel-button"
        >
          {t('cancel')}
        </CancelButton>
        <SubmitButton
          onClick={formik.handleSubmit}
          disabled={
            !isEmpty(formik.errors) ||
            loading ||
            formik.values === formik.initialValues
          }
          id="change-password-submit-button"
        >
          {loading ? <CircularLoader /> : t('confirm')}
        </SubmitButton>
      </div>
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  editProfileDialog: {
    width: '400px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  passwordField: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
  helperText: {
    lineHeight: '1.2',
  },
}));
