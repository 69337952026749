import { makeStyles, Backdrop } from '@material-ui/core';
import { CircularLoader } from 'components/Progress/CircularLoader';

export const LoaderOnBackdrop = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open>
      <CircularLoader />
    </Backdrop>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.main,
  },
}));
