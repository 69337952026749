// eslint-disable-next-line
export enum orderEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export type ConsentItem = {
  documentLanguage: string;
  documentLink: string;
  id: string;
  name: string;
  required: boolean;
  status: string;
  target: string;
  type: string;
  version: number;
};
