import { makeStyles, Typography, Fade } from '@material-ui/core';
import { useAuth } from 'context/providers/AuthProvider';
import { LettersAvatar } from 'components/Avatar/LettersAvatar';

type UserAvatarProps = {
  initialsOnly: boolean;
};

export const UserAvatar = ({ initialsOnly }: UserAvatarProps) => {
  const { user, isDoctor } = useAuth();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <LettersAvatar user={user} />
      <Fade
        in={!initialsOnly}
        timeout={{ enter: isDoctor ? 500 : 0, exit: 0 }}
        unmountOnExit
        mountOnEnter
      >
        <Typography id="user-avatar-name" className={classes.name}>{`${
          user!.firstName
        } 
      ${user!.lastName}`}</Typography>
      </Fade>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 1, 0, 1),
  },
  name: {
    fontSize: '12px',
    color: theme.palette.grey[900],
  },
}));
