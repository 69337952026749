import { Dispatch, SetStateAction } from 'react';
import { DateTime } from 'luxon';
import {
  ValueTypes,
  FilterNames,
  FilterOperands,
} from 'context/providers/FiltersProvider';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export class DateFilter {
  name: FilterNames;
  valueType: ValueTypes;
  rawValue: {
    low: MaterialUiPickersDate;
    high: MaterialUiPickersDate;
  };
  setValue: Dispatch<
    SetStateAction<{
      low: MaterialUiPickersDate;
      high: MaterialUiPickersDate;
    }>
  >;
  label: { text: string; unit: string };

  constructor(
    name: FilterNames,
    valueType: ValueTypes,
    rawValue: {
      low: MaterialUiPickersDate;
      high: MaterialUiPickersDate;
    },
    setValue: Dispatch<
      SetStateAction<{
        low: MaterialUiPickersDate;
        high: MaterialUiPickersDate;
      }>
    >,
    label: { text: string; unit: string }
  ) {
    this.name = name;
    this.valueType = valueType;
    this.rawValue = rawValue;
    this.setValue = setValue;
    this.label = label;
  }

  shouldSkip = () => {
    return (
      !this.rawValue ||
      (this.rawValue.low === null && this.rawValue.high === null)
    );
  };

  valueLow = () => {
    if (this.rawValue && this.rawValue.low) {
      return this.processValue(this.rawValue.low, FilterOperands.GTE);
    }
  };

  valueHigh = () => {
    if (this.rawValue && this.rawValue.high) {
      return this.processValue(this.rawValue.high, FilterOperands.LTE);
    }
  };

  // eslint-disable-next-line
  processValue = (date: MaterialUiPickersDate, operand: FilterOperands) => {
    if (!date) {
      return;
    }
    const stringifiedDate = date.toString();
    const jsDate = new Date(stringifiedDate);

    // set beginning or end of the day
    if (operand === FilterOperands.GTE) {
      jsDate.setHours(0, 0, 0, 0);
    } else {
      jsDate.setHours(23, 59, 59, 999);
    }
    const formattedDate = DateTime.fromJSDate(jsDate).toISO();
    return formattedDate;
  };

  clear = () => {
    this.setValue({ low: null, high: null });
  };

  toApiFilter = () => {
    const lowValueFilter = {
      field: this.name,
      operand: FilterOperands.GTE,
      value: this.valueLow(),
    };
    const highValueFilter = {
      field: this.name,
      operand: FilterOperands.LTE,
      value: this.valueHigh(),
    };

    if (this.rawValue.high === null) {
      return [lowValueFilter];
    }
    if (this.rawValue.low === null) {
      return [highValueFilter];
    }
    return [lowValueFilter, highValueFilter];
  };
}
