import { MouseEvent } from 'react';
import clsx from 'clsx';
import { ButtonBase } from 'components/Button/ButtonBase';
import { ButtonProps, makeStyles } from '@material-ui/core';

type ButtonBaseWithIconProps = Omit<ButtonProps, 'onClick'> & {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  labelClass?: string;
};

export const ButtonBaseWithIcon = ({
  startIcon,
  onClick,
  disabled,
  endIcon,
  labelClass,
  ...props
}: ButtonBaseWithIconProps) => {
  const classes = useStyles();

  return (
    <ButtonBase
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      disabled={disabled}
      classes={{
        startIcon: classes.startIcon,
        root: classes.root,
        label: clsx(classes.label, labelClass, {
          [classes.disabled]: disabled,
        }),
        iconSizeSmall: classes.icon,
      }}
      {...props}
    />
  );
};

ButtonBaseWithIcon.defaultProps = {
  labelClass: undefined,
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '8px',
    padding: theme.spacing(1, 2, 1, 6),
    height: '46px',
  },
  startIcon: {
    position: 'absolute',
    left: theme.spacing(2),
  },
  label: {
    color: theme.palette.primary.darkmode,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.5px',
    lineHeight: '30px',
  },
  icon: {
    height: '16px',
    width: '16px',
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
}));
