import { createMuiTheme } from '@material-ui/core';
import { Shadows } from '@material-ui/core/styles/shadows';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

declare module '@material-ui/core/styles/createPalette' {
  interface SimplePaletteColorOptions {
    darkmode?: string;
    background?: string;
    hover?: string;
  }

  interface PaletteColor {
    darkmode?: string;
    background?: string;
    hover?: string;
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6080CD',
      light: '#A0B3E1',
      dark: '#243B72',
      darkmode: '#3659AC',
      background: '#DFE6F5',
      contrastText: '#F7F7FC',
      hover: 'rgba(96, 128, 205, 0.05)',
    },
    secondary: {
      main: '#2DD3E6',
      light: '#9FEBF4',
      dark: '#1395A4',
      background: '#BFF2F8',
      contrastText: '#F7F7FC',
    },
    error: {
      main: '#ED2E7E',
      dark: '#C30052',
      darkmode: '#FF84B7',
    },
    success: {
      main: '#00BA88',
      dark: '#00966D',
      darkmode: '#34EAB9',
    },
    warning: {
      main: '#FF9C54',
      dark: '#946200',
      darkmode: '#FFD789',
    },
    text: {
      primary: '#4E4B66',
      secondary: '#A0A3BD',
      disabled: 'rgba(0, 0, 0, 0.26)',
    },
    background: {
      default: '#FFFEFE',
    },
    divider: '#D9DBE9',
    grey: {
      300: '#9C9A9A',
      400: '#C4C4C4',
      500: '#F9F9FA',
      600: '#EEF5FF',
      700: '#6E7191',
      800: '#EFF0F7',
      900: '#14142B',
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontSize: '36px',
      lineHeight: '32px',
      fontWeight: 500,
    },
    h2: {
      fontSize: '24px',
      lineHeight: '30px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '0.75px',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '0.75px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    button: {
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '0.75px',
      fontWeight: 600,
      textTransform: 'none',
    },
    caption: {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 400,
    },
  },
  shadows: [
    'none',
    '0px 2px 10px rgba(96, 128, 205, 0.3)',
    '0px 2px 10px rgba(127, 208, 219, 0.48)',
    '0px 2px 10px rgba(96, 128, 205, 0.3)',
    '0px 2px 10px rgba(96, 128, 205, 0.3)',
    '0px 2px 10px rgba(96, 128, 205, 0.3)',
    '0px 2px 10px rgba(96, 128, 205, 0.3)',
    '0px 2px 10px rgba(96, 128, 205, 0.3)',
    '0px 2px 10px rgba(96, 128, 205, 0.3)',
  ].concat(Array(22).fill('none')) as Shadows,
  shape: {
    borderRadius: 16,
  },
  overrides: {
    MuiTouchRipple: {
      child: {
        backgroundColor: '#3659AC',
      },
    },
    MuiLink: {
      underlineHover: {
        '&:focus, &:hover, &:visited, &:link, &:active': {
          textDecoration: 'none',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        '&$active': {
          '&& $icon': {
            color: '#2DD3E6',
          },
        },
      },
      icon: {
        opacity: 0.2,
        transition: 'none',
      },
    },
  },
});

export default theme;
