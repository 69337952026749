import { MouseEvent } from 'react';
import { makeStyles, TableSortLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  useExaminations,
  SortLabels,
} from 'context/providers/ExaminationsProvider';
import { CustomTooltip } from 'components/Tooltip/CustomTooltip';
import { TooltipIconBase } from 'components/Tooltip/TooltipIconBase';
import { HeadCell } from 'components/Table/HeadCell';
import {
  TooltipContent,
  TooltipContentData,
} from 'pages/examinations/examinationsList/TooltipContent';
import { orderEnum } from 'commonTypes/otherTypes';

type HeadCellWithTooltipProps = {
  label: TooltipContentData;
  id: string;
};

export const HeadCellWithTooltip = ({
  label,
  id,
}: HeadCellWithTooltipProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { order, orderBy, handleSortRequest } = useExaminations();

  const createSortHandler = (property: SortLabels) => (
    event: MouseEvent<unknown>
  ) => {
    handleSortRequest(event, property);
  };

  type LabelContentProps = {
    className?: string;
  };

  const LabelContent = ({ className }: LabelContentProps) => {
    return (
      <div className={className || undefined}>
        {t(`examinations.${label.text}`)}
      </div>
    );
  };

  return (
    <HeadCell
      sortable={label.sortable}
      sortDirection={label.sortable && orderBy === id ? order : false}
      id={id}
    >
      <div className={classes.headCellContent}>
        {label.sortable ? (
          <TableSortLabel
            id={`${id}-sort-label`}
            active={orderBy === label.name}
            direction={orderBy === label.name ? order : orderEnum.ASC}
            onClick={createSortHandler(label.name as SortLabels)}
          >
            <LabelContent />
          </TableSortLabel>
        ) : (
          <LabelContent className={label.tooltip.text && classes.margin} />
        )}
        <CustomTooltip
          title={
            label.tooltip.text === '' ? '' : <TooltipContent data={label} />
          }
          id={`${label.name}-tooltip`}
        >
          <div>
            {label.tooltip.text && (
              <TooltipIconBase id={`${label.name}-tooltip-icon`} size={20} />
            )}
          </div>
        </CustomTooltip>
      </div>
    </HeadCell>
  );
};

const useStyles = makeStyles((theme) => ({
  headCellContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  margin: {
    marginRight: theme.spacing(1),
  },
}));
