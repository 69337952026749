import { SetStateAction, Dispatch } from 'react';
import JSZip from 'jszip';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import {
  AcceptedFiles,
  AcceptedFilesBatchExtensions,
} from 'pages/examinations/examinationUpload/processFiles';
import { uploadExamination } from 'api/examinations/examinationsApi';
import { uploadExaminationFile } from 'pages/examinations/examinationFileUpload/sendExaminationFile';
import {
  checkExaminationStatus,
  ExaminationStatuses,
} from 'pages/examinations/examinationUpload/checkExaminationStatus';
import { UploadResult } from 'pages/examinations/examinationUpload/AddExaminationDialog';
import { AcceptedOptionalFileTypes } from 'utils/constants';

export const sendExaminationPackage = (
  uploadedFiles: AcceptedFiles,
  examinationId: string,
  profileId: string,
  setUploadStatus: Dispatch<SetStateAction<number>>,
  setIsProcessing: Dispatch<SetStateAction<boolean>>
) => {
  const base64examinationId = btoa(examinationId);
  const jsnFilePromise = getExaminationData(uploadedFiles.JSN.file!);

  const filesForZip = cloneDeep(uploadedFiles);
  const zipFilePromise = createZip(filesForZip, base64examinationId);
  const wavFile = uploadedFiles.WAV.file;

  return Promise.all([jsnFilePromise, zipFilePromise])
    .then(([jsnFile, zipFile]) => {
      return uploadExamination(
        base64examinationId,
        zipFile,
        jsnFile,
        wavFile,
        profileId,
        setUploadStatus
      ).catch((error) => {
        throw error;
      });
    })
    .then((examinationData) => {
      if (wavFile) {
        const isBatchUpload = true;
        return uploadExaminationFile(
          examinationData.examinationId,
          wavFile,
          AcceptedOptionalFileTypes.WAV,
          setUploadStatus,
          isBatchUpload
        ).then(() => {
          return examinationData;
        });
      }
      return examinationData;
    })
    .then((examinationData) => {
      return checkExaminationStatus(
        examinationData.examinationId,
        setIsProcessing
      );
    })
    .then((result: ExaminationStatuses) => {
      if (result === ExaminationStatuses.FAILED) {
        throw new Error(UploadResult.PROCESSING_ERROR);
      }
    });
};

const createZip = (
  uploadedFiles: AcceptedFiles,
  base64examinationId: string
): Promise<Blob> => {
  const zip = new JSZip();

  Object.entries(uploadedFiles).forEach((uploadedFile) => {
    const [extension, processedFile] = uploadedFile;

    if (extension === AcceptedFilesBatchExtensions.WAV) {
      return;
    }

    if (!processedFile.file) {
      throw new Error('Required file missing');
    }
    const base64name = `${base64examinationId}.${extension}`;
    zip.file(base64name, processedFile.file);
  });

  return zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: {
      level: 1,
    },
  });
};

type ExaminationData = {
  sensorNumber: string | undefined;
  recordId: string | undefined;
  examinationStart: string | undefined;
  examinationEnd: string | undefined;
  firmwareVersion: string | undefined;
  hardwareVersion: string | undefined;
  [key: string]: string | undefined;
};

const getExaminationData = (jsnFile: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const jsnContent = JSON.parse(event.target!.result as string);
      const examinationData: ExaminationData = {
        sensorNumber: undefined,
        recordId: undefined,
        examinationStart: undefined,
        examinationEnd: undefined,
        firmwareVersion: undefined,
        hardwareVersion: undefined,
      };

      const formattedStart = DateTime.fromSeconds(
        jsnContent.examination_start_timestamp,
        { setZone: true }
      ).toISO();
      const formattedEnd = DateTime.fromSeconds(
        jsnContent.examination_stop_timestamp,
        { setZone: true }
      ).toISO();

      examinationData.sensorNumber = jsnContent.sensor_serial;
      examinationData.recordId = btoa(jsnContent.examination_id);
      examinationData.examinationStart = formattedStart;
      examinationData.examinationEnd = formattedEnd;
      examinationData.firmwareVersion = jsnContent.firmware_version;
      examinationData.hardwareVersion = jsnContent.hardware_revision;

      Object.keys(jsnContent).forEach((key) => {
        if (key.startsWith('classifier')) {
          const regex = /_/g;
          const newKey = key.replace(regex, '');
          examinationData[newKey] = jsnContent[key];
        }
      });

      const examinationDataJson = JSON.stringify(examinationData);
      const examinationDataBlob: Blob = new Blob([examinationDataJson], {
        type: 'application/json',
      });

      resolve(examinationDataBlob);
    };
    reader.readAsText(jsnFile);
  });
};
