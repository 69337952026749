import { Dispatch, SetStateAction, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useFilters, FilterOperands } from 'context/providers/FiltersProvider';
import { FiltersContainer } from 'pages/examinations/examinationsList/examTableActions/filters/FiltersContainer';
import { AhiSlider } from 'pages/examinations/examinationsList/examTableActions/filters/AhiSlider';
import { SISlider } from 'pages/examinations/examinationsList/examTableActions/filters/SISlider';
import { SleepTimeSlider } from 'pages/examinations/examinationsList/examTableActions/filters/SleepTimeSlider';
import { ExamNumberInput } from 'pages/examinations/examinationsList/examTableActions/filters/ExamNumberInput';
import { DatePicker } from 'pages/examinations/examinationsList/examTableActions/filters/DatePicker';
import { ApplyFiltersButton } from 'pages/examinations/examinationsList/examTableActions/filters/ApplyFiltersButton';
import { FilterChip } from 'pages/examinations/examinationsList/examTableActions/filters/FilterChip';
import { IconButton } from 'components/Button/IconButton';
import { ReactComponent as Close } from 'icons/Close.svg';

type FiltersAreaProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  resetTable: () => void;
};

export const FiltersArea = ({
  open,
  setOpen,
  resetTable,
}: FiltersAreaProps) => {
  const classes = useStyles();
  const {
    tstFilter,
    ahiFilter,
    siFilter,
    examStartDateFilter,
    examNumberFilter,
    submittedFilters,
    handleFiltersApply,
  } = useFilters();

  useEffect(() => {
    if (!submittedFilters) {
      setOpen(false);
    }
  }, [submittedFilters, setOpen]);

  const applyFilters = () => {
    handleFiltersApply();
    resetTable();
  };

  return (
    <>
      <FiltersContainer id="filters-area" open={open}>
        <div className={classes.inputContainer}>
          <ExamNumberInput
            value={examNumberFilter.rawValue}
            onChangeValue={examNumberFilter.setValue}
            className={classes.bigMargin}
          />
          <DatePicker
            value={examStartDateFilter.rawValue}
            onChangeValue={examStartDateFilter.setValue}
            operand={FilterOperands.GTE}
            className={classes.bigMargin}
            id="date-from-input"
          />
          <DatePicker
            value={examStartDateFilter.rawValue}
            onChangeValue={examStartDateFilter.setValue}
            operand={FilterOperands.LTE}
            id="date-to-input"
          />
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>
        </div>
        <div className={classes.sliderContainer}>
          <SleepTimeSlider
            className={classes.bigMargin}
            value={tstFilter.rawValue}
            onChangeValue={tstFilter.setValue}
          />
          <AhiSlider
            className={classes.bigMargin}
            value={ahiFilter.rawValue}
            onChangeValue={ahiFilter.setValue}
          />
          <SISlider
            className={classes.smallMargin}
            value={siFilter.rawValue}
            onChangeValue={siFilter.setValue}
          />
          <ApplyFiltersButton
            className={classes.applyButton}
            onClick={() => applyFilters()}
          />
        </div>
      </FiltersContainer>
      <div className={classes.chipsContainer}>
        {submittedFilters.map((filter) => (
          <FilterChip
            key={filter.label.text}
            filter={filter}
            id={`${filter.name}-chip`}
          />
        ))}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    display: 'flex',
  },
  inputContainer: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  bigMargin: {
    margin: theme.spacing(0, 8, 0, 0),
  },
  smallMargin: {
    margin: theme.spacing(0, 4, 0, 0),
  },
  applyButton: {
    marginTop: 'auto',
    marginLeft: 'auto',
  },
  chipsContainer: {
    marginTop: theme.spacing(2),
  },
  closeButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(-1),
    boxShadow: 'none',
    width: '36px',
    height: '36px',
    padding: theme.spacing(1),
  },
}));
