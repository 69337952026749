import { SetStateAction, useEffect, Dispatch } from 'react';
import {
  makeStyles,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import { ReactComponent as DownArrow } from 'icons/DownArrow.svg';
import { RegisterValues } from 'pages/auth/register/formikValues';
import { UserConsentsForm } from 'components/Consents/UserConsentsForm';

type ConsentListProps = {
  formik: FormikContextType<RegisterValues>;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
};

export const ConsentList = ({
  formik,
  expanded,
  setExpanded,
}: ConsentListProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { errors, touched } = formik;

  const consentError =
    (Boolean(errors.dataProcessingAgreement) &&
      touched.dataProcessingAgreement) ||
    Boolean(
      errors.termsConditionsAgreement && touched.termsConditionsAgreement
    );

  useEffect(() => {
    if (consentError) {
      setExpanded(true);
    }
  }, [consentError, setExpanded]);

  const summaryText = () => {
    if (consentError) {
      return t('consents.accept-consents');
    }
    if (expanded) {
      return t('consents.collapse-consents');
    }
    return t('consents.expand-consents');
  };

  return (
    <Accordion
      className={classes.accordion}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      id="consent-list"
    >
      <AccordionSummary expandIcon={<DownArrow />} className={classes.summary}>
        <Typography className={consentError ? classes.error : undefined}>
          {summaryText()}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <UserConsentsForm
          formik={formik}
          consentError={consentError}
          isDoctor={false}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  accordion: {
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(2, 0, 2, 0),

    '&:before': {
      display: 'none',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    color: theme.palette.error.main,
  },
  summary: {
    height: '52px',
  },
}));
