import {
  ConsentNamesUser,
  ConsentNamesProfile,
} from 'components/Consents/ConsentNames';
import { RegisterValues } from 'pages/auth/register/formikValues';
import {
  UserConsentFormValues,
  ProfileConsentFormValues,
} from 'components/Consents/types';
import { User } from 'commonTypes/authTypes';
import { ProfileFormValues } from 'pages/profiles/ProfileForm';

export const TCAgreementsUser = [
  ConsentNamesUser.generalTerms,
  ConsentNamesUser.termsApplication,
  ConsentNamesUser.termsProduct,
  ConsentNamesUser.privacyPolicy,
];
export const DPAgreementsUser = [ConsentNamesUser.healthClause];
export const marketingAgreements = [ConsentNamesUser.marketingClause];
export const contactAgreements = [ConsentNamesUser.SMSClause];
export const entrustingAgreements = [ConsentNamesUser.entrustingAgreement];

export const TCAgreementsProfile = [
  ConsentNamesProfile.generalTerms,
  ConsentNamesProfile.termsApplication,
  ConsentNamesProfile.termsProduct,
  ConsentNamesProfile.privacyPolicy,
];
export const DPAgreementsProfile = [ConsentNamesProfile.healthClause];

export const processUserAgreements = (
  values: RegisterValues | UserConsentFormValues,
  isDoctor: boolean
) => {
  const basicConsents: { [key: string]: boolean } = {
    [ConsentNamesUser.generalTerms]: false,
    [ConsentNamesUser.termsApplication]: false,
    [ConsentNamesUser.termsProduct]: false,
    [ConsentNamesUser.privacyPolicy]: false,
    [ConsentNamesUser.healthClause]: false,
    [ConsentNamesUser.marketingClause]: false,
    [ConsentNamesUser.SMSClause]: false,
  };

  const doctorConsents = {
    ...basicConsents,
    [ConsentNamesUser.entrustingAgreement]: false,
  };

  const consents = isDoctor ? doctorConsents : basicConsents;

  const markAsChecked = (agreements: ConsentNamesUser[]) => {
    agreements.forEach((agreement) => {
      consents[agreement] = true;
    });
  };

  if (values.termsConditionsAgreement) {
    markAsChecked(TCAgreementsUser);
  }

  if (values.dataProcessingAgreement) {
    markAsChecked(DPAgreementsUser);
  }

  if (values.entrustingAgreement) {
    markAsChecked(entrustingAgreements);
  }

  if (values.marketingAgreement) {
    markAsChecked(marketingAgreements);
  }

  if (values.contactAgreement) {
    markAsChecked(contactAgreements);
  }

  const consentsArray: { consentName: string; accepted: boolean }[] = [];

  Object.keys(consents).forEach((consentName) => {
    consentsArray.push({ consentName, accepted: consents[consentName] });
  });

  return consentsArray;
};

export const generateInitialUserConsents = (user: User, isDoctor: boolean) => {
  const isCheckboxChecked = (requiredAgreements: ConsentNamesUser[]) =>
    requiredAgreements.every((agreement) => {
      return user?.userConsents.includes(agreement);
    });

  const basicInitialValues = {
    termsConditionsAgreement: isCheckboxChecked(TCAgreementsUser),
    dataProcessingAgreement: isCheckboxChecked(DPAgreementsUser),
    marketingAgreement: isCheckboxChecked(marketingAgreements),
    contactAgreement: isCheckboxChecked(contactAgreements),
  };

  const doctorInitialValues = {
    ...basicInitialValues,
    entrustingAgreement: isCheckboxChecked(entrustingAgreements),
  };

  return isDoctor ? doctorInitialValues : basicInitialValues;
};

export const processProfileAgreements = (
  values: ProfileFormValues | ProfileConsentFormValues
) => {
  const consents: { [key: string]: boolean } = {
    [ConsentNamesProfile.generalTerms]: false,
    [ConsentNamesProfile.termsApplication]: false,
    [ConsentNamesProfile.termsProduct]: false,
    [ConsentNamesProfile.privacyPolicy]: false,
    [ConsentNamesProfile.healthClause]: false,
  };

  const markAsChecked = (agreements: ConsentNamesProfile[]) => {
    agreements.forEach((agreement) => {
      consents[agreement] = true;
    });
  };

  if (values.termsConditionsAgreement) {
    markAsChecked(TCAgreementsProfile);
  }

  if (values.dataProcessingAgreement) {
    markAsChecked(DPAgreementsProfile);
  }

  const consentsArray: { consentName: string; accepted: boolean }[] = [];

  Object.keys(consents).forEach((consentName) => {
    consentsArray.push({ consentName, accepted: consents[consentName] });
  });

  return consentsArray;
};

export const generateInitialProfileConsents = (
  profileConsents: ConsentNamesProfile[]
) => {
  const isCheckboxChecked = (requiredAgreements: ConsentNamesProfile[]) =>
    requiredAgreements.every((agreement) => {
      return profileConsents.includes(agreement);
    });

  const initialValues = {
    termsConditionsAgreement: isCheckboxChecked(TCAgreementsProfile),
    dataProcessingAgreement: isCheckboxChecked(DPAgreementsProfile),
  };

  return initialValues;
};

export const hasRequiredProfileConsents = (
  profileConsents: ConsentNamesProfile[] | undefined
) => {
  const requiredConsents = TCAgreementsProfile.concat(DPAgreementsProfile);

  return requiredConsents.every((consent) => {
    return profileConsents?.includes(consent);
  });
};
