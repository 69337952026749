import * as Yup from 'yup';
import i18n from 'translations/i18n';
import { passwordValidation } from 'validation/validationFunctions';

export const changePasswordValidationSchema = (newPassword: string) => {
  const validatePasswordMatch = (newPasswordRepeat: string | undefined) => {
    return newPasswordRepeat === newPassword;
  };

  return Yup.object().shape({
    currentPassword: Yup.string().required(i18n.t('validation.required-field')),
    newPassword: passwordValidation(),
    newPasswordRepeat: Yup.string()
      .required(i18n.t('validation.required-field'))
      .test(
        'Check if password matches',
        i18n.t('validation.invalid-passwordConfirm'),
        validatePasswordMatch
      ),
  });
};
