import { useEffect } from 'react';
import theme from 'theme';
import { makeStyles } from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import { CustomResponsiveContainer } from 'charts/ChartComponents/CustomResponsiveContainer';
import { FixedSizeContainer } from 'charts/ChartComponents/FixedSizeContainer';
import { useTranslation } from 'react-i18next';
import { ChartData } from 'charts/chartTypes/chartTypes';
import { useXAxisData } from 'charts/LineCharts/useXAxisData';

export const PositionChart = ({ chartData }: ChartData) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, start, end } = chartData;

  const { xAxisDomain, xAxisTicks, createXAxisData } = useXAxisData(start, end);

  useEffect(() => {
    createXAxisData();
  }, [createXAxisData]);

  return (
    <FixedSizeContainer height={150} className={classes.container}>
      <CustomResponsiveContainer>
        <LineChart
          data={data}
          margin={{ top: 0, bottom: 0 }}
          id="position-chart"
        >
          <XAxis
            dataKey="timeStamp"
            type="number"
            domain={xAxisDomain}
            ticks={xAxisTicks}
            tick={
              isEmpty(xAxisTicks)
                ? false
                : {
                    fill: theme.palette.text.primary,
                    id: 'position-x-axis-tick',
                  }
            }
            padding={{ right: 20 }}
            tickFormatter={(timeStamp) =>
              DateTime.fromSeconds(timeStamp).toFormat('HH:mm')
            }
            hide
          />
          <YAxis
            type="category"
            tickFormatter={(tick) => {
              return t(`charts.${tick}`);
            }}
            tick={{
              fill: theme.palette.text.primary,
              fontSize: '12px',
              id: 'position-category',
            }}
            stroke={theme.palette.text.secondary}
            padding={{ top: 20, bottom: 20 }}
            interval={0}
            width={80}
          />
          <CartesianGrid strokeDasharray="4 4" />
          <Line
            type="linear"
            dataKey="value"
            id="position-line"
            stroke={theme.palette.primary.main}
            dot={false}
            isAnimationActive={false}
          />
        </LineChart>
      </CustomResponsiveContainer>
    </FixedSizeContainer>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 0,
    marginBottom: 0,

    '& .recharts-cartesian-grid-vertical line': {
      strokeDasharray: '0',
      stroke: theme.palette.text.secondary,
    },

    '& .recharts-cartesian-grid-vertical line:last-child': {
      opacity: 0,
    },

    '& .recharts-cartesian-grid-horizontal line:nth-child(n + 6)': {
      opacity: 0,
    },
  },
}));
