import { useTranslation } from 'react-i18next';
import { makeStyles, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import { SubmitButton } from 'components/Button/SubmitButton';
import { ReactComponent as Check } from 'icons/Check.svg';

type OkButtonProps = Omit<ButtonProps, 'onClick'> & {
  onClick: () => void;
};

export const OkButton = ({ onClick, className, ...props }: OkButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <SubmitButton
      className={clsx(className, classes.okButton)}
      onClick={onClick}
      startIcon={<Check />}
      {...props}
    >
      {t('OK')}
    </SubmitButton>
  );
};

const useStyles = makeStyles((theme) => ({
  okButton: {
    width: '100px',
  },
}));
