import { Dispatch, SetStateAction } from 'react';
import { RangeSlider } from 'components/Slider/RangeSlider';
import { useTranslation } from 'react-i18next';
import { SliderProps } from '@material-ui/core';
import { sleepTimeMarks } from 'pages/examinations/examinationsList/examTableActions/filters/sliderMarks';

type SISliderProps = Omit<SliderProps, 'value'> & {
  value: number[];
  onChangeValue: Dispatch<SetStateAction<number[]>>;
};

export const SleepTimeSlider = ({ value, marks, ...props }: SISliderProps) => {
  const { t } = useTranslation();

  return (
    <RangeSlider
      id="sleep-time-slider"
      label={t('examinations.sleep-time')}
      marks={sleepTimeMarks}
      max={14}
      min={0}
      step={0.5}
      labelUnit="h"
      value={value}
      {...props}
    />
  );
};
