import { ReactComponent as Tooltip } from 'icons/Tooltip.svg';
import { makeStyles, Theme } from '@material-ui/core';

type TooltipIconBaseProps = {
  id: string;
  size: number;
  hasMargin?: boolean;
};

export const TooltipIconBase = ({ id, ...props }: TooltipIconBaseProps) => {
  const classes = useStyles(props);

  return <Tooltip id={id} className={classes.tooltip} />;
};

TooltipIconBase.defaultProps = {
  hasMargin: undefined,
};

const useStyles = makeStyles<
  Theme,
  Pick<TooltipIconBaseProps, 'size' | 'hasMargin'>
>((theme: Theme) => ({
  tooltip: {
    minWidth: (props) => props.size,
    minHeight: (props) => props.size,
    maxHeight: (props) => props.size,
    maxWidth: (props) => props.size,
    marginLeft: (props) => (props.hasMargin ? theme.spacing(1) : undefined),
  },
}));
