import { useState, useEffect } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomLink } from 'components/Link/Link';
import { getConsentList } from 'api/consent/consentApi';
import { ConsentItem } from 'commonTypes/otherTypes';
import { ConsentNamesUser } from 'components/Consents/ConsentNames';

const termsOfUsePath = '/account/terms-of-use';

const allowedConsents: { [key: string]: string } = {
  [ConsentNamesUser.generalTerms]: termsOfUsePath,
  [ConsentNamesUser.termsApplication]: termsOfUsePath,
  [ConsentNamesUser.termsProduct]: termsOfUsePath,
  [ConsentNamesUser.privacyPolicy]: termsOfUsePath,
  [ConsentNamesUser.GDPRAccount]: termsOfUsePath,
  [ConsentNamesUser.entrustingAgreement]: termsOfUsePath,
};

type ListItem = {
  name: string;
  path: string;
};

export const TermOfUsePage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [consentList, setConsentList] = useState<ListItem[]>([]);

  useEffect(() => {
    getConsentList().then((response) => {
      response.forEach((consent: ConsentItem) => {
        if (consent.name in allowedConsents) {
          allowedConsents[consent.name] = consent.documentLink;
        }
      });
      const docNames = Object.keys(allowedConsents);
      const formattedList: ListItem[] = [];
      docNames.forEach((name) => {
        formattedList.push({ name, path: allowedConsents[name] });
      });
      setConsentList(formattedList);
    });
  }, []);

  return (
    <>
      <Typography className={classes.header} variant="h2">
        {t('account.terms-of-use')}
      </Typography>
      {consentList.map((element, index) => {
        const target = element.path === termsOfUsePath ? '_self' : '_blank';

        return (
          <CustomLink
            className={classes.link}
            label={`${index + 1}. ${t(`consents.${element.name}`)}`}
            to={element.path}
            target={target}
            key={element.name}
          />
        );
      })}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(7),
  },
  link: {
    marginTop: theme.spacing(2),
  },
}));

export default TermOfUsePage;
