import { toRoman } from 'roman-numerals';
import { DateTime } from 'luxon';

export const calculateXAxisDomain = (dates: {
  from: DateTime;
  to: DateTime;
}) => {
  const left = dates.from.toMillis();
  const right = dates.to.toMillis();

  return {
    left,
    right,
  };
};

export const createXAxisTicks = (left: number, right: number) => {
  const numberOfTicks = 14;
  const distanceBetweenticks = (right - left) / 14;

  let currentLeft = left;
  const ticks = [currentLeft];

  for (let i = 0; i < numberOfTicks; i += 1) {
    const tick = currentLeft + distanceBetweenticks;
    currentLeft = tick;

    ticks.push(tick);
  }
  return ticks;
};

export const createXAxisTicksForYear = (right: number) => {
  const numberOfTicks = 12;
  const lastDate = DateTime.fromMillis(right);
  const firstDayOfLastMonth = DateTime.fromObject({
    year: lastDate.year,
    month: lastDate.month,
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
  });

  const ticks = [firstDayOfLastMonth.toMillis()];
  let currentRight = firstDayOfLastMonth;

  for (let i = 0; i < numberOfTicks; i += 1) {
    const tick = currentRight.minus({ months: 1 }).toMillis();
    currentRight = DateTime.fromMillis(tick);
    ticks.push(tick);
  }
  return ticks;
};

export const formatXAxisTick = (value: number) => {
  const date = DateTime.fromMillis(value);
  const month = toRoman(date.month);
  return `${date.day.toString()} ${month}`;
};
