import clsx from 'clsx';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import { ReactComponent as Check } from 'icons/Check.svg';
import { ReactComponent as CheckDisabled } from 'icons/CheckDisabled.svg';

type SubmitButtonProps = Omit<ButtonProps, 'onClick'> & {
  onClick?: () => void;
  isLoading?: boolean;
};

export const SubmitButton = ({
  children,
  className,
  onClick,
  isLoading,
  disabled,
  ...props
}: SubmitButtonProps) => {
  const classes = useStyles();

  const chooseIcon = () => {
    if (isLoading) return null;
    if (disabled) return <CheckDisabled />;
    return <Check />;
  };

  return (
    <Button
      variant="contained"
      color="primary"
      className={clsx(className, classes.button)}
      classes={{
        startIcon: classes.startIcon,
      }}
      onClick={onClick}
      disabled={disabled}
      startIcon={chooseIcon()}
      {...props}
    >
      {children}
    </Button>
  );
};

SubmitButton.defaultProps = {
  isLoading: false,
  onClick: undefined,
};

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: '14px',
    fontWeight: 500,
    borderRadius: '8px',
    height: '46px',
    width: '146px',
  },
  startIcon: {
    padding: theme.spacing(0, 1, 0, 1),
  },
}));
