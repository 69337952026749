import { Sensor } from 'commonTypes/sensorTypes';
import { User } from 'commonTypes/authTypes';

export const checkIfSensorLentToOtherProfile = (
  sensorData: Sensor,
  user: User,
  activeProfileId: string | undefined
) => {
  if (sensorData.lendedToProfile && sensorData.ownerId) {
    const isSensorOwner = sensorData.ownerId === user!.id;
    const lenderId = sensorData.lendedToProfile.profileId;
    const returnDate = sensorData.lendedToProfile.returnedAt;
    if (isSensorOwner && !returnDate && activeProfileId !== lenderId) {
      return true;
    }
    return false;
  }
  return false;
};
