import { makeStyles } from '@material-ui/core';
import { useConfig } from 'context/providers/ConfigProvider';
import { SummaryCircleChart } from 'charts/CircleCharts/SummaryCircleChart';
import {
  calculateAverageValue,
  metricsEnum,
} from 'pages/examinations/examinationsSummary/calculateAverageValue';
import { ExaminationSimple } from 'commonTypes/examinationTypes';

type CircleChartsProps = {
  data: ExaminationSimple[] | null;
};

export const CircleCharts = ({ data }: CircleChartsProps) => {
  const classes = useStyles();
  const { colorRanges } = useConfig();

  const chartData = data || [];

  const ahi = {
    label: 'average-ahi',
    value: calculateAverageValue(chartData, metricsEnum.AHI),
    range: colorRanges.ahi,
    unit: '',
  };

  const sleepEfficiency = {
    label: 'sleep-efficiency',
    value: calculateAverageValue(chartData, metricsEnum.SLEEPEFFICIENCY),
    range: colorRanges.sleep_efficiency,
    unit: '%',
  };

  return (
    <div id="summary-circle-charts" className={classes.circleChartsContainer}>
      <SummaryCircleChart
        metric={ahi}
        id={ahi.label}
        className={classes.rightMargin}
      />
      <SummaryCircleChart metric={sleepEfficiency} id={sleepEfficiency.label} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  circleChartsContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  rightMargin: {
    marginRight: theme.spacing(4),
  },
}));
