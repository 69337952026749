import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { User, Profile, ProfileSimple } from 'commonTypes/authTypes';
import Avatar from '@material-ui/core/Avatar';
import { generateAvatarColor } from 'utils/generateAvatarColor';

type AvatarProps = {
  user: User | Profile | ProfileSimple;
  className?: string;
  id?: string;
};

export const LettersAvatar = ({ user, className, id }: AvatarProps) => {
  const firstLetter = user && user.firstName.charAt(0).toUpperCase();
  const secondLetter = user && user.lastName.charAt(0).toUpperCase();
  const color = generateAvatarColor(firstLetter, secondLetter);
  const classes = useStyles({ color });

  return (
    <Avatar
      className={clsx(className, classes.avatar)}
      id={id}
    >{`${firstLetter}${secondLetter}`}</Avatar>
  );
};

LettersAvatar.defaultProps = {
  className: undefined,
  id: undefined,
};

type Props = {
  color: string;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  avatar: {
    margin: '0 auto',
    color: theme.palette.grey[900],
    backgroundColor: ({ color }) => {
      return color;
    },
    fontSize: '18px',
  },
}));
