import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { getConfigFile } from 'api/examinations/examinationsApi';

export type ConfigData = {
  env: string | undefined;
  sleep_score: number[] | undefined;
  snoring_index: number[] | undefined;
  ahi: number[] | undefined;
  breath_score: number[] | undefined;
  sleep_efficiency: number[] | undefined;
  supine_ahi: number[] | undefined;
  supine_snoring_index: number[] | undefined;
};

export type ConfigContextValue = {
  colorRanges: ConfigData;
};

export type ConfigProviderProps = {
  children: ReactNode;
};

const defaultValue: ConfigContextValue = {
  colorRanges: {
    env: undefined,
    sleep_score: undefined,
    snoring_index: undefined,
    ahi: undefined,
    breath_score: undefined,
    sleep_efficiency: undefined,
    supine_ahi: undefined,
    supine_snoring_index: undefined,
  },
};

const ConfigContext = createContext(defaultValue);

const ConfigProvider: FC<ConfigProviderProps> = (
  props: ConfigProviderProps
) => {
  const [colorRanges, setColorRanges] = useState(defaultValue.colorRanges);

  useEffect(() => {
    getConfigFile().then((data) => {
      setColorRanges(data);
    });
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        colorRanges,
      }}
      {...props}
    />
  );
};

const useConfig = () => useContext(ConfigContext);
export { ConfigProvider, ConfigContext, useConfig };
