import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomLink } from 'components/Link/Link';
import { AuthFooter } from 'pages/auth/AuthFooter';

export const RegisterFooter = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.centerContainer}>
        <Typography className={classes.haveAccountText}>
          {t('register-page.have-account')}
        </Typography>
        <CustomLink to="/" label={t('register-page.login')} />
      </div>
      <AuthFooter />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  haveAccountText: {
    marginRight: theme.spacing(2),
  },
}));
