import { useState } from 'react';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { AddExaminationButton } from 'pages/examinations/examinationUpload/AddExaminationButton';
import { AddExaminationDialog } from 'pages/examinations/examinationUpload/AddExaminationDialog';
import { SelectedExamination } from 'commonTypes/examinationTypes';
import { PdfDownloadButton } from 'pages/examinations/pdfDownload/PdfDownloadButton';
import { PdfDownloadDialog } from 'pages/examinations/pdfDownload/PdfDownloadDialog';
import { ExamDeleteButton } from 'pages/examinations/examinationDelete/ExamDeleteButton';
import { ExamDeleteDialog } from 'pages/examinations/examinationDelete/ExamDeleteDialog';
import { FilterButton } from 'pages/examinations/examinationsList/examTableActions/filters/FilterButton';
import { FiltersArea } from 'pages/examinations/examinationsList/examTableActions/filters/FiltersArea';
import { useFilters } from 'context/providers/FiltersProvider';

type ActionsPanelProps = {
  selectedExaminations: SelectedExamination[];
  resetSelected: () => void;
  resetTable: () => void;
};

export const ActionsPanel = ({
  selectedExaminations,
  resetSelected,
  resetTable,
}: ActionsPanelProps) => {
  const classes = useStyles();
  const { handleFiltersApply } = useFilters();
  const { submittedFilters } = useFilters();
  const [addExaminationDialogOpen, setAddExaminationDialogOpen] = useState(
    false
  );
  const [pdfDownloadDialogOpen, setPdfDownloadDialogOpen] = useState(false);
  const [examDeleteDialogOpen, setExamDeleteDialogOpen] = useState(false);
  const [filtersAreaOpen, setFiltersAreaOpen] = useState(
    !isEmpty(submittedFilters)
  );

  let isOwnerOfSelected = true;
  selectedExaminations.forEach((examination) => {
    if (examination.isOwner === false) {
      isOwnerOfSelected = false;
    }
  });

  const postDeleteAction = () => {
    resetSelected();
    return handleFiltersApply();
  };

  return (
    <>
      <div className={classes.container}>
        <div>
          <FilterButton
            className={classes.marginRight}
            filtersAreaOpen={filtersAreaOpen}
            setFiltersAreaOpen={setFiltersAreaOpen}
            resetTable={resetTable}
          />
          <AddExaminationButton
            setDialogOpen={setAddExaminationDialogOpen}
            id="add-examination-button"
          />
          <AddExaminationDialog
            open={addExaminationDialogOpen}
            onClose={() => setAddExaminationDialogOpen(false)}
            resetTable={resetTable}
          />
        </div>
        <div>
          <ExamDeleteButton
            onClick={() => setExamDeleteDialogOpen(true)}
            disabled={isEmpty(selectedExaminations) || !isOwnerOfSelected}
            className={classes.marginRight}
            tooltip={!isOwnerOfSelected}
          />
          <ExamDeleteDialog
            open={examDeleteDialogOpen}
            onClose={() => setExamDeleteDialogOpen(false)}
            selectedExaminations={selectedExaminations}
            postDeleteAction={postDeleteAction}
            shouldClose
          />
          <PdfDownloadButton
            onClick={() => setPdfDownloadDialogOpen(true)}
            disabled={isEmpty(selectedExaminations)}
          />
          <PdfDownloadDialog
            open={pdfDownloadDialogOpen}
            close={() => setPdfDownloadDialogOpen(false)}
            selectedExaminations={selectedExaminations}
          />
        </div>
      </div>
      <FiltersArea
        open={filtersAreaOpen}
        setOpen={setFiltersAreaOpen}
        resetTable={resetTable}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    height: '50px',
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
}));
