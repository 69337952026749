import { ReactNode } from 'react';
import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

type GenderButtonProps = {
  icon: ReactNode;
  text: string;
  selected: boolean;
  disabled: boolean;
  onClick: () => void;
  id: string;
};

export const GenderButton = ({
  icon,
  text,
  selected,
  disabled,
  onClick,
  ...props
}: GenderButtonProps) => {
  const classes = useStyles();

  const customOnClick = () => {
    if (disabled) return;
    onClick();
  };

  return (
    <ListItem
      className={classes.genderButton}
      selected={selected}
      onClick={customOnClick}
      disabled={disabled}
      {...props}
    >
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

const useStyles = makeStyles((theme) => ({
  genderButton: {
    width: '156px',
    height: '56px',
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    cursor: 'pointer',

    '&:hover': {
      borderColor: theme.palette.secondary.background,
    },

    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.background,
      borderColor: theme.palette.secondary.background,

      '&:hover': {
        backgroundColor: theme.palette.secondary.background,
        borderColor: theme.palette.secondary.background,
      },
    },

    '&.Mui-disabled': {
      '&:hover': {
        borderColor: theme.palette.secondary.main,
        cursor: 'default',
      },
    },
  },
  icon: {
    minWidth: '36px',
  },
}));
