import {
  makeStyles,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const NoResultsItem = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <ListItem className={classes.noResultsItem}>
        <ListItemText
          primary={
            <Typography noWrap className={classes.text}>
              {t('no-results')}
            </Typography>
          }
        />
      </ListItem>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  noResultsItem: {
    borderRadius: '8px',
    height: '50px',
    margin: theme.spacing(0, 1, 0, 1),
    width: theme.spacing(33),
    backgroundColor: theme.palette.grey[600],
  },
  text: {
    textAlign: 'center',
  },
}));
