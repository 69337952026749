import { ReactNode } from 'react';
import clsx from 'clsx';
import { Box, makeStyles } from '@material-ui/core';

type BoxBaseProps = {
  children: ReactNode;
  className?: string;
};

export const BoxBase = ({ children, className }: BoxBaseProps) => {
  const classes = useStyles();

  return <Box className={clsx(className, classes.box)}>{children}</Box>;
};

BoxBase.defaultProps = {
  className: undefined,
};

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.grey[600],
    padding: theme.spacing(2),
    width: 'fit-content',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
  },
}));
