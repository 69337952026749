import { Switch, Route, Redirect } from 'react-router-dom';
import { commonRoutes } from 'config/commonRoutes';
import LoginPage from 'pages/auth/login/LoginPage';
import RegisterPage from 'pages/auth/register/RegisterPage';

export const NotAuthenticatedApp = () => {
  return (
    <Switch>
      <Route exact path="/" component={LoginPage} />
      <Route path="/register" component={RegisterPage} />
      {commonRoutes.map((route) => {
        return <Route {...route} key={route.path} />;
      })}
      <Redirect from="*" to="/" />
    </Switch>
  );
};
