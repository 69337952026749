import i18n from 'translations/i18n';

export const performingExaminationQA = {
  header: i18n.t('help.performing-examination.title'),
  questions: [
    {
      question: i18n.t('help.performing-examination.question1'),
      answer: [
        {
          text: i18n.t('help.performing-examination.question1-answer'),
          images: { src: ['images/faq/performingExamination/question1-1.png'] },
        },
      ],
    },
    {
      question: i18n.t('help.performing-examination.question2'),
      answer: [
        {
          text: i18n.t('help.performing-examination.question2-answer'),
          images: { src: ['images/faq/performingExamination/question2-1.png'] },
        },
      ],
    },
    {
      question: i18n.t('help.performing-examination.question3'),
      answer: [
        {
          text: i18n.t('help.performing-examination.question3-answer'),
          images: { src: ['images/faq/performingExamination/question3-1.png'] },
        },
      ],
    },
    {
      question: i18n.t('help.performing-examination.question4'),
      answer: [
        {
          text: i18n.t('help.performing-examination.question4-answer-part1'),
          images: { src: ['images/faq/performingExamination/question4-1.png'] },
        },
        {
          text: i18n.t('help.performing-examination.question4-answer-part2'),
          images: { src: ['images/faq/performingExamination/question4-2.png'] },
        },
        {
          text: i18n.t('help.performing-examination.question4-answer-part3'),
          images: { src: ['images/faq/performingExamination/question4-3.png'] },
        },
        {
          text: i18n.t('help.performing-examination.question4-answer-part4'),
          images: { src: ['images/faq/performingExamination/question4-4.png'] },
        },
      ],
    },
    {
      question: i18n.t('help.performing-examination.question5'),
      answer: [
        {
          text: i18n.t('help.performing-examination.question5-answer-part1'),
        },
        {
          text: i18n.t('help.performing-examination.question5-answer-part2'),
          bulletpoint: true,
          images: { src: ['images/faq/performingExamination/question5-1.png'] },
        },
        {
          text: i18n.t('help.performing-examination.question5-answer-part3'),
          bulletpoint: true,
          images: { src: ['images/faq/performingExamination/question5-2.png'] },
        },
        {
          text: i18n.t('help.performing-examination.question5-answer-part4'),
        },
      ],
    },
    {
      question: i18n.t('help.performing-examination.question6'),
      answer: [
        {
          text: i18n.t('help.performing-examination.question6-answer'),
          images: {
            src: [
              'images/faq/performingExamination/question6-1.png',
              'images/faq/performingExamination/question6-2.png',
            ],
          },
        },
      ],
    },
    {
      question: i18n.t('help.performing-examination.question7'),
      answer: [
        {
          text: i18n.t('help.performing-examination.question7-answer-part1'),
          images: { src: ['images/faq/performingExamination/question7-1.png'] },
        },
        {
          text: i18n.t('help.performing-examination.question7-answer-part2'),
        },
      ],
    },
    {
      question: i18n.t('help.performing-examination.question8'),
      answer: [
        {
          text: i18n.t('help.performing-examination.question8-answer-part1'),
        },
        {
          text: i18n.t('help.performing-examination.question8-answer-part2'),
          bulletpoint: true,
          images: { src: ['images/faq/performingExamination/question8-1.png'] },
        },
        {
          text: i18n.t('help.performing-examination.question8-answer-part3'),
          bulletpoint: true,
          images: { src: ['images/faq/performingExamination/question8-2.png'] },
        },
        {
          text: i18n.t('help.performing-examination.question8-answer-part4'),
          bulletpoint: true,
          images: { src: ['images/faq/performingExamination/question8-3.png'] },
        },
        {
          text: i18n.t('help.performing-examination.question8-answer-part5'),
          bulletpoint: true,
          images: { src: ['images/faq/performingExamination/question8-4.png'] },
        },
        {
          text: i18n.t('help.performing-examination.question8-answer-part6'),
        },
      ],
    },
  ],
};
