import { makeStyles, Typography, AccordionSummary } from '@material-ui/core';
import { ReactComponent as Expand } from 'icons/DownArrow.svg';
import { QAItem } from 'pages/help/QAGroup';

type QuestionProps = {
  item: QAItem;
  index: number;
  group: string;
};

export const Question = ({ item, index, group }: QuestionProps) => {
  const classes = useStyles();

  return (
    <AccordionSummary
      expandIcon={<Expand />}
      classes={{
        root: classes.summaryRoot,
        content: classes.summaryContent,
        expandIcon: classes.expandIcon,
      }}
      id={`item-${index}-summary`}
    >
      <Typography
        variant="subtitle1"
        className={classes.text}
        id={`${group}-question-${index}-header`}
      >
        {item.question}
      </Typography>
    </AccordionSummary>
  );
};

const useStyles = makeStyles((theme) => ({
  summaryRoot: {
    marginBottom: -1,
    minHeight: 48,
    flexDirection: 'row-reverse',
    paddingLeft: 0,
    '&.Mui-expanded': {
      minHeight: 48,
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
  },
  summaryContent: {
    margin: theme.spacing(1, 0, 1, 0),
    '&.Mui-expanded': {
      margin: theme.spacing(1, 0, 1, 0),
    },
  },
  expandIcon: {
    marginRight: theme.spacing(2),
  },
  text: {
    fontSize: '14px',
    fontWeight: 500,
  },
}));
