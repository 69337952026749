import { useState } from 'react';
import { DialogProps } from '@material-ui/core';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { emailValidationSchema } from 'pages/auth/resetPassword/resetPasswordValidationSchema';
import { requestPasswordReset } from 'api/auth/authApi';
import { InfoDialog } from 'components/Dialog/InfoDialog';
import { ResetRequestForm } from 'pages/auth/resetPassword/ResetRequestForm';
import { ResetConfirmation } from 'pages/auth/resetPassword/ResetConfirmation';

type ResetRequestDialogProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
};

export const ResetRequestDialog = ({
  open,
  onClose,
}: ResetRequestDialogProps) => {
  const [emailSent, setEmailSent] = useState(false);

  const requestPasswordResetMutation = useMutation(requestPasswordReset, {
    onError: () => {
      formik.resetForm();
    },
    onSuccess: (data) => {
      setEmailSent(true);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      requestPasswordResetMutation.mutate(values.email);
    },
    validationSchema: emailValidationSchema(),
  });

  const resetData = () => {
    requestPasswordResetMutation.reset();
    formik.resetForm();
    setEmailSent(false);
  };

  const handleClose = () => {
    onClose();
    setTimeout(resetData, 1000);
  };

  return (
    <InfoDialog open={open} onClose={handleClose} closeable>
      {emailSent ? (
        <ResetConfirmation />
      ) : (
        <ResetRequestForm
          formik={formik}
          isError={requestPasswordResetMutation.isError}
          isLoading={requestPasswordResetMutation.isLoading}
        />
      )}
    </InfoDialog>
  );
};
