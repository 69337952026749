import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CancelButton } from 'components/Button/CancelButton';
import { SubmitButton } from 'components/Button/SubmitButton';
import { OkButton } from 'components/Button/OkButton';
import { CircularLoader } from 'components/Progress/CircularLoader';

type DialogActionsProps = {
  handleClose: () => void;
  handleSubmit: () => void;
  handleConfirm: () => void;
  uploadResolved: boolean;
  uploadStarted: boolean;
  isLoading: boolean;
  isValid: boolean;
};

export const DialogActions = ({
  handleClose,
  handleSubmit,
  handleConfirm,
  uploadResolved,
  uploadStarted,
  isLoading,
  isValid,
}: DialogActionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.actionButtons}>
      {uploadResolved ? (
        <OkButton onClick={handleConfirm} id="examination-add-confirm" />
      ) : (
        <>
          <CancelButton
            id="add-examination-cancel"
            onClick={handleClose}
            className={classes.cancelButton}
            disabled={uploadStarted}
          >
            {t('cancel')}
          </CancelButton>
          <SubmitButton
            isLoading={isLoading}
            id="add-examination-submit"
            onClick={handleSubmit}
            disabled={!isValid || isLoading || uploadStarted}
          >
            {isLoading ? <CircularLoader /> : t('confirm')}
          </SubmitButton>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
}));
