import * as Yup from 'yup';
import i18n from 'translations/i18n';
import { emailValidation } from 'validation/validationFunctions';

export const loginValidationSchema = () => {
  return Yup.object().shape({
    email: emailValidation(),
    password: Yup.string().required(i18n.t('validation.required-password')),
  });
};
