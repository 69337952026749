import { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useAuth } from 'context/providers/AuthProvider';
import { AddFirstProfile } from 'pages/profiles/addFirstProfile/AddFirstProfile';
import { ActiveProfilePanel } from 'pages/profiles/activeProfileInfo/ActiveProfilePanel';

export const ProfilePanel = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const [addProfileResult, setAddProfileResult] = useState({
    resolved: false,
    status: '',
  });

  return (
    <div
      className={clsx(classes.profilePanel, {
        [classes.minHeight]: user && user.profile,
      })}
    >
      {user && user.profile ? (
        <ActiveProfilePanel />
      ) : (
        <AddFirstProfile
          addProfileError={addProfileResult.status === 'error'}
          setAddProfileResult={setAddProfileResult}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  profilePanel: {
    display: 'flex',
    backgroundColor: theme.palette.grey[600],
    height: 'fit-content',
    padding: theme.spacing(2),
    borderRadius: '8px',
  },
  minHeight: {
    minHeight: '136px',
  },
}));
