import { useEffect, useState } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
} from 'recharts';
import theme from 'theme';
import { DateTime } from 'luxon';
import { CustomResponsiveContainer } from 'charts/ChartComponents/CustomResponsiveContainer';
import { CustomXAxisTick } from 'charts/DotCharts/DotChartComponents/CustomXAxisTick';
import { FixedSizeContainer } from 'charts/ChartComponents/FixedSizeContainer';
import { DividedAhiChartDataItem } from 'charts/chartTypes/chartTypes';
import { formatYAxisTick } from 'charts/utils';
import { formatXAxisTick } from 'charts/DotCharts/utils';
import { ExaminationSimple } from 'commonTypes/examinationTypes';
import { TimeFrames } from 'pages/examinations/ExaminationsSummaryPage';
import { useXAxisData } from 'charts/DotCharts/useXAxisData';
import { useYAxisData } from 'charts/DotCharts/useYAxisData';

type DividedAhiDotChartProps = {
  data: ExaminationSimple[] | null;
  timeFrame: TimeFrames;
  dates: { from: DateTime | undefined; to: DateTime | undefined };
};

export const DividedAhiDotChart = ({
  data,
  timeFrame,
  dates,
}: DividedAhiDotChartProps) => {
  const [chartData, setChartData] = useState<
    DividedAhiChartDataItem[] | undefined
  >(undefined);
  const { xAxisDomain, xAxisTicks, createXAxisData } = useXAxisData(
    timeFrame,
    dates
  );
  const {
    yAxisTicks,
    createYAxisData,
    setHighestValue,
    highestValue,
  } = useYAxisData(true);

  useEffect(() => {
    const createChartData = () => {
      if (!data) {
        return;
      }
      const dataObjects: DividedAhiChartDataItem[] = [];
      data.forEach((examination) => {
        const {
          examinationId,
          supinePositionRatio,
          supineAhi,
          nonSupineAhi,
          startDate,
        } = examination;
        if (
          supinePositionRatio === null ||
          supineAhi === null ||
          nonSupineAhi === null
        ) {
          return;
        }
        if (supineAhi > highestValue || nonSupineAhi > highestValue) {
          setHighestValue(supineAhi > nonSupineAhi ? supineAhi : nonSupineAhi);
        }
        const luxonDate = DateTime.fromISO(startDate);

        const dotData = {
          examid: examinationId,
          name: luxonDate.toMillis(),
          nonSupineAhi,
          supineAhi,
          supinePositionRatio: -supinePositionRatio,
        };
        dataObjects.push(dotData);
      });

      return dataObjects;
    };
    if (data) {
      setChartData(createChartData());
      createXAxisData();
      createYAxisData();
    }
  }, [
    setChartData,
    data,
    createXAxisData,
    createYAxisData,
    highestValue,
    setHighestValue,
  ]);

  return (
    <>
      <FixedSizeContainer>
        <CustomResponsiveContainer>
          <ScatterChart
            data={chartData}
            id={`div-ahi-dot-chart-${timeFrame}`}
            margin={{ left: -25, right: 10, top: 2 }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3" />
            <XAxis
              dataKey="name"
              height={60}
              interval={0}
              padding={{ right: 10 }}
              stroke={theme.palette.text.secondary}
              tickFormatter={
                timeFrame === TimeFrames.MONTH ? formatXAxisTick : undefined
              }
              tick={
                timeFrame === TimeFrames.MONTH
                  ? { transform: 'translate(0, 6)' }
                  : (props) => <CustomXAxisTick {...props} />
              }
              type="number"
              domain={xAxisDomain}
              ticks={xAxisTicks}
            />
            <YAxis
              ticks={yAxisTicks}
              tickFormatter={formatYAxisTick}
              stroke={theme.palette.text.secondary}
              domain={[-120, 120]}
            />
            <ReferenceLine y={0} />
            <Scatter
              isAnimationActive={false}
              data={chartData}
              name="supinePositionRatio"
              dataKey="supinePositionRatio"
              fill={theme.palette.secondary.dark}
              id="supine-position-dot"
            />
            <Scatter
              isAnimationActive={false}
              data={chartData}
              name="nonSupineAhi"
              dataKey="nonSupineAhi"
              fill={theme.palette.warning.darkmode}
              id="nsAhi-dot"
            />
            <Scatter
              isAnimationActive={false}
              data={chartData}
              name="supineAhi"
              dataKey="supineAhi"
              fill={theme.palette.success.darkmode}
              id="sAhi-dot"
            />
          </ScatterChart>
        </CustomResponsiveContainer>
      </FixedSizeContainer>
    </>
  );
};
