import {
  makeStyles,
  FormControl,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { AgreementsCheckbox } from 'pages/auth/register/AgreementsCheckbox';
import {
  ConsentWithDocuments,
  DocumentLink,
} from 'pages/auth/register/ConsentWithDocuments';
import { ConsentNamesProfile } from 'components/Consents/ConsentNames';
import { ProfileFormValues } from 'pages/profiles/ProfileForm';
import { ProfileConsentFormValues } from 'components/Consents/types';
import { profileActionType } from 'commonTypes/profileTypes';

type ConsentFormProps = {
  formik:
    | FormikContextType<ProfileFormValues>
    | FormikContextType<ProfileConsentFormValues>;
  type: profileActionType;
  consentError?: boolean;
  className?: string;
};

export const ProfileConsentsForm = ({
  formik,
  type,
  consentError,
  className,
}: ConsentFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { values, errors, touched, handleChange } = formik;

  return (
    <FormControl
      error={consentError || !isEmpty(formik.errors)}
      component="fieldset"
      className={clsx(className, classes.margin)}
    >
      <Typography className={classes.infoText}>
        {t('consents.profile-info')}
      </Typography>
      <AgreementsCheckbox
        name="termsConditionsAgreement"
        id="terms-conditions-agreement"
        value={values.termsConditionsAgreement}
        checked={values.termsConditionsAgreement}
        label={<ConsentWithDocuments />}
        onChange={handleChange}
        disabled={type === profileActionType.EDIT}
      />
      {errors.termsConditionsAgreement && touched.termsConditionsAgreement && (
        <FormHelperText className={classes.helper}>
          {errors.termsConditionsAgreement}
        </FormHelperText>
      )}
      <AgreementsCheckbox
        name="dataProcessingAgreement"
        id="data-processing-agreement"
        value={values.dataProcessingAgreement}
        checked={values.dataProcessingAgreement}
        label={t('consents.checkbox2')}
        onChange={handleChange}
        disabled={type === profileActionType.EDIT}
      />
      {errors.dataProcessingAgreement && touched.dataProcessingAgreement && (
        <FormHelperText className={classes.helper}>
          {errors.dataProcessingAgreement}
        </FormHelperText>
      )}
      <Typography className={classes.requiredInfo} variant="caption">
        {t('required-field')}
      </Typography>
      {type === profileActionType.EDIT && (
        <Typography variant="caption" className={classes.mailto}>
          {t('consents.withdraw-required-consent')}
          <a href="mailto:contact@clebre.com" className={classes.link}>
            contact@clebre.com
          </a>
        </Typography>
      )}
      <Typography variant="caption">
        {t('consents.administrator-info')}
        <DocumentLink name={ConsentNamesProfile.GDPRProfile} />
      </Typography>
    </FormControl>
  );
};

ProfileConsentsForm.defaultProps = {
  className: undefined,
  consentError: false,
};

const useStyles = makeStyles((theme) => ({
  requiredInfo: {
    margin: theme.spacing(0.5, 0, 1, 0),
  },
  helper: {
    marginBottom: theme.spacing(1),
  },
  infoText: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  link: {
    fontSize: '12px',
    color: theme.palette.secondary.dark,
  },
  mailto: {
    marginBottom: theme.spacing(1),
  },
}));
