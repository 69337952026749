import { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useExaminations } from 'context/providers/ExaminationsProvider';
import { BasicDetails } from 'pages/examinations/examinationDetails/BasicDetails';
import { AdvancedDetails } from 'pages/examinations/examinationDetails/AdvancedDetails';
import { SleepScore } from 'commonTypes/examinationTypes';
import { PdfDownloadButton } from 'pages/examinations/pdfDownload/PdfDownloadButton';
import { PdfDownloadDialog } from 'pages/examinations/pdfDownload/PdfDownloadDialog';
import { ExamDeleteButton } from 'pages/examinations/examinationDelete/ExamDeleteButton';
import { ExamDeleteDialog } from 'pages/examinations/examinationDelete/ExamDeleteDialog';
import { CircleCharts } from 'pages/examinations/examinationDetails/CircleCharts';

type DetailsPanelProps = {
  sleepScore: SleepScore;
  naturalId: string | undefined;
  examinationId: string | undefined;
  isOwner: boolean;
};

export const DetailsPanel = ({
  sleepScore,
  naturalId,
  examinationId,
  isOwner,
}: DetailsPanelProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { getExaminations } = useExaminations();
  const [isPdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const openPdfDialog = () => {
    setPdfDialogOpen(true);
  };

  const closePdfDialog = () => {
    setPdfDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const postDeleteAction = () => {
    getExaminations();
    history.push('/examinations-list');
  };

  return (
    <div id="examination-details" className={classes.examinationDetails}>
      <BasicDetails sleepScore={sleepScore} />
      <AdvancedDetails sleepScore={sleepScore} />
      <div>
        <div className={classes.examNumber}>
          <Typography variant="caption" className={classes.numberLabel}>
            {t('examinations.number')}
          </Typography>
          <Typography id="natural-id" variant="h3" className={classes.number}>
            {naturalId}
          </Typography>
        </div>
        <>
          {examinationId && naturalId && (
            <div className={classes.buttons}>
              <ExamDeleteButton
                onClick={openDeleteDialog}
                className={classes.deleteButton}
                disabled={!isOwner}
                tooltip
              />
              <ExamDeleteDialog
                open={isDeleteDialogOpen}
                onClose={closeDeleteDialog}
                selectedExaminations={[{ id: examinationId, isOwner }]}
                postDeleteAction={postDeleteAction}
                shouldClose={false}
              />
              <PdfDownloadButton
                onClick={openPdfDialog}
                className={classes.pdfDownloadButton}
              />
              <PdfDownloadDialog
                naturalId={naturalId}
                open={isPdfDialogOpen}
                close={closePdfDialog}
                selectedExaminations={[{ id: examinationId, isOwner }]}
              />
            </div>
          )}
          <CircleCharts
            sleepScoreMetrics={sleepScore}
            className={classes.circleCharts}
          />
        </>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  examinationDetails: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  examNumber: {
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',

    '@media (max-width:1500px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  numberLabel: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  number: {
    color: theme.palette.grey[900],
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  deleteButton: {
    margin: theme.spacing(0, 2, 2, 0),
  },
  pdfDownloadButton: {
    marginBottom: theme.spacing(2),
  },
  circleCharts: {
    '@media (max-width:1500px)': {
      display: 'none',
    },
  },
}));
