import { Dispatch, SetStateAction } from 'react';
import {
  useGoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { useMutation } from 'react-query';
import { loginWithGoogleCode } from 'api/auth/authApi';
import { useAuth } from 'context/providers/AuthProvider';
import { GoogleAuthButton } from 'components/Button/GoogleAuthButton';
import {
  googleAuthErrors,
  GoogleAuthError,
} from 'pages/auth/googleAuth/googleAuthErrors';
import { loginErrors } from 'pages/auth/login/loginErrors';

type GoogleAuthProps = {
  label: string;
  className: string;
  setError: Dispatch<SetStateAction<string | null>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const GoogleAuth = ({
  label,
  className,
  setError,
  setLoading,
}: GoogleAuthProps) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID!;
  const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
  const { login, logout } = useAuth();

  const loginWithGoogleMutation = useMutation(loginWithGoogleCode, {
    onError: () => {
      setError(loginErrors.genericError.message);
      logout();
      setLoading(false);
    },
    onSuccess: (data) => {
      login(data);
      setLoading(false);
    },
  });

  const onSuccess = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if (response.code) {
      setLoading(true);
      loginWithGoogleMutation.mutate(response.code);
    }
  };

  const onFailure = ({ error }: GoogleAuthError) => {
    if (error === googleAuthErrors.googlePopupClosed.id) {
      setError(googleAuthErrors.googlePopupClosed.message);
    } else if (error === googleAuthErrors.initializationFailed.id) {
      setError(null);
    } else {
      setError(loginErrors.genericError.message);
    }
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    responseType: 'code',
    scope: 'openid email profile',
    redirectUri,
  });

  return (
    <GoogleAuthButton onClick={signIn} label={label} className={className} />
  );
};
