import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { FormikContextType } from 'formik';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  Gender,
  GenderEnum,
  profileActionType,
} from 'commonTypes/profileTypes';
import { TextFieldBase } from 'components/Input/TextFieldBase';
import { DateInput } from 'components/Input/DateInput';
import {
  handleBlurAndTrim,
  weightHeightHandleChange,
  capitalizeOnChange,
  phoneNoHandleChange,
  phoneNoHandleBlur,
  phoneNoHandleFocus,
} from 'utils/formHelpers';
import { validatePesel } from 'api/profiles/profilesApi';
import { useAuth } from 'context/providers/AuthProvider';

export type ProfileFormValues = {
  firstName: string;
  dateOfBirth: string | MaterialUiPickersDate;
  govIdentification: string;
  lastName: string;
  weight: string | number;
  height: string | number;
  phoneNumber: string;
  gender: Gender;
  noPesel: boolean;
  termsConditionsAgreement?: boolean;
  dataProcessingAgreement?: boolean;
};

type ProfileFormProps = {
  formik: FormikContextType<ProfileFormValues>;
  setPeselValidationCache: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  peselValidationCache: { [key: string]: boolean };
  setGender: (gender: Gender) => void;
  type: profileActionType;
  disablePeselRelatedField: () => boolean;
};

export const ProfileForm = ({
  formik,
  setPeselValidationCache,
  peselValidationCache,
  setGender,
  type,
  disablePeselRelatedField,
}: ProfileFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    initialValues,
  } = formik;
  const { isDoctor } = useAuth();

  const handleGovidChange = (event: ChangeEvent<HTMLInputElement>) => {
    const eventCopy = { ...event };
    eventCopy.target.value = event.target.value.replace(/[^0-9]+/g, '');
    handleChange(eventCopy);

    if (eventCopy.target.value.length === 11) {
      validatePesel(eventCopy.target.value).then((data) => {
        const peselCacheCopy = { ...peselValidationCache };
        peselCacheCopy[eventCopy.target.value] = data.result;
        setPeselValidationCache(peselCacheCopy);
        setFieldTouched('govIdentification');
        if (data.result === true) {
          setFieldValue('dateOfBirth', data.dateOfBirth);
          setFieldTouched('dateOfBirth');
          setGender(data.gender);
        } else {
          setFieldValue('dateOfBirth', null);
          setGender(GenderEnum.male);
        }
      });
    }
  };

  return (
    <form className={classes.profileForm}>
      <div className={classes.inputColumn}>
        <TextFieldBase
          required
          name="firstName"
          id="first-name-input"
          label={t('first-name')}
          className={classes.input}
          value={values.firstName}
          onChange={(event) => capitalizeOnChange(event, formik)}
          onBlur={(event) => handleBlurAndTrim(event, formik)}
          error={Boolean(errors.firstName && touched.firstName)}
          helperText={
            errors.firstName && touched.firstName ? errors.firstName : null
          }
        />
        <DateInput
          required
          name="dateOfBirth"
          id="date-of-birth-input"
          label={t('profiles.date-of-birth')}
          className={classes.input}
          value={values.dateOfBirth}
          setFieldValue={setFieldValue}
          disabled={disablePeselRelatedField()}
          handleBlur={handleBlur}
          error={Boolean(errors.dateOfBirth && touched.dateOfBirth)}
          helperText={
            errors.dateOfBirth && touched.dateOfBirth
              ? errors.dateOfBirth
              : null
          }
        />
        <TextFieldBase
          required={isDoctor && !formik.values.noPesel}
          name="govIdentification"
          id="gov-identification-input"
          label={t('profiles.govid')}
          disabled={
            Boolean(
              type === profileActionType.EDIT && initialValues.govIdentification
            ) || formik.values.noPesel
          }
          className={classes.input}
          value={values.govIdentification}
          onChange={handleGovidChange}
          onBlur={handleBlur}
          error={Boolean(errors.govIdentification && touched.govIdentification)}
          helperText={
            errors.govIdentification && touched.govIdentification
              ? errors.govIdentification
              : null
          }
        />
        {isDoctor && (
          <Typography className={classes.requiredInfo} variant="caption">
            {t('required-field')}
          </Typography>
        )}
      </div>
      <div className={classes.inputColumn}>
        <TextFieldBase
          required
          name="lastName"
          id="last-name-input"
          label={t('last-name')}
          className={classes.input}
          value={values.lastName}
          onChange={(event) => capitalizeOnChange(event, formik)}
          onBlur={(event) => handleBlurAndTrim(event, formik)}
          error={Boolean(errors.lastName && touched.lastName)}
          helperText={
            errors.lastName && touched.lastName ? errors.lastName : null
          }
        />
        <div className={classes.weightHeight}>
          <TextFieldBase
            required
            name="weight"
            id="weight-input"
            label={t('profiles.weight')}
            className={clsx(classes.numberInput, classes.smallInput)}
            value={values.weight}
            type="number"
            onChange={(event) => weightHeightHandleChange(event, handleChange)}
            onBlur={handleBlur}
            error={Boolean(errors.weight && touched.weight)}
            helperText={errors.weight && touched.weight ? errors.weight : null}
          />
          <TextFieldBase
            required
            name="height"
            id="height-input"
            label={t('profiles.height')}
            className={clsx(classes.numberInput, classes.smallInput)}
            value={values.height}
            type="number"
            onChange={(event) => weightHeightHandleChange(event, handleChange)}
            onBlur={handleBlur}
            error={Boolean(errors.height && touched.height)}
            helperText={errors.height && touched.height ? errors.height : null}
          />
        </div>
        {isDoctor && (
          <TextFieldBase
            name="phoneNumber"
            id="phone-number-input"
            label={t('profiles.phone-number')}
            className={classes.input}
            value={values.phoneNumber}
            onChange={(event) => phoneNoHandleChange(event, handleChange)}
            onBlur={(event) => phoneNoHandleBlur(event, formik)}
            onFocus={() => phoneNoHandleFocus(formik)}
            error={Boolean(errors.phoneNumber && touched.phoneNumber)}
            helperText={
              errors.phoneNumber && touched.phoneNumber
                ? errors.phoneNumber
                : t('validation.phoneNo-helper')
            }
          />
        )}
      </div>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  inputColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '48%',
  },
  profileForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  weightHeight: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  smallInput: {
    width: '48%',
  },
  numberInput: {
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& [type=number]': {
      '-moz-appearance': 'textfield',
    },
    marginBottom: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(3),
  },
  requiredInfo: {
    margin: theme.spacing(1, 0, 1, 0),
  },
}));
