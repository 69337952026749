import {
  Dialog,
  DialogContent,
  makeStyles,
  DialogProps,
  IconButton,
  Typography,
} from '@material-ui/core';
import { ReactComponent as Close } from 'icons/Close.svg';
import { ReactComponent as CloseDisabled } from 'icons/CloseDisabled.svg';

type DialogBaseProps = Omit<DialogProps, 'onClose'> & {
  onClose?: () => void;
  disabled?: boolean;
};

export const DialogBase = ({
  children,
  onClose,
  open,
  title,
  className,
  disabled,
  ...props
}: DialogBaseProps) => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: className }} open={open} maxWidth="lg" {...props}>
      <div className={classes.topBar}>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          disabled={disabled}
        >
          {disabled ? <CloseDisabled /> : <Close />}
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

DialogBase.defaultProps = {
  onClose: undefined,
  disabled: false,
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(0, 4, 2, 4),
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    margin: theme.spacing(1),
  },
  title: {
    color: theme.palette.primary.main,
    margin: theme.spacing(2, 0, 0, 4),
  },
}));
