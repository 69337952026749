import { makeStyles, FormControl, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import { isEmpty } from 'lodash';
import { AgreementsCheckbox } from 'pages/auth/register/AgreementsCheckbox';
import {
  ConsentWithDocuments,
  DoctorConsent,
} from 'pages/auth/register/ConsentWithDocuments';
import { RegisterValues } from 'pages/auth/register/formikValues';
import { UserConsentFormValues } from 'components/Consents/types';
import { ConsentsInfo } from 'components/Consents/ConsentsInfo';

type ConsentFormProps = {
  formik:
    | FormikContextType<RegisterValues>
    | FormikContextType<UserConsentFormValues>;
  consentError?: boolean;
  isDoctor: boolean;
  className?: string;
  edit?: boolean;
};

export const UserConsentsForm = ({
  formik,
  consentError,
  isDoctor,
  className,
  edit,
}: ConsentFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { values, errors, touched, handleChange } = formik;

  return (
    <FormControl
      error={consentError || !isEmpty(formik.errors)}
      component="fieldset"
      className={className}
    >
      <AgreementsCheckbox
        name="termsConditionsAgreement"
        id="terms-conditions-agreement"
        value={values.termsConditionsAgreement}
        checked={values.termsConditionsAgreement}
        label={<ConsentWithDocuments />}
        onChange={handleChange}
        disabled={edit}
      />
      {errors.termsConditionsAgreement && touched.termsConditionsAgreement && (
        <FormHelperText className={classes.helper}>
          {errors.termsConditionsAgreement}
        </FormHelperText>
      )}
      <AgreementsCheckbox
        name="dataProcessingAgreement"
        id="data-processing-agreement"
        value={values.dataProcessingAgreement}
        checked={values.dataProcessingAgreement}
        label={t('consents.checkbox2')}
        onChange={handleChange}
        disabled={edit}
      />
      {errors.dataProcessingAgreement && touched.dataProcessingAgreement && (
        <FormHelperText className={classes.helper}>
          {errors.dataProcessingAgreement}
        </FormHelperText>
      )}
      {isDoctor && (
        <>
          <AgreementsCheckbox
            name="entrustingAgreement"
            id="entrusting-agreement"
            value={values.entrustingAgreement}
            checked={values.entrustingAgreement}
            label={<DoctorConsent />}
            onChange={handleChange}
            disabled={edit}
          />
          {errors.entrustingAgreement && touched.entrustingAgreement && (
            <FormHelperText className={classes.helper}>
              {errors.entrustingAgreement}
            </FormHelperText>
          )}
        </>
      )}
      <AgreementsCheckbox
        name="marketingAgreement"
        id="marketing-agreement"
        value={values.marketingAgreement}
        checked={values.marketingAgreement}
        label={t('consents.checkbox3')}
        onChange={handleChange}
      />
      <AgreementsCheckbox
        name="contactAgreement"
        id="contact-agreement"
        value={values.contactAgreement}
        checked={values.contactAgreement}
        label={t('consents.checkbox4')}
        onChange={handleChange}
      />
      <ConsentsInfo edit={edit} />
    </FormControl>
  );
};

UserConsentsForm.defaultProps = {
  className: undefined,
  consentError: false,
  edit: false,
};

const useStyles = makeStyles((theme) => ({
  helper: {
    marginBottom: theme.spacing(1),
  },
}));
