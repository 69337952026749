import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    const scrollableContent = document.getElementById('scrollable-content');
    if (scrollableContent) {
      scrollableContent.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};
