import {
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { ProfileSimple } from 'commonTypes/authTypes';
import { LettersAvatar } from 'components/Avatar/LettersAvatar';
import { useAuth } from 'context/providers/AuthProvider';
import { PrimaryProfileItemText } from 'pages/profiles/manageProfiles/PrimaryProfileItemText';
import { SecondaryProfileItemText } from 'pages/profiles/manageProfiles/SecondaryProfileItemText';

type ProfileItemProps = {
  profile: ProfileSimple;
};

export const ProfileItem = ({ profile }: ProfileItemProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { setActiveProfileId, activeProfile, isBasic } = useAuth();
  const { requiredConsents } = profile;

  const handleClick = (profileId: string) => {
    if (location.pathname !== '/') {
      history.push('/');
    }
    setActiveProfileId(profileId);
  };

  return (
    <>
      <ListItem
        button
        onClick={() => handleClick(profile.profileId)}
        className={clsx(classes.profileItem, {
          [classes.selectedItem]:
            profile.profileId === activeProfile?.profileId,
          [classes.noConsents]: !requiredConsents && isBasic,
        })}
      >
        <ListItemAvatar className={classes.avatarContainer}>
          <LettersAvatar user={profile} className={classes.avatar} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={<PrimaryProfileItemText profile={profile} />}
          secondary={<SecondaryProfileItemText profile={profile} />}
        />
      </ListItem>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  profileItem: {
    borderRadius: '8px',
    height: '68px',
    margin: theme.spacing(0, 1, 0.25, 1),
    padding: theme.spacing(1, 1, 1, 0),
    width: theme.spacing(33),

    '&:hover': {
      backgroundColor: theme.palette.grey[600],
    },
  },
  selectedItem: {
    backgroundColor: theme.palette.grey[600],
  },
  avatar: {
    width: '32px',
    height: '32px',
    fontSize: '14px',
    margin: theme.spacing(0, 1, 0, 1),
  },
  avatarContainer: {
    minWidth: '32px',
  },
  noConsents: {
    backgroundColor: theme.palette.grey[100],
  },
}));
