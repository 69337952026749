import { ExaminationSimple } from 'commonTypes/examinationTypes';

// eslint-disable-next-line
export enum metricsEnum {
  AHI = 'ahi',
  SLEEPEFFICIENCY = 'sleepEfficiency',
  TOTALSLEEPTIME = 'totalSleepTime',
}

export const calculateAverageValue = (
  data: ExaminationSimple[],
  metric: metricsEnum
) => {
  if (!data) {
    return;
  }
  let sum = 0;
  data.forEach((examination) => {
    if (examination[metric] !== null) {
      // @ts-ignore
      sum += examination[metric];
    }
  });
  if (sum === 0) {
    return 0;
  }
  return sum / data.length;
};
