import { useCallback, useState } from 'react';
import {
  createXAxisTicks,
  createXAxisTicksForYear,
  calculateXAxisDomain,
} from 'charts/DotCharts/utils';
import { TimeFrames } from 'pages/examinations/ExaminationsSummaryPage';
import { DateTime } from 'luxon';

export const useXAxisData = (
  timeFrame: string,
  dates: { from: DateTime | undefined; to: DateTime | undefined }
) => {
  const [xAxisDomain, setXAxisDomain] = useState<number[]>([]);
  const [xAxisTicks, setXAxisTicks] = useState<number[]>([]);

  const createXAxisData = useCallback(() => {
    if (dates.from && dates.to) {
      const { left, right } = calculateXAxisDomain(
        dates as { from: DateTime; to: DateTime }
      );
      setXAxisDomain([left, right]);

      if (timeFrame === TimeFrames.YEAR && right) {
        setXAxisTicks(createXAxisTicksForYear(right));
      } else {
        setXAxisTicks(createXAxisTicks(left, right));
      }
    }
  }, [timeFrame, dates]);

  return { xAxisDomain, xAxisTicks, createXAxisData };
};
