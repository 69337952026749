import { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { CircleCharts } from 'pages/examinations/examinationsSummary/CircleCharts';
import { BarAndDotCharts } from 'pages/examinations/examinationsSummary/BarAndDotCharts';
import { TimeFrameSelect } from 'pages/examinations/examinationsSummary/TimeFrameSelect';
import { AverageSleepTime } from 'pages/examinations/examinationsSummary/AverageSleepTime';
import { NavigationButtons } from 'pages/examinations/examinationsSummary/NavigationButtons';
import { useAuth } from 'context/providers/AuthProvider';
import { ExaminationSimple, NavDirections } from 'commonTypes/examinationTypes';
import { getExaminationsList } from 'api/examinations/examinationsApi';

// eslint-disable-next-line
export enum TimeFrames {
  LAST_RESULTS = '14-results',
  MONTH = '1',
  THREE_MONTHS = '3',
  SIX_MONTHS = '6',
  YEAR = '12',
}

const DEFAULT_SLICE = [0, 14];
const DEFAULT_DATE_RANGE = { from: undefined, to: undefined };

const ExaminationsSummaryPage = () => {
  const { activeProfile, user } = useAuth();
  const [examinations, setExaminations] = useState<ExaminationSimple[] | null>(
    null
  );
  const classes = useStyles();
  const [timeFrame, setTimeFrame] = useState(TimeFrames.LAST_RESULTS);
  const [dates, setDates] = useState<{
    from: DateTime | undefined;
    to: DateTime | undefined;
  }>(DEFAULT_DATE_RANGE);
  const [chartData, setChartData] = useState<ExaminationSimple[] | null>(null);
  const [currentExaminationsSlice, setCurrentExaminationSlice] = useState(
    DEFAULT_SLICE
  );

  useEffect(() => {
    setExaminations(null);
    const { CancelToken } = axios;
    const source = CancelToken.source();

    if (activeProfile?.profileId) {
      getExaminationsList(activeProfile.profileId, [], source)
        .then((data) => {
          setExaminations(data);
          setTimeFrame(TimeFrames.LAST_RESULTS);
          setCurrentExaminationSlice(DEFAULT_SLICE);
        })
        .catch(() => {
          setTimeFrame(TimeFrames.LAST_RESULTS);
          setCurrentExaminationSlice(DEFAULT_SLICE);
        });
    }

    return () => {
      source.cancel();
    };
  }, [
    activeProfile?.profileId,
    activeProfile?.hasConsents,
    user?.requiredConsents,
  ]);

  const getLatestExaminations = (
    examinationList: ExaminationSimple[] | null,
    slice: number[]
  ) => {
    if (!examinationList) {
      return null;
    }
    if (examinationList.length < 14) {
      return examinationList;
    }
    return examinationList.slice(slice[0], slice[1]);
  };

  const getExaminationsFromTimeframe = (
    examinationList: ExaminationSimple[] | null,
    dateFrom: DateTime | undefined,
    dateTo: DateTime | undefined
  ) => {
    if (!examinationList || !dateFrom || !dateTo) {
      return null;
    }
    const selectedExaminations: ExaminationSimple[] = [];

    for (let i = 0; i < examinationList.length; i += 1) {
      const luxonExaminationStart = DateTime.fromISO(
        examinationList[i].startDate
      );
      if (
        luxonExaminationStart >= dateFrom &&
        luxonExaminationStart <= dateTo
      ) {
        selectedExaminations.push(examinationList[i]);
      }
      if (luxonExaminationStart < dateFrom) {
        break;
      }
    }
    return selectedExaminations;
  };

  useEffect(() => {
    if (timeFrame !== TimeFrames.LAST_RESULTS) {
      return;
    }
    const data = getLatestExaminations(examinations, currentExaminationsSlice);
    setChartData(data);
  }, [timeFrame, currentExaminationsSlice, examinations]);

  useEffect(() => {
    if (timeFrame === TimeFrames.LAST_RESULTS) {
      return;
    }
    const data = getExaminationsFromTimeframe(
      examinations,
      dates.from,
      dates.to
    );
    if (data) {
      setChartData(data);
    }
  }, [timeFrame, dates.from, dates.to, examinations]);

  const selectTimeFrame = (timeframe: TimeFrames) => {
    setTimeFrame(timeframe);
    if (timeframe === TimeFrames.LAST_RESULTS) {
      setCurrentExaminationSlice(DEFAULT_SLICE);
    } else {
      const dateFrom = DateTime.now().minus({
        months: parseInt(timeframe, 10),
      });
      const dateTo = DateTime.now();
      setDates({ from: dateFrom, to: dateTo });
    }
  };

  const isPrevDisabled = () => {
    if (
      chartData &&
      examinations &&
      !isEmpty(chartData) &&
      !isEmpty(examinations)
    ) {
      return (
        chartData[chartData.length - 1].examinationId ===
        examinations[examinations.length - 1].examinationId
      );
    }
    return false;
  };

  const isNextDisabled = () => {
    if (timeFrame === TimeFrames.LAST_RESULTS) {
      if (currentExaminationsSlice[0] === 0) {
        return true;
      }
    }

    if (timeFrame !== TimeFrames.LAST_RESULTS && dates.to) {
      return dates.to.startOf('day') >= DateTime.now().startOf('day');
    }
    return false;
  };

  const checkIfNavDisabled = (direction: NavDirections) => {
    let disabled = false;
    if (!examinations || isEmpty(examinations)) {
      disabled = true;
      return disabled;
    }
    if (direction === NavDirections.NEXT) {
      disabled = isNextDisabled();
    } else {
      disabled = isPrevDisabled();
    }
    return disabled;
  };

  return (
    <>
      {activeProfile && (
        <>
          <div className={classes.topBar}>
            <TimeFrameSelect
              timeFrame={timeFrame}
              selectTimeFrame={selectTimeFrame}
              setChartData={setChartData}
            />
            <NavigationButtons
              timeFrame={timeFrame}
              dates={dates}
              setDates={setDates}
              currentExaminationsSlice={currentExaminationsSlice}
              setCurrentExaminationSlice={setCurrentExaminationSlice}
              checkIfNavDisabled={checkIfNavDisabled}
              setChartData={setChartData}
            />
            <AverageSleepTime data={chartData} />
          </div>
          <CircleCharts data={chartData} />
          <BarAndDotCharts
            data={chartData}
            timeFrame={timeFrame}
            dates={dates}
          />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  topBar: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignContent: 'center',
  },
}));

export default ExaminationsSummaryPage;
