import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const ResetConfirmation = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.heading} variant="h2">
        {t('forgot-password.reset-password')}
      </Typography>
      <Typography className={classes.message}>
        {t('forgot-password.reset-confirmation')}
      </Typography>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
  message: {
    margin: theme.spacing(2, 0, 4, 0),
    textAlign: 'center',
  },
}));
