import { ChangeEvent } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type FileUploadButtonProps = {
  handleSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  className: string;
};

export const OtherFileUploadButton = ({
  handleSelect,
  className,
}: FileUploadButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Button
      component="label"
      color="primary"
      variant="contained"
      className={clsx(className, classes.addFileButton)}
    >
      {t('examinations.file-select')}
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={(event) => handleSelect(event)}
        accept="*"
        id="other-file-upload-button"
      />
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  addFileButton: {
    width: 'fit-content',
    fontSize: '14px',
    fontWeight: 500,
    borderRadius: '8px',
    margin: theme.spacing(1, 0, 2, 0),
  },
}));
