import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SleepScore } from 'commonTypes/examinationTypes';
import {
  DetailsItem,
  DetailsItemType,
} from 'pages/examinations/examinationDetails/DetailsItem';

type AdvancedDetailsProps = {
  sleepScore: SleepScore;
};

export const AdvancedDetails = ({ sleepScore }: AdvancedDetailsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const data = [
    {
      label: {
        name: 'number-of-apneas',
        tooltip: {
          text: t('examinations.number-of-apneas-tooltip'),
        },
      },
      type: DetailsItemType.NUMBER,
      value: sleepScore.apneaCount,
    },
    {
      label: {
        name: 'number-of-hypopneas',
        tooltip: {
          text: t('examinations.number-of-hypopneas-tooltip'),
        },
      },
      type: DetailsItemType.NUMBER,
      value: sleepScore.hypopneaCount,
    },
    {
      label: {
        name: 'supine-position-length',
        tooltip: {
          text: t('examinations.supine-position-length-tooltip'),
        },
      },
      type: DetailsItemType.TIME,
      value: sleepScore.supinePositionDuration,
    },
    {
      label: {
        name: 'non-supine-position-length',
        tooltip: {
          text: t('examinations.non-supine-position-length-tooltip'),
        },
      },
      type: DetailsItemType.TIME,
      value: sleepScore.nonSupinePositionDuration,
    },
    {
      label: {
        name: 'number-of-normal-breath-episodes',
        tooltip: {
          text: t('examinations.number-of-normal-breath-episodes-tooltip'),
        },
      },
      type: DetailsItemType.NUMBER,
      value: sleepScore.normalBreathEpisodesCount,
    },
    {
      label: {
        name: 'number-of-snore-episodes',
        tooltip: {
          text: t('examinations.number-of-snore-episodes-tooltip'),
        },
      },
      type: DetailsItemType.NUMBER,
      value: sleepScore.snoreEpisodesCount,
    },
    {
      label: {
        name: 'respiratory-rate-variability',
        tooltip: {
          text: t('examinations.respiratory-rate-variability-tooltip'),
        },
      },
      type: DetailsItemType.NUMBER,
      value: sleepScore.respiratoryRateVariability,
    },
    {
      label: {
        name: 'total-sleep-time',
        tooltip: {
          text: t('examinations.total-sleep-time-tooltip'),
        },
      },
      type: DetailsItemType.TIME,
      value: sleepScore.totalSleepTime,
    },
    {
      label: {
        name: 'total-registration-time',
        tooltip: {
          text: t('examinations.total-registration-time-tooltip'),
        },
      },
      type: DetailsItemType.TIME,
      value: sleepScore.totalRegistrationTime,
    },
    {
      label: {
        name: 'total-wake-time',
        tooltip: {
          text: t('examinations.total-wake-time-tooltip'),
        },
      },
      type: DetailsItemType.TIME,
      value: sleepScore.totalWakeTime,
    },
  ];

  return (
    <div className={classes.detailsContainer}>
      <Typography variant="h3" className={classes.title}>
        {t('advanced')}
      </Typography>
      {data.map((item) => (
        <DetailsItem
          id={item.label.name}
          key={item.label.name}
          label={item.label}
          value={item.value}
          type={item.type}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    width: '340px',
    minWidth: '340px',
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[900],
  },
}));
