import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { User, PermissionsEnum } from 'commonTypes/authTypes';
import { setupInterceptors } from 'api/config/interceptors';
import { checkConsents } from 'api/profiles/profilesApi';
import { hasRequiredProfileConsents } from 'components/Consents/helpers';

export type AuthContextValue = {
  user?: User;
  login: (user: User) => void;
  logout: () => void;
  activeProfile?: ActiveProfile;
  setActiveProfileId: (profileId: string | undefined) => void;
  isDoctor: boolean;
  isBasic: boolean;
};

export type AuthProviderProps = {
  children: ReactNode;
};

const defaultValue: AuthContextValue = {
  user: undefined,
  login: () => {},
  logout: () => {},
  activeProfile: undefined,
  setActiveProfileId: () => {},
  isDoctor: false,
  isBasic: false,
};

type ActiveProfile = {
  profileId: string;
  hasConsents: boolean;
};

const AuthContext = createContext(defaultValue);

const AuthProvider: FC<AuthProviderProps> = (props: AuthProviderProps) => {
  const [user, setUser] = useState<User>(undefined);
  const [activeProfile, setActiveProfile] = useState<ActiveProfile | undefined>(
    undefined
  );

  let isDoctor = false;
  let isBasic = false;
  if (
    user &&
    user.currentGroup.permissions.includes(PermissionsEnum.IS_DOCTOR)
  ) {
    isDoctor = true;
  }
  if (
    user &&
    user.currentGroup.permissions.includes(PermissionsEnum.IS_BASIC) &&
    !user.currentGroup.permissions.includes(PermissionsEnum.IS_DOCTOR)
  ) {
    isBasic = true;
  }

  const checkConsentsAndSetProfile = (profileId: string) => {
    return checkConsents(profileId).then((response) => {
      setActiveProfile({
        profileId,
        hasConsents: hasRequiredProfileConsents(response),
      });
    });
  };

  const setProfile = (profileId: string) => {
    setActiveProfile({
      profileId,
      hasConsents: true,
    });
  };

  const login = useCallback(
    (userData) => {
      setUser({ ...userData });
      if (localStorage.activeProfile) {
        if (isBasic) {
          checkConsentsAndSetProfile(localStorage.activeProfile);
        } else {
          setProfile(localStorage.activeProfile);
        }
      } else if (userData.profile) {
        if (isBasic) {
          checkConsentsAndSetProfile(userData.profile.profileId);
        } else {
          setProfile(userData.profile.profileId);
        }
        localStorage.setItem('activeProfile', userData.profile.profileId);
      }
    },
    [isBasic]
  );

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('activeProfile');
    setUser(undefined);
    setActiveProfile(undefined);
  }, []);

  const setActiveProfileId = useCallback(
    (profileId) => {
      if (isBasic) {
        checkConsentsAndSetProfile(profileId);
      } else {
        setProfile(profileId);
      }
      localStorage.setItem('activeProfile', profileId);
    },
    [isBasic]
  );

  useMemo(() => {
    setupInterceptors(logout);
  }, [logout]);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        activeProfile,
        setActiveProfileId,
        isDoctor,
        isBasic,
      }}
      {...props}
    />
  );
};

const useAuth = () => useContext(AuthContext);
export { AuthProvider, AuthContext, useAuth };
