import { ValidationErrors } from 'pages/examinations/examinationUpload/AddExaminationDialog';

export type JsnMetadata = {
  examinationId: string;
  sensorSerial: string;
  firmwareVersion: string;
  hardwareRevision: string;
};

export const validateJsn = (jsn: File): Promise<JsnMetadata> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const jsnContent = JSON.parse(event.target!.result as string) as Record<
          string,
          string
        >;
        const isValid = checkStructure(jsnContent);
        if (isValid) {
          resolve({
            examinationId: jsnContent.examination_id,
            sensorSerial: jsnContent.sensor_serial,
            firmwareVersion: jsnContent.firmware_version,
            hardwareRevision: jsnContent.hardware_revision,
          });
        } else {
          reject(new Error(ValidationErrors.JsnInvalid));
        }
      } catch (error) {
        reject(new Error(ValidationErrors.JsnInvalid));
      }
    };
    reader.readAsText(jsn);
  });
};

const checkStructure = (jsn: Record<string, string>) => {
  // firmware version >= 1.2.18
  const requiredKeys = [
    'sensor_serial',
    'examination_id',
    'examination_duration_wav',
    'examination_duration_acc',
    'examination_start_timestamp',
    'examination_stop_timestamp',
    'firmware_version',
    'hardware_revision',
    'classifier_1_version',
    'classifier_2_version',
    'classifier_3_version',
    'classifier_4_version',
    'samples_acc_saved',
    'samples_audio_saved',
    'samples_audio_missed',
  ];

  const valid = requiredKeys.every((key) => key in jsn);

  return valid;
};
