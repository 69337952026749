import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import clsx from 'clsx';
import {
  GenderEnum,
  Gender,
  profileActionType,
} from 'commonTypes/profileTypes';
import { DialogBase } from 'components/Dialog/DialogBase';
import { DialogProps, makeStyles } from '@material-ui/core';
import { ProfileForm, ProfileFormValues } from 'pages/profiles/ProfileForm';
import { ProfileConsentsForm } from 'components/Consents/ProfileConsentsForm';
import { GenderButton } from 'pages/profiles/GenderButton';
import { ReactComponent as Men } from 'icons/Men.svg';
import { ReactComponent as Woman } from 'icons/Woman.svg';
import { SubmitButton } from 'components/Button/SubmitButton';
import { CancelButton } from 'components/Button/CancelButton';
import { CircularLoader } from 'components/Progress/CircularLoader';
import { CheckboxWithLabel } from 'components/Input/CheckboxWithLabel';
import { useAuth } from 'context/providers/AuthProvider';

type ProfileDialogProps = Omit<DialogProps, 'onClose'> & {
  handleClose: () => void;
  formik: FormikContextType<ProfileFormValues>;
  title: string;
  isLoading: boolean;
  setPeselValidationCache: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  peselValidationCache: { [key: string]: boolean };
  type: profileActionType;
};

export const ProfileDialog = ({
  open,
  handleClose,
  formik,
  title,
  isLoading,
  setPeselValidationCache,
  peselValidationCache,
  type,
}: ProfileDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isDoctor, isBasic } = useAuth();

  const setGender = (gender: Gender) => {
    formik.setFieldValue('gender', gender);
  };

  const showNoPeselCheckbox = () => {
    if (isDoctor && type === profileActionType.ADD) {
      return true;
    }
    if (
      isDoctor &&
      type === profileActionType.EDIT &&
      !formik.initialValues.govIdentification
    ) {
      return true;
    }
    return false;
  };

  const disablePeselRelatedField = () => {
    if (
      (formik.values.dateOfBirth && type === profileActionType.EDIT) ||
      formik.values.govIdentification.length === 11
    ) {
      return true;
    }
    return false;
  };

  const handleNoPeselChange = (event: ChangeEvent<HTMLElement>) => {
    formik.handleChange(event);
    formik.setFieldValue('govIdentification', '', false);
    formik.setFieldTouched('govIdentification', false, true);
  };

  return (
    <DialogBase
      className={clsx({
        [classes.profileDialog]: true,
        [classes.basicDialog]: isBasic,
        [classes.doctorDialog]: isDoctor,
        [classes.doctorWithoutCheckbox]: isDoctor && !showNoPeselCheckbox(),
      })}
      open={open}
      onClose={handleClose}
      title={title}
      id="profile-dialog"
    >
      <div className={classes.genderContainer}>
        <GenderButton
          id="male-select"
          icon={<Men />}
          text={t('men')}
          selected={formik.values.gender === GenderEnum.male}
          onClick={() => setGender(GenderEnum.male)}
          disabled={disablePeselRelatedField()}
        />
        <GenderButton
          id="female-select"
          icon={<Woman />}
          text={t('woman')}
          selected={formik.values.gender === GenderEnum.female}
          onClick={() => setGender(GenderEnum.female)}
          disabled={disablePeselRelatedField()}
        />
      </div>
      <ProfileForm
        formik={formik}
        setPeselValidationCache={setPeselValidationCache}
        peselValidationCache={peselValidationCache}
        setGender={setGender}
        type={type}
        disablePeselRelatedField={disablePeselRelatedField}
      />
      {isBasic && <ProfileConsentsForm formik={formik} type={type} />}
      {showNoPeselCheckbox() && (
        <CheckboxWithLabel
          id="no-pesel-checkbox"
          name="noPesel"
          smallFont
          checkboxClass={classes.checkbox}
          label={t('profiles.dont-have-govid')}
          value={formik.values.noPesel}
          checked={formik.values.noPesel}
          onChange={(event) => handleNoPeselChange(event)}
        />
      )}
      <div
        className={clsx({
          [classes.actionButtons]: true,
          [classes.noCheckoboxActions]: isDoctor && !showNoPeselCheckbox(),
        })}
      >
        <CancelButton
          className={classes.cancelButton}
          id="profile-form-cancel"
          onClick={handleClose}
        >
          {t('cancel')}
        </CancelButton>
        <SubmitButton
          isLoading={isLoading}
          id="profile-form-submit"
          onClick={formik.handleSubmit}
        >
          {isLoading ? <CircularLoader /> : t('confirm')}
        </SubmitButton>
      </div>
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  profileDialog: {
    width: '756px',
  },
  basicDialog: {
    minHeight: '686px',
  },
  doctorDialog: {
    height: '508px',
  },
  doctorWithoutCheckbox: {
    height: '480px',
  },
  genderContainer: {
    display: 'flex',
    margin: theme.spacing(1, 0, 2, 0),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  noCheckoboxActions: {
    marginTop: theme.spacing(3),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },
}));
