import { useState, Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { IconButton } from 'components/Button/IconButton';
import { ReactComponent as Edit } from 'icons/Edit.svg';
import { Profile } from 'commonTypes/authTypes';
import { EditProfileDialog } from 'pages/profiles/editProfile/EditProfileDialog';
import { Toast } from 'components/Alert/Toast';

type EditButtonProps = {
  profileData: Profile;
  setProfileData: Dispatch<SetStateAction<Profile | undefined>>;
  className: string;
};

export const EditProfileButton = ({
  profileData,
  setProfileData,
  className,
}: EditButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editProfileDialogOpen, setEditProfileDialogOpen] = useState(false);
  const [editProfileResult, setEditProfileResult] = useState({
    resolved: false,
    status: '',
  });

  const handleEditProfileClick = () => {
    setEditProfileDialogOpen(true);
  };

  const handleClose = () => {
    setEditProfileDialogOpen(false);
  };

  return (
    <>
      <IconButton
        className={clsx(className, classes.editButton)}
        onClick={() => handleEditProfileClick()}
        id="edit-profile-button"
      >
        <Edit />
      </IconButton>
      <EditProfileDialog
        open={editProfileDialogOpen}
        onClose={handleClose}
        setEditProfileResult={setEditProfileResult}
        profileData={profileData}
        setProfileData={setProfileData}
      />
      <Toast
        open={editProfileResult.status === 'error'}
        setState={setEditProfileResult}
        severity="error"
      >
        {t('profiles.error.edit-profile')}
      </Toast>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  editButton: {
    width: '46px',
    height: '46px',
    marginTop: theme.spacing(1),

    '&:hover': {
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
}));
