import {
  LoginUserDTO,
  RegisterUserDTO,
  ActivateUserDTO,
  ResetPasswordDTO,
  EditUserDTO,
  ChangePasswordDTO,
} from 'api/auth/authDTOs';
import { loginErrors } from 'pages/auth/login/loginErrors';
import { authApi, mainApi } from 'api/config/interceptors';

export const loginWithEmail = (loginData: LoginUserDTO) => {
  return authApi
    .post('/user/clebre/login', loginData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 403) {
        throw new Error(loginErrors.userNotActive.id);
      } else {
        throw error;
      }
    });
};

export const loginWithGoogleCode = (code: string) => {
  return authApi
    .post('/user/login', null, {
      params: {
        code,
        createIfNotExists: true,
        uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const register = (registerData: RegisterUserDTO) => {
  return authApi
    .post('/user/register', registerData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const activateUser = ({ userId, code }: ActivateUserDTO) => {
  return mainApi.get(`/user/activate/${userId}/${code}`).then((response) => {
    return response;
  });
};

export const requestPasswordReset = (email: string) => {
  const encodedEmail = encodeURIComponent(email);
  return mainApi
    .get(`/user/resetPassword?email=${encodedEmail}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const resetPassword = (resetPasswordData: ResetPasswordDTO) => {
  return mainApi
    .post(`/user/resetPassword`, resetPasswordData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const editSelf = (editData: EditUserDTO) => {
  return mainApi
    .put(`/user/self`, editData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const changePassword = (changePasswordData: ChangePasswordDTO) => {
  return authApi
    .post(`/user/changePassword`, changePasswordData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};
