import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

type FixedSizeContainerProps = {
  children: ReactNode;
  height?: number;
  className?: string;
};

export const FixedSizeContainer = ({
  children,
  height,
  className,
}: FixedSizeContainerProps) => {
  const cssProps = {
    height,
  };
  const classes = useStyles(cssProps);

  return (
    <div className={clsx(className, classes.fixedContainer)}>{children}</div>
  );
};

FixedSizeContainer.defaultProps = {
  height: 400,
  className: undefined,
};

const useStyles = makeStyles((theme) => ({
  fixedContainer: {
    margin: theme.spacing(2, 0, 2, 0),
    height: (cssProps: Pick<FixedSizeContainerProps, 'height'>) =>
      cssProps.height,
    maxWidth: '1400px',
  },
}));
