import { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from 'recharts';
import { round, isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core';
import theme from 'theme';
import { useHistory } from 'react-router-dom';
import { CustomCursor } from 'charts/BarCharts/BarChartComponents/CustomCursor';
import { CustomLabel } from 'charts/BarCharts/BarChartComponents/CustomLabel';
import { CustomXAxisTick } from 'charts/BarCharts/BarChartComponents/CustomXAxisTick';
import { CustomResponsiveContainer } from 'charts/ChartComponents/CustomResponsiveContainer';
import { FixedSizeContainer } from 'charts/ChartComponents/FixedSizeContainer';
import { ExaminationSimple } from 'commonTypes/examinationTypes';
import { formatXAxisDate, fillEmptyData } from 'charts/BarCharts/utils';
import { formatYAxisTick } from 'charts/utils';
import { DividedAhiChartDataItem } from 'charts/chartTypes/chartTypes';

type DividedAhiChartProps = {
  data: ExaminationSimple[] | null;
};

export const DividedAhiBarChart = ({ data }: DividedAhiChartProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [chartData, setChartData] = useState<
    DividedAhiChartDataItem[] | undefined
  >(undefined);

  useEffect(() => {
    const createChartData = () => {
      if (!data) {
        return;
      }
      const dataObjects: DividedAhiChartDataItem[] = [];
      data.forEach((examination) => {
        const {
          supinePositionRatio,
          supineAhi,
          nonSupineAhi,
          examinationId,
          startDate,
        } = examination;
        if (
          supinePositionRatio === null ||
          supineAhi === null ||
          nonSupineAhi === null
        ) {
          return;
        }
        const { dayString, day, month } = formatXAxisDate(startDate);

        const barData = {
          examid: examinationId,
          name: `${dayString} ${day} ${month}`,
          supineAhi: round(supineAhi),
          nonSupineAhi: round(nonSupineAhi),
          supinePositionRatio: -round(supinePositionRatio),
        };
        dataObjects.push(barData);
      });

      fillEmptyData(data, dataObjects);

      return dataObjects;
    };
    if (data) {
      setChartData(createChartData());
    }
  }, [setChartData, data]);

  const handleExaminationClick = (examinationData: {
    examid: string | undefined;
  }) => {
    history.push(`/examination/${examinationData.examid}`);
  };

  return (
    <FixedSizeContainer>
      <CustomResponsiveContainer>
        <BarChart
          id="divided-ahi-chart"
          data={chartData}
          barSize={24}
          margin={{
            left: -25,
            right: 10,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3" />
          <XAxis
            dataKey="name"
            reversed
            height={60}
            stroke={theme.palette.text.secondary}
            interval={0}
            tick={
              !isEmpty(chartData)
                ? (props) => <CustomXAxisTick {...props} />
                : false
            }
          />
          <YAxis
            ticks={[-100, -75, -50, -25, 0, 25, 50, 75, 100]}
            tickFormatter={formatYAxisTick}
            stroke={theme.palette.text.secondary}
            domain={[-120, 120]}
            allowDataOverflow
          />
          <Tooltip
            contentStyle={{ display: 'none' }}
            cursor={
              <CustomCursor
                dataLoaded={!isEmpty(chartData)}
                onClick={handleExaminationClick}
              />
            }
          />
          <ReferenceLine y={0} />
          <Bar
            id="supine-ahi-bar"
            dataKey="supineAhi"
            fill={theme.palette.success.darkmode}
            transform="translate(0, -5)"
            radius={40}
            minPointSize={3}
            label={
              <CustomLabel
                dataKey="supineAhi"
                data={chartData}
                onClick={handleExaminationClick}
              />
            }
            onClick={handleExaminationClick}
            className={classes.dataBar}
          />
          <Bar
            id="non-supine-ahi-bar"
            dataKey="nonSupineAhi"
            fill={theme.palette.warning.darkmode}
            transform="translate(-0, -5)"
            radius={40}
            minPointSize={3}
            label={
              <CustomLabel
                dataKey="nonSupineAhi"
                data={chartData}
                onClick={handleExaminationClick}
              />
            }
            onClick={handleExaminationClick}
            className={classes.dataBar}
          />
          <Bar
            id="supine-position-bar"
            dataKey="supinePositionRatio"
            fill={theme.palette.secondary.dark}
            transform="translate(0, 5)"
            radius={40}
            minPointSize={3}
            label={
              <CustomLabel
                dataKey="supinePositionRatio"
                data={chartData}
                onClick={handleExaminationClick}
              />
            }
            onClick={handleExaminationClick}
            className={classes.dataBar}
          />
        </BarChart>
      </CustomResponsiveContainer>
    </FixedSizeContainer>
  );
};

const useStyles = makeStyles(() => ({
  dataBar: {
    cursor: 'pointer',
  },
}));
