import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'context/providers/AuthProvider';
import { DialogBase } from 'components/Dialog/DialogBase';
import { DialogContent } from 'pages/examinations/examinationUpload/DialogContent';
import { useFilters } from 'context/providers/FiltersProvider';

// eslint-disable-next-line
export enum ValidationErrors {
  FileNamesInvalid = 'file-names-invalid',
  JsnInvalid = 'jsn-invalid',
  ExamIdInvalid = 'exam-id-invalid',
  SensorInvalid = 'sensor-invalid',
  FirmwareInvalid = 'firmware-invalid',
  OtherError = 'other-error',
}

// eslint-disable-next-line
export enum UploadResult {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
  TIMEOUT_ERROR = 'TIMEOUT_ERROR',
}

type AddExaminationDialogProps = {
  open: boolean;
  onClose: () => void;
  resetTable?: () => void;
};

export const AddExaminationDialog = ({
  open,
  onClose,
  resetTable,
}: AddExaminationDialogProps) => {
  const { t } = useTranslation();
  const { activeProfile } = useAuth();
  const { handleFiltersApply } = useFilters();
  const classes = useStyles();
  const [uploadStatus, setUploadStatus] = useState<number>(0);
  const [uploadResult, setUploadResult] = useState<UploadResult | null>(null);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setUploadStatus(0);
      setUploadResult(null);
    }, 500);
  };

  const handleConfirm = () => {
    if (activeProfile && uploadResult === UploadResult.SUCCESS) {
      handleFiltersApply();
      if (resetTable) {
        resetTable();
      }
    }
    handleClose();
  };

  const uploadStarted = uploadStatus !== 0;
  const uploadResolved = uploadResult !== null;

  return (
    <DialogBase
      open={open}
      onClose={handleConfirm}
      title={t('examinations.adding')}
      className={classes.addExaminationDialog}
      id="add-examination-dialog"
      disabled={uploadStarted && !uploadResolved}
    >
      <DialogContent
        setUploadStatus={setUploadStatus}
        uploadStatus={uploadStatus}
        handleClose={handleClose}
        uploadResult={uploadResult}
        setUploadResult={setUploadResult}
        uploadResolved={uploadResolved}
        uploadStarted={uploadStarted}
        handleConfirm={handleConfirm}
      />
    </DialogBase>
  );
};

AddExaminationDialog.defaultProps = {
  resetTable: undefined,
};

const useStyles = makeStyles((theme) => ({
  addExaminationDialog: {
    maxWidth: '756px',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
}));
