import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Typography, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { MissingFilesInfo } from 'pages/examinations/examinationUpload/MissingFilesInfo';
import { ReactComponent as Warning } from 'icons/Warning.svg';
import {
  ValidationErrors,
  UploadResult,
} from 'pages/examinations/examinationUpload/AddExaminationDialog';
import { AcceptedFilesBatchExtensions } from 'pages/examinations/examinationUpload/processFiles';

type DisplayInfoAreaProps = {
  missingExtensions: AcceptedFilesBatchExtensions[];
  validationError: ValidationErrors | null;
  uploadResult: UploadResult | null;
  isProcessing: boolean;
};

type WarningMessageProps = {
  children: string;
};

const WarningMessage = ({ children }: WarningMessageProps) => {
  const classes = useStyles();

  return (
    <div className={classes.warningMessage}>
      <Warning />
      <Typography className={classes.message} variant="body2">
        {children}
      </Typography>
    </div>
  );
};

export const DisplayInfoArea = ({
  missingExtensions,
  validationError,
  uploadResult,
  isProcessing,
}: DisplayInfoAreaProps) => {
  const { t } = useTranslation();

  return (
    <>
      {validationError && (
        <WarningMessage>
          {t(`examinations.validation.${validationError}`)}
        </WarningMessage>
      )}
      {!isEmpty(missingExtensions) && (
        <MissingFilesInfo missingExtensions={missingExtensions} />
      )}
      {isProcessing && (
        <Typography variant="body2">{t('examinations.processing')}</Typography>
      )}
      {uploadResult === UploadResult.SUCCESS && (
        <Alert severity="success">{t('examinations.upload-success')}</Alert>
      )}
      {uploadResult === UploadResult.ERROR && (
        <Alert severity="error">{t('examinations.upload-error')}</Alert>
      )}
      {uploadResult === UploadResult.PROCESSING_ERROR && (
        <Alert severity="error">{t('examinations.processing-error')}</Alert>
      )}
      {uploadResult === UploadResult.TIMEOUT_ERROR && (
        <Alert severity="error">{t('examinations.timeout-error')}</Alert>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  warningMessage: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  message: {
    marginLeft: theme.spacing(2),
  },
}));
