import { Dispatch, SetStateAction } from 'react';
import { TextField, makeStyles } from '@material-ui/core';

type MessageInputProps = {
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  disabled: boolean;
  className: string;
};

export const MessageInput = ({
  message,
  setMessage,
  disabled,
  className,
}: MessageInputProps) => {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      inputProps={{ maxLength: 2000 }}
      disabled={disabled}
      value={message}
      classes={{ root: classes.input }}
      className={className}
      // eslint-disable-next-line
      InputProps={{
        id: 'message-input',
        classes: {
          input: classes.text,
        },
      }}
      FormHelperTextProps={{
        id: 'character-counter',
        className: classes.helperText,
      }}
      helperText={message ? `${message.length}/2000` : `0/2000`}
      multiline
      rows={10}
      variant="outlined"
      onChange={(event) => {
        setMessage(event.target.value);
      }}
      onBlur={() => setMessage(message.trim())}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  helperText: {
    textAlign: 'right',
  },
  text: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    color: theme.palette.grey[900],
  },
  input: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      },
    },
  },
}));
