import { useState, ChangeEvent } from 'react';
import { TextField, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DialogBase } from 'components/Dialog/DialogBase';
import { OtherFileUploadButton } from 'pages/examinations/examinationFileUpload/OtherFileUploadButton';
import { CancelButton } from 'components/Button/CancelButton';
import { SubmitButton } from 'components/Button/SubmitButton';
import { otherFileType } from 'utils/constants';
import { OptionalFile } from 'commonTypes/examinationTypes';

type OtherFileUploadDialogProps = {
  open: boolean;
  closeDialog: () => void;
  handleUpload: (
    selectedFile: File,
    optionalFileType: OptionalFile,
    comment: string | null
  ) => void;
};

export const OtherFileUploadDialog = ({
  open,
  closeDialog,
  handleUpload,
}: OtherFileUploadDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [comment, setComment] = useState<string | null>(null);

  const handleSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const uploadFile = () => {
    if (selectedFile) {
      handleClose();
      handleUpload(selectedFile, otherFileType, comment);
    }
  };

  const handleClose = () => {
    closeDialog();
    setTimeout(() => {
      setSelectedFile(undefined);
      setComment(null);
    }, 100);
  };

  return (
    <DialogBase
      title={t('examinations.file-add')}
      className={classes.container}
      open={open}
      onClose={handleClose}
      id="other-file-upload-dialog"
    >
      <div className={classes.fileInfo}>
        <OtherFileUploadButton
          handleSelect={handleSelect}
          className={classes.uploadButton}
        />
        {selectedFile && (
          <Typography id="selected-file-name" className={classes.text}>
            {selectedFile.name}
          </Typography>
        )}
      </div>
      <TextField
        fullWidth
        label={t('examinations.file-comment')}
        inputProps={{ maxLength: 255 }}
        classes={{ root: classes.input }}
        // eslint-disable-next-line
        InputProps={{
          id: 'comment-input',
          classes: {
            input: classes.text,
          },
        }}
        FormHelperTextProps={{
          id: 'character-counter',
          className: classes.helperText,
        }}
        helperText={comment ? `${comment.length}/255` : `0/255`}
        multiline
        rows={8}
        variant="outlined"
        onChange={(event) => {
          setComment(event.target.value);
        }}
      />
      <div className={classes.buttons}>
        <CancelButton
          id="other-file-cancel-button"
          onClick={handleClose}
          className={classes.cancelButton}
        >
          {t('cancel')}
        </CancelButton>
        <SubmitButton
          id="other-file-submit-button"
          disabled={!selectedFile}
          onClick={uploadFile}
        >
          {t('confirm')}
        </SubmitButton>
      </div>
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '500px',
    minWidth: '350px',
  },
  fileInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    minWidth: '126px',
  },
  helperText: {
    textAlign: 'right',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  text: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    color: theme.palette.grey[900],
  },
  input: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      },
    },
  },
}));
