import * as Yup from 'yup';
import { consentValidation } from 'validation/validationFunctions';

export const userConsentsValidationSchema = (isDoctor: boolean) => {
  const basicConsentsValidation = () => {
    return Yup.object().shape({
      termsConditionsAgreement: consentValidation(),
      dataProcessingAgreement: consentValidation(),
    });
  };

  const doctorConsentsValidation = () => {
    return Yup.object().shape({
      termsConditionsAgreement: consentValidation(),
      dataProcessingAgreement: consentValidation(),
      entrustingAgreement: consentValidation(),
    });
  };

  const validationSchema = isDoctor
    ? doctorConsentsValidation
    : basicConsentsValidation;

  return validationSchema;
};

export const profileConsentsValidationSchema = () => {
  return Yup.object().shape({
    termsConditionsAgreement: consentValidation(),
    dataProcessingAgreement: consentValidation(),
  });
};
