import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DocumentLink } from 'pages/auth/register/ConsentWithDocuments';
import { ConsentNamesUser } from 'components/Consents/ConsentNames';

type ConsentsInfoProps = {
  edit: boolean | undefined;
};

export const ConsentsInfo = ({ edit }: ConsentsInfoProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.requiredInfo} variant="caption">
        {t('consents.required-consent')}
      </Typography>
      {edit && (
        <Typography variant="caption" className={classes.mailto}>
          {t('consents.withdraw-required-consent')}
          <a href="mailto:contact@clebre.com" className={classes.link}>
            contact@clebre.com
          </a>
        </Typography>
      )}
      <Typography variant="caption">
        {t('consents.administrator-info')}
        <DocumentLink name={ConsentNamesUser.GDPRAccount} />
      </Typography>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  requiredInfo: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  link: {
    fontSize: '12px',
    color: theme.palette.secondary.dark,
  },
  mailto: {
    marginBottom: theme.spacing(1),
  },
}));
