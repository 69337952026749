import { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { Profile } from 'commonTypes/authTypes';
import { DialogProps } from '@material-ui/core';
import { ProfileFormValues } from 'pages/profiles/ProfileForm';
import { profileValidationSchema } from 'pages/profiles/profileValidationSchema';
import { useAuth } from 'context/providers/AuthProvider';
import { editProfile, getProfiles } from 'api/profiles/profilesApi';
import { updateUserFromToken } from 'utils/decodeJWT';
import { ProfileDialog } from 'pages/profiles/ProfileDialog';
import { doNotHavePesel } from 'utils/constants';
import { useProfiles } from 'context/providers/ProfilesProvider';
import { GenderEnum, profileActionType } from 'commonTypes/profileTypes';
import { generateInitialProfileConsents } from 'components/Consents/helpers';
import { processProfileValues } from 'pages/profiles/helpers';

type EditProfileDialogProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  setEditProfileResult: Dispatch<
    SetStateAction<{ resolved: boolean; status: string }>
  >;
  profileData: Profile;
  setProfileData: Dispatch<SetStateAction<Profile | undefined>>;
};

export const EditProfileDialog = ({
  open,
  onClose,
  setEditProfileResult,
  profileData,
  setProfileData,
}: EditProfileDialogProps) => {
  const { t } = useTranslation();
  const { login, isDoctor, isBasic } = useAuth();
  const { setProfiles } = useProfiles();
  const [peselValidationCache, setPeselValidationCache] = useState({});

  const editProfileMutation = useMutation(editProfile, {
    onError: () => {
      setEditProfileResult({ resolved: true, status: 'error' });
    },
    onSuccess: (data) => {
      updateUserFromToken(login);
      setProfileData(data);
      getProfiles().then((profiles) => setProfiles(profiles.content));
      setEditProfileResult({ resolved: true, status: 'success' });
    },
    onSettled: () => {
      handleClose();
    },
  });

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      formik.resetForm();
    }, 500);
  };

  const showPesel = () => {
    if (
      profileData.govIdentification &&
      profileData.govIdentification !== doNotHavePesel
    ) {
      return profileData.govIdentification;
    }
    return '';
  };

  const initialConsents = generateInitialProfileConsents(
    profileData.profileConsents
  );

  const doctorInitialValues = {
    firstName: profileData.firstName || '',
    lastName: profileData.lastName || '',
    govIdentification: showPesel(),
    dateOfBirth: profileData.dateOfBirth || null,
    height: profileData.height || '',
    weight: profileData.weight || '',
    phoneNumber: profileData.phoneNumber || '',
    gender: profileData.gender || GenderEnum.male,
    noPesel: profileData.govIdentification === doNotHavePesel,
  };

  const basicInitialValues = {
    ...doctorInitialValues,
    termsConditionsAgreement: initialConsents.termsConditionsAgreement,
    dataProcessingAgreement: initialConsents.dataProcessingAgreement,
  };

  const formik = useFormik<ProfileFormValues>({
    initialValues: isDoctor ? doctorInitialValues : basicInitialValues,
    onSubmit: (values) => {
      const apiValues = processProfileValues(
        values,
        profileActionType.EDIT,
        isBasic,
        isDoctor
      );
      setEditProfileResult({ resolved: false, status: '' });
      editProfileMutation.mutate({
        profileId: profileData.profileId,
        profileData: apiValues,
      });
    },
    validationSchema: profileValidationSchema(
      isDoctor,
      peselValidationCache,
      profileActionType.EDIT
    ),
    enableReinitialize: true,
  });

  return (
    <ProfileDialog
      open={open}
      type={profileActionType.EDIT}
      id="edit-profile-dialog"
      title={t('profiles.edit-profile')}
      formik={formik}
      handleClose={handleClose}
      isLoading={editProfileMutation.isLoading}
      setPeselValidationCache={setPeselValidationCache}
      peselValidationCache={peselValidationCache}
    />
  );
};
