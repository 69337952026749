import { SetStateAction, Dispatch, ChangeEvent, MouseEvent } from 'react';
import { makeStyles, TablePagination } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type TablePaginationProps = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  totalRecords: number;
};

export const Pagination = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalRecords,
}: TablePaginationProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      classes={{
        root: classes.root,
        toolbar: classes.toolbar,
        caption: classes.caption,
        selectRoot: classes.selectRoot,
        actions: classes.actions,
        menuItem: classes.menuItem,
      }}
      SelectProps={{
        id: 'rows-per-page',
        classes: { select: classes.select },
        MenuProps: { classes: { paper: classes.menu, list: classes.list } },
      }}
      backIconButtonProps={{
        id: 'previous-page',
        classes: { root: classes.iconButton },
      }}
      nextIconButtonProps={{
        id: 'next-page',
        classes: { root: classes.iconButton },
      }}
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      labelRowsPerPage=""
      labelDisplayedRows={({ from, to, count }) => {
        return ` ${from} - ${to} ${t('of')} ${count}`;
      }}
      page={page}
      rowsPerPage={rowsPerPage}
      count={totalRecords}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleRowsPerPage}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    fontSize: '14px',
    fontWeight: 400,
  },
  caption: {
    fontSize: '12px',
    fontWeight: 500,
  },
  selectRoot: {
    boxShadow: theme.shadows[1],
    borderRadius: '4px',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  actions: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  select: {
    paddingLeft: theme.spacing(1),
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '4px',
    },
  },
  menu: {
    borderRadius: '4px',
  },
  list: {
    padding: 0,
  },
  menuItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  iconButton: {
    padding: theme.spacing(1),
  },
}));
