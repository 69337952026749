import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { makeStyles, Typography } from '@material-ui/core';
import { findLeasedSensors } from 'utils/helpers';
import { useAuth } from 'context/providers/AuthProvider';
import { Profile } from 'commonTypes/authTypes';

type DoctorProfileDataProps = {
  profileData: Profile;
};

export const DoctorProfileData = ({ profileData }: DoctorProfileDataProps) => {
  const { t } = useTranslation();
  const { isDoctor } = useAuth();
  const classes = useStyles();

  const leasedSensors = findLeasedSensors(profileData.sensorLeases);

  return (
    <>
      {isDoctor && profileData && (
        <>
          {profileData.phoneNumber && (
            <div id="active-profile-phone" className={classes.dataBox}>
              <Typography className={classes.label}>
                {t('profiles.phone')}
              </Typography>
              <Typography variant="body2">{`${profileData.phoneNumber}`}</Typography>
            </div>
          )}
          {!isEmpty(leasedSensors) && (
            <div id="active-profile-sensor" className={classes.dataBox}>
              <Typography className={classes.label}>{t('sensor')}</Typography>
              {leasedSensors.map((sensor) => {
                return (
                  <Typography
                    variant="body2"
                    key={sensor.leasedAt}
                  >{`${sensor.serialNumber}`}</Typography>
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  dataBox: {
    margin: theme.spacing(0, 3, 0, 3),
  },
}));
