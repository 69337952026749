import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type MenuItemProps = {
  name: string;
  path: string;
  icon: ReactNode;
  onClick: () => void;
  selected: boolean;
  expanded: boolean | undefined;
  transitionFinished: boolean | undefined;
  component?: any;
  target?: string;
};

export const MenuItem = ({
  name,
  path,
  icon,
  onClick,
  selected,
  expanded,
  transitionFinished,
  component,
  target,
}: MenuItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t(`navbar.${name}`)!}
      classes={{
        tooltipPlacementBottom: clsx(classes.tooltip, {
          [classes.showTooltip]: !expanded && transitionFinished,
        }),
      }}
    >
      <ListItem
        className={clsx(classes.listItem, { [classes.expandedItem]: expanded })}
        component={component}
        target={target}
        href={path}
        to={path}
        key={name}
        onClick={onClick}
        selected={selected}
      >
        <ListItemIcon
          className={clsx(classes.icon, { [classes.bigIcon]: !expanded })}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          className={!expanded ? classes.hide : undefined}
          primary={t(`navbar.${name}`)}
        />
      </ListItem>
    </Tooltip>
  );
};

MenuItem.defaultProps = {
  component: RouterLink,
  target: undefined,
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    margin: theme.spacing(3, 0, 3, 0),
    height: '60px',
    color: theme.palette.grey[700],
    borderRadius: theme.shape.borderRadius,
    minWidth: '72px',

    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.background,
    },
    '@media (max-height:780px)': {
      margin: theme.spacing(1, 0, 1, 0),
    },
  },
  expandedItem: {
    minWidth: '174px',
  },
  bigIcon: {
    padding: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  tooltip: {
    margin: theme.spacing(1),
    visibility: 'hidden',
  },
  showTooltip: {
    visibility: 'visible',
  },
  icon: {
    minWidth: '45px',
  },
}));
