import { FocusEventHandler } from 'react';
import clsx from 'clsx';
import i18n from 'translations/i18n';
import { makeStyles, TextFieldProps, InputAdornment } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ReactComponent as Calendar } from 'icons/Calendar.svg';

type DateInputProps = TextFieldProps & {
  setFieldValue: (
    field: string,
    value: string | MaterialUiPickersDate,
    shouldValidate?: boolean
  ) => void;
  className?: string;
  id: string;
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value: string | MaterialUiPickersDate;
};

export const DateInput = ({
  setFieldValue,
  className,
  id,
  value,
  label,
  required,
  error,
  helperText,
  name,
  disabled,
  handleBlur,
}: DateInputProps) => {
  const classes = useStyles();

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setFieldValue('dateOfBirth', date);
  };

  const locale = i18n.language;
  const formats = {
    pl: 'DD/MM/YYYY',
    en: 'DD/MM/YYYY',
  };

  return (
    <KeyboardDatePicker
      autoOk
      disableFuture
      minDate="1900-01-01"
      variant="inline"
      inputVariant="standard"
      format={locale === 'pl' ? formats.pl : formats.en}
      placeholder={
        locale === 'pl'
          ? formats.pl.toLocaleLowerCase()
          : formats.en.toLowerCase()
      }
      value={value}
      onChange={(date) => handleDateChange(date)}
      KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
      className={clsx(className, classes.textField)}
      InputProps={{
        classes: { root: classes.dateInput },
        id,
        startAdornment: (
          <InputAdornment position="start">
            <Calendar className={classes.icon} />
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: 'focused',
        },
      }}
      required={required}
      name={name}
      label={label}
      disabled={disabled}
      onBlur={handleBlur}
      error={error}
      helperText={helperText}
    />
  );
};

DateInput.defaultProps = {
  className: undefined,
};

const useStyles = makeStyles((theme) => ({
  dateInput: {
    fontWeight: 400,
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    '&.focused:not(.Mui-error)': {
      color: theme.palette.text.primary,
    },
  },
  textField: {
    height: '48px',
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '& .MuiInput-underline:not(.Mui-disabled):hover::before': {
      borderBottomColor: theme.palette.primary.main,
    },
  },
  icon: {
    padding: 0,
    paddingBottom: '2px',
  },
}));
