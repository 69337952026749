import { makeStyles } from '@material-ui/core';
import {
  TooltipContent,
  TooltipContentData,
} from 'pages/examinations/examinationsList/TooltipContent';

type SSSIChartTooltipProps = {
  data: TooltipContentData[];
};

export const SSSIChartTooltip = ({ data }: SSSIChartTooltipProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {data.map((dataItem) => {
        return <TooltipContent key={dataItem.name} data={dataItem} multiple />;
      })}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
}));
