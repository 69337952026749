import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useAuth } from 'context/providers/AuthProvider';
import {
  AcceptedFiles,
  processedFile,
  AcceptedFilesBatchExtensions,
} from 'pages/examinations/examinationUpload/processFiles';
import { FileDropzone } from 'pages/examinations/examinationUpload/FileDropzone';
import { DisplayFilesArea } from 'pages/examinations/examinationUpload/DisplayFilesArea';
import { DisplayInfoArea } from 'pages/examinations/examinationUpload/DisplayInfoArea';
import { sendExaminationPackage } from 'pages/examinations/examinationUpload/sendExaminationPackage';
import { ProgressBarWithLabel } from 'components/Progress/ProgressBar';
import { DialogActions } from 'pages/examinations/examinationUpload/DialogActions';
import { SensorLendingWarning } from 'pages/examinations/examinationUpload/SensorLendingWarning';
import { SensorBorrowerData } from 'commonTypes/sensorTypes';
import {
  ValidationErrors,
  UploadResult,
} from 'pages/examinations/examinationUpload/AddExaminationDialog';

type DialogContentProps = {
  handleClose: () => void;
  handleConfirm: () => void;
  uploadStatus: number;
  setUploadStatus: Dispatch<SetStateAction<number>>;
  uploadResult: UploadResult | null;
  setUploadResult: Dispatch<SetStateAction<UploadResult | null>>;
  uploadResolved: boolean;
  uploadStarted: boolean;
};

export const DialogContent = ({
  handleClose,
  setUploadStatus,
  uploadStatus,
  uploadResult,
  setUploadResult,
  uploadResolved,
  uploadStarted,
  handleConfirm,
}: DialogContentProps) => {
  const { t } = useTranslation();
  const { activeProfile } = useAuth();

  const [filesState, setFilesState] = useState<AcceptedFiles>({
    JSN: { file: undefined, name: undefined, extension: undefined },
    ADR: { file: undefined, name: undefined, extension: undefined },
    PA2: { file: undefined, name: undefined, extension: undefined },
    DAC: { file: undefined, name: undefined, extension: undefined },
    E8H: { file: undefined, name: undefined, extension: undefined },
    WAV: { file: undefined, name: undefined, extension: undefined },
  });
  const [isValidating, setIsValidating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [examinationId, setExaminationId] = useState<string>('');
  const [uploadedFiles, setUploadedFiles] = useState<processedFile[]>([]);
  const [missingExtensions, setMissingExtensions] = useState<
    AcceptedFilesBatchExtensions[]
  >([]);
  const [
    validationError,
    setValidationError,
  ] = useState<ValidationErrors | null>(null);
  const [isValid, setIsValid] = useState(false);
  const [
    sensorBorrowerData,
    setSensorBorrowerData,
  ] = useState<SensorBorrowerData | null>(null);

  const firstMount = useRef(true);
  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      return;
    }
    setIsValid(!validationError && isEmpty(missingExtensions));
  }, [validationError, missingExtensions]);

  const handleSubmit = () => {
    if (isValid && activeProfile?.profileId) {
      // show progress bar immediately after click
      setUploadStatus(1);
      sendExaminationPackage(
        filesState,
        examinationId,
        activeProfile.profileId,
        setUploadStatus,
        setIsProcessing
      )
        .then(() => {
          setUploadResult(UploadResult.SUCCESS);
        })
        .catch((error) => {
          if (error.message === UploadResult.PROCESSING_ERROR) {
            setUploadResult(UploadResult.PROCESSING_ERROR);
          } else if (error.message === UploadResult.TIMEOUT_ERROR) {
            setUploadResult(UploadResult.TIMEOUT_ERROR);
          } else {
            setUploadResult(UploadResult.ERROR);
          }
        });
    }
  };

  return (
    <>
      <Typography variant="body2">{t('examinations.adding-info')}</Typography>
      <FileDropzone
        filesState={filesState}
        setFilesState={setFilesState}
        setUploadedFiles={setUploadedFiles}
        setMissingExtensions={setMissingExtensions}
        setValidationError={setValidationError}
        setIsValidating={setIsValidating}
        setExaminationId={setExaminationId}
        setSensorBorrowerData={setSensorBorrowerData}
        disabled={uploadStarted}
      />
      <div>
        <DisplayFilesArea files={uploadedFiles} />
        <DisplayInfoArea
          missingExtensions={missingExtensions}
          validationError={validationError}
          uploadResult={uploadResult}
          isProcessing={isProcessing}
        />
      </div>
      {uploadStarted && !isProcessing && !uploadResolved && (
        <div>
          <Typography variant="body2">
            {t('examinations.upload-in-progress')}
          </Typography>
          <ProgressBarWithLabel
            id="upload-examination-progress"
            progress={uploadStatus}
          />
        </div>
      )}
      <DialogActions
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        uploadResolved={uploadResolved}
        uploadStarted={uploadStarted}
        isLoading={isValidating}
        isValid={isValid}
        handleConfirm={handleConfirm}
      />
      {sensorBorrowerData && (
        <SensorLendingWarning
          sensorBorrowerData={sensorBorrowerData}
          setSensorBorrowerData={setSensorBorrowerData}
          closeUploadDialog={handleClose}
        />
      )}
    </>
  );
};
