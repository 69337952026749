import axios from 'axios';
import { mainApi } from 'api/config/interceptors';
import { ConsentsArray } from 'api/consent/consentDTOs';

export const getConsentByName = (consentName: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_BACKEND_API_URL}/public/consent/doc/${consentName}`
    )
    .then((response) => {
      return response.data;
    });
};

export const getConsentList = () => {
  return mainApi.get(`/public/consent/user/list`).then((response) => {
    return response.data;
  });
};

export const setUserConsents = (consents: ConsentsArray) => {
  return mainApi.post(`/consent/user`, consents).then((response) => {
    return response.data;
  });
};

export const setProfileConsents = (
  consents: ConsentsArray,
  profileId: string
) => {
  return mainApi
    .post(`/consent/profile/${profileId}`, consents)
    .then((response) => {
      return response.data;
    });
};
