import { MouseEvent } from 'react';
import { makeStyles, ButtonBaseProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ButtonBaseWithIcon } from 'components/Button/ButtonBaseWithIcon';
import { ReactComponent as Search } from 'icons/Search.svg';
import { ReactComponent as SearchDisabled } from 'icons/SearchDisabled.svg';
import { useFilters } from 'context/providers/FiltersProvider';

type ApplyFiltersButtonProps = ButtonBaseProps & {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const ApplyFiltersButton = ({
  className,
  onClick,
}: ApplyFiltersButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { validationError } = useFilters();

  return (
    <ButtonBaseWithIcon
      disabled={validationError}
      className={className}
      color="primary"
      variant="contained"
      labelClass={classes.buttonLabel}
      onClick={onClick}
      startIcon={validationError ? <SearchDisabled /> : <Search />}
      id="apply-filters-button"
    >
      {t('search')}
    </ButtonBaseWithIcon>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonLabel: {
    color: theme.palette.common.white,
  },
}));
