import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  MouseEvent,
  useEffect,
} from 'react';
import axios, { CancelTokenSource } from 'axios';
import { ExaminationSimple } from 'commonTypes/examinationTypes';
import {
  FilterType,
  getExaminationsList,
} from 'api/examinations/examinationsApi';
import { useAuth } from 'context/providers/AuthProvider';
import { isEmpty } from 'lodash';
import { orderEnum } from 'commonTypes/otherTypes';
import {
  stableSort,
  getComparator,
} from 'pages/examinations/examinationsList/sortHelpers';

export type ExaminationsList = {
  examinationsList: ExaminationSimple[] | null;
  filtered: boolean;
};

// eslint-disable-next-line
export enum SortLabels {
  START_DATE = 'startDate',
  AHI = 'ahi',
  SUPINE_AHI = 'supineAhi',
  SNORING_INDEX = 'snoringIndex',
  SUPINE_SNORING_INDEX = 'supineSnoringIndex',
}

export type ExaminationsContextValue = {
  examinations: ExaminationsList;
  setExaminations: Dispatch<SetStateAction<ExaminationsList>>;
  examinationsLoading: boolean;
  getExaminations: (
    source?: CancelTokenSource,
    filters?: FilterType[],
    order?: orderEnum,
    sortOrder?: SortLabels
  ) => void;
  order: orderEnum;
  orderBy: SortLabels;
  handleSortRequest: (event: MouseEvent<unknown>, property: SortLabels) => void;
};

export type ExaminationsProviderProps = {
  children: ReactNode;
};

const defaultValue: ExaminationsContextValue = {
  examinations: { examinationsList: null, filtered: false },
  setExaminations: () => {},
  examinationsLoading: false,
  getExaminations: () => {},
  order: orderEnum.DESC,
  orderBy: SortLabels.START_DATE,
  handleSortRequest: () => {},
};

const ExaminationsContext = createContext(defaultValue);

const ExaminationsProvider: FC<ExaminationsProviderProps> = (
  props: ExaminationsProviderProps
) => {
  const { activeProfile, user } = useAuth();
  const [examinations, setExaminations] = useState<ExaminationsList>({
    examinationsList: null,
    filtered: false,
  });
  const [order, setOrder] = useState(orderEnum.DESC);
  const [orderBy, setOrderBy] = useState(SortLabels.START_DATE);
  const [loading, setLoading] = useState<boolean>(false);

  const sortExaminations = useCallback(
    (data: ExaminationSimple[], sortOrder: orderEnum, sortBy: SortLabels) => {
      if (!sortOrder || !sortBy) {
        return data;
      }
      return stableSort(data, getComparator(sortOrder, sortBy));
    },
    []
  );

  const getExaminations = useCallback(
    (source = null, filters = [], sortOrder = null, sortBy = null) => {
      if (activeProfile?.profileId) {
        setLoading(true);
        const filtered = !isEmpty(filters);
        return getExaminationsList(activeProfile.profileId, filters, source)
          .then((data) => {
            const sortedExaminations = sortExaminations(
              data,
              sortOrder,
              sortBy
            );
            setExaminations({ examinationsList: sortedExaminations, filtered });
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    },
    [
      activeProfile?.profileId,
      activeProfile?.hasConsents,
      user?.requiredConsents,
      sortExaminations,
    ]
  );

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    getExaminations(source);

    return () => {
      source.cancel();
    };
  }, [getExaminations]);

  useEffect(() => {
    setOrder(orderEnum.DESC);
    setOrderBy(SortLabels.START_DATE);
  }, [activeProfile?.profileId]);

  const handleSortRequest = (
    event: MouseEvent<unknown>,
    property: SortLabels
  ) => {
    const isAsc = orderBy === property && order === orderEnum.ASC;
    setOrder(isAsc ? orderEnum.DESC : orderEnum.ASC);
    setOrderBy(property);
    const sortedExaminations = stableSort(
      examinations.examinationsList,
      getComparator(isAsc ? orderEnum.DESC : orderEnum.ASC, property)
    );
    setExaminations({ ...examinations, examinationsList: sortedExaminations });
  };

  return (
    <ExaminationsContext.Provider
      value={{
        examinations,
        setExaminations,
        examinationsLoading: loading,
        getExaminations,
        order,
        orderBy,
        handleSortRequest,
      }}
      {...props}
    />
  );
};

const useExaminations = () => useContext(ExaminationsContext);
export { ExaminationsProvider, ExaminationsContext, useExaminations };
