import * as Yup from 'yup';
import {
  firstNameValidation,
  lastNameValidation,
} from 'validation/validationFunctions';

export const userValidationSchema = () => {
  return Yup.object().shape({
    firstName: firstNameValidation(),
    lastName: lastNameValidation(),
  });
};
