import { makeStyles } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useExaminations } from 'context/providers/ExaminationsProvider';
import { NextButton } from 'components/Button/NextButton';
import { PreviousButton } from 'components/Button/PreviousButton';
import { NavDirections } from 'commonTypes/examinationTypes';

export const NavigationButtons = () => {
  const { examinations } = useExaminations();
  const { examinationId } = useParams<{ examinationId: string }>();
  const classes = useStyles();
  const history = useHistory();

  const { examinationsList } = examinations;

  const findCurrentExaminationIndex = () => {
    if (!examinationsList) {
      return;
    }
    const currentExamination = examinationsList.find((examination) => {
      return examination.examinationId === examinationId;
    });

    if (currentExamination) {
      return examinationsList.indexOf(currentExamination);
    }
  };

  const checkIfDisabled = (direction: NavDirections) => {
    if (!examinationsList) {
      return;
    }
    let disabled = false;
    let endIndex;

    if (direction === NavDirections.NEXT) {
      endIndex = 0;
    } else {
      endIndex = examinationsList.length - 1;
    }

    if (examinationId === examinationsList[endIndex].examinationId) {
      disabled = true;
    }
    return disabled;
  };

  const navigate = (direction: NavDirections) => {
    const currentExamIndex = findCurrentExaminationIndex();

    if (currentExamIndex === undefined || !examinationsList) {
      return;
    }
    let destinationIndex;

    if (direction === NavDirections.NEXT) {
      destinationIndex = currentExamIndex - 1;
    } else {
      destinationIndex = currentExamIndex + 1;
    }
    history.push(
      `/examination/${examinationsList[destinationIndex].examinationId}`
    );
  };

  return (
    <>
      <PreviousButton
        onClick={() => navigate(NavDirections.PREV)}
        className={classes.prevButton}
        id="previous-examination"
        disabled={checkIfDisabled(NavDirections.PREV)}
      />
      <NextButton
        onClick={() => navigate(NavDirections.NEXT)}
        className={classes.nextButton}
        id="next-examination"
        disabled={checkIfDisabled(NavDirections.NEXT)}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  nextButton: {
    marginRight: theme.spacing(2),
  },
  prevButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));
