import { TextField, makeStyles } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';

export const TextFieldBase = ({ helperText, id, ...props }: TextFieldProps) => {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      id={id}
      classes={{
        root: classes.textField,
      }}
      inputProps={{ style: { fontWeight: 400 } }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: 'focused',
        },
      }}
      helperText={helperText}
      {...props}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.default,
    height: '48px',
    minHeight: '48px',

    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '& .MuiInput-underline:not(.Mui-disabled):hover::before': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px',
    },
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    '&.focused:not(.Mui-error)': {
      color: theme.palette.text.secondary,
    },
  },
}));
