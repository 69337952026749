import { Rectangle, RectangleProps } from 'recharts';
import { makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';

type CustomCursorProps = Omit<RectangleProps, 'onClick'> & {
  dataLoaded: boolean;
  onClick: (examinationData: { examid: string | undefined }) => void;
  payload?: any;
};

export const CustomCursor = (props: CustomCursorProps) => {
  const classes = useStyles();
  const { x, y, width, height, payload, dataLoaded, onClick } = props;

  if (!dataLoaded || !payload || isEmpty(payload)) {
    return null;
  }

  let emptyData = false;
  payload.forEach((entry: any) => {
    if (entry.value === undefined) {
      emptyData = true;
    }
  });

  if (emptyData) {
    return null;
  }

  return (
    <Rectangle
      className={classes.selectableArea}
      x={x}
      y={y}
      width={width}
      height={height}
      onClick={() => onClick(payload[0].payload)}
    />
  );
};

CustomCursor.defaultProps = {
  payload: undefined,
};

const useStyles = makeStyles((theme) => ({
  selectableArea: {
    fill: theme.palette.primary.background,
    cursor: 'pointer',
  },
}));
