import { AcceptedOptionalFileTypes } from 'utils/constants';

export type ExaminationSimple = {
  examinationId: string;
  examinationNaturalId: string;
  startDate: string;
  endDate: string;
  totalSleepTime: number | null;
  sleepScore: number | null;
  ahi: number | null;
  snoringIndex: number | null;
  timeToSleepFromExaminationBeginning: number | null;
  supinePositionRatio: number | null;
  supineSnorigIndex: number | null;
  supineAhi: number | null;
  isExaminationOwner: boolean;
  nonSupineAhi: number | null;
  sleepEfficiency: number | null;
  totalRegistrationTime: number;
  examinationStatus: {
    examinationStatus: ExamStatuses | undefined;
  };
};

export type SelectedExamination = {
  id: string;
  isOwner: boolean;
};

export type SleepScore = {
  ahi?: number | null;
  apneaCount?: number | null;
  breathScore?: number | null;
  hypopneaCount?: number | null;
  meanRespiratoryRate?: number | null;
  nonSupineAhi?: number | null;
  nonSupinePositionDuration?: number | null;
  nonSupinePositionRatio?: number | null;
  nonSupineSnoringIndex?: number | null;
  normalBreathEpisodesCount?: number | null;
  respiratoryRateVariability?: number | null;
  sleepEfficiency?: number | null;
  sleepScore?: number | null;
  snoreEpisodesCount?: number | null;
  snoringIndex?: number | null;
  supineAhi?: number | null;
  supinePositionDuration?: number | null;
  supinePositionRatio?: number | null;
  supineSnoringIndex?: number | null;
  totalRegistrationTime?: number | null;
  totalSleepTime?: number | null;
  totalWakeTime?: number | null;
  uprightPositionRatio?: number | null;
};

export type Examination = {
  addedAt: string;
  classifierVersion: string;
  endDateTime: string;
  examinationFiles: ExaminationFile[];
  examinationId: string;
  examinationNaturalId: string;
  examinationStatus: {
    examinationStatus: string;
    description: string;
    createdAt: string;
  };
  firmwareVersion: string;
  recordId: string;
  sensorSerialNumber: string;
  sleepScore: SleepScore;
  startDateTime: string;
};

export type ExaminationFile = {
  addedAt: string;
  addedBy: string;
  crc: number;
  fileName: string;
  fileTypes: string;
  id: string;
  uploaded: boolean;
  description?: string;
  originalName?: string;
};

export type OptionalFile = {
  fileType: AcceptedOptionalFileTypes;
  description: string;
  acceptExtension: string;
};

// eslint-disable-next-line
export enum NavDirections {
  NEXT = 'NEXT',
  PREV = 'PREV',
}

// eslint-disable-next-line
export enum ExamStatuses {
  DONE = 'DONE',
  FAILED = 'FAILED',
  INCOMPLETE = 'INCOMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
}

export type ExaminationData = {
  sleepScore: SleepScore;
  examinationNaturalId: string | undefined;
  examinationId: string | undefined;
  examinationFiles: ExaminationFile[];
  isExaminationOwner: boolean;
  startDateTime: string | undefined;
  endDateTime: string | undefined;
  examinationStatus: {
    examinationStatus: ExamStatuses | undefined;
  };
};
