import { SetStateAction, Dispatch } from 'react';
import { crc32 } from 'crc';
import {
  maxBlockSize,
  zipFileShareInExamWithWav,
  zipFileShareInExamWithoutWav,
  AcceptedOptionalFileTypes,
} from 'utils/constants';
import { valueExists } from 'utils/helpers';
import {
  uploadFileBlock,
  initiateFileUpload,
  commitFileUpload,
} from 'api/examinations/examinationsApi';
import { UploadResult } from 'pages/examinations/examinationDetails/ExamFileRow';

export type FileData = {
  crc: number | undefined;
  fileSize: number | undefined;
  fileType: AcceptedOptionalFileTypes | undefined;
  numberOfBlocks: number | undefined;
  originalName?: string;
  description?: string;
};

export const uploadExaminationFile = async (
  examinationId: string,
  file: File,
  fileType: AcceptedOptionalFileTypes,
  setUploadStatus: Dispatch<SetStateAction<number>>,
  isBatchUpload = false,
  comment: string | null = null
) => {
  const fileData = await getFileData(
    file,
    fileType,
    setUploadStatus,
    isBatchUpload,
    comment
  );
  if (!fileData || !Object.values(fileData).every(valueExists)) return;

  return initiateFileUpload(examinationId, fileData)
    .then((response) => {
      const examinationFileId = response.id;
      return uploadFileInBlocks(
        examinationId,
        examinationFileId,
        file,
        fileData,
        setUploadStatus,
        isBatchUpload
      ).then(() => {
        return examinationFileId;
      });
    })
    .then((examinationFileId) => {
      return commitFileUpload(examinationId, examinationFileId);
    });
};

const getFileData = async (
  file: File,
  fileType: AcceptedOptionalFileTypes,
  setUploadStatus: Dispatch<SetStateAction<number>>,
  isBatchUpload: boolean,
  comment: string | null
) => {
  if (file.size === 0) {
    if (isBatchUpload) {
      setUploadStatus(100);
    } else {
      throw new Error(UploadResult.EMPTY_FILE);
    }
    return null;
  }
  const fileData: FileData = {
    crc: undefined,
    fileSize: undefined,
    fileType: undefined,
    numberOfBlocks: undefined,
  };
  fileData.crc = await generateCheckSum(file);
  fileData.fileSize = file.size;
  fileData.fileType = fileType;
  fileData.numberOfBlocks = Math.ceil(file.size / maxBlockSize);
  if (fileData.fileType === AcceptedOptionalFileTypes.OTHER) {
    fileData.originalName = file.name;
    if (comment) {
      fileData.description = comment;
    }
  }

  return fileData;
};

const generateCheckSum = (file: File): Promise<number> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const data = reader.result;
      if (data) {
        const crc = crc32(data as Buffer);
        resolve(crc);
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

const uploadFileInBlocks = async (
  examinationId: string,
  examinationFileId: string,
  file: File,
  fileData: FileData,
  setUploadStatus: Dispatch<SetStateAction<number>>,
  isBatchUpload: boolean
) => {
  const { numberOfBlocks } = fileData;
  let progressByBlock: number;

  if (isBatchUpload) {
    setUploadStatus(zipFileShareInExamWithWav + 1);
    progressByBlock =
      (zipFileShareInExamWithoutWav - (zipFileShareInExamWithWav + 1)) /
      numberOfBlocks!;
  } else {
    setUploadStatus(1);
    progressByBlock = 100 / numberOfBlocks!;
  }

  const setProgress = (blockNumber: number) => {
    let newProgress;
    if (isBatchUpload) {
      newProgress =
        blockNumber * progressByBlock + zipFileShareInExamWithWav + 1;
    } else {
      newProgress = blockNumber * progressByBlock;
    }

    setUploadStatus(newProgress);
    if (newProgress > 100) {
      setUploadStatus(100);
    }
  };

  let startByte = 0;

  for (let blockNumber = 1; blockNumber <= numberOfBlocks!; blockNumber += 1) {
    let block;
    if (startByte + maxBlockSize > file.size) {
      block = file.slice(startByte, file.size);
    } else {
      block = file.slice(startByte, startByte + maxBlockSize);
    }

    // eslint-disable-next-line
    await uploadFileBlock(
      examinationId,
      examinationFileId,
      blockNumber,
      block,
      fileData.fileType!
    ).then(() => {
      return examinationFileId;
    });
    startByte += maxBlockSize;

    setProgress(blockNumber);
  }
};
