import i18n from 'translations/i18n';

export const sensorHandlingQA = {
  header: i18n.t('help.sensor-handling.title'),
  questions: [
    {
      question: i18n.t('help.sensor-handling.question1'),
      answer: [
        {
          text: i18n.t('help.sensor-handling.question1-answer'),
        },
      ],
    },
    {
      question: i18n.t('help.sensor-handling.question2'),
      answer: [
        {
          text: i18n.t('help.sensor-handling.question2-answer-part1'),
        },
        {
          text: i18n.t('help.sensor-handling.question2-answer-part2'),
          bulletpoint: true,
        },
        {
          text: i18n.t('help.sensor-handling.question2-answer-part3'),
          bulletpoint: true,
        },
        {
          text: i18n.t('help.sensor-handling.question2-answer-part4'),
          bulletpoint: true,
        },
        {
          text: i18n.t('help.sensor-handling.question2-answer-part5'),
          bulletpoint: true,
        },
        {
          text: i18n.t('help.sensor-handling.question2-answer-part6'),
          bulletpoint: true,
        },
        {
          text: i18n.t('help.sensor-handling.question2-answer-part7'),
        },
      ],
    },
    {
      question: i18n.t('help.sensor-handling.question3'),
      answer: [
        {
          text: i18n.t('help.sensor-handling.question3-answer-part1'),
        },
        {
          text: i18n.t('help.sensor-handling.question3-answer-part2'),
          bulletpoint: true,
        },
        {
          text: i18n.t('help.sensor-handling.question3-answer-part3'),
          bulletpoint: true,
          images: { src: ['images/faq/sensorHandling/question3-1.png'] },
        },
        {
          text: i18n.t('help.sensor-handling.question3-answer-part4'),
        },
      ],
    },
  ],
};
