import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

// eslint-disable-next-line
export enum LabelColors {
  BLUE = 'BLUE',
  DARK_BLUE = 'DARK_BLUE',
  PINK = 'PINK',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  DARK_YELLOW = 'DARK_YELLOW',
}

type LabelItemProps = {
  label: string;
  color: LabelColors;
};

export const LegendItem = ({ label, color }: LabelItemProps) => {
  const classes = useStyles();

  return (
    <div className={classes.legendItem}>
      <div className={clsx(classes.legendIcon, classes[color])} />
      <Typography
        variant="caption"
        className={classes.legendValue}
        id={`${label}-legend-item`}
      >
        {label}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  legendItem: {
    display: 'flex',
    alignItems: 'center',
  },
  legendIcon: {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  legendValue: {
    marginRight: theme.spacing(1),
  },
  BLUE: {
    backgroundColor: theme.palette.primary.light,
  },
  PINK: {
    backgroundColor: theme.palette.error.darkmode,
  },
  GREEN: {
    backgroundColor: theme.palette.success.darkmode,
  },
  YELLOW: {
    backgroundColor: theme.palette.warning.darkmode,
  },
  DARK_BLUE: {
    backgroundColor: theme.palette.secondary.dark,
  },
  DARK_YELLOW: {
    backgroundColor: theme.palette.warning.dark,
  },
}));
