import clsx from 'clsx';
import { makeStyles, ButtonBaseProps } from '@material-ui/core';
import { IconButton } from 'components/Button/IconButton';
import { ReactComponent as Edit } from 'icons/Edit.svg';

export const EditButton = ({ className, onClick, id }: ButtonBaseProps) => {
  const classes = useStyles();

  return (
    <>
      <IconButton
        onClick={onClick}
        id={id}
        className={clsx(className, classes.editButton)}
      >
        <Edit />
      </IconButton>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  editButton: {
    width: '46px',
    height: '46px',

    '&:hover': {
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
}));
