import { SleepScoreSnoringIndexBarChart } from 'charts/BarCharts/SleepScoreSnoringIndexChart';
import { DividedAhiBarChart } from 'charts/BarCharts/DividedAhiChart';
import { DividedAhiDotChart } from 'charts/DotCharts/DividedAhiChart';
import { DividedAhiChartLegend } from 'charts/ChartComponents/DividedAhiChartLegend';
import { CombinedAhiBarChart } from 'charts/BarCharts/CombinedAhiChart';
import { CombinedAhiDotChart } from 'charts/DotCharts/CombinedAhiChart';
import { CombinedAhiChartLegend } from 'charts/ChartComponents/CombinedAhiChartLegend';
import { SleepScoreSnoringIndexDotChart } from 'charts/DotCharts/SleepScoreSnoringIndexChart';
import { SSSIChartLegend } from 'charts/ChartComponents/SSSIChartLegend';
import { TimeFrames } from 'pages/examinations/ExaminationsSummaryPage';
import { ExaminationSimple } from 'commonTypes/examinationTypes';
import { overrideBarPosition } from 'charts/BarCharts/BarChartComponents/customBar';
import { DateTime } from 'luxon';

type BarAndDotChartsProps = {
  timeFrame: TimeFrames;
  data: ExaminationSimple[] | null;
  dates: { from: DateTime | undefined; to: DateTime | undefined };
};

export const BarAndDotCharts = ({
  timeFrame,
  data,
  dates,
}: BarAndDotChartsProps) => {
  overrideBarPosition();

  return (
    <>
      <SSSIChartLegend />
      {timeFrame === TimeFrames.LAST_RESULTS ? (
        <SleepScoreSnoringIndexBarChart data={data} />
      ) : (
        <SleepScoreSnoringIndexDotChart
          data={data}
          timeFrame={timeFrame}
          dates={dates}
        />
      )}
      <DividedAhiChartLegend />
      {timeFrame === TimeFrames.LAST_RESULTS ? (
        <DividedAhiBarChart data={data} />
      ) : (
        <DividedAhiDotChart data={data} timeFrame={timeFrame} dates={dates} />
      )}
      <CombinedAhiChartLegend />
      {timeFrame === TimeFrames.LAST_RESULTS ? (
        <CombinedAhiBarChart data={data} />
      ) : (
        <CombinedAhiDotChart data={data} timeFrame={timeFrame} dates={dates} />
      )}
    </>
  );
};
