export type SSSIChartDataItem = {
  examid: string | undefined;
  name: string | number | undefined;
  sleepScore: number | undefined;
  snoringIndex: number | undefined;
};

export type DividedAhiChartDataItem = {
  examid: string | undefined;
  name: string | number | undefined;
  supinePositionRatio: number | undefined;
  supineAhi: number | undefined;
  nonSupineAhi: number | undefined;
};

export type CombinedAhiChartDataItem = {
  examid: string | undefined;
  name: string | number | undefined;
  ahi: number | undefined;
};

// eslint-disable-next-line
export enum GraphTypes {
  SLEEP_AWAKE = 'SLEEP_AWAKE',
  POSITION = 'POSITION',
  APNEA = 'APNEA',
  SNORE = 'SNORE',
}

// eslint-disable-next-line
export enum SleepAwakeLabels {
  SLEEP = 'SLEEP',
  AWAKE = 'AWAKE',
}

// eslint-disable-next-line
export enum PositionLabels {
  RIGHT_SIDE = 'RIGHT_SIDE',
  LEFT_SIDE = 'LEFT_SIDE',
  PERPENDICULAR = 'PERPENDICULAR',
  SUPINE = 'SUPINE',
  PRONE = 'PRONE',
}

export type ChartLabels = SleepAwakeLabels | PositionLabels;

export type ChartDataDTO = {
  graphType: GraphTypes;
  values: {
    startTimestamp: number;
    endTimestamp: number;
    value: number | null;
    label: ChartLabels | null;
  }[];
};

export type ChartsData = {
  startedAt: string;
  endedAt: string;
  graphs: ChartDataDTO[];
};

export type ProcessedChartValues = {
  timeStamp: number | undefined;
  value: number | ChartLabels | null;
}[];

export type ChartData = {
  chartData: {
    start: string | undefined;
    end: string | undefined;
    data: ProcessedChartValues;
  };
};
