import { FC, ReactNode } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter } from 'react-router-dom';
import { AuthProvider } from 'context/providers/AuthProvider';
import { ConfigProvider } from 'context/providers/ConfigProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import theme from 'theme';
import i18n from 'translations/i18n';
import pl from 'dayjs/locale/pl';
import en from 'dayjs/locale/en';
import DayjsUtils from '@date-io/dayjs';

const queryClient = new QueryClient();

const AppProviders: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider
        locale={i18n.language === 'pl' ? pl : en}
        utils={DayjsUtils}
      >
        <QueryClientProvider client={queryClient}>
          <ConfigProvider>
            <AuthProvider>
              <HashRouter>{children}</HashRouter>
            </AuthProvider>
          </ConfigProvider>
        </QueryClientProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
export default AppProviders;
