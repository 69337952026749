import {
  makeStyles,
  TableRow,
  TableCellProps,
  Typography,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { round } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'context/providers/ConfigProvider';
import { DataCell } from 'components/Table/DataCell';
import { CheckboxBase } from 'components/Input/Checkbox';
import { ValueColorIndicator } from 'pages/examinations/examinationsList/ValueColorIndicator';
import { ExaminationSimple, ExamStatuses } from 'commonTypes/examinationTypes';
import { formatDurationInSeconds } from 'utils/helpers';
import { ReactComponent as Warning } from 'icons/Warning.svg';
import { CustomTooltip } from 'components/Tooltip/CustomTooltip';

type ExaminationRowProps = {
  examination: ExaminationSimple;
  handleCheckboxClick: (
    examinationId: string,
    isExaminationOwner: boolean
  ) => void;
  selected: boolean;
};

export const ExaminationRow = ({
  examination,
  handleCheckboxClick,
  selected,
}: ExaminationRowProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { colorRanges } = useConfig();
  const {
    examinationId,
    startDate,
    totalRegistrationTime,
    examinationNaturalId,
    totalSleepTime,
    timeToSleepFromExaminationBeginning,
    supinePositionRatio,
    ahi,
    supineAhi,
    snoringIndex,
    supineSnorigIndex,
    isExaminationOwner,
    examinationStatus,
  } = examination;

  const handleRowClick = () => {
    history.push(`/examination/${examinationId}`);
  };

  const ClickableDataCell = ({ children, ...props }: TableCellProps) => {
    return (
      <DataCell onClick={handleRowClick} {...props}>
        {children}
      </DataCell>
    );
  };

  type DataCellWithColorProps = TableCellProps & {
    range: number[] | undefined;
    value: number | null;
  };

  const DataCellWithColor = ({
    children,
    range,
    value,
    id,
    ...props
  }: DataCellWithColorProps) => {
    return (
      <ClickableDataCell onClick={handleRowClick} {...props}>
        <div className={classes.colorCell}>
          {range && value !== null && (
            <ValueColorIndicator
              range={range}
              value={round(value, 1)}
              id={id}
            />
          )}
          {value !== null && round(value, 1)}
        </div>
      </ClickableDataCell>
    );
  };

  return (
    <TableRow
      key={examinationId}
      className={classes.dataRow}
      id="examination-data-row"
      hover
    >
      <ClickableDataCell id={`${examinationNaturalId}-date-number`}>
        <div className={classes.cellContainer}>
          <div className={classes.twoRowsCell}>
            <span className={classes.date}>
              {startDate &&
                // local date-time (not UTC)
                DateTime.fromISO(startDate).toFormat('dd/LL/yyyy HH:mm')}
            </span>
            <span className={classes.examinationNumber} id="examination-number">
              {examinationNaturalId}
            </span>
          </div>
          {examinationStatus.examinationStatus === ExamStatuses.INCOMPLETE && (
            <CustomTooltip
              className={classes.tooltip}
              title={
                <Typography variant="caption">
                  {t('charts.incomplete-data')}
                </Typography>
              }
            >
              <Warning
                onClick={(e) => e.stopPropagation()}
                className={classes.warningIcon}
              />
            </CustomTooltip>
          )}
        </div>
      </ClickableDataCell>
      <ClickableDataCell id={`${examinationNaturalId}-duration`}>
        {totalRegistrationTime &&
          formatDurationInSeconds(totalRegistrationTime)}
      </ClickableDataCell>
      <ClickableDataCell id={`${examinationNaturalId}-sleep-time`}>
        {totalSleepTime && formatDurationInSeconds(totalSleepTime)}
      </ClickableDataCell>
      <ClickableDataCell id={`${examinationNaturalId}-time-to-sleep`}>
        {timeToSleepFromExaminationBeginning &&
          formatDurationInSeconds(timeToSleepFromExaminationBeginning)}
      </ClickableDataCell>
      <ClickableDataCell id={`${examinationNaturalId}-supine-position-ratio`}>
        {supinePositionRatio && round(supinePositionRatio, 1)}
      </ClickableDataCell>
      <DataCellWithColor
        id={`${examinationNaturalId}-ahi`}
        range={colorRanges.ahi}
        value={ahi}
      />
      <DataCellWithColor
        id={`${examinationNaturalId}-supine-ahi`}
        range={colorRanges.supine_ahi}
        value={supineAhi}
      />
      <DataCellWithColor
        id={`${examinationNaturalId}-snoring-index`}
        range={colorRanges.snoring_index}
        value={snoringIndex}
      />
      <DataCellWithColor
        id={`${examinationNaturalId}-supine-snoring-index`}
        range={colorRanges.supine_snoring_index}
        value={supineSnorigIndex}
      />
      <DataCell
        align="center"
        className={classes.checkboxCell}
        id={`${examinationNaturalId}-choose-examination`}
      >
        <CheckboxBase
          size={24}
          radius={4}
          onClick={() => handleCheckboxClick(examinationId, isExaminationOwner)}
          checked={selected}
          id={`${examinationNaturalId}-checkbox`}
        />
      </DataCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme) => ({
  checkboxCell: {
    padding: theme.spacing(0, 2, 0, 2),
    cursor: 'default',
  },
  dataRow: {
    '&:hover': {
      cursor: 'pointer',
    },
    '&:nth-child(even)': {
      backgroundColor: theme.palette.grey[500],
    },
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  twoRowsCell: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(1),
  },
  examinationNumber: {
    color: theme.palette.grey[700],
    marginTop: theme.spacing(0.5),
  },
  colorCell: {
    display: 'flex',
    alignItems: 'center',
  },
  cellContainer: {
    display: 'flex',
  },
  warningIcon: {
    height: '20px',
    width: '20px',
    margin: theme.spacing('auto', 0, 'auto', 'auto'),
    cursor: 'default',
  },
  tooltip: {
    width: '210px',
  },
  date: {
    whiteSpace: 'nowrap',
  },
}));
