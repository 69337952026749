import { ChangeEvent } from 'react';
import { TableBody } from '@material-ui/core';
import { ExamFileRow } from 'pages/examinations/examinationDetails/ExamFileRow';
import {
  optionalFileTypes,
  otherFileType,
  AcceptedOptionalFileTypes,
} from 'utils/constants';
import { OptionalFile, ExaminationFile } from 'commonTypes/examinationTypes';

type TableRowsProps = {
  files: ExaminationFile[];
  openOtherFileDialog: () => void;
  handleUpload: (
    selectedFile: File,
    optionalFileType: OptionalFile,
    comment?: string | null
  ) => void;
};

export const TableRows = ({
  files,
  openOtherFileDialog,
  handleUpload,
}: TableRowsProps) => {
  const uploadFile = (
    event: ChangeEvent<HTMLInputElement>,
    optionalFileType: OptionalFile
  ) => {
    if (event.target.files) {
      handleUpload(event.target.files[0], optionalFileType);
    }
  };

  return (
    <>
      <TableBody>
        {optionalFileTypes.map((optionalFileType) => {
          let uploadedFile = null;
          files.forEach((file) => {
            if (file.fileTypes === optionalFileType.fileType) {
              uploadedFile = file;
            }
          });
          return (
            <ExamFileRow
              optionalFile={optionalFileType}
              key={optionalFileType.fileType}
              file={uploadedFile}
              handleUpload={(event) => uploadFile(event, optionalFileType)}
            />
          );
        })}
        {files.map((file) => {
          if (file.fileTypes === AcceptedOptionalFileTypes.OTHER) {
            return (
              <ExamFileRow
                optionalFile={otherFileType}
                key={file.id}
                file={file}
              />
            );
          }
        })}
        <ExamFileRow
          optionalFile={otherFileType}
          key="add-other-file"
          file={null}
          handleDialogOpen={openOtherFileDialog}
        />
      </TableBody>
    </>
  );
};
