import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { activateUser } from 'api/auth/authApi';
import { decodeToken } from 'utils/decodeJWT';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'context/providers/AuthProvider';
import { ActivationFailedDialog } from 'pages/auth/activateAccount/ActivationFailedDialog';

type activationParams = {
  match: {
    params: {
      userId: string;
      code: string;
    };
  };
};

const AccountActivation = ({
  match: {
    params: { userId, code },
  },
}: activationParams) => {
  const history = useHistory();
  const activationStarted = useRef(false);
  const { login, logout, user } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);

  const activateMutation = useMutation(activateUser, {
    onError: () => {
      logout();
      setDialogOpen(true);
    },
    onSuccess: (response) => {
      const token = response.headers.authorization;
      if (token) {
        const tokenData = decodeToken(token);
        if (tokenData) {
          history.push('/');
          const { decodedUser } = tokenData;
          login(decodedUser);
        }
        decodeToken(token);
      } else {
        logout();
        setDialogOpen(true);
      }
    },
  });

  useEffect(() => {
    // activations already in progress
    if (activationStarted.current) {
      return;
    }
    if (user) {
      logout();
      return;
    }
    // no user logged in - activation
    if (userId && code) {
      activationStarted.current = true;
      activateMutation.mutate({ userId, code });
    }
  }, [activateMutation, user, logout, code, userId]);

  return (
    <>
      <ActivationFailedDialog open={dialogOpen} />
    </>
  );
};

export default AccountActivation;
