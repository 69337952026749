import { useEffect, useState } from 'react';
import { LoaderOnBackdrop } from 'components/Progress/LoaderOnBackdrop';
import { useAuth } from 'context/providers/AuthProvider';
import { decodeToken } from 'utils/decodeJWT';
import { AuthenticatedApp } from 'AuthenticatedApp';
import { NotAuthenticatedApp } from 'NotAuthenticatedApp';

const Root = () => {
  const { user, login, logout } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkIfUserLoggedIn = () => {
      if (localStorage.token) {
        const tokenData = decodeToken(localStorage.token);
        if (tokenData) {
          const { decodedUser } = tokenData;
          login(decodedUser);
        } else {
          logout();
        }
      } else {
        logout();
      }
      setLoading(false);
    };
    checkIfUserLoggedIn();
  }, [login, logout]);

  if (loading) return <LoaderOnBackdrop />;

  return user ? <AuthenticatedApp /> : <NotAuthenticatedApp />;
};

export default Root;
