import { makeStyles } from '@material-ui/core';
import { SleepAwakeChart } from 'charts/LineCharts/SleepAwakeChart';
import { PositionChart } from 'charts/LineCharts/PositionChart';
import { SnoreChart } from 'charts/LineCharts/SnoreChart';
import { ApneaChart } from 'charts/LineCharts/ApneaChart';
import {
  ChartsData,
  GraphTypes,
  SleepAwakeLabels,
  PositionLabels,
  ProcessedChartValues,
} from 'charts/chartTypes/chartTypes';

type LineChartsProps = {
  chartsData: ChartsData | null;
  duration: { start: string | undefined; end: string | undefined };
};

const initialSleepAwakeData = [
  { timeStamp: undefined, value: SleepAwakeLabels.AWAKE },
  { timeStamp: undefined, value: SleepAwakeLabels.SLEEP },
];

const initialPositionData = [
  { timeStamp: undefined, value: PositionLabels.PERPENDICULAR },
  { timeStamp: undefined, value: PositionLabels.PRONE },
  { timeStamp: undefined, value: PositionLabels.LEFT_SIDE },
  { timeStamp: undefined, value: PositionLabels.RIGHT_SIDE },
  { timeStamp: undefined, value: PositionLabels.SUPINE },
];

export const LineCharts = ({ chartsData, duration }: LineChartsProps) => {
  const classes = useStyles();

  const selectGraph = (graphType: GraphTypes) => {
    return chartsData!.graphs.find((graph) => graph.graphType === graphType);
  };
  const { start, end } = duration;

  const processChartData = (graphType: GraphTypes) => {
    const isCategoryChart =
      graphType === GraphTypes.SLEEP_AWAKE || graphType === GraphTypes.POSITION;

    let initialData: ProcessedChartValues = [];

    if (isCategoryChart) {
      initialData =
        graphType === GraphTypes.SLEEP_AWAKE
          ? initialSleepAwakeData
          : initialPositionData;
    }

    if (!chartsData) {
      return {
        start,
        end,
        data: initialData,
      };
    }

    const chart = selectGraph(graphType);
    let data: ProcessedChartValues = [];

    if (chart) {
      const sorted = chart.values.sort(
        (a, b) => (a.startTimestamp > b.startTimestamp && 1) || -1
      );
      data = sorted.flatMap((value) => {
        return [
          {
            timeStamp: value.startTimestamp,
            value: isCategoryChart ? value.label : value.value,
          },
          {
            timeStamp: value.endTimestamp,
            value: isCategoryChart ? value.label : value.value,
          },
        ];
      });
    }

    if (isCategoryChart) {
      // A trick to keep Y Axis categories always in the same order
      data = initialData.concat(data);
    }

    return {
      start: chartsData.startedAt,
      end: chartsData.endedAt,
      data,
    };
  };

  return (
    <div className={classes.container}>
      <SleepAwakeChart chartData={processChartData(GraphTypes.SLEEP_AWAKE)} />
      <PositionChart chartData={processChartData(GraphTypes.POSITION)} />
      <SnoreChart chartData={processChartData(GraphTypes.SNORE)} />
      <ApneaChart chartData={processChartData(GraphTypes.APNEA)} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    '@media (min-width:1500px)': {
      marginTop: theme.spacing(3),
    },
  },
}));
