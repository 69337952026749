import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Plus } from 'icons/Plus.svg';
import { IconButton } from 'components/Button/IconButton';
import { AddProfileDialog } from 'pages/profiles/AddProfileDialog';
import { Toast } from 'components/Alert/Toast';

export const AddProfileButton = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [addProfileDialogOpen, setAddProfileDialogOpen] = useState(false);
  const [addProfileResult, setAddProfileResult] = useState({
    resolved: false,
    status: '',
  });

  return (
    <>
      <IconButton
        id="add-next-profile-button"
        className={classes.addProfile}
        onClick={() => setAddProfileDialogOpen(true)}
      >
        <Plus />
      </IconButton>
      <AddProfileDialog
        open={addProfileDialogOpen}
        onClose={() => setAddProfileDialogOpen(false)}
        setAddProfileResult={setAddProfileResult}
      />
      <Toast
        open={addProfileResult.status === 'error'}
        setState={setAddProfileResult}
        severity="error"
      >
        {t('profiles.error.add-profile')}
      </Toast>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  addProfile: {
    width: '40px',
    height: '40px',
  },
}));
