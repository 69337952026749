import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';
import { PasswordInput } from 'components/Input/PasswordInput';
import { TextFieldBase } from 'components/Input/TextFieldBase';
import { TextFieldWithIcon } from 'components/Input/TextFieldWithIcon';
import { ReactComponent as Mail } from 'icons/Mail.svg';
import {
  handleBlurAndTrim,
  capitalizeOnChange,
  handleChangeAndTrim,
} from 'utils/formHelpers';
import { RegisterValues } from 'pages/auth/register/formikValues';

type RegisterFormProps = {
  formik: FormikContextType<RegisterValues>;
};

export const RegisterForm = ({ formik }: RegisterFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { errors, touched, values, handleBlur, handleChange } = formik;

  return (
    <>
      <TextFieldBase
        required
        name="firstName"
        id="firstName"
        label={t('first-name')}
        className={clsx(classes.regularMargin, {
          [classes.errorMargin]: errors.firstName && touched.firstName,
        })}
        value={values.firstName}
        onChange={(event) => capitalizeOnChange(event, formik)}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
        error={Boolean(errors.firstName && touched.firstName)}
        helperText={
          errors.firstName && touched.firstName ? errors.firstName : null
        }
      />
      <TextFieldBase
        required
        name="lastName"
        id="lastName"
        label={t('last-name')}
        className={clsx(classes.regularMargin, {
          [classes.errorMargin]: errors.lastName && touched.lastName,
        })}
        value={values.lastName}
        onChange={(event) => capitalizeOnChange(event, formik)}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
        error={Boolean(errors.lastName && touched.lastName)}
        helperText={
          errors.lastName && touched.lastName ? errors.lastName : null
        }
      />
      <TextFieldWithIcon
        required
        name="email"
        id="email"
        label={t('email')}
        className={clsx(classes.regularMargin, {
          [classes.errorMargin]: errors.email && touched.email,
        })}
        icon={<Mail />}
        value={values.email}
        onChange={(event) => handleChangeAndTrim(event, formik)}
        onBlur={handleBlur}
        error={Boolean(errors.email && touched.email)}
        helperText={errors.email && touched.email ? errors.email : null}
      />
      <PasswordInput
        required
        className={classes.bigMargin}
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(errors.password && touched.password)}
        helperText={
          errors.password && touched.password
            ? errors.password
            : t('password-helper')
        }
      />
      <Typography variant="caption">{t('required-field')}</Typography>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  regularMargin: {
    marginBottom: theme.spacing(2),
  },
  bigMargin: {
    marginBottom: theme.spacing(5),
  },
  errorMargin: {
    marginBottom: theme.spacing(3),
  },
}));
