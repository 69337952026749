import { Dispatch, SetStateAction } from 'react';
import { ButtonBaseProps } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ButtonBaseWithIcon } from 'components/Button/ButtonBaseWithIcon';
import { ReactComponent as Filter } from 'icons/Filter.svg';
import { ReactComponent as Close } from 'icons/Close.svg';
import { useFilters } from 'context/providers/FiltersProvider';
import { useExaminations } from 'context/providers/ExaminationsProvider';

type FilterButtonProps = ButtonBaseProps & {
  filtersAreaOpen: boolean;
  setFiltersAreaOpen: Dispatch<SetStateAction<boolean>>;
  resetTable: () => void;
};

export const FilterButton = ({
  className,
  filtersAreaOpen,
  setFiltersAreaOpen,
  resetTable,
}: FilterButtonProps) => {
  const { t } = useTranslation();
  const { clearAllFilters, submittedFilters } = useFilters();
  const { getExaminations } = useExaminations();

  const handleClick = () => {
    if (!isEmpty(submittedFilters)) {
      clearAllFilters();
      getExaminations();
      resetTable();
    }
    setFiltersAreaOpen(!filtersAreaOpen);
  };

  return (
    <ButtonBaseWithIcon
      className={className}
      onClick={handleClick}
      startIcon={!isEmpty(submittedFilters) ? <Close /> : <Filter />}
      id="filters-button"
    >
      {t('filters')}
    </ButtonBaseWithIcon>
  );
};
