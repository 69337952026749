import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { colorEnum } from 'utils/matchSleepScoreColor';

type ColorIndicatorProps = {
  color: colorEnum;
  id: string | undefined;
  className?: string;
};

export const ColorIndicator = ({
  color,
  id,
  className,
}: ColorIndicatorProps) => {
  const classes = useStyles();

  return (
    <div
      id={id}
      className={clsx(classes.colorIndicator, classes[color], className)}
    />
  );
};

ColorIndicator.defaultProps = {
  className: undefined,
};

const useStyles = makeStyles((theme) => ({
  colorIndicator: {
    width: '12px',
    height: '12px',
    minWidth: '12px',
    minHeight: '12px',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  GREEN: {
    backgroundColor: theme.palette.success.darkmode,
  },
  YELLOW: {
    backgroundColor: theme.palette.warning.darkmode,
  },
  ORANGE: {
    backgroundColor: theme.palette.warning.main,
  },
  RED: {
    backgroundColor: theme.palette.error.dark,
  },
  TRANSPARENT: {
    backgroundColor: 'transparent',
  },
}));
