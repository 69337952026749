import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Divider } from '@material-ui/core';
import { Sensor } from 'commonTypes/sensorTypes';
import { ReactComponent as SensorIcon } from 'icons/Sensor.svg';
import { SensorDataItem } from 'pages/sensors/SensorDataItem';
import { SensorProfileItem } from 'pages/sensors/SensorProfileItem';

type SensorItemProps = {
  sensor: Sensor;
};

export const SensorItem = ({ sensor }: SensorItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    firmwareVersion,
    hardwareRevision,
    serialNumber,
    lendedToProfile,
  } = sensor;

  const dataItems = [
    { label: t('sensors.firmware'), data: firmwareVersion },
    { label: t('sensors.hardware'), data: hardwareRevision.toLowerCase() },
    { label: t('sensors.serial'), data: serialNumber },
  ];

  return (
    <div className={classes.container} id="sensor-item">
      <div className={classes.dataContainer}>
        <div>
          <div className={classes.sensorNameContainer}>
            <SensorIcon className={classes.sensorIcon} />
            <Typography
              variant="body1"
              className={classes.sensorName}
              id={`${serialNumber}-sensor-name`}
            >
              {`Clebre ${serialNumber.slice(-3)}`}
            </Typography>
          </div>
          <div className={classes.sensorDataContainer}>
            {dataItems.map((item) => {
              return (
                <SensorDataItem
                  item={item}
                  key={item.label}
                  id={serialNumber}
                />
              );
            })}
          </div>
        </div>
        <Divider
          orientation="vertical"
          flexItem
          className={classes.verticalDivider}
        />
        <SensorProfileItem profile={lendedToProfile} id={serialNumber} />
      </div>
      <Divider />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '720px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  sensorNameContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  dataContainer: {
    display: 'flex',
  },
  sensorIcon: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
  },
  sensorName: {
    color: theme.palette.primary.darkmode,
  },
  sensorDataContainer: {
    display: 'flex',
  },
  verticalDivider: {
    height: '77px',
    margin: `auto 0 auto 0`,
  },
}));
