import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getConsentByName } from 'api/consent/consentApi';
import {
  ConsentNamesUser,
  ConsentNamesProfile,
} from 'components/Consents/ConsentNames';

type DocumentLinkProps = {
  name: ConsentNamesUser | ConsentNamesProfile;
};

export const DocumentLink = ({ name }: DocumentLinkProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const [consentUrl, setConsentUrl] = useState(`/#${location.pathname}`);
  const [target, setTarget] = useState('_self');

  useEffect(() => {
    getConsentByName(name)
      .then(() => {
        setConsentUrl(
          `${process.env.REACT_APP_BACKEND_API_URL}/public/consent/doc/${name}`
        );
        setTarget('_blank');
      })
      .catch(() => {});
  }, [name]);

  return (
    <a
      href={consentUrl}
      target={target}
      rel="noreferrer"
      className={classes.link}
    >
      {t(`consents.${name}-form`)}
    </a>
  );
};

export const ConsentWithDocuments = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.label}>
      * Zapoznałem się z <DocumentLink name={ConsentNamesUser.generalTerms} />,
      w tym również z <DocumentLink name={ConsentNamesUser.termsApplication} />,{' '}
      <DocumentLink name={ConsentNamesUser.termsProduct} /> i{' '}
      <DocumentLink name={ConsentNamesUser.privacyPolicy} /> oraz akceptuję ich
      postanowienia.
    </Typography>
  );
};

export const DoctorConsent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Typography className={clsx(classes.labelCentered, classes.label)}>
      {t(`consents.checkbox-doctor`)}{' '}
      <DocumentLink name={ConsentNamesUser.entrustingAgreement} />
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  link: {
    pointerEvents: 'auto',
    color: theme.palette.secondary.main,

    '&:visited': {
      color: theme.palette.secondary.dark,
    },
  },
  label: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  labelCentered: {
    marginTop: theme.spacing(1),
  },
}));
