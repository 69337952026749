import clsx from 'clsx';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import { ReactComponent as Close } from 'icons/Close.svg';
import { ReactComponent as CloseDisabled } from 'icons/CloseDisabled.svg';

type CancelButtonProps = Omit<ButtonProps, 'onClick'> & {
  onClick: () => void;
};

export const CancelButton = ({
  children,
  className,
  onClick,
  disabled,
  ...props
}: CancelButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      color="primary"
      className={clsx(className, classes.button)}
      classes={{
        startIcon: classes.startIcon,
      }}
      onClick={onClick}
      startIcon={disabled ? <CloseDisabled /> : <Close />}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    boxShadow: theme.shadows[1],
    fontSize: '14px',
    fontWeight: 500,
    borderRadius: '8px',
    height: '46px',
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
  startIcon: {
    padding: theme.spacing(0, 1, 0, 1),
  },
}));
