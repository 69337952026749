import { useState, useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { ExaminationsTable } from 'pages/examinations/examinationsList/ExaminationsTable';
import { ActionsPanel } from 'pages/examinations/examinationsList/examTableActions/ActionsPanel';
import { AddFirstExamination } from 'pages/examinations/examinationUpload/AddFirstExamination';
import { useAuth } from 'context/providers/AuthProvider';
import { useExaminations } from 'context/providers/ExaminationsProvider';
import {
  ExaminationSimple,
  SelectedExamination,
} from 'commonTypes/examinationTypes';
import { BigLoader } from 'components/Progress/BigLoader';

export type ExaminationsList = {
  data: ExaminationSimple[];
  filtered: boolean;
};

const ExaminationsListPage = () => {
  const classes = useStyles();
  const { activeProfile } = useAuth();
  const { examinations, examinationsLoading } = useExaminations();
  const [selectedExaminations, setSelectedExaminations] = useState<
    SelectedExamination[]
  >([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { examinationsList } = examinations;

  const resetTable = useCallback(() => {
    setPage(0);
    setRowsPerPage(10);
    setSelectedExaminations([]);
  }, [setSelectedExaminations]);

  useEffect(() => {
    resetTable();
  }, [activeProfile?.profileId, resetTable]);

  const resetSelected = useCallback(() => {
    setSelectedExaminations([]);
  }, [setSelectedExaminations]);

  if (isEmpty(examinationsList) && examinationsLoading) {
    return <BigLoader className={classes.loader} />;
  }

  return (
    <>
      {activeProfile && examinationsList && (
        <>
          {isEmpty(examinationsList) && !examinations.filtered ? (
            <AddFirstExamination />
          ) : (
            <>
              <ActionsPanel
                selectedExaminations={selectedExaminations}
                resetSelected={resetSelected}
                resetTable={resetTable}
              />
              <ExaminationsTable
                selectedExaminations={selectedExaminations}
                setSelectedExaminations={setSelectedExaminations}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  loader: {
    marginTop: theme.spacing(6),
  },
}));

export default ExaminationsListPage;
