import { DetailsCircleChart } from 'charts/CircleCharts/DetailsCircleChart';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { SleepScore } from 'commonTypes/examinationTypes';
import { useConfig } from 'context/providers/ConfigProvider';

type CircleChartsProps = {
  sleepScoreMetrics: SleepScore;
  className?: string;
};

export const CircleCharts = ({
  sleepScoreMetrics,
  className,
}: CircleChartsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { colorRanges } = useConfig();

  const { sleepScore, breathScore, sleepEfficiency } = sleepScoreMetrics;

  const data = [
    {
      label: t('examinations.sleep-score'),
      value: sleepScore,
      range: colorRanges.sleep_score,
      unit: '%',
    },
    {
      label: t('examinations.breath-score'),
      value: breathScore,
      range: colorRanges.breath_score,
      unit: '%',
    },
    {
      label: t('examinations.sleep-efficiency'),
      value: sleepEfficiency,
      range: colorRanges.sleep_efficiency,
      unit: '%',
    },
  ];

  return (
    <div className={clsx(className, classes.container)}>
      {data.map((dataItem) => {
        return (
          <DetailsCircleChart
            key={dataItem.label}
            id={dataItem.label}
            metric={dataItem}
          />
        );
      })}
    </div>
  );
};

CircleCharts.defaultProps = {
  className: undefined,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '300px',
    marginTop: '-20px',

    '@media (min-width:1500px)': {
      marginLeft: '-15px',
      marginTop: theme.spacing(0.5),
    },
  },
}));
