import { DateTime, Duration } from 'luxon';
import { ProfileSimple, SensorLease } from 'commonTypes/authTypes';
import { isEmpty, round } from 'lodash';

export const capitalize = (text: string) => {
  const words = text.toLowerCase().split(' ');

  if (words) {
    const capitalized = words
      .map((word) => {
        if (word !== '') {
          return word[0].toUpperCase() + word.substring(1);
        }
        return '';
      })
      .join(' ');
    return capitalized;
  }
  return '';
};

export const calculateAge = (dateOfBirth: string | undefined) => {
  if (!dateOfBirth) return null;

  const today = DateTime.now();
  const formattedDateOfBirth = DateTime.fromSQL(dateOfBirth);
  const age = today.diff(formattedDateOfBirth, 'years').years;
  return Math.floor(age);
};

export const sortProfilesList = (
  activeProfileId: string,
  userProfiles: ProfileSimple[]
) => {
  let activeProfile: ProfileSimple;

  const isActiveProfile = (profile: ProfileSimple) => {
    if (profile.profileId === activeProfileId) {
      activeProfile = profile;
    }
    return profile.profileId !== activeProfileId;
  };
  const profilesList = userProfiles.filter(isActiveProfile);

  profilesList.unshift(activeProfile!);
  return profilesList;
};

export const findLeasedSensors = (sensorLeases: SensorLease[]) => {
  if (isEmpty(sensorLeases)) return sensorLeases;

  const hasSensors = sensorLeases.filter((lease) => {
    return !lease.returnedAt;
  });
  return hasSensors;
};

type DurationObject = {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
};

const formatDuration = (durationObject: DurationObject) => {
  if (durationObject.hours && durationObject.minutes) {
    return `${durationObject.hours}h ${round(durationObject.minutes, 0)}m`;
  }
  if (durationObject.hours) {
    return `${durationObject.hours}h`;
  }
  if (durationObject.minutes) {
    return `${round(durationObject.minutes, 0)}m`;
  }
  return 0;
};

export const formatDurationInSeconds = (durationInSeconds: number) => {
  const durationObject = Duration.fromObject({
    hours: 0,
    minutes: 0,
    seconds: durationInSeconds,
  })
    .normalize()
    .toObject();

  return formatDuration(durationObject);
};

export const valueExists = (value: any) => {
  return !!value;
};
