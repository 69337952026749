import { SyntheticEvent, Dispatch, SetStateAction } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

type ToastProps = AlertProps & {
  setState: Dispatch<SetStateAction<{ resolved: boolean; status: string }>>;
  open: boolean;
};

export const Toast = ({ children, severity, open, setState }: ToastProps) => {
  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({ resolved: false, status: '' });
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {children}
      </Alert>
    </Snackbar>
  );
};
