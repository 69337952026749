import { ReactNode } from 'react';
import clsx from 'clsx';
import { TextField, makeStyles, TextFieldProps } from '@material-ui/core';

type TextFieldWithIconProps = TextFieldProps & {
  label: string;
  icon: ReactNode;
};

export const TextFieldWithIcon = ({
  label,
  icon,
  className,
  id,
  value,
  ...props
}: TextFieldWithIconProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.container)}>
      <div className={classes.icon}>{icon}</div>
      <TextField
        id={id}
        value={value}
        fullWidth
        className={classes.textField}
        inputProps={{ className: classes.input }}
        InputLabelProps={{
          shrink: value ? true : undefined,
          classes: {
            root: classes.label,
            focused: 'focused',
          },
        }}
        label={label}
        {...props}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'inline-block',
    height: '48px',
    minHeight: '48px',
  },
  textField: {
    backgroundColor: theme.palette.background.default,

    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '& .MuiInput-underline:not(.Mui-disabled):hover::before': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px',
    },
  },
  label: {
    position: 'absolute',
    left: 35,
    color: theme.palette.text.primary,
    fontWeight: 400,
    '&.focused:not(.Mui-error)': {
      color: theme.palette.text.secondary,
    },
  },
  icon: {
    position: 'absolute',
    left: 0,
    top: 16,
    width: '1.6rem',
    height: '1.6rem',
    zIndex: 10,
  },
  input: {
    paddingLeft: '35px',
    fontWeight: 400,
  },
}));
