import { ReactChild } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Container, Box } from '@material-ui/core';

type RegisterWrapperProps = {
  children: ReactChild;
};

export const RegisterWrapper = ({ children }: RegisterWrapperProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.background}>
      <Container className={classes.container}>
        <img
          src="images/clebre-logoP293.png"
          alt="clebre logo"
          className={classes.logo}
        />
        <Typography className={classes.welcomeText} variant="h4">
          {t('register-page.welcome-text')}
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          {t('register-page.subtitle')}
        </Typography>
        <div className={classes.content}>{children}</div>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    padding: theme.spacing(2, 0, 2, 0),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    width: '370px',

    [theme.breakpoints.down('xs')]: {
      width: '300px',
    },
  },
  logo: {
    width: '120px',

    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },
  welcomeText: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      fontSize: '26px',
      marginTop: theme.spacing(2),
    },
  },
  subtitle: {
    textAlign: 'center',
    width: '396px',
    margin: theme.spacing(2, 0, 1, 0),

    [theme.breakpoints.down('sm')]: {
      width: '300px',
      margin: theme.spacing(1, 0, 0, 0),
    },
  },
}));
