import { ReactNode } from 'react';
import clsx from 'clsx';
import { FormControlLabel, makeStyles, CheckboxProps } from '@material-ui/core';
import { CheckboxBase } from 'components/Input/Checkbox';

type CheckboxWithLabelProps = Omit<CheckboxProps, 'size'> & {
  label: string | ReactNode;
  smallFont?: boolean;
  className?: string;
  labelClickable?: boolean;
  checkboxClass?: string;
  size?: number;
};

export const CheckboxWithLabel = ({
  label,
  smallFont,
  className,
  onChange,
  name,
  checked,
  labelClickable,
  checkboxClass,
  size,
  id,
  disabled,
}: CheckboxWithLabelProps) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={clsx(className, { [classes.notClickable]: !labelClickable })}
      classes={{ label: clsx({ [classes.label]: smallFont }) }}
      control={
        <CheckboxBase
          disabled={disabled}
          onChange={onChange}
          className={clsx(checkboxClass, classes.checkBox)}
          size={size}
          id={id}
        />
      }
      label={label}
      name={name}
      checked={checked}
    />
  );
};

CheckboxWithLabel.defaultProps = {
  smallFont: false,
  className: undefined,
  labelClickable: true,
  checkboxClass: undefined,
  size: undefined,
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    fontWeight: 400,

    '&.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
  checkBox: {
    pointerEvents: 'auto',
  },
  notClickable: {
    pointerEvents: 'none',
  },
}));
