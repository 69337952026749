import { SetStateAction, useState, Dispatch } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DialogBase } from 'components/Dialog/DialogBase';
import { CancelButton } from 'components/Button/CancelButton';
import { SubmitButton } from 'components/Button/SubmitButton';
import { MessageInput } from 'pages/account/MessageInput';
import { CircularLoader } from 'components/Progress/CircularLoader';
import { sendMessage } from 'api/contact/contactApi';

type ContactFormProps = {
  open: boolean;
  onClose: () => void;
  setSendMsgResult: Dispatch<
    SetStateAction<{ resolved: boolean; status: string }>
  >;
};

export const ContactForm = ({
  open,
  onClose,
  setSendMsgResult,
}: ContactFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
    setMessage('');
  };

  const handleSubmit = () => {
    setLoading(true);
    setSendMsgResult({ resolved: false, status: '' });
    sendMessage(message.trim())
      .then(() => {
        setLoading(false);
        handleClose();
        setSendMsgResult({ resolved: true, status: 'success' });
      })
      .catch(() => {
        setLoading(false);
        setSendMsgResult({ resolved: true, status: 'error' });
      });
  };

  return (
    <DialogBase
      open={open}
      onClose={handleClose}
      id="contact-dialog"
      title={t('account.ask-question')}
      className={classes.contactForm}
      disabled={loading}
    >
      <MessageInput
        message={message}
        setMessage={setMessage}
        disabled={loading}
        className={classes.msgInput}
      />
      <div className={classes.buttons}>
        <CancelButton
          id="cancel-button"
          onClick={handleClose}
          className={classes.cancelButton}
          disabled={loading}
        >
          {t('cancel')}
        </CancelButton>
        <SubmitButton
          id="submit-button"
          disabled={message.trim().length === 0 || loading}
          onClick={handleSubmit}
          className={classes.submitButton}
          isLoading={loading}
        >
          {loading ? <CircularLoader /> : t('send')}
        </SubmitButton>
      </div>
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  contactForm: {
    width: '500px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  submitButton: {
    width: '120px',
  },
  msgInput: {
    marginTop: theme.spacing(1),
  },
}));
