import {
  Dialog,
  DialogContent,
  makeStyles,
  DialogProps,
  IconButton,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Close } from 'icons/Close.svg';

type InfoDialogProps = Omit<DialogProps, 'onClose'> & {
  onClose?: () => void;
  closeable?: boolean;
  title?: string;
  contentClass?: string;
};

export const InfoDialog = ({
  children,
  onClose,
  closeable,
  title,
  className,
  contentClass,
  ...props
}: InfoDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: className }} {...props}>
      <div
        className={clsx(classes.topBar, {
          [classes.alignRight]: closeable && !title,
        })}
      >
        {title && (
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
        )}
        {closeable && (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        )}
      </div>
      <DialogContent className={clsx(classes.dialogContent, contentClass)}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

InfoDialog.defaultProps = {
  onClose: undefined,
  closeable: false,
  title: undefined,
  contentClass: undefined,
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(2, 6, 4, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'flex-between',
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  closeButton: {
    margin: theme.spacing(1),
  },
  title: {
    color: theme.palette.primary.main,
    margin: theme.spacing(3, 4, 2, 4),
  },
}));
