import { makeStyles } from '@material-ui/core';
import {
  TooltipContent,
  TooltipContentData,
} from 'pages/examinations/examinationsList/TooltipContent';

type DividedAhiChartTooltipProps = {
  data: TooltipContentData[];
};

export const DividedAhiChartTooltip = ({
  data,
}: DividedAhiChartTooltipProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TooltipContent data={data[0]} multiple />
      <div className={classes.verticalContainer}>
        <TooltipContent
          className={classes.bottomMargin}
          data={data[1]}
          multiple
        />
        <TooltipContent data={data[2]} multiple />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomMargin: {
    marginBottom: theme.spacing(2),
  },
}));
