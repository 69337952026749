import { Typography, makeStyles } from '@material-ui/core';
import { FormikContextType } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextFieldWithIcon } from 'components/Input/TextFieldWithIcon';
import { ButtonBase } from 'components/Button/ButtonBase';
import { CircularLoader } from 'components/Progress/CircularLoader';
import { ReactComponent as Mail } from 'icons/Mail.svg';
import { ErrorMessage } from 'pages/auth/ErrorMessage';
import { handleChangeAndTrim } from 'utils/formHelpers';

type ResetRequestValues = {
  email: string;
};

type ResetRequestFormProps = {
  formik: FormikContextType<ResetRequestValues>;
  isError: boolean;
  isLoading: boolean;
};

export const ResetRequestForm = ({
  formik,
  isError,
  isLoading,
}: ResetRequestFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.heading} variant="h2">
        {t('forgot-password.reset-password')}
      </Typography>
      <Typography className={classes.message}>
        {t('forgot-password.enter-email')}
      </Typography>
      {isError && <ErrorMessage message={t('forgot-password.error.generic')} />}
      <TextFieldWithIcon
        name="email"
        id="email"
        label={t('email')}
        className={classes.emailInput}
        icon={<Mail />}
        value={formik.values.email}
        onChange={(event) => handleChangeAndTrim(event, formik)}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors.email && formik.touched.email)}
        helperText={
          formik.errors.email && formik.touched.email
            ? formik.errors.email
            : null
        }
      />
      <ButtonBase
        onClick={formik.handleSubmit}
        className={classes.submitButton}
      >
        {isLoading ? <CircularLoader color="primary" /> : t('send')}
      </ButtonBase>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  message: {
    margin: theme.spacing(3, 0, 3, 0),
    textAlign: 'center',
  },
  emailInput: {
    margin: theme.spacing(0, 0, 3, 0),
    width: '327px',
  },
  submitButton: {
    margin: theme.spacing(3, 0, 3, 0),
  },
}));
