import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ColorIndicator } from 'components/Other/ColorIndicator';
import { colorEnum } from 'utils/matchSleepScoreColor';
import { CustomTooltip } from 'components/Tooltip/CustomTooltip';
import { TooltipContent } from 'pages/examinations/examinationsList/TooltipContent';
import { TooltipIconBase } from 'components/Tooltip/TooltipIconBase';

type SummaryCircleChartLegendProps = {
  data: {
    label: string;
    value: number | undefined;
    range: number[] | undefined;
    unit: string;
  };
};

export const SummaryCircleChartLegend = ({
  data,
}: SummaryCircleChartLegendProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const colorIndicators = [
    colorEnum.GREEN,
    colorEnum.YELLOW,
    colorEnum.ORANGE,
    colorEnum.RED,
  ];

  const containsFullRange = data.range && data.range.length === 5;
  const rangeWithoutUpperBoundary = data.label === 'average-ahi';

  const tooltipData = {
    name: data.label,
    tooltip: {
      text: t(`examinations.${data.label}-tooltip`),
      range: data.range,
    },
  };

  if (!data.range) {
    return null;
  }

  return (
    <div>
      <div>
        <CustomTooltip
          title={<TooltipContent data={tooltipData} />}
          id={`${tooltipData.name}-tooltip`}
        >
          <div className={classes.content}>
            <Typography id={`${data.label}-legend-header`} variant="body1">
              {t(`examinations.${data.label}`)}
            </Typography>
            {tooltipData.tooltip.text && (
              <TooltipIconBase
                id={`${tooltipData.name}-tooltip-icon`}
                size={22}
                hasMargin
              />
            )}
          </div>
        </CustomTooltip>
      </div>
      {containsFullRange && (
        <>
          {colorIndicators.map((indicator, index) => {
            const colorRange = data.range!.slice(index, index + 2);
            return (
              <div
                key={`${data.label}-${indicator}`}
                className={classes.rangeLabel}
              >
                <ColorIndicator
                  id={`${data.label}-color-indicator-${indicator}`}
                  color={indicator}
                />
                {rangeWithoutUpperBoundary && indicator === colorEnum.RED ? (
                  <Typography
                    id={`${data.label}-color-${indicator}-text`}
                    variant="body2"
                  >{`${colorRange[0]}+`}</Typography>
                ) : (
                  <Typography
                    id={`${data.label}-color-${indicator}-text`}
                    variant="body2"
                  >{`${colorRange[0]}-${colorRange[1]}`}</Typography>
                )}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  rangeLabel: {
    marginTop: theme.spacing(0.25),
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
