import { Dispatch, SetStateAction } from 'react';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/core';
import { NextButton } from 'components/Button/NextButton';
import { PreviousButton } from 'components/Button/PreviousButton';
import { TimeFrames } from 'pages/examinations/ExaminationsSummaryPage';
import { ExaminationSimple, NavDirections } from 'commonTypes/examinationTypes';

type VisibleDates = {
  from: DateTime | undefined;
  to: DateTime | undefined;
};

type NavigationButtonsProps = {
  timeFrame: TimeFrames;
  dates: VisibleDates;
  setDates: Dispatch<SetStateAction<VisibleDates>>;
  currentExaminationsSlice: number[];
  setCurrentExaminationSlice: Dispatch<SetStateAction<number[]>>;
  checkIfNavDisabled: (direction: NavDirections) => boolean;
  setChartData: Dispatch<SetStateAction<ExaminationSimple[] | null>>;
};

export const NavigationButtons = ({
  timeFrame,
  dates,
  setDates,
  checkIfNavDisabled,
  currentExaminationsSlice,
  setCurrentExaminationSlice,
  setChartData,
}: NavigationButtonsProps) => {
  const classes = useStyles();

  const navigate = (direction: NavDirections) => {
    if (timeFrame === TimeFrames.LAST_RESULTS) {
      if (direction === NavDirections.NEXT) {
        setCurrentExaminationSlice([
          currentExaminationsSlice[0] - 14,
          currentExaminationsSlice[1] - 14,
        ]);
      } else {
        setCurrentExaminationSlice([
          currentExaminationsSlice[0] + 14,
          currentExaminationsSlice[1] + 14,
        ]);
      }
    } else {
      // clear chart data before the new data is calculated
      // without it the chart will show old values on new x axis for a while
      setChartData([]);
      if (!dates.from || !dates.to) {
        return;
      }
      let lastDate;
      let firstDate;

      if (direction === NavDirections.NEXT) {
        firstDate = dates.from.plus({ months: parseInt(timeFrame, 10) });
        lastDate = dates.to.plus({ months: parseInt(timeFrame, 10) });
      } else {
        firstDate = dates.from.minus({ months: parseInt(timeFrame, 10) });
        lastDate = dates.to.minus({ months: parseInt(timeFrame, 10) });
      }
      setDates({ from: firstDate, to: lastDate });
    }
  };

  return (
    <>
      <PreviousButton
        id="prev-examinations"
        onClick={() => navigate(NavDirections.PREV)}
        className={classes.previousButton}
        disabled={checkIfNavDisabled(NavDirections.PREV)}
      />
      <NextButton
        id="next-examinations"
        onClick={() => navigate(NavDirections.NEXT)}
        className={classes.nextButton}
        disabled={checkIfNavDisabled(NavDirections.NEXT)}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  previousButton: {
    marginRight: theme.spacing(1),
  },
  nextButton: {
    marginRight: theme.spacing(4),
  },
}));
