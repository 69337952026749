import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ColorIndicator } from 'components/Other/ColorIndicator';
import { colorEnum } from 'utils/matchSleepScoreColor';

export type TooltipContentData = {
  name: string;
  text?: string;
  sortable?: boolean;
  tooltip: {
    text: string;
    range?: number[];
  };
};

type TooltipContentProps = {
  data: TooltipContentData;
  multiple?: boolean;
  className?: string;
};

export const TooltipContent = ({
  data,
  multiple,
  className,
}: TooltipContentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const colorIndicators = [
    { color: colorEnum.GREEN, description: t('examinations.normal-range') },
    { color: colorEnum.YELLOW, description: t('examinations.mild-range') },
    { color: colorEnum.ORANGE, description: t('examinations.moderate-range') },
    { color: colorEnum.RED, description: t('examinations.severe-range') },
  ];

  const containsFullRange =
    data.tooltip.range && data.tooltip.range.length === 5;

  const rangeWithoutUpperBoundary =
    data.name === 'ahi' ||
    data.name === 'supine-ahi' ||
    data.name === 'average-ahi';

  return (
    <div
      className={multiple ? clsx(className, classes.wideTooltip) : undefined}
    >
      {multiple && (
        <Typography variant="body2">
          {t(`examinations.${data.name}`)}
        </Typography>
      )}
      <Typography variant="caption">{data.tooltip.text}</Typography>
      {containsFullRange && (
        <div id={`${data.name}-color-range`}>
          {colorIndicators.map((indicator, index) => {
            const colorRange = data.tooltip.range!.slice(index, index + 2);
            return (
              <div
                key={`${data.name}-${indicator.color}`}
                className={classes.rangeLabel}
              >
                <ColorIndicator
                  id={`${data.name}-color-indicator`}
                  color={indicator.color}
                />
                {rangeWithoutUpperBoundary &&
                indicator.color === colorEnum.RED ? (
                  <Typography variant="caption">{`> ${colorRange[0]} ${indicator.description}`}</Typography>
                ) : (
                  <Typography variant="caption">{`${colorRange[0]}-${colorRange[1]} ${indicator.description}`}</Typography>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

TooltipContent.defaultProps = {
  multiple: false,
  className: undefined,
};

const useStyles = makeStyles((theme) => ({
  rangeLabel: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  wideTooltip: {
    minWidth: '200px',
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}));
