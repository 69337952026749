import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { makeStyles, TextFieldProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextFieldWithIcon } from 'components/Input/TextFieldWithIcon';
import { ReactComponent as Tag } from 'icons/Tag.svg';
import { FilterNames, useFilters } from 'context/providers/FiltersProvider';

type ExamNumberInputProps = TextFieldProps & {
  value: string | undefined;
  onChangeValue: Dispatch<SetStateAction<string>>;
};

export const ExamNumberInput = ({
  value,
  onChangeValue,
  className,
}: ExamNumberInputProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setValidationError } = useFilters();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValidationError(false);
    const eventCopy = { ...event };
    eventCopy.target.value = eventCopy.target.value.trim();
    if (eventCopy.target.value.length === 1) {
      setValidationError(true);
    }
    if (eventCopy.target.value.length > 20) {
      return;
    }
    onChangeValue(eventCopy.target.value);
  };

  const isError = () => {
    const error = Boolean(value && value.length < 2);
    return error;
  };

  return (
    <TextFieldWithIcon
      id={FilterNames.EXAMNUMBER}
      label={t('examinations.examination-number')}
      icon={<Tag />}
      className={clsx(className, classes.numberInput)}
      onChange={(event) => handleChange(event)}
      value={value}
      error={isError()}
      helperText={isError() ? t('validation.invalid-exam-no') : null}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  numberInput: {
    width: '210px',
  },
}));
