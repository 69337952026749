import { useCallback, useState } from 'react';
import { DateTime } from 'luxon';
import { createXAxisTicks } from 'charts/LineCharts/utils';

export const useXAxisData = (
  examinationStart: string | undefined,
  examinationEnd: string | undefined
) => {
  const [xAxisDomain, setXAxisDomain] = useState<number[]>([]);
  const [xAxisTicks, setXAxisTicks] = useState<number[]>([]);

  const createXAxisData = useCallback(() => {
    if (examinationStart && examinationEnd) {
      const startTimestamp = DateTime.fromISO(examinationStart).toSeconds();

      const ticks = createXAxisTicks(examinationStart, examinationEnd);
      setXAxisDomain([startTimestamp, ticks[ticks.length - 1]]);
      setXAxisTicks(ticks);
    }
  }, [examinationStart, examinationEnd]);

  return { xAxisDomain, xAxisTicks, createXAxisData };
};
