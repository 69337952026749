import { makeStyles } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';
import clsx from 'clsx';
import { isInternalLink } from 'is-internal-link';

type CustomLinkProps = LinkProps & {
  label: string;
  onClick?: () => void;
  className?: string;
};

export const CustomLink = ({
  label,
  to,
  className,
  ...props
}: CustomLinkProps) => {
  const classes = useStyles();

  if (isInternalLink(to as string)) {
    return (
      <Link {...props} to={to} className={clsx(className, classes.linkText)}>
        {label}
      </Link>
    );
  }
  return (
    <a
      href={to as string}
      className={clsx(className, classes.linkText)}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      {label}
    </a>
  );
};

CustomLink.defaultProps = {
  onClick: undefined,
  className: undefined,
};

const useStyles = makeStyles((theme) => ({
  linkText: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    width: 'fit-content',
    textDecoration: 'none',
    display: 'block',
  },
}));
