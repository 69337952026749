import clsx from 'clsx';
import {
  makeStyles,
  Typography,
  Slider,
  SliderProps,
  Mark,
} from '@material-ui/core';
import { Dispatch, SetStateAction, ChangeEvent } from 'react';

type RangeSliderProps = Omit<SliderProps, 'marks'> & {
  label: string;
  labelUnit?: string;
  marks: Mark[];
  value: number[];
  onChangeValue: Dispatch<SetStateAction<number[]>>;
};

export const RangeSlider = ({
  marks,
  label,
  className,
  labelUnit,
  value,
  onChangeValue,
  id,
  ...props
}: RangeSliderProps) => {
  const classes = useStyles();
  const lastValueLabel = marks[marks.length - 1].label;
  const lastValue = marks[marks.length - 1].value;

  const handleChange = (
    event: ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    onChangeValue(newValue as number[]);
  };

  return (
    <div className={clsx(className, classes.container)}>
      <div className={classes.labelContainer}>
        <Typography noWrap variant="caption">
          {label}
        </Typography>
        <Typography
          noWrap
          variant="body1"
          className={classes.values}
          id={`${id}-label-values`}
        >{`${value[0]} - ${
          value[1] === lastValue ? lastValueLabel : value[1]
        }${labelUnit}`}</Typography>
      </div>
      <Slider
        classes={{
          rail: classes.rail,
          thumb: classes.thumb,
          mark: classes.mark,
          markActive: classes.markActive,
        }}
        id={id}
        name={id}
        color="secondary"
        value={value}
        // @ts-ignore
        onChange={handleChange}
        marks={marks}
        {...props}
      />
    </div>
  );
};

RangeSlider.defaultProps = {
  labelUnit: '',
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '210px',
  },
  rail: {
    color: theme.palette.grey[400],
  },
  thumb: {
    width: '20px',
    height: '20px',
    postion: 'relative',
    bottom: '4px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
    '&:hover, &active': {
      boxShadow: '0px 0px 0px 8px rgb(45 211 230 / 16%)',
    },
  },
  mark: {
    backgroundColor: theme.palette.grey[400],
    height: '4px',
    width: '2px',
    marginTop: -1,
  },
  markActive: {
    backgroundColor: theme.palette.secondary.main,
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  values: {
    color: theme.palette.grey[900],
    marginLeft: theme.spacing(1),
  },
}));
