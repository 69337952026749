import clsx from 'clsx';
import { Button, makeStyles } from '@material-ui/core';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';

type ButtonProps = Omit<ButtonBaseProps, 'onClick'> & {
  onClick: () => void;
};

export const ButtonPrimary = ({
  children,
  className,
  onClick,
  disabled,
  id,
}: ButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      className={clsx(className, classes.button)}
      onClick={onClick}
      id={id}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    height: '56px',
    minHeight: '48px',
  },
}));
