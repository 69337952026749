import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import { makeStyles } from '@material-ui/core';
import { TextFieldWithIcon } from 'components/Input/TextFieldWithIcon';
import { PasswordInput } from 'components/Input/PasswordInput';
import { ReactComponent as Mail } from 'icons/Mail.svg';
import { handleChangeAndTrim } from 'utils/formHelpers';

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  formik: FormikContextType<LoginValues>;
};

export const LoginForm = ({ formik }: LoginFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <TextFieldWithIcon
        name="email"
        id="email"
        label={t('email')}
        className={classes.smallMargin}
        icon={<Mail />}
        value={formik.values.email}
        onChange={(event) => handleChangeAndTrim(event, formik)}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors.email && formik.touched.email)}
        helperText={
          formik.errors.email && formik.touched.email
            ? formik.errors.email
            : null
        }
      />
      <PasswordInput
        className={classes.bigMargin}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors.password && formik.touched.password)}
        helperText={
          formik.errors.password && formik.touched.password
            ? formik.errors.password
            : t('password-helper')
        }
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  smallMargin: {
    marginBottom: theme.spacing(4),

    '@media (max-height:700px)': {
      marginBottom: theme.spacing(3),
    },
  },
  bigMargin: {
    marginBottom: theme.spacing(4),
  },
}));
