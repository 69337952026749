import { SetStateAction, useState, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core';
import { InfoDialog } from 'components/Dialog/InfoDialog';
import { ProfileConsentsForm } from 'components/Consents/ProfileConsentsForm';
import {
  processProfileAgreements,
  generateInitialProfileConsents,
} from 'components/Consents/helpers';
import { SubmitButton } from 'components/Button/SubmitButton';
import { CircularLoader } from 'components/Progress/CircularLoader';
import { setProfileConsents } from 'api/consent/consentApi';
import { profileConsentsValidationSchema } from 'components/Consents/consentsValidationSchema';
import { profileActionType } from 'commonTypes/profileTypes';
import { useAuth } from 'context/providers/AuthProvider';
import { ConsentNamesProfile } from 'components/Consents/ConsentNames';

type ConsentDialogProps = {
  open: boolean;
  setAcceptConsentsResult: Dispatch<
    SetStateAction<{ resolved: boolean; status: string }>
  >;
  profileId: string | undefined;
  profileConsents: ConsentNamesProfile[];
};

export const ProfileConsentDialog = ({
  open,
  setAcceptConsentsResult,
  profileId,
  profileConsents,
}: ConsentDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setActiveProfileId } = useAuth();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: generateInitialProfileConsents(profileConsents),
    onSubmit: (values) => {
      if (!profileId) return;
      setAcceptConsentsResult({ resolved: false, status: '' });
      setLoading(true);
      const apiValues = processProfileAgreements(values);
      setProfileConsents(apiValues, profileId)
        .then(() => {
          setActiveProfileId(profileId);
          setTimeout(() => {
            setLoading(false);
          }, 300);
          setAcceptConsentsResult({ resolved: false, status: 'success' });
        })
        .catch(() => {
          setLoading(false);
          setAcceptConsentsResult({ resolved: true, status: 'error' });
        });
    },
    validationSchema: profileConsentsValidationSchema,
  });

  return (
    <InfoDialog
      open={open}
      title={t('consents.profile-title')}
      className={classes.dialog}
      id="profile-consents-dialog"
      contentClass={classes.content}
      // this dialog covers only a part of the app
      container={() => document.getElementById('scrollable-content')}
      BackdropProps={{ className: classes.backdrop }}
      style={{ position: 'absolute' }}
      disableEnforceFocus
    >
      <ProfileConsentsForm formik={formik} type={profileActionType.ADD} />
      <div className={classes.actions}>
        <SubmitButton
          onClick={formik.handleSubmit}
          disabled={!formik.dirty || !formik.isValid}
          isLoading={loading}
          id="submit-button"
        >
          {loading ? <CircularLoader /> : t('confirm')}
        </SubmitButton>
      </div>
    </InfoDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: '650px',
  },
  actions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  content: {
    paddingTop: theme.spacing(0),
  },
  backdrop: {
    position: 'absolute',
    backgroundColor: theme.palette.text.disabled,
    borderRadius: '8px',
  },
}));
