import { ReactNode } from 'react';
import { makeStyles, IconButton, IconButtonProps } from '@material-ui/core';
import clsx from 'clsx';

type CustomIconButtonProps = IconButtonProps & {
  component?: ReactNode;
};

const CustomIconButton = ({
  children,
  onClick,
  className,
  ...props
}: CustomIconButtonProps) => {
  const classes = useStyles();

  return (
    <IconButton
      className={clsx(className, classes.iconButton)}
      onClick={onClick}
      {...props}
    >
      {children}
    </IconButton>
  );
};

CustomIconButton.defaultProps = {
  component: undefined,
};

const useStyles = makeStyles((theme) => ({
  iconButton: {
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[1],
    borderRadius: '8px',

    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.divider,
      boxShadow: 'none',
    },
  },
}));

export { CustomIconButton as IconButton };
