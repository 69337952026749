import { useState, ChangeEvent } from 'react';
import { DialogProps, Typography, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PasswordInput } from 'components/Input/PasswordInput';
import { InfoDialog } from 'components/Dialog/InfoDialog';
import { ButtonBase } from 'components/Button/ButtonBase';
import { CustomLink } from 'components/Link/Link';
import { ErrorMessage } from 'pages/auth/ErrorMessage';
import { CircularLoader } from 'components/Progress/CircularLoader';
import { passwordValidationSchema } from 'pages/auth/resetPassword/resetPasswordValidationSchema';
import { resetPassword } from 'api/auth/authApi';
import { useAuth } from 'context/providers/AuthProvider';

type ResetPasswordDialogProps = DialogProps & {
  userIdParam: string;
  code: string;
};

export const ResetPasswordDialog = ({
  open,
  userIdParam,
  code,
}: ResetPasswordDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { login } = useAuth();
  const history = useHistory();
  const [password, setPassword] = useState('');

  const resetPasswordMutation = useMutation(resetPassword, {
    onError: (error) => {
      formik.resetForm();
    },
    onSuccess: (userData) => {
      history.push('/');
      login(userData);
    },
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    onSubmit: (values) => {
      const resetPasswordData = {
        password: values.password,
        resetCode: code,
        userId: userIdParam,
      };
      resetPasswordMutation.mutate(resetPasswordData);
    },
    validationSchema: passwordValidationSchema(password),
  });

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    formik.handleChange(event);
  };

  return (
    <InfoDialog open={open}>
      <Typography className={classes.heading} variant="h2">
        {t('forgot-password.reset-password')}
      </Typography>
      <Typography className={classes.message}>
        {t('forgot-password.set-new-password')}
      </Typography>
      {resetPasswordMutation.isError && (
        <ErrorMessage message={t('forgot-password.error.invalid-link')} />
      )}
      <PasswordInput
        className={classes.input}
        value={formik.values.password}
        onChange={handlePasswordChange}
        onBlur={formik.handleBlur}
        error={formik.errors.password && formik.touched.password}
        helperText={
          formik.errors.password && formik.touched.password
            ? formik.errors.password
            : null
        }
      />
      <PasswordInput
        className={classes.input}
        name="passwordConfirm"
        id="passwordConfirm"
        label={t('forgot-password.repeat-password')}
        value={formik.values.passwordConfirm}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.passwordConfirm && formik.touched.passwordConfirm}
        helperText={
          formik.errors.passwordConfirm && formik.touched.passwordConfirm
            ? formik.errors.passwordConfirm
            : null
        }
      />
      <ButtonBase className={classes.button} onClick={formik.handleSubmit}>
        {resetPasswordMutation.isLoading ? (
          <CircularLoader color="primary" />
        ) : (
          t('send')
        )}
      </ButtonBase>
      <CustomLink label={t('back-to-login')} to="/" />
    </InfoDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    margin: theme.spacing(4, 0, 2, 0),
  },
  message: {
    margin: theme.spacing(2, 0, 2, 0),
    textAlign: 'center',
  },
  input: {
    margin: theme.spacing(3, 0, 3, 0),
    width: '327px',
  },
  button: {
    margin: theme.spacing(4, 0, 4, 0),
  },
}));
