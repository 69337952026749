import { Dispatch, SetStateAction } from 'react';
import { RangeSlider } from 'components/Slider/RangeSlider';
import { useTranslation } from 'react-i18next';
import { SliderProps } from '@material-ui/core';
import { siMarks } from 'pages/examinations/examinationsList/examTableActions/filters/sliderMarks';

type SISliderProps = Omit<SliderProps, 'value'> & {
  value: number[];
  onChangeValue: Dispatch<SetStateAction<number[]>>;
};

export const SISlider = ({ value, marks, ...props }: SISliderProps) => {
  const { t } = useTranslation();

  return (
    <RangeSlider
      id="si-slider"
      label={t('examinations.snoring-index-label')}
      marks={siMarks}
      labelUnit="%"
      max={100}
      value={value}
      {...props}
    />
  );
};
