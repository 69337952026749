import { Dispatch, SetStateAction } from 'react';

// eslint-disable-next-line
export enum AcceptedFilesBatchExtensions {
  JSN = 'JSN',
  ADR = 'ADR',
  PA2 = 'PA2',
  DAC = 'DAC',
  E8H = 'E8H',
  WAV = 'WAV',
}

export type AcceptedFiles = {
  [key in AcceptedFilesBatchExtensions]: processedFile;
};

export type processedFile = {
  name: string | undefined;
  extension: AcceptedFilesBatchExtensions | undefined;
  file: File | undefined;
};

type FileValidationResult = {
  loadedFiles: processedFile[];
  missingFiles: AcceptedFilesBatchExtensions[];
  fileNamesValid: boolean;
  updatedFilesState: AcceptedFiles;
};

export const validateFiles = (
  acceptedFiles: File[],
  filesState: AcceptedFiles,
  setFilesState: Dispatch<SetStateAction<AcceptedFiles>>
): FileValidationResult => {
  const preparedFiles = prepareForProcessing(acceptedFiles);

  const updatedFilesState = updateRequiredFiles(
    preparedFiles,
    filesState,
    setFilesState
  );

  const [loadedFiles, missingFiles] = checkRequiredFiles(updatedFilesState);

  const fileNamesValid = checkFileNames(loadedFiles);

  return { loadedFiles, missingFiles, fileNamesValid, updatedFilesState };
};

const prepareForProcessing = (files: File[]) => {
  const processedFiles: processedFile[] = [];

  files.forEach((file: File) => {
    const splitted = file.name.split('.');
    if (splitted.length > 1) {
      const fileExtension = splitted.pop()!.toUpperCase();
      if (fileExtension in AcceptedFilesBatchExtensions) {
        const fileName = splitted.join('.');
        processedFiles.push({
          name: fileName,
          extension: fileExtension as AcceptedFilesBatchExtensions,
          file,
        });
      }
    }
  });
  return processedFiles;
};

export const updateRequiredFiles = (
  processedFiles: processedFile[],
  filesState: AcceptedFiles,
  setFilesState: Dispatch<SetStateAction<AcceptedFiles>>
): AcceptedFiles => {
  const filesCopy = { ...filesState };

  processedFiles.forEach((file) => {
    if (file.file && file.file.name.toUpperCase() === 'SENSOR.JSN') {
      return;
    }

    if (file.extension && file.extension in filesCopy) {
      filesCopy[file.extension].file = file.file;
      filesCopy[file.extension].name = file.name;
      filesCopy[file.extension].extension = file.extension;
    }
  });

  setFilesState(filesCopy);

  return filesCopy;
};

const checkRequiredFiles = (
  updatedFilesState: AcceptedFiles
): [processedFile[], AcceptedFilesBatchExtensions[]] => {
  const requiredFiles = [
    AcceptedFilesBatchExtensions.JSN,
    AcceptedFilesBatchExtensions.ADR,
    AcceptedFilesBatchExtensions.DAC,
    AcceptedFilesBatchExtensions.E8H,
    AcceptedFilesBatchExtensions.PA2,
  ];
  const missingFiles: AcceptedFilesBatchExtensions[] = [];

  requiredFiles.forEach((extension) => {
    if (updatedFilesState[extension].file === undefined) {
      missingFiles.push(extension);
    }
  });

  const loadedFiles: processedFile[] = Object.values(updatedFilesState).filter(
    (value): value is processedFile => {
      return value.file !== undefined;
    }
  );

  return [loadedFiles, missingFiles];
};

export const checkFileNames = (files: processedFile[]) => {
  const referenceFile = files[0];
  let valid = true;

  files.forEach((file) => {
    if (file.name !== referenceFile.name) {
      valid = false;
    }
  });
  return valid;
};
