import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Warning } from 'icons/Warning.svg';

type MissingFilesInfoProps = {
  missingExtensions: string[];
};

export const MissingFilesInfo = ({
  missingExtensions,
}: MissingFilesInfoProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.missingExtensions} id="missing-files-info">
      <Warning />
      <Typography variant="body2" className={classes.missingFilesInfo}>
        {t('examinations.validation.missing-files-info')}
      </Typography>
      {missingExtensions.map((extension: string) => {
        return (
          <Typography
            key={extension}
            id={extension}
            variant="body2"
            className={classes.extension}
          >{`.${extension}`}</Typography>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  missingFilesInfo: {
    margin: theme.spacing(0, 1, 0, 1),
  },
  missingExtensions: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  extension: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));
