import { useState } from 'react';
import { makeStyles, List, Slide, Divider } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { ProfileItem } from 'pages/profiles/manageProfiles/ProfileItem';
import { ProfileSimple } from 'commonTypes/authTypes';
import { SearchBox } from 'pages/profiles/manageProfiles/SearchBox';
import { AddProfileButton } from 'pages/profiles/manageProfiles/AddNextProfileButton';
import { NoResultsItem } from 'pages/profiles/manageProfiles/NoResultsItem';
import { useProfiles } from 'context/providers/ProfilesProvider';

export const ProfilesList = () => {
  const classes = useStyles();
  const { profiles } = useProfiles();
  const [noResults, setNoResults] = useState(false);

  return (
    <>
      <Slide in direction="right" timeout={300} mountOnEnter unmountOnExit>
        <div className={classes.container} id="profiles-list">
          <div className={classes.content}>
            <div className={classes.actions}>
              <SearchBox setNoResults={setNoResults} />
              <AddProfileButton />
            </div>
            <div className={classes.profileList}>
              <List component="nav">
                {!isEmpty(profiles) &&
                  profiles.map((profile: ProfileSimple) => {
                    return (
                      <ProfileItem profile={profile} key={profile.profileId} />
                    );
                  })}
                {noResults && <NoResultsItem />}
              </List>
            </div>
          </div>
          <Divider className={classes.rightBorder} orientation="vertical" />
        </div>
      </Slide>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  profileList: {
    maxWidth: 288,
    width: 288,
    backgroundColor: theme.palette.background.default,
    overflowY: 'auto',
    overflowX: 'hidden',
    flexGrow: 1,
  },
  divider: {
    marginTop: theme.spacing(3),
    height: '100%',
  },
  container: {
    display: 'flex',
    marginTop: theme.spacing(3),
    height: 'calc(100vh - 24px)',
  },
  rightBorder: {
    height: 'calc(100% - 24px)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
  },
}));
