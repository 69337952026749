import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';
import { formatDurationInSeconds } from 'utils/helpers';
import { ReactComponent as TooltipIcon } from 'icons/Tooltip.svg';
import { CustomTooltip } from 'components/Tooltip/CustomTooltip';
import {
  TooltipContent,
  TooltipContentData,
} from 'pages/examinations/examinationsList/TooltipContent';

// eslint-disable-next-line
export enum DetailsItemType {
  NUMBER = 'NUMBER',
  PERCENT = 'PERCENT',
  TIME = 'TIME',
}

type DetailsItemProps = {
  id: string;
  label: TooltipContentData;
  value: number | null | undefined;
  type: DetailsItemType;
};

export const DetailsItem = ({ label, value, type, id }: DetailsItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.dataItem}>
      <CustomTooltip
        id={`${label.name}-tooltip`}
        title={label.tooltip.text === '' ? '' : <TooltipContent data={label} />}
      >
        <TooltipIcon
          className={classes.icon}
          id={`${label.name}-tooltip-icon`}
        />
      </CustomTooltip>
      <div className={classes.textContent}>
        <Typography variant="caption" className={classes.label}>
          {t(`examinations.${label.name}`)}
        </Typography>
        <Typography variant="caption" className={classes.value} id={id}>
          {value !== undefined && value !== null && (
            <>
              {type === DetailsItemType.NUMBER && round(value, 1)}
              {type === DetailsItemType.PERCENT && `${round(value, 1)}%`}
              {type === DetailsItemType.TIME && formatDurationInSeconds(value)}
            </>
          )}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dataItem: {
    display: 'flex',
    alignItems: 'center',
    height: '28px',
    borderRadius: '4px',

    '&:nth-child(even)': {
      backgroundColor: theme.palette.grey[800],
    },
  },
  label: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[700],
  },
  value: {
    marginRight: theme.spacing(3),
    color: theme.palette.text.primary,
  },
  textContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    marginLeft: theme.spacing(1),
    width: '18px',
    height: '18px',
  },
}));
