import { FocusEvent, ChangeEvent } from 'react';
import { FormikContextType } from 'formik';
import { capitalize } from 'utils/helpers';

export const capitalizeOnChange = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  formik: FormikContextType<any>
) => {
  const eventCopy = { ...event };
  eventCopy.target.value = capitalize(event.target.value);
  formik.handleChange(eventCopy);
};

export const handleBlurAndTrim = (
  event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
  formik: FormikContextType<any>
) => {
  const eventCopy = { ...event };
  eventCopy.target.value = eventCopy.target.value.trim();
  formik.handleBlur(eventCopy);
  formik.handleChange(eventCopy);
};

export const handleChangeAndTrim = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  formik: FormikContextType<any>
) => {
  const eventCopy = { ...event };
  eventCopy.target.value = eventCopy.target.value.trim();
  formik.handleChange(eventCopy);
};

export const weightHeightHandleChange = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
) => {
  // allow only digits
  const eventCopy = { ...event };
  eventCopy.target.value = event.target.value.replace(/[^0-9]+/g, '');

  if (event.currentTarget.value.length > 3) {
    return;
  }
  handleChange(eventCopy);
};

export const phoneNoHandleChange = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
) => {
  // allow only digits and '+' sign
  const eventCopy = { ...event };
  eventCopy.target.value = event.target.value.replace(/[^0-9,+]+/g, '');

  if (event.currentTarget.value.length > 20) {
    return;
  }
  handleChange(eventCopy);
};

export const phoneNoHandleBlur = (
  event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
  formik: FormikContextType<any>
) => {
  if (event.target.value.length <= 3) {
    formik.setFieldValue('phoneNumber', '');
  }
  formik.handleBlur(event);
};

export const phoneNoHandleFocus = (formik: FormikContextType<any>) => {
  if (formik.values.phoneNumber === '') {
    formik.setFieldValue('phoneNumber', '+48');
  }
};

// React doesn't accept null as input value
export const emptyStringToNull = (values: any) => {
  const valuesCopy = { ...values };

  Object.keys(values).forEach((key) => {
    if (values[key] === '') {
      valuesCopy[key] = null;
    }
  });
  return valuesCopy;
};
