// eslint-disable-next-line
export enum GenderEnum {
  male = 'MALE',
  female = 'FEMALE',
}

export type Gender = GenderEnum | '';

// eslint-disable-next-line
export enum profileActionType {
  EDIT = 'EDIT',
  ADD = 'ADD',
}
