import { Switch, Route, Redirect } from 'react-router-dom';
import { commonRoutes } from 'config/commonRoutes';
import ExaminationsListPage from 'pages/examinations/ExaminationsListPage';
import ExaminationPage from 'pages/examinations/ExaminationPage';
import ExaminationsSummaryPage from 'pages/examinations/ExaminationsSummaryPage';
import SensorsPage from 'pages/sensors/SensorsPage';
import AccountPage from 'pages/account/AccountPage';
import HelpPage from 'pages/help/HelpPage';
import TermOfUsePage from 'pages/account/TermsOfUsePage';
import { ExaminationsProvider } from 'context/providers/ExaminationsProvider';
import { FiltersProvider } from 'context/providers/FiltersProvider';
import {
  ActiveProfileWrapper,
  RoutesWithNav,
  ConsentsGuard,
} from 'AppWrappers';

export const AuthenticatedApp = () => {
  return (
    <Switch>
      {commonRoutes.map((route) => {
        return <Route {...route} key={route.path} />;
      })}
      <Redirect from="/register" to="/" />
      <ConsentsGuard>
        <RoutesWithNav>
          <Route
            exact
            path={['/examination/:examinationId', '/', '/examinations-list']}
            render={() => (
              <ExaminationsProvider>
                <FiltersProvider>
                  <ActiveProfileWrapper>
                    <Switch>
                      <Route
                        path="/examination/:examinationId"
                        component={ExaminationPage}
                      />
                      <Route
                        exact
                        path="/examinations-list"
                        component={ExaminationsListPage}
                      />
                      <Route
                        exact
                        path="/"
                        component={ExaminationsSummaryPage}
                      />
                    </Switch>
                  </ActiveProfileWrapper>
                </FiltersProvider>
              </ExaminationsProvider>
            )}
          />
          <Route path="/help" component={HelpPage} />
          <Route exact path="/account" component={AccountPage} />
          <Route path="/account/terms-of-use" component={TermOfUsePage} />
          <Route path="/sensors" component={SensorsPage} />
        </RoutesWithNav>
      </ConsentsGuard>
    </Switch>
  );
};
