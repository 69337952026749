import { UserGroupEnum } from 'commonTypes/authTypes';

export type RegisterValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: UserGroupEnum;
  termsConditionsAgreement: boolean;
  dataProcessingAgreement: boolean;
  marketingAgreement: boolean;
  contactAgreement: boolean;
  entrustingAgreement?: boolean;
};

export const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  role: UserGroupEnum.BASIC,
  termsConditionsAgreement: false,
  dataProcessingAgreement: false,
  marketingAgreement: false,
  contactAgreement: false,
};
