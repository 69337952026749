import { Chip, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { ReactComponent as Close } from 'icons/Close.svg';
import { RangeFilter } from 'pages/examinations/examinationsList/examTableActions/filters/RangeFilter';
import { useFilters, FilterOperands } from 'context/providers/FiltersProvider';
import { SingleValueFilter } from 'pages/examinations/examinationsList/examTableActions/filters/SingleValueFilter';
import { DateFilter } from 'pages/examinations/examinationsList/examTableActions/filters/DateFilter';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type FilterChipProps = {
  filter: RangeFilter | SingleValueFilter | DateFilter;
  id: string;
};

export const FilterChip = ({ filter, id }: FilterChipProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleFiltersApply } = useFilters();

  const handleDelete = () => {
    filter.clear();
    handleFiltersApply([filter]);
  };

  const calculateContent = () => {
    if (filter instanceof RangeFilter) {
      const { label, rawValue } = filter;

      const [firstMark, lastMark] = filter.marks;

      if (rawValue[0] === firstMark) {
        return `${label.text} ${t('to')} ${rawValue[1]}${label.unit}`;
      }
      if (rawValue[1] === lastMark) {
        return `${label.text} ${t('from')} ${rawValue[0]}${label.unit}`;
      }
      return `${label.text} ${rawValue[0]} - ${rawValue[1]}${label.unit}`;
    }

    if (filter instanceof SingleValueFilter) {
      const { label, rawValue } = filter;

      return `${label.text} ${rawValue}`;
    }

    if (filter instanceof DateFilter) {
      const { label, rawValue } = filter;

      const processDate = (date: MaterialUiPickersDate) => {
        const isoDate = filter.processValue(date, FilterOperands.LTE);
        if (!isoDate) return;
        return DateTime.fromISO(isoDate).toFormat('dd/LL/yyyy');
      };

      if (rawValue.low && rawValue.high) {
        return `${label.text} ${processDate(rawValue.low)} - ${processDate(
          rawValue.high
        )}${label.unit}`;
      }
      if (rawValue.high) {
        return `${label.text} ${t('to')} ${processDate(rawValue.high)}${
          label.unit
        }`;
      }
      if (rawValue.low) {
        return `${label.text} ${t('from')} ${processDate(rawValue.low)}${
          label.unit
        }`;
      }
    }
  };

  if (!filter.rawValue) {
    return null;
  }

  return (
    <Chip
      id={id}
      className={classes.chip}
      label={calculateContent()}
      size="small"
      variant="outlined"
      onDelete={handleDelete}
      deleteIcon={
        <Close className={classes.icon} id={`${filter.name}-delete`} />
      }
    />
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '12px',
    height: '20px',
    marginLeft: theme.spacing(0.25),
  },
  chip: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.primary.background,
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
}));
