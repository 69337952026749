import { makeStyles, Typography } from '@material-ui/core';

type SensorDataItemProps = {
  item: { label: string; data: string };
  id: string;
};

export const SensorDataItem = ({ item, id }: SensorDataItemProps) => {
  const classes = useStyles();
  const { label, data } = item;

  return (
    <div className={classes.dataItem} id={`${id}-${label}`}>
      <Typography variant="caption">{label}</Typography>
      <Typography variant="button">{data}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dataItem: {
    width: '140px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),

    '&:last-child': {
      width: '180px',
      marginRight: theme.spacing(3),
    },
  },
}));
