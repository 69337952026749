// eslint-disable-next-line
export enum ConsentNamesUser {
  generalTerms = 'general_terms_user',
  privacyPolicy = 'privacy_policy_user',
  termsApplication = 'terms_application_user',
  termsProduct = 'terms_product_user',
  entrustingAgreement = 'entrusting_agreement',
  GDPRAccount = 'GDPR_account',
  healthClause = 'health_clause_user',
  marketingClause = 'marketing_clause',
  SMSClause = 'sms_clause',
}

// eslint-disable-next-line
export enum ConsentNamesProfile {
  generalTerms = 'general_terms_profile',
  privacyPolicy = 'privacy_policy_profile',
  termsApplication = 'terms_application_profile',
  termsProduct = 'terms_product_profile',
  healthClause = 'health_clause_profile',
  GDPRProfile = 'GDPR_profile',
}
