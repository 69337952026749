import { Mark } from '@material-ui/core';

export const calcMarksRange = (marks: any) => {
  return [marks[0].value, marks[marks.length - 1].value];
};

export const sleepTimeMarks: Mark[] = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 14,
    label: '14+',
  },
];

export const ahiMarks: Mark[] = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 30,
    label: '30+',
  },
];

export const siMarks: Mark[] = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 75,
    label: '75',
  },
  {
    value: 100,
    label: '100',
  },
];
