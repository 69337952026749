import { DateTime } from 'luxon';
import { ProfileFormValues } from 'pages/profiles/ProfileForm';
import { processProfileAgreements } from 'components/Consents/helpers';
import { doNotHavePesel } from 'utils/constants';
import { emptyStringToNull } from 'utils/formHelpers';
import { User } from 'commonTypes/authTypes';
import { createProfileDTO, editProfileValues } from 'api/profiles/profileDTOs';
import { profileActionType } from 'commonTypes/profileTypes';

export const processProfileValues = (
  values: ProfileFormValues,
  type: profileActionType,
  isBasic: boolean,
  isDoctor: boolean,
  user?: User
): createProfileDTO | editProfileValues => {
  const consentsArray = processProfileAgreements(values);
  const formattedValues = emptyStringToNull(values);

  const apiValues: createProfileDTO | editProfileValues = {
    firstName: formattedValues.firstName,
    lastName: formattedValues.lastName,
    govIdentification: formattedValues.noPesel
      ? doNotHavePesel
      : formattedValues.govIdentification,
    dateOfBirth:
      typeof formattedValues.dateOfBirth !== 'string' &&
      formattedValues.dateOfBirth
        ? DateTime.fromHTTP(formattedValues.dateOfBirth.toString()).toISODate()
        : formattedValues.dateOfBirth,
    height: formattedValues.height,
    weight: formattedValues.weight,
    gender: formattedValues.gender,
  };

  if (isBasic) {
    apiValues.consents = consentsArray;
  }

  if (isDoctor) {
    apiValues.phoneNumber = formattedValues.phoneNumber;
  }

  if (type === profileActionType.ADD) {
    apiValues.accountId = user!.id;
  }

  return apiValues;
};
