import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  getExaminationById,
  getChartData,
} from 'api/examinations/examinationsApi';
import { useAuth } from 'context/providers/AuthProvider';
import { DetailsPanel } from 'pages/examinations/examinationDetails/DatailsPanel';
import { ExaminationFiles } from 'pages/examinations/examinationDetails/ExaminationFiles';
import { ExaminationData, ExamStatuses } from 'commonTypes/examinationTypes';
import { ChartsData } from 'charts/chartTypes/chartTypes';
import { LineCharts } from 'pages/examinations/examinationDetails/LineCharts';
import { CircleCharts } from 'pages/examinations/examinationDetails/CircleCharts';
import {
  initialExamData,
  initialSleepScore,
} from 'pages/examinations/examinationDetails/examInitialData';
import { ReactComponent as Warning } from 'icons/Warning.svg';

const ExaminationPage = () => {
  const { examinationId } = useParams<{ examinationId: string }>();
  const { activeProfile, user } = useAuth();
  const classes = useStyles();
  const { t } = useTranslation();
  const [examinationData, setExaminationData] = useState<ExaminationData>(
    initialExamData
  );
  const [chartsData, setChartData] = useState<ChartsData | null>(null);
  const examStatus = examinationData.examinationStatus.examinationStatus;

  const fetchExamination = useCallback(() => {
    if (activeProfile?.profileId) {
      getExaminationById(activeProfile.profileId, examinationId)
        .then((data) => {
          if (data.sleepScore) {
            setExaminationData(data);
          } else {
            const dataCopy = { ...data };
            dataCopy.sleepScore = initialSleepScore;
            setExaminationData(dataCopy);
          }
        })
        .catch(() => {
          setExaminationData(initialExamData);
        });
    }
  }, [
    activeProfile?.profileId,
    activeProfile?.hasConsents,
    user?.requiredConsents,
    examinationId,
  ]);

  const fetchChartData = useCallback(() => {
    getChartData(examinationId)
      .then((data) => {
        setChartData(data);
      })
      .catch(() => {
        setChartData(null);
      });
  }, [examinationId]);

  useEffect(() => {
    fetchExamination();
    fetchChartData();
  }, [fetchExamination, fetchChartData, examinationId]);

  const duration = {
    start: examinationData.startDateTime,
    end: examinationData.endDateTime,
  };

  return (
    <>
      <DetailsPanel
        sleepScore={examinationData.sleepScore}
        naturalId={examinationData.examinationNaturalId}
        examinationId={examinationData.examinationId}
        isOwner={examinationData.isExaminationOwner}
      />
      <CircleCharts
        sleepScoreMetrics={examinationData.sleepScore}
        className={classes.circleCharts}
      />
      {examStatus === ExamStatuses.INCOMPLETE && (
        <div className={classes.infoContainer}>
          <Warning className={classes.warningIcon} />
          <Typography variant="body2">{t('charts.incomplete-data')}</Typography>
        </div>
      )}
      <LineCharts chartsData={chartsData} duration={duration} />
      {examinationData.examinationId && (
        <ExaminationFiles
          files={examinationData.examinationFiles}
          examinationId={examinationData.examinationId}
          fetchExamination={fetchExamination}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  circleCharts: {
    '@media (min-width:1500px)': {
      display: 'none',
    },
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  warningIcon: {
    marginRight: theme.spacing(1),
    width: '18px',
    height: '18px',
  },
}));

export default ExaminationPage;
