import { makeStyles, Typography } from '@material-ui/core';
import { round } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ProfileSimple } from 'commonTypes/authTypes';
import { calculateAge } from 'utils/helpers';
import { GenderEnum } from 'commonTypes/profileTypes';
import { ReactComponent as Male } from 'icons/GreyMale.svg';
import { ReactComponent as Female } from 'icons/GreyFemale.svg';
import { useConfig } from 'context/providers/ConfigProvider';
import { ValueColorIndicator } from 'pages/examinations/examinationsList/ValueColorIndicator';
import { ReactComponent as SensorWithBackground } from 'icons/SensorWithBackground.svg';

type SecondRowProps = {
  profile: ProfileSimple;
};

export const SecondRow = ({ profile }: SecondRowProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { colorRanges } = useConfig();

  const {
    bmi,
    gender,
    resultOfLastExamination,
    dateOfBirth,
    hasLoanedSensor,
    profileId,
  } = profile;

  return (
    <div className={classes.secondRow}>
      <Typography noWrap variant="caption" className={classes.captionText}>
        {`${t('profiles.bmi')} `}
        {bmi}
      </Typography>
      <Typography noWrap variant="caption" className={classes.captionText}>
        {calculateAge(dateOfBirth)}
        {` ${t('profiles.years-old')}`}
      </Typography>
      {gender === GenderEnum.male ? (
        <Male className={classes.genderIcon} id={`${profileId}-gender`} />
      ) : (
        <Female className={classes.genderIcon} id={`${profileId}-gender`} />
      )}
      {colorRanges.sleep_score && resultOfLastExamination && (
        <ValueColorIndicator
          id="sleep-score-color-indicator"
          value={round(resultOfLastExamination, 1)}
          range={colorRanges.sleep_score}
        />
      )}
      {hasLoanedSensor && <SensorWithBackground className={classes.sensor} />}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  secondRow: {
    color: theme.palette.text.secondary,
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
  },
  captionText: {
    marginRight: theme.spacing(1),
  },
  genderIcon: {
    width: '16px',
    height: '14px',
    margin: theme.spacing(0, 1.5, 0, 0),
  },
  sensor: {
    width: '16px',
    height: '16px',
    minWidth: '16px',
    minHeight: '16px',
    margin: '0 0 0 auto',
    alignSelf: 'center',
  },
}));
