import { Dispatch, SetStateAction } from 'react';
import { isEmpty } from 'lodash';
import {
  ValueTypes,
  FilterNames,
  FilterOperands,
} from 'context/providers/FiltersProvider';

export class SingleValueFilter {
  name: FilterNames;
  valueType: ValueTypes;
  rawValue: string | undefined;
  setValue: Dispatch<SetStateAction<string>>;
  label: { text: string; unit: string };

  constructor(
    name: FilterNames,
    valueType: ValueTypes,
    rawValue: string | undefined,
    setValue: Dispatch<SetStateAction<string>>,
    label: { text: string; unit: string }
  ) {
    this.name = name;
    this.valueType = valueType;
    this.rawValue = rawValue;
    this.setValue = setValue;
    this.label = label;
  }

  shouldSkip = () => {
    return !this.rawValue || isEmpty(this.rawValue);
  };

  clear = () => {
    this.setValue('');
  };

  toApiFilter = () => {
    return [
      {
        field: this.name,
        operand: FilterOperands.LIKE,
        value: this.rawValue,
      },
    ];
  };
}
