import { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AddExaminationButton } from 'pages/examinations/examinationUpload/AddExaminationButton';
import { AddExaminationDialog } from 'pages/examinations/examinationUpload/AddExaminationDialog';

export const AddFirstExamination = () => {
  const [addExaminationDialogOpen, setAddExaminationDialogOpen] = useState(
    false
  );
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Typography variant="h3" className={classes.introText}>
        {t('examinations.first-add')}
      </Typography>
      <AddExaminationButton
        setDialogOpen={setAddExaminationDialogOpen}
        id="add-first-examination-button"
      />
      <AddExaminationDialog
        open={addExaminationDialogOpen}
        onClose={() => setAddExaminationDialogOpen(false)}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  introText: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
}));
