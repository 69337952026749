export type GoogleAuthError = {
  error: string;
};

export const googleAuthErrors = {
  googlePopupClosed: {
    id: 'popup_closed_by_user',
    message: 'google-popup-closed',
  },
  initializationFailed: {
    id: 'idpiframe_initialization_failed',
    message: '',
  },
};
