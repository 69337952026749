import { XAxisProps } from 'recharts';
import { DateTime } from 'luxon';
import { toRoman } from 'roman-numerals';

type CustomXAxisTickProps = XAxisProps & {
  payload: { value: string };
  index: number;
};

export const CustomXAxisTick = (props: CustomXAxisTickProps) => {
  const { x, y, fill, payload, index } = props;

  const date = DateTime.fromMillis(parseInt(payload.value, 10));

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill={fill}>
        <tspan textAnchor="middle" x="0" id={`${index}-xaxis-tick`}>
          {`${date.day} ${toRoman(date.month)}`}
        </tspan>
        <tspan textAnchor="middle" x="0" dy="20">
          {date.year}
        </tspan>
      </text>
    </g>
  );
};
