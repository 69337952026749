import { makeStyles, Tooltip, TooltipProps } from '@material-ui/core';
import clsx from 'clsx';

type customTooltipProps = TooltipProps & {
  multiple?: boolean;
};

export const CustomTooltip = ({
  title,
  children,
  multiple,
  className,
  ...props
}: customTooltipProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={title}
      classes={{
        tooltip: clsx(classes.tooltip, className, {
          [classes.wideTooltip]: multiple,
        }),
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

CustomTooltip.defaultProps = {
  multiple: false,
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(1.5),
    width: '250px',
  },
  wideTooltip: {
    minWidth: 'fit-content',
  },
}));
