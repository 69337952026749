import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBaseWithIcon } from 'components/Button/ButtonBaseWithIcon';
import { ReactComponent as Plus } from 'icons/Plus.svg';

type AddExaminationButtonProps = {
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  id: string;
};

export const AddExaminationButton = ({
  setDialogOpen,
  ...props
}: AddExaminationButtonProps) => {
  const { t } = useTranslation();

  return (
    <ButtonBaseWithIcon
      startIcon={<Plus />}
      onClick={() => setDialogOpen(true)}
      {...props}
    >
      {t('examinations.add')}
    </ButtonBaseWithIcon>
  );
};
