import { RegisterValues } from 'pages/auth/register/formikValues';
import { UserGroupEnum } from 'commonTypes/authTypes';
import { processUserAgreements } from 'components/Consents/helpers';
import { RegisterUserDTO } from 'api/auth/authDTOs';

export const processRegisterValues = (
  values: RegisterValues
): RegisterUserDTO => {
  const consentsArray = processUserAgreements(values, false);

  const apiValues = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    password: values.password,
    role: UserGroupEnum.BASIC,
    consents: consentsArray,
  };

  return apiValues;
};
