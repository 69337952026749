import * as Yup from 'yup';
import {
  firstNameValidation,
  lastNameValidation,
  passwordValidation,
  emailValidation,
  consentValidation,
} from 'validation/validationFunctions';

export const registerValidationSchema = () => {
  return Yup.object().shape({
    email: emailValidation(),
    password: passwordValidation(),
    firstName: firstNameValidation(),
    lastName: lastNameValidation(),
    termsConditionsAgreement: consentValidation(),
    dataProcessingAgreement: consentValidation(),
  });
};
