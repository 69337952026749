import { SetStateAction, Dispatch, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';
import { acceptedFileExtensions } from 'utils/constants';
import { useAuth } from 'context/providers/AuthProvider';
import { ReactComponent as Plus } from 'icons/Plus.svg';
import { ReactComponent as PlusDisabled } from 'icons/PlusDisabled.svg';
import {
  validateFiles,
  AcceptedFiles,
  processedFile,
  AcceptedFilesBatchExtensions,
} from 'pages/examinations/examinationUpload/processFiles';
import { validateJsn } from 'pages/examinations/examinationUpload/validation/validateJSN';
import { validateWithApiCalls } from 'pages/examinations/examinationUpload/validation/validateWithApiCalls';
import { ValidationErrors } from 'pages/examinations/examinationUpload/AddExaminationDialog';
import { SensorBorrowerData, Sensor } from 'commonTypes/sensorTypes';
import { checkIfSensorLentToOtherProfile } from 'pages/examinations/examinationUpload/checkSensorBorrower';

type FileDropzoneProps = {
  filesState: AcceptedFiles;
  setFilesState: Dispatch<SetStateAction<AcceptedFiles>>;
  setUploadedFiles: Dispatch<SetStateAction<processedFile[]>>;
  setMissingExtensions: Dispatch<
    SetStateAction<AcceptedFilesBatchExtensions[]>
  >;
  setValidationError: Dispatch<SetStateAction<ValidationErrors | null>>;
  setIsValidating: Dispatch<SetStateAction<boolean>>;
  setExaminationId: Dispatch<SetStateAction<string>>;
  setSensorBorrowerData: Dispatch<SetStateAction<SensorBorrowerData | null>>;
  disabled: boolean;
};

export const FileDropzone = ({
  filesState,
  setFilesState,
  setUploadedFiles,
  setMissingExtensions,
  setValidationError,
  setIsValidating,
  setExaminationId,
  setSensorBorrowerData,
  disabled,
}: FileDropzoneProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeProfile, user } = useAuth();

  const onDrop = useCallback(
    (acceptedFiles) => {
      setValidationError(null);

      const {
        loadedFiles,
        missingFiles,
        fileNamesValid,
        updatedFilesState,
      } = validateFiles(acceptedFiles, filesState, setFilesState);

      if (!isEmpty(loadedFiles)) {
        setUploadedFiles(loadedFiles);
        setMissingExtensions(missingFiles);
        if (!fileNamesValid) {
          setValidationError(ValidationErrors.FileNamesInvalid);
          return;
        }
      }

      const selectedJsn = updatedFilesState.JSN.file;

      if (selectedJsn) {
        setIsValidating(true);
        validateJsn(selectedJsn)
          .then((jsnMetadata) => {
            setExaminationId(jsnMetadata.examinationId);
            return validateWithApiCalls(jsnMetadata);
          })
          .then((sensorData: Sensor) => {
            const warning = checkIfSensorLentToOtherProfile(
              sensorData,
              user,
              activeProfile?.profileId
            );
            if (warning) {
              setSensorBorrowerData(sensorData.lendedToProfile);
            }
          })
          .then(() => {
            setIsValidating(false);
          })
          .catch((error) => {
            if (Object.values(ValidationErrors).includes(error.message)) {
              setValidationError(error.message);
            } else {
              setValidationError(ValidationErrors.OtherError);
            }
            setIsValidating(false);
          });
      }
    },
    [
      filesState,
      setFilesState,
      setMissingExtensions,
      setUploadedFiles,
      setValidationError,
      setIsValidating,
      setExaminationId,
      user,
      activeProfile?.profileId,
      setSensorBorrowerData,
    ]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileExtensions.join(),
    disabled,
  });

  return (
    <section>
      <div
        {...getRootProps({
          className: clsx(classes.dropzone, {
            [classes.dropzoneDisabled]: disabled,
          }),
        })}
        id="dropzone-container"
      >
        <input {...getInputProps()} id="file-dropzone" disabled={disabled} />
        {disabled ? <PlusDisabled /> : <Plus />}
        <Typography
          className={clsx(classes.text, { [classes.textDisabled]: disabled })}
        >
          {t('examinations.dropzone-helper')}
        </Typography>
      </div>
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.palette.primary.darkmode,
    marginLeft: theme.spacing(2),
  },
  dropzone: {
    border: `1px dashed ${theme.palette.secondary.main}`,
    margin: theme.spacing(2, 0, 3, 0),
    height: '96px',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  dropzoneDisabled: {
    border: `1px dashed ${theme.palette.text.disabled}`,
    cursor: 'default',
  },
  textDisabled: {
    color: theme.palette.text.disabled,
  },
}));
