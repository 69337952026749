import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SleepScore } from 'commonTypes/examinationTypes';
import { useConfig } from 'context/providers/ConfigProvider';
import {
  DetailsItem,
  DetailsItemType,
} from 'pages/examinations/examinationDetails/DetailsItem';

type BasicDetailsProps = {
  sleepScore: SleepScore;
};

export const BasicDetails = ({ sleepScore }: BasicDetailsProps) => {
  const classes = useStyles();
  const { colorRanges } = useConfig();
  const { t } = useTranslation();

  const data = [
    {
      label: {
        name: 'supine-ahi',
        tooltip: {
          text: t('examinations.supine-ahi-tooltip'),
          range: colorRanges.supine_ahi,
        },
      },
      type: DetailsItemType.NUMBER,
      value: sleepScore.supineAhi,
    },
    {
      label: {
        name: 'non-supine-ahi',
        tooltip: {
          text: t('examinations.non-supine-ahi-tooltip'),
        },
      },
      type: DetailsItemType.NUMBER,
      value: sleepScore.nonSupineAhi,
    },
    {
      label: {
        name: 'supine-position',
        tooltip: {
          text: t('examinations.supine-position-tooltip'),
        },
      },
      type: DetailsItemType.PERCENT,
      value: sleepScore.supinePositionRatio,
    },
    {
      label: {
        name: 'non-supine-position',
        tooltip: {
          text: t('examinations.non-supine-position-tooltip'),
        },
      },
      type: DetailsItemType.PERCENT,
      value: sleepScore.nonSupinePositionRatio,
    },
    {
      label: {
        name: 'upright-position',
        tooltip: {
          text: t('examinations.upright-position-tooltip'),
        },
      },
      type: DetailsItemType.PERCENT,
      value: sleepScore.uprightPositionRatio,
    },
    {
      label: {
        name: 'mean-respiratory-rate',
        tooltip: {
          text: t('examinations.mean-respiratory-rate-tooltip'),
        },
      },
      type: DetailsItemType.NUMBER,
      value: sleepScore.meanRespiratoryRate,
    },
    {
      label: {
        name: 'snoring-index',
        tooltip: {
          text: t('examinations.snoring-index-tooltip'),
          range: colorRanges.snoring_index,
        },
      },
      type: DetailsItemType.PERCENT,
      value: sleepScore.snoringIndex,
    },
    {
      label: {
        name: 'supine-snoring-index',
        tooltip: {
          text: t('examinations.supine-snoring-index-tooltip'),
          range: colorRanges.supine_snoring_index,
        },
      },
      type: DetailsItemType.PERCENT,
      value: sleepScore.supineSnoringIndex,
    },
    {
      label: {
        name: 'non-supine-snoring-index',
        tooltip: {
          text: t('examinations.non-supine-snoring-index-tooltip'),
        },
      },
      type: DetailsItemType.PERCENT,
      value: sleepScore.nonSupineSnoringIndex,
    },
  ];

  return (
    <div className={classes.detailsContainer}>
      <Typography variant="h3" className={classes.title}>
        {t('basic')}
      </Typography>
      {data.map((item) => (
        <DetailsItem
          id={item.label.name}
          key={item.label.name}
          label={item.label}
          value={item.value}
          type={item.type}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    minWidth: '300px',
    marginRight: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[900],
  },
}));
