import { Typography, Divider, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { processedFile } from 'pages/examinations/examinationUpload/processFiles';

type FilesArray = {
  files: processedFile[];
};

const FilesRow = ({ files }: FilesArray) => {
  const classes = useStyles();

  return (
    <div className={classes.filesRow}>
      {files.map((file) => {
        if (file.file) {
          return (
            <Typography
              key={file.file.name}
              id={file.file.name}
              variant="body2"
              align="center"
              className={classes.fileItem}
            >
              {file.file.name}
            </Typography>
          );
        }
      })}
    </div>
  );
};

export const DisplayFilesArea = ({ files }: FilesArray) => {
  const classes = useStyles();

  if (isEmpty(files)) return null;

  const threeElementRows = [];
  for (let i = 0; i < files.length; i += 3) {
    const filesRow = files.slice(i, i + 3);
    threeElementRows.push(filesRow);
  }

  return (
    <>
      {threeElementRows.map((row) => {
        if (row[0].file) {
          return <FilesRow key={row[0].file.name} files={row} />;
        }
      })}
      <Divider className={classes.divider} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  fileItem: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 2, 1, 0),
    width: '30%',
  },
  filesRow: {
    display: 'flex',
  },
  divider: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}));
