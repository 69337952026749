import { Bar } from 'recharts';

// override bar positioning
// bars should be directly under each other, no matter the bar width
export const overrideBarPosition = () => {
  const barGetComposedDataOrig = Bar.getComposedData;
  Bar.getComposedData = (data: any) => {
    const result: any = barGetComposedDataOrig(data);
    const gap = 4;

    if (result) {
      const blockSize = result.width / 14;
      for (let i = 0; i < result.data.length; i += 1) {
        const element = result.data[i];

        let newValue =
          result.width -
          blockSize * i -
          blockSize / 2 +
          result.left -
          result.right;

        if (data.dataKey === 'supineAhi') {
          if (element.width > 16) {
            newValue -= (element.width + gap) / 2;
          } else {
            newValue -= (element.width + gap / 2) / 2 - gap / 2;
          }
        }
        if (data.dataKey === 'nonSupineAhi') {
          if (element.width > 18) {
            newValue += (element.width + gap) / 2;
          } else {
            newValue += (element.width + gap) / 2 + gap / 2;
          }
        }
        if (data.dataKey === 'supinePositionRatio') {
          element.width = 28;
          newValue -= gap;
        }
        if (
          data.dataKey === 'ahi' ||
          data.dataKey === 'sleepScore' ||
          data.dataKey === 'snoringIndex'
        ) {
          if (element.width > 22) {
            newValue -= gap / 2;
          }
        }
        element.x = newValue;
      }
    }
    return result;
  };
};
