import { RadialBarChart } from 'recharts';
import { makeStyles } from '@material-ui/core';
import { round } from 'lodash';
import theme from 'theme';
import { BaseCircleChart } from 'charts/CircleCharts/BaseCircleChart';

type DetailsCircleChartProps = {
  metric: {
    label: string;
    value: number | null | undefined;
    range: number[] | undefined;
    unit: string;
  };
  id: string;
};

const circleSize = 100;

export const DetailsCircleChart = ({ metric, id }: DetailsCircleChartProps) => {
  const classes = useStyles();

  let roundedValue;

  if (metric.value === null || metric.value === undefined) {
    roundedValue = null;
  }

  if (
    metric.value !== null &&
    metric.value !== undefined &&
    metric.range !== undefined
  ) {
    roundedValue = round(metric.value, 0);
  }

  const data = [{ name: metric.label, value: roundedValue }];

  return (
    <RadialBarChart
      id={id}
      width={circleSize}
      height={circleSize + 20}
      cx={circleSize / 2}
      cy={circleSize / 2}
      innerRadius={30}
      outerRadius={40}
      barSize={10}
      data={data}
      startAngle={90}
      endAngle={-270}
    >
      {BaseCircleChart({
        circleSize,
        metric,
        roundedValue,
        innerLabelClass: classes.innerLabel,
      })}
      <text
        className={classes.outerLabel}
        x={circleSize / 2}
        y={circleSize}
        textAnchor="middle"
        dominantBaseline="end"
      >
        {metric.label}
      </text>
    </RadialBarChart>
  );
};

const useStyles = makeStyles(() => ({
  innerLabel: {
    fill: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 600,
  },
  outerLabel: {
    fill: theme.palette.grey[700],
    fontSize: '12px',
    fontWeight: 400,
  },
}));
