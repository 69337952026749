import { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { ButtonBase } from 'components/Button/ButtonBase';
import { ReactComponent as Plus } from 'icons/Plus.svg';
import { AddProfileDialog } from 'pages/profiles/AddProfileDialog';

type AddProfileButtonProps = {
  setAddProfileResult: Dispatch<
    SetStateAction<{ resolved: boolean; status: string }>
  >;
};

export const AddFirstProfileButton = ({
  setAddProfileResult,
}: AddProfileButtonProps) => {
  const [addProfileDialogOpen, setAddProfileDialogOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <ButtonBase
        id="add-first-profile-button"
        startIcon={<Plus />}
        onClick={() => setAddProfileDialogOpen(true)}
        className={classes.addProfileButton}
        classes={{
          startIcon: classes.startIcon,
        }}
      >
        {t('profiles.add-examined-profile')}
      </ButtonBase>
      <AddProfileDialog
        open={addProfileDialogOpen}
        onClose={() => setAddProfileDialogOpen(false)}
        setAddProfileResult={setAddProfileResult}
        firstProfile
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  addProfileButton: {
    minWidth: '330px',
    height: '56px',
    color: theme.palette.grey[900],
    backgroundColor: theme.palette.background.default,
    borderColor: 'transparent',
    borderRadius: '8px',

    '&:hover': {
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  startIcon: {
    position: 'absolute',
    left: theme.spacing(3),
  },
}));
