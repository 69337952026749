import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { IconButton } from 'components/Button/IconButton';
import { ReactComponent as NextIcon } from 'icons/Next.svg';
import { ReactComponent as NextIconDisabled } from 'icons/NextDisabled.svg';

type NextButtonProps = {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  id: string;
};

export const NextButton = ({
  className,
  disabled,
  ...props
}: NextButtonProps) => {
  const classes = useStyles();

  return (
    <IconButton
      className={clsx(className, classes.button)}
      disabled={disabled}
      {...props}
    >
      {disabled ? <NextIconDisabled /> : <NextIcon />}
    </IconButton>
  );
};

NextButton.defaultProps = {
  className: undefined,
  disabled: false,
};

const useStyles = makeStyles((theme) => ({
  button: {
    height: '46px',
    width: '46px',
  },
}));
