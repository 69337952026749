import { Typography, makeStyles } from '@material-ui/core';

export const AuthFooter = () => {
  const classes = useStyles();
  return (
    <Typography className={classes.footer}>Terms of use © Clebre</Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  footer: {
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));
