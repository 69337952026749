import { Gender } from 'commonTypes/profileTypes';
import {
  ConsentNamesUser,
  ConsentNamesProfile,
} from 'components/Consents/ConsentNames';

export type User =
  | {
      id: string;
      active: boolean;
      currentGroup: CurrentGroup;
      email: string;
      firstName: string;
      lastName: string;
      profile: Profile | null;
      requiredConsents: boolean;
      userConsents: ConsentNamesUser[];
    }
  | undefined;

export type Profile = {
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  bmi: number;
  govIdentification?: string;
  height: number;
  weight: number;
  phoneNumber?: string | null;
  profileId: string;
  registrationDate: string;
  sensorLeases: SensorLease[];
  profileConsents: ConsentNamesProfile[];
};

export type ProfileSimple = {
  countOfExaminations: number;
  dateOfBirth: string;
  dateOfLastExamination?: string | null;
  firstName: string;
  gender: Gender;
  govIdentification: string;
  hasLoanedSensor: boolean;
  lastName: string;
  phoneNumber?: string | null;
  profileId: string;
  resultOfLastExamination?: number | null;
  requiredConsents: boolean;
  bmi: number;
};

export type SensorLease = {
  leasedAt: string;
  returnedAt: string | null;
  serialNumber: string;
};

export type CurrentGroup = {
  name: string;
  permissions: Permissions;
  priority: number;
};

export type TokenExpiration = number | undefined;

export type JwtToken = {
  sub: string;
  aud: [string];
  iss: string;
  exp: number;
  body: string;
  jti: string;
};

export type Permissions = PermissionsEnum[];

// eslint-disable-next-line
export enum PermissionsEnum {
  IS_DOCTOR = 'IS_DOCTOR',
  IS_BASIC = 'IS_BASIC',
  IS_CLASSIFIER = 'IS_CLASSIFIER',
  CONSENT_MANAGER = 'CONSENT_MANAGER',
  EXAMINATION_ADD = 'EXAMINATION_ADD',
  EXAMINATION_ADD_GLOBAL = 'EXAMINATION_ADD_GLOBAL',
  EXAMINATION_CLASSIFY = 'EXAMINATION_CLASSIFY',
  EXAMINATION_DELETE = 'EXAMINATION_DELETE',
  EXAMINATION_DELETE_GLOBAL = 'EXAMINATION_DELETE_GLOBAL',
  EXAMINATION_GET = 'EXAMINATION_GET',
  EXAMINATION_GET_GLOBAL = 'EXAMINATION_GET_GLOBAL',
  EXAMINATION_LIST = 'EXAMINATION_LIST',
  EXAMINATION_LIST_GLOBAL = 'EXAMINATION_LIST_GLOBAL',
  EXPERT_CLASSIFICATION_FINISH = 'EXPERT_CLASSIFICATION_FINISH',
  EXPERT_CLASSIFICATION_GET = 'EXPERT_CLASSIFICATION_GET',
  EXPERT_CLASSIFICATION_GET_GLOBAL = 'EXPERT_CLASSIFICATION_GET_GLOBAL',
  EXPERT_CLASSIFICATION_LIST = 'EXPERT_CLASSIFICATION_LIST',
  EXPERT_CLASSIFICATION_LIST_GLOBAL = 'EXPERT_CLASSIFICATION_LIST_GLOBAL',
  EXPERT_CLASSIFICATION_START = 'EXPERT_CLASSIFICATION_START',
  FILE_DELETE_GLOBAL = 'FILE_DELETE_GLOBAL',
  FILE_DOWNLOAD = 'FILE_DOWNLOAD',
  FILE_DOWNLOAD_GLOBAL = 'FILE_DOWNLOAD_GLOBAL',
  FILE_LIST = 'FILE_LIST',
  FILE_LIST_GLOBAL = 'FILE_LIST_GLOBAL',
  FILE_UPLOAD = 'FILE_UPLOAD',
  FILE_UPLOAD_GLOBAL = 'FILE_UPLOAD_GLOBAL',
  FIRMWARE_ADD = 'FIRMWARE_ADD',
  FIRMWARE_DOWNLOAD = 'FIRMWARE_DOWNLOAD',
  FIRMWARE_EDIT = 'FIRMWARE_EDIT',
  FIRMWARE_GET = 'FIRMWARE_GET',
  FIRMWARE_LIST = 'FIRMWARE_LIST',
  FIRMWARE_PACKAGE_GET = 'FIRMWARE_PACKAGE_GET',
  GENERATE_DEMO_DEV = 'GENERATE_DEMO_DEV',
  GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN',
  GROUP_MANAGER = 'GROUP_MANAGER',
  PROFILE_ADD = 'PROFILE_ADD',
  PROFILE_ADD_VALIDATED = 'PROFILE_ADD_VALIDATED',
  PROFILE_EDIT = 'PROFILE_EDIT',
  PROFILE_EDIT_GLOBAL = 'PROFILE_EDIT_GLOBAL',
  PROFILE_GET = 'PROFILE_GET',
  PROFILE_GET_GLOBAL = 'PROFILE_GET_GLOBAL',
  PROFILE_LIST = 'PROFILE_LIST',
  PROFILE_LIST_GLOBAL = 'PROFILE_LIST_GLOBAL',
  SENSOR_ADD = 'SENSOR_ADD',
  SENSOR_EDIT = 'SENSOR_EDIT',
  SENSOR_GET = 'SENSOR_GET',
  SENSOR_GET_GLOBAL = 'SENSOR_GET_GLOBAL',
  SENSOR_LEND = 'SENSOR_LEND',
  SENSOR_LEND_GLOBAL = 'SENSOR_LEND_GLOBAL',
  SENSOR_LEND_LIST = 'SENSOR_LEND_LIST',
  SENSOR_LEND_LIST_GLOBAL = 'SENSOR_LEND_LIST_GLOBAL',
  SENSOR_LIST = 'SENSOR_LIST',
  SENSOR_LIST_GLOBAL = 'SENSOR_LIST_GLOBAL',
  SENSOR_OWNERSHIP_CREATE = 'SENSOR_OWNERSHIP_CREATE',
  SENSOR_OWNERSHIP_CREATE_GLOBAL = 'SENSOR_OWNERSHIP_CREATE_GLOBAL',
  SENSOR_OWNERSHIP_LIST = 'SENSOR_OWNERSHIP_LIST',
  SENSOR_OWNERSHIP_LIST_GLOBAL = 'SENSOR_OWNERSHIP_LIST_GLOBAL',
  SENSOR_OWNERSHIP_REMOVE = 'SENSOR_OWNERSHIP_REMOVE',
  SENSOR_OWNERSHIP_REMOVE_GLOBAL = 'SENSOR_OWNERSHIP_REMOVE_GLOBAL',
  SENSOR_REMOVE_DAMAGE = 'SENSOR_REMOVE_DAMAGE',
  SENSOR_REMOVE_DAMAGE_GLOBAL = 'SENSOR_REMOVE_DAMAGE_GLOBAL',
  USER_EDIT = 'USER_EDIT',
  USER_EDIT_GLOBAL = 'USER_EDIT_GLOBAL',
  USER_GET = 'USER_GET',
  USER_GET_GLOBAL = 'USER_GET_GLOBAL',
  USER_LIST = 'USER_LIST',
}

// eslint-disable-next-line
export enum UserGroupEnum {
  BASIC = 'BASIC',
  DOCTOR = 'DOCTOR',
  ADMIN = 'ADMIN',
  CLASSIFIER = 'CLASSIFIER',
  RESEARCHER = 'RESEARCHER',
}
