import { useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useAuth } from 'context/providers/AuthProvider';
import { LettersAvatar } from 'components/Avatar/LettersAvatar';
import { EditButton } from 'components/Button/EditButton';
import { BoxBase } from 'components/Box/Box';
import { EditUserDialog } from 'pages/account/EditUserDialog';

type UserInfoProps = {
  className: string;
};

export const UserInfo = ({ className }: UserInfoProps) => {
  const { user } = useAuth();
  const classes = useStyles();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  if (!user) {
    return null;
  }

  return (
    <BoxBase className={className}>
      <LettersAvatar user={user} className={classes.avatar} id="user-avatar" />
      <div className={classes.nameContainer}>
        <Typography
          variant="button"
          className={classes.userName}
          id="user-name"
        >{`${user.firstName} ${user.lastName}`}</Typography>
        <Typography noWrap id="user-email">
          {user.email}
        </Typography>
      </div>
      <EditButton
        className={classes.editButton}
        onClick={() => setEditDialogOpen(true)}
        id="edit-account-button"
      />
      <EditUserDialog
        open={editDialogOpen}
        close={() => setEditDialogOpen(false)}
      />
    </BoxBase>
  );
};

const useStyles = makeStyles((theme) => ({
  userName: {
    color: theme.palette.grey[900],
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  editButton: {
    marginLeft: theme.spacing(2),
  },
  nameContainer: {
    maxWidth: '300px',
  },
}));
