import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react';
import { useAuth } from 'context/providers/AuthProvider';
import { ProfileSimple } from 'commonTypes/authTypes';
import { getProfiles } from 'api/profiles/profilesApi';
import { sortProfilesList } from 'utils/helpers';

export type ProfilesContextValue = {
  profiles: ProfileSimple[];
  setProfiles: Dispatch<SetStateAction<ProfileSimple[]>>;
  fetchProfiles: () => void;
};

export type ProfilesProviderProps = {
  children: ReactNode;
};

const defaultValue: ProfilesContextValue = {
  profiles: [],
  setProfiles: () => {},
  fetchProfiles: () => {},
};

const ProfilesContext = createContext(defaultValue);

const ProfilesProvider: FC<ProfilesProviderProps> = (
  props: ProfilesProviderProps
) => {
  const { activeProfile, user } = useAuth();
  const [profiles, setProfiles] = useState<ProfileSimple[]>([]);

  const fetchProfiles = useCallback(() => {
    getProfiles()
      .then((userProfiles) => {
        if (activeProfile?.profileId) {
          const sortedProfiles = sortProfilesList(
            activeProfile.profileId,
            userProfiles.content
          );
          setProfiles(sortedProfiles);
        } else {
          setProfiles(userProfiles.content);
        }
      })
      .catch(() => {});
  }, [
    activeProfile?.profileId,
    activeProfile?.hasConsents,
    user?.requiredConsents,
  ]);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  return (
    <ProfilesContext.Provider
      value={{
        profiles,
        setProfiles,
        fetchProfiles,
      }}
      {...props}
    />
  );
};

const useProfiles = () => useContext(ProfilesContext);
export { ProfilesProvider, ProfilesContext, useProfiles };
