import { makeStyles, Backdrop, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { ProgressBarWithLabel } from 'components/Progress/ProgressBar';
import { OkButton } from 'components/Button/OkButton';
import { UploadResult } from 'pages/examinations/examinationDetails/ExamFileRow';

type ExamFileUploadProgressProps = {
  progress: number;
  uploadResult: UploadResult | null;
  handleClose: () => void;
};

export const ExamFileUploadInfo = ({
  progress,
  uploadResult,
  handleClose,
}: ExamFileUploadProgressProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Backdrop open className={classes.backdrop}>
      <Paper className={classes.container} id="file-upload-info">
        {progress !== 0 && !uploadResult && (
          <div className={classes.progress}>
            <Typography>{t('examinations.upload-file-progress')}</Typography>
            <ProgressBarWithLabel
              progress={progress}
              id="file-upload-progress"
            />
          </div>
        )}
        {uploadResult && (
          <>
            {uploadResult === UploadResult.SUCCESS && (
              <Alert severity="success" id="file-upload-success">
                {t('examinations.file-upload-success')}
              </Alert>
            )}
            {uploadResult === UploadResult.ERROR && (
              <Alert severity="error" id="file-upload-error">
                {t('examinations.file-upload-error')}
              </Alert>
            )}
            {uploadResult === UploadResult.EMPTY_FILE && (
              <Alert severity="error" id="file-empty">
                {t('examinations.file-empty')}
              </Alert>
            )}
            <div className={classes.actions}>
              <OkButton onClick={handleClose} id="file-upload-confirm" />
            </div>
          </>
        )}
      </Paper>
    </Backdrop>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
  },
  container: {
    width: '500px',
    padding: theme.spacing(4, 4, 2, 4),
  },
  alert: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(2),
  },
}));
