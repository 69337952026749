import { useState, Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useAuth } from 'context/providers/AuthProvider';
import { DialogBase } from 'components/Dialog/DialogBase';
import { UserConsentsForm } from 'components/Consents/UserConsentsForm';
import { SubmitButton } from 'components/Button/SubmitButton';
import { CancelButton } from 'components/Button/CancelButton';
import { CircularLoader } from 'components/Progress/CircularLoader';
import {
  generateInitialUserConsents,
  processUserAgreements,
} from 'components/Consents/helpers';
import { setUserConsents } from 'api/consent/consentApi';
import { updateUserFromToken } from 'utils/decodeJWT';

type EditConsentsDialogProps = {
  open: boolean;
  onClose: () => void;
  setEditConsentsResult: Dispatch<
    SetStateAction<{ resolved: boolean; status: string }>
  >;
};

export const EditConsentsDialog = ({
  open,
  onClose,
  setEditConsentsResult,
}: EditConsentsDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isDoctor, user, login } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      formik.resetForm();
    }, 100);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: generateInitialUserConsents(user, isDoctor),
    onSubmit: (values) => {
      setEditConsentsResult({ resolved: false, status: '' });
      setLoading(true);
      const apiValues = processUserAgreements(values, isDoctor);
      setUserConsents(apiValues)
        .then(() => {
          setLoading(false);
          updateUserFromToken(login);
          handleClose();
          setEditConsentsResult({ resolved: true, status: 'success' });
        })
        .catch(() => {
          setLoading(false);
          setEditConsentsResult({ resolved: true, status: 'error' });
        });
    },
  });

  return (
    <DialogBase
      open={open}
      title={t('account.manage-consents')}
      onClose={handleClose}
      className={classes.dialog}
      id="edit-consents-dialog"
    >
      <UserConsentsForm
        formik={formik}
        isDoctor={isDoctor}
        className={classes.form}
        edit
      />
      <div className={classes.actions}>
        <CancelButton
          id="cancel-button"
          onClick={handleClose}
          className={classes.cancelButton}
          disabled={loading}
        >
          {t('cancel')}
        </CancelButton>
        <SubmitButton
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
          isLoading={loading}
          id="submit-button"
        >
          {loading ? <CircularLoader /> : t('confirm')}
        </SubmitButton>
      </div>
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: '650px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(3, 0, 1, 0),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(2),
  },
}));
