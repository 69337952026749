import { Dispatch, SetStateAction } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  makeStyles,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useExaminations } from 'context/providers/ExaminationsProvider';
import { ExaminationsTableHeader } from 'pages/examinations/examinationsList/TableHeader';
import { ExaminationRow } from 'pages/examinations/examinationsList/ExaminationRow';
import { Pagination } from 'pages/examinations/examinationsList/TablePagination';
import { NoResultsRow } from 'pages/examinations/examinationsList/NoResultsRow';
import {
  ExaminationSimple,
  SelectedExamination,
} from 'commonTypes/examinationTypes';
import { BigLoader } from 'components/Progress/BigLoader';

type ExaminationsTableProps = {
  selectedExaminations: SelectedExamination[];
  setSelectedExaminations: Dispatch<SetStateAction<SelectedExamination[]>>;
  page: number;
  rowsPerPage: number;
  setPage: Dispatch<SetStateAction<number>>;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
};

export const ExaminationsTable = ({
  selectedExaminations,
  setSelectedExaminations,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}: ExaminationsTableProps) => {
  const classes = useStyles();
  const { examinations, examinationsLoading } = useExaminations();
  const { examinationsList } = examinations;

  if (examinationsLoading) {
    return <BigLoader className={classes.loader} />;
  }

  if (!examinationsList) {
    return null;
  }

  const allSelected =
    !isEmpty(examinationsList) &&
    examinationsList.length === selectedExaminations.length;

  const handleCheckboxClick = (
    examinationId: string,
    isExaminationOwner: boolean
  ) => {
    const selectedIndex = selectedExaminations.findIndex((examination) => {
      return examination.id === examinationId;
    });
    let newSelected: SelectedExamination[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedExaminations, {
        id: examinationId,
        isOwner: isExaminationOwner,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedExaminations.slice(1));
    } else if (selectedIndex === selectedExaminations.length - 1) {
      newSelected = newSelected.concat(selectedExaminations.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedExaminations.slice(0, selectedIndex),
        selectedExaminations.slice(selectedIndex + 1)
      );
    }
    setSelectedExaminations(newSelected);
  };

  const allExaminationIds = examinationsList.map((examination) => {
    return {
      id: examination.examinationId,
      isOwner: examination.isExaminationOwner,
    };
  });

  const handleSelectAllClick = () => {
    if (allSelected) {
      setSelectedExaminations([]);
    } else {
      setSelectedExaminations(allExaminationIds);
    }
  };

  return (
    <>
      <TableContainer
        id="examinations-table"
        component={Paper}
        className={classes.container}
      >
        <Table className={classes.table} stickyHeader>
          <ExaminationsTableHeader
            handleSelectAllClick={handleSelectAllClick}
            allSelected={allSelected}
            disabled={isEmpty(examinationsList)}
          />
          <TableBody>
            {isEmpty(examinationsList) ? (
              <NoResultsRow />
            ) : (
              <>
                {examinationsList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((examination: ExaminationSimple) => (
                    <ExaminationRow
                      key={examination.examinationId}
                      examination={examination}
                      handleCheckboxClick={handleCheckboxClick}
                      selected={
                        !isEmpty(
                          selectedExaminations.filter(
                            (selected) =>
                              selected.id === examination.examinationId
                          )
                        )
                      }
                    />
                  ))}
              </>
            )}
          </TableBody>
        </Table>
        <Pagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalRecords={examinationsList.length}
        />
      </TableContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  container: {
    marginTop: theme.spacing(3),
    borderRadius: '5px',
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
    overflow: 'visible',
  },
  loader: {
    marginTop: theme.spacing(8),
  },
}));
