import { makeStyles } from '@material-ui/core';
import { Profile } from 'commonTypes/authTypes';
import { BasicProfileData } from 'pages/profiles/activeProfileInfo/BasicProfileData';
import { DoctorProfileData } from 'pages/profiles/activeProfileInfo/DoctorProfileData';

type ProfileDataProps = {
  profileData: Profile;
};

export const ActiveProfileData = ({ profileData }: ProfileDataProps) => {
  const classes = useStyles();

  return (
    <div className={classes.profileData}>
      <BasicProfileData profileData={profileData} />
      <DoctorProfileData profileData={profileData} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  profileData: {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
}));
