import i18n from 'translations/i18n';

export const mobileAppQA = {
  header: i18n.t('help.mobile-app.title'),
  questions: [
    {
      question: i18n.t('help.mobile-app.question1'),
      answer: [
        {
          text: i18n.t('help.mobile-app.question1-answer-part1'),
          images: {
            src: [
              'images/faq/mobileApp/question1-1.png',
              'images/faq/mobileApp/question1-2.png',
              'images/faq/mobileApp/question1-3.png',
              'images/faq/mobileApp/question1-4.png',
            ],
          },
        },
        {
          text: i18n.t('help.mobile-app.question1-answer-part2'),
          images: { src: ['images/faq/mobileApp/question1-5.png'] },
        },
        {
          text: i18n.t('help.mobile-app.question1-answer-part3'),
        },
      ],
    },
    {
      question: i18n.t('help.mobile-app.question2'),
      answer: [
        {
          text: i18n.t('help.mobile-app.question2-answer-part1'),
          images: { src: ['images/faq/mobileApp/question2-1.png'] },
        },
        {
          text: i18n.t('help.mobile-app.question2-answer-part2'),
        },
        {
          text: i18n.t('help.mobile-app.question2-answer-part3'),
          bulletpoint: true,
        },
        {
          text: i18n.t('help.mobile-app.question2-answer-part4'),
          bulletpoint: true,
        },
        {
          text: i18n.t('help.mobile-app.question2-answer-part5'),
          bulletpoint: true,
        },
      ],
    },
  ],
};
