import { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SensorItem } from 'pages/sensors/SensorItem';
import { getSensorsList } from 'api/sensors/sensorsApi';
import { Sensor } from 'commonTypes/sensorTypes';
import { isEmpty } from 'lodash';
import { useAuth } from 'context/providers/AuthProvider';

const SensorsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = useAuth();
  const [sensors, setSensors] = useState<Sensor[] | null>(null);

  useEffect(() => {
    getSensorsList()
      .then((data) => setSensors(data.content))
      .catch(() => {});
  }, [user?.requiredConsents]);

  return (
    <>
      <Typography variant="h2" className={classes.header}>
        {t('sensors.my-sensors')}
      </Typography>
      {sensors && (
        <>
          {isEmpty(sensors) ? (
            <Typography
              variant="h3"
              align="center"
              className={classes.noSensors}
            >
              {t('sensors.no-sensors')}
            </Typography>
          ) : (
            sensors.map((sensor: Sensor) => {
              return <SensorItem sensor={sensor} key={sensor.serialNumber} />;
            })
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(7),
  },
  sensorIcon: {
    width: '20px',
    height: '20px',
  },
  noSensors: {
    marginTop: theme.spacing(14),
    color: theme.palette.primary.main,
  },
}));

export default SensorsPage;
