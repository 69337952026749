import { ColorIndicator } from 'components/Other/ColorIndicator';
import { matchSleepScoreColor } from 'utils/matchSleepScoreColor';

type ValueColorIndicatorProps = {
  value: number | null;
  range: number[];
  id: string | undefined;
  className?: string;
};

export const ValueColorIndicator = ({
  value,
  range,
  id,
  className,
}: ValueColorIndicatorProps) => {
  if (value === null) {
    return null;
  }
  const color = matchSleepScoreColor(value, range);

  return <ColorIndicator id={id} color={color} className={className} />;
};

ValueColorIndicator.defaultProps = {
  className: undefined,
};
