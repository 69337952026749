import { useConfig } from 'context/providers/ConfigProvider';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as Tooltip } from 'icons/Tooltip.svg';
import { CustomTooltip } from 'components/Tooltip/CustomTooltip';
import { SSSIChartTooltip } from 'charts/ChartComponents/SSSIChartTooltip';
import { LegendItem, LabelColors } from 'charts/ChartComponents/LegendItem';
import { LegendWrapper } from 'charts/ChartComponents/LegendWrapper';

export const SSSIChartLegend = () => {
  const { colorRanges } = useConfig();
  const { t } = useTranslation();
  const classes = useStyles();

  const tooltipData = [
    {
      name: 'sleep-score',
      tooltip: {
        text: t(`examinations.sleep-score-tooltip`),
        range: colorRanges.sleep_score,
      },
    },
    {
      name: 'snoring-index',
      tooltip: {
        text: t(`examinations.snoring-index-tooltip`),
        range: colorRanges.snoring_index,
      },
    },
  ];

  const legendItems = [
    { label: t('examinations.sleep-score'), color: LabelColors.BLUE },
    { label: t('examinations.snoring-index'), color: LabelColors.PINK },
  ];

  return (
    <LegendWrapper>
      {legendItems.map((item) => {
        return (
          <LegendItem key={item.label} label={item.label} color={item.color} />
        );
      })}
      <CustomTooltip
        id="SSSI-chart-legend-tooltip"
        title={<SSSIChartTooltip data={tooltipData} />}
        multiple
      >
        <Tooltip
          className={classes.tooltip}
          id="SSSI-chart-legend-tooltip-icon"
        />
      </CustomTooltip>
    </LegendWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    minWidth: '22px',
    minHeight: '22px',
    maxHeight: '22px',
    maxWidth: '22px',
    marginLeft: theme.spacing(1),
  },
}));
