import { ReactChild } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Container, Box } from '@material-ui/core';

type LoginWrapperProps = {
  children: ReactChild;
};

export const LoginWrapper = ({ children }: LoginWrapperProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.background}>
      <Container className={classes.container}>
        <img
          src="images/clebre-logoP293.png"
          alt="clebre logo"
          className={classes.logo}
        />
        <Typography className={classes.welcomeText} variant="h4">
          {t('login-page.welcome-text')}
        </Typography>
        <div className={classes.content}>{children}</div>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.background.default,
    margin: 0,
  },
  container: {
    height: '100vh',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '370px',
    height: '55vh',
    maxHeight: '600px',
    padding: 0,

    [theme.breakpoints.down('xs')]: {
      width: '300px',
    },
    '@media (max-height:900px)': {
      height: '60vh',
    },

    '@media (max-height:800px)': {
      height: '65vh',
    },
  },
  welcomeText: {
    color: theme.palette.primary.main,
    margin: theme.spacing(4, 2, 4, 2),
    textAlign: 'center',
    fontSize: '28px',

    [theme.breakpoints.down('xs')]: {
      fontSize: '26px',
      margin: theme.spacing(3, 2, 1, 2),
    },
  },
  logo: {
    width: '160px',
    marginBottom: theme.spacing(2),

    '@media (max-height:900px)': {
      marginBottom: 0,
    },

    [theme.breakpoints.down('xs')]: {
      width: '120px',
    },
  },
}));
