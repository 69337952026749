import { SetStateAction, Dispatch, ReactNode } from 'react';
import clsx from 'clsx';
import i18n from 'translations/i18n';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ReactComponent as Calendar } from 'icons/Calendar.svg';
import { FilterOperands, useFilters } from 'context/providers/FiltersProvider';

type DatePickerProps = {
  value: {
    low: MaterialUiPickersDate;
    high: MaterialUiPickersDate;
  };
  onChangeValue: Dispatch<
    SetStateAction<{
      low: MaterialUiPickersDate;
      high: MaterialUiPickersDate;
    }>
  >;
  operand: FilterOperands;
  className?: string;
  id: string;
};

export const DatePicker = ({
  value,
  onChangeValue,
  operand,
  className,
  id,
}: DatePickerProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setValidationError } = useFilters();

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setValidationError(false);
    if (operand === FilterOperands.LTE) {
      onChangeValue({ ...value, high: date });
    }
    if (operand === FilterOperands.GTE) {
      onChangeValue({ ...value, low: date });
    }
  };

  const getDateOnly = (date: MaterialUiPickersDate) => {
    const stringifiedDate = date!.toString();
    const jsDate = new Date(stringifiedDate);
    return jsDate.setHours(0, 0, 0, 0);
  };

  const disableDate = (date: MaterialUiPickersDate) => {
    if (operand === FilterOperands.LTE && value.low && date) {
      const day = getDateOnly(date);
      const selectedDayLow = getDateOnly(value.low);
      return day < selectedDayLow;
    }
    return false;
  };

  const handleError = (error: string | ReactNode | undefined) => {
    if (error) {
      setValidationError(true);
    }
  };

  const locale = i18n.language;
  const formats = {
    pl: 'DD/MM/YYYY',
    en: 'DD/MM/YYYY',
  };

  return (
    <KeyboardDatePicker
      autoOk
      disableToolbar
      disableFuture
      minDate={
        operand === FilterOperands.LTE && value.low ? value.low : '1900-01-01'
      }
      onError={(error) => handleError(error)}
      shouldDisableDate={(date) => disableDate(date)}
      minDateMessage={t('examinations.invalid-date')}
      maxDateMessage={t('examinations.invalid-date')}
      invalidDateMessage={t('examinations.invalid-date')}
      variant="inline"
      inputVariant="standard"
      label={
        operand === FilterOperands.GTE
          ? t('examinations.date-from')
          : t('examinations.date-to')
      }
      format={locale === 'pl' ? formats.pl : formats.en}
      placeholder={
        locale === 'pl'
          ? formats.pl.toLocaleLowerCase()
          : formats.en.toLowerCase()
      }
      value={operand === FilterOperands.GTE ? value.low : value.high}
      className={clsx(className, classes.textField)}
      InputAdornmentProps={{
        position: 'start',
        className: classes.calendarIcon,
      }}
      onChange={(date) => handleDateChange(date)}
      keyboardIcon={<Calendar />}
      InputProps={{
        classes: { root: classes.dateInput, input: classes.input },
        id,
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: 'focused',
        },
      }}
    />
  );
};

DatePicker.defaultProps = {
  className: undefined,
};

const useStyles = makeStyles((theme) => ({
  dateInput: {
    fontWeight: 400,
    width: '210px',
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    '&.focused:not(.Mui-error)': {
      color: theme.palette.text.primary,
    },
  },
  calendarIcon: {
    position: 'relative',
    left: -10,
  },
  input: {
    position: 'relative',
    left: -18,
  },
  textField: {
    height: '48px',
    maxWidth: '210px',
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '& .MuiInput-underline:not(.Mui-disabled):hover::before': {
      borderBottomColor: theme.palette.primary.main,
    },
  },
}));
