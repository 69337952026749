export const initialSleepScore = {
  ahi: undefined,
  apneaCount: undefined,
  breathScore: undefined,
  hypopneaCount: undefined,
  meanRespiratoryRate: undefined,
  nonSupineAhi: undefined,
  nonSupinePositionDuration: undefined,
  nonSupinePositionRatio: undefined,
  nonSupineSnoringIndex: undefined,
  normalBreathEpisodesCount: undefined,
  respiratoryRateVariability: undefined,
  sleepEfficiency: undefined,
  sleepScore: undefined,
  snoreEpisodesCount: undefined,
  snoringIndex: undefined,
  supineAhi: undefined,
  supinePositionDuration: undefined,
  supinePositionRatio: undefined,
  supineSnoringIndex: undefined,
  totalRegistrationTime: undefined,
  totalSleepTime: undefined,
  totalWakeTime: undefined,
  uprightPositionRatio: undefined,
};

export const initialExamData = {
  sleepScore: initialSleepScore,
  examinationNaturalId: undefined,
  examinationId: undefined,
  examinationFiles: [],
  isExaminationOwner: false,
  startDateTime: undefined,
  endDateTime: undefined,
  examinationStatus: { examinationStatus: undefined },
};
