import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import { AddFirstProfileButton } from 'pages/profiles/addFirstProfile/AddFirstProfileButton';
import { Toast } from 'components/Alert/Toast';

type AddProfileProps = {
  addProfileError: boolean;
  setAddProfileResult: Dispatch<
    SetStateAction<{ resolved: boolean; status: string }>
  >;
};

export const AddFirstProfile = ({
  addProfileError,
  setAddProfileResult,
}: AddProfileProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <AddFirstProfileButton setAddProfileResult={setAddProfileResult} />
      <Typography className={classes.introText}>
        {t('welcome.app-intro')}
      </Typography>
      <Toast
        open={addProfileError}
        setState={setAddProfileResult}
        severity="error"
      >
        {t('profiles.error.add-profile')}
      </Toast>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  introText: {
    margin: theme.spacing('auto', 2, 'auto', 3),
  },
}));
