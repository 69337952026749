import { useRef, useEffect } from 'react';
import { ResetPasswordDialog } from 'pages/auth/resetPassword/ResetPasswordDialog';
import { useAuth } from 'context/providers/AuthProvider';

type ResetPasswordPageProps = {
  match: {
    params: {
      userId: string;
      code: string;
    };
  };
};

const ResetPasswordPage = ({
  match: {
    params: { userId, code },
  },
}: ResetPasswordPageProps) => {
  const { logout, user } = useAuth();

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current && user) {
      firstUpdate.current = false;
      logout();
    }
  });

  return <ResetPasswordDialog open userIdParam={userId} code={code} />;
};

export default ResetPasswordPage;
