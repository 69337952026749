import jwtDecode from 'jwt-decode';
import { JwtToken, User } from 'commonTypes/authTypes';

export const decodeToken = (token: string) => {
  const decodedToken: JwtToken = jwtDecode(token);
  const tokenExpiration = decodedToken.exp;
  if (isTokenValid(tokenExpiration)) {
    const decodedUser: User = JSON.parse(decodedToken.body);
    return { decodedUser };
  }
  return null;
};

const isTokenValid = (tokenExpiration: number) => {
  const today = Date.now();
  const currentDate = new Date(today);
  // timestamp in miliseconds
  const expirationDate = new Date(tokenExpiration * 1000);
  return expirationDate > currentDate;
};

export const updateUserFromToken = (login: (user: User) => void) => {
  const tokenData = decodeToken(localStorage.token);
  if (tokenData) {
    const { decodedUser } = tokenData;
    login(decodedUser);
  }
};
