import clsx from 'clsx';
import { makeStyles, CircularProgress } from '@material-ui/core';

type BigLoaderProps = {
  className?: string;
};

export const BigLoader = ({ className }: BigLoaderProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.root)}>
      <CircularProgress size={60} color="secondary" />
    </div>
  );
};

BigLoader.defaultProps = {
  className: undefined,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}));
