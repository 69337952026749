import { makeStyles, TableRow, TableCell } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const NoResultsRow = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell className={classes.noResultsRow} colSpan={10}>
        {t('no-results')}
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme) => ({
  noResultsRow: {
    backgroundColor: theme.palette.grey[500],
    textAlign: 'center',
    borderRadius: '8px',
  },
}));
