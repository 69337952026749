import { SetStateAction, useState, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core';
import { useAuth } from 'context/providers/AuthProvider';
import { InfoDialog } from 'components/Dialog/InfoDialog';
import { UserConsentsForm } from 'components/Consents/UserConsentsForm';
import {
  processUserAgreements,
  generateInitialUserConsents,
} from 'components/Consents/helpers';
import { SubmitButton } from 'components/Button/SubmitButton';
import { CircularLoader } from 'components/Progress/CircularLoader';
import { setUserConsents } from 'api/consent/consentApi';
import { userConsentsValidationSchema } from 'components/Consents/consentsValidationSchema';
import { updateUserFromToken } from 'utils/decodeJWT';

type ConsentDialogProps = {
  open: boolean;
  setAcceptConsentsResult: Dispatch<
    SetStateAction<{ resolved: boolean; status: string }>
  >;
};

export const UserConsentDialog = ({
  open,
  setAcceptConsentsResult,
}: ConsentDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { login, isDoctor, user } = useAuth();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: generateInitialUserConsents(user, isDoctor),
    onSubmit: (values) => {
      setAcceptConsentsResult({ resolved: false, status: '' });
      setLoading(true);
      const apiValues = processUserAgreements(values, isDoctor);
      setUserConsents(apiValues)
        .then(() => {
          setLoading(false);
          updateUserFromToken(login);
          setAcceptConsentsResult({ resolved: false, status: 'success' });
        })
        .catch(() => {
          setLoading(false);
          setAcceptConsentsResult({ resolved: true, status: 'error' });
        });
    },
    validationSchema: userConsentsValidationSchema(isDoctor),
  });

  return (
    <InfoDialog
      open={open}
      title={t('consents.title')}
      className={classes.dialog}
      id="user-consents-dialog"
    >
      <UserConsentsForm formik={formik} isDoctor={isDoctor} />
      <div className={classes.actions}>
        <SubmitButton
          onClick={formik.handleSubmit}
          disabled={!formik.dirty || !formik.isValid}
          isLoading={loading}
          id="submit-button"
        >
          {loading ? <CircularLoader /> : t('confirm')}
        </SubmitButton>
      </div>
    </InfoDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: '650px',
  },
  actions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
}));
