import { Typography, makeStyles, DialogProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InfoDialog } from 'components/Dialog/InfoDialog';

type EmailSentConfirmationProps = Omit<DialogProps, 'onClose'> & {
  email: string;
  onClose: () => void;
};

export const EmailSentConfirmation = ({
  email,
  open,
  onClose,
}: EmailSentConfirmationProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <InfoDialog open={open} onClose={onClose} closeable>
      <Typography className={classes.heading} variant="h2">
        {t('register-page.check-mailbox')}
      </Typography>
      <Typography className={classes.email}>{email}</Typography>
      <Typography className={classes.message}>
        {t('register-page.email-sent')}
      </Typography>
    </InfoDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  email: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(2, 0, 2, 0),
  },
  message: {
    margin: theme.spacing(2, 0, 2, 0),
    textAlign: 'center',
  },
}));
