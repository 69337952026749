import i18n from 'translations/i18n';

export const doNotHavePesel = '00000000000';

export const maxBlockSize = 10000000; // bytes

export const zipFileShareInExamWithWav = 10; // %
export const zipFileShareInExamWithoutWav = 100; // %

export const acceptedFileExtensions = [
  '.dac',
  '.e8h',
  '.jsn',
  '.pa2',
  '.adr',
  '.wav',
];

// eslint-disable-next-line
export enum AcceptedOptionalFileTypes {
  EDF = 'EDF',
  WAV = 'WAV',
  PSG_APNEAS = 'PSG_APNEAS',
  PSG_SLEEP_PHASES = 'PSG_SLEEP_PHASES',
  PSG_POSITION = 'PSG_POSITION',
  OTHER = 'OTHER',
}

export const optionalFileTypes = [
  {
    fileType: AcceptedOptionalFileTypes.WAV,
    description: 'Wav',
    acceptExtension: '.wav',
  },
  {
    fileType: AcceptedOptionalFileTypes.EDF,
    description: 'Edf',
    acceptExtension: '.edf',
  },
  {
    fileType: AcceptedOptionalFileTypes.PSG_APNEAS,
    description: i18n.t('examinations.apneas-xls'),
    acceptExtension: '.xls, .xlsx',
  },
  {
    fileType: AcceptedOptionalFileTypes.PSG_SLEEP_PHASES,
    description: i18n.t('examinations.sleep-phases-xls'),
    acceptExtension: '.xls, .xlsx',
  },
  {
    fileType: AcceptedOptionalFileTypes.PSG_POSITION,
    description: i18n.t('examinations.position-xls'),
    acceptExtension: '.xls, .xlsx',
  },
];

export const otherFileType = {
  fileType: AcceptedOptionalFileTypes.OTHER,
  description: i18n.t('examinations.other-file'),
  acceptExtension: '*',
};
