import { MouseEvent } from 'react';
import clsx from 'clsx';
import { Button, makeStyles, ButtonProps } from '@material-ui/core';

type ButtonBaseProps = Omit<ButtonProps, 'onClick'> & {
  onClick: (() => void) | ((event: MouseEvent<HTMLButtonElement>) => void);
};

export const ButtonBase = ({
  children,
  className,
  onClick,
  startIcon,
  disabled,
  ...props
}: ButtonBaseProps) => {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      color="primary"
      className={clsx(className, classes.button, {
        [classes.disabledButton]: disabled,
      })}
      onClick={onClick}
      startIcon={startIcon}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1.5, 8, 1.5, 8),
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
  disabledButton: {
    pointerEvents: 'none',
  },
}));
