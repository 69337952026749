import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { IconButton } from 'components/Button/IconButton';
import { ReactComponent as PreviousIcon } from 'icons/Previous.svg';
import { ReactComponent as PreviousIconDisabled } from 'icons/PreviousDisabled.svg';

type PreviousButtonProps = {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  id: string;
};

export const PreviousButton = ({
  className,
  disabled,
  ...props
}: PreviousButtonProps) => {
  const classes = useStyles();

  return (
    <IconButton
      className={clsx(className, classes.button)}
      disabled={disabled}
      {...props}
    >
      {disabled ? <PreviousIconDisabled /> : <PreviousIcon />}
    </IconButton>
  );
};

PreviousButton.defaultProps = {
  className: undefined,
  disabled: false,
};

const useStyles = makeStyles((theme) => ({
  button: {
    height: '46px',
    width: '46px',
  },
}));
