import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfilePanel } from 'pages/profiles/ProfilePanel';
import { Navbar } from 'components/Navbar/Navbar';
import { ScrollToTop } from 'utils/ScrollToTop';
import { ProfilesProvider } from 'context/providers/ProfilesProvider';
import { useAuth } from 'context/providers/AuthProvider';
import { UserConsentDialog } from 'components/Consents/UserConsentDialog';
import { Toast } from 'components/Alert/Toast';

type ChildrenProps = {
  children: ReactNode;
};

export const RoutesWithNav = ({ children }: ChildrenProps) => {
  return (
    <ProfilesProvider>
      <Navbar>
        <ScrollToTop />
        {children}
      </Navbar>
    </ProfilesProvider>
  );
};

export const ActiveProfileWrapper = ({ children }: ChildrenProps) => {
  return (
    <>
      <ProfilePanel />
      {children}
    </>
  );
};

export const ConsentsGuard = ({ children }: ChildrenProps) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [consentsDialogOpen, setConsentsDialogOpen] = useState(false);
  const [acceptConsentsResult, setAcceptConsentsResult] = useState({
    resolved: false,
    status: '',
  });

  useEffect(() => {
    if (!user?.requiredConsents) {
      setConsentsDialogOpen(true);
    } else setConsentsDialogOpen(false);
  }, [user]);

  return (
    <>
      <UserConsentDialog
        open={consentsDialogOpen}
        setAcceptConsentsResult={setAcceptConsentsResult}
      />
      <Toast
        open={acceptConsentsResult.status === 'error'}
        setState={setAcceptConsentsResult}
        severity="error"
      >
        {t('error')}
      </Toast>
      {children}
    </>
  );
};
