import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

type LegendWrapperProps = {
  children: ReactNode;
};

export const LegendWrapper = ({ children }: LegendWrapperProps) => {
  const classes = useStyles();

  return <div className={classes.legendWrapper}>{children}</div>;
};

const useStyles = makeStyles((theme) => ({
  legendWrapper: {
    display: 'flex',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));
